import { useFiltersState, useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { getTags } from 'screens/Rum';
import { DateSelection } from 'types/DateSelection';
import { getRollupByVisualization } from 'utils/rollup';

import { getApplicationFilter } from 'screens/Rum/requests';
import { RuleProps } from '../types';
import { AlertsRumParsedProps } from '../AlertsCreateRUM/type';

const AlertsDetailsPropertiesRum = ({
  date,
  parsedMetadata,
  rule,
}: {
  date: DateSelection;
  parsedMetadata: AlertsRumParsedProps;
  rule: RuleProps;
}): ReactElement => {
  const applicationFilterRequest = useRequest(getApplicationFilter, true, true);

  const { rumSpecificData } = parsedMetadata;
  const filtersState = useFiltersState({
    initialState: rumSpecificData?.filterState?.state,
    shouldWriteToUrl: false,
  });

  useEffect(() => {
    applicationFilterRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tags = useMemo(
    () => {
      return getTags({
        filtersState: filtersState,
        setIdSearch: () => {},
        idSearch: rumSpecificData.idSearch,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );

  const applicationFilterName = useMemo(() => {
    if (!applicationFilterRequest.result) return null;
    return applicationFilterRequest.result.find(
      (application) => application.id === rumSpecificData.applicationFilter,
    )?.name;
  }, [applicationFilterRequest.result, rumSpecificData.applicationFilter]);

  return (
    <div className="alerts__details__properties__summary__item">
      <div className="flex flex-row items-center">
        <span className="text-sm font-bold text-text-secondary">
          Application: &nbsp;
        </span>
        {applicationFilterName}
      </div>
      <div className="flex flex-row items-center pt-2">
        <span className="text-sm font-bold text-text-secondary">
          Event Type: &nbsp;
        </span>
        {rumSpecificData.eventType}
      </div>
      {tags.map((tag, index) => {
        const query = parsedMetadata.queries[index];
        return (
          <div key={index} className="flex flex-col pt-2">
            <div className="mr-2 font-bold text-text-secondary">
              Query ({query.queryKey})
            </div>
            {tags.length > 0 && (
              <div className="flex flex-row items-center">
                <div className="mr-2 text-text-secondary">Filters:</div>
                <div>
                  {tags.map((t) => (
                    <div className="chip" key={index}>
                      {t.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex flex-row items-center">
              <div className="mr-2 text-text-secondary">Limit to:</div>
              <div>{query.limitToValue}</div>, &nbsp;
              <div className="mr-2 text-text-secondary">Sort direction:</div>
              <div>{query.limitTo}</div>, &nbsp;
              <div className="mr-2 text-text-secondary">Rollup:</div>
              <div>
                {query.rollUpInSeconds ||
                  `${getRollupByVisualization(date, 'bar')}s`}
              </div>
              , &nbsp;
              <div className="mr-2 text-text-secondary">Group by:</div>
              {query.groupBys.map((groupBy) => (
                <div className="chip" key={groupBy}>
                  {groupBy === '*'
                    ? 'Everything'
                    : groupBy === 'service_hash'
                      ? 'service'
                      : groupBy}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AlertsDetailsPropertiesRum;
