import {
  ChartLegendTableColumn,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { Filter, useSearch } from 'hooks';
import React, { useMemo, useState } from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ChartGridV2 } from 'components';
import multipleQueryRangeWithLabelsForRUMMobile from 'utils/chartGrid/multipleQueryRangeWithLabelsForRUMMobile';
import { formatLatencyYAxisTick } from 'utils';
import { RumEventType } from '../types';
import rumMobileInstant from '../requests/rumMobileInstant';

type GetRowArgs = {
  applicationFilter: string;
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  setDate: (date: DateSelection) => void;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  filters: Filter[];
  search: ReturnType<typeof useSearch>;
  updateBuildTime: (newBuildTime: number) => void;
  eventType: RumEventType;
};

export const getRows = ({
  applicationFilter,
  date,
  facetRegex,
  setDate,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  filters,
  search,
  updateBuildTime,
  eventType,
}: GetRowArgs) => {
  const aggregateWidgetBuildTime = (
    date: DateSelection,
    aggregateFunction: string,
  ) => {
    return rumMobileInstant({
      eventType,
      applicationFilter,
      endTimeUnix: date.endTimeUnix,
      facetRegex,
      startTimeUnix: date.startTimeUnix,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      aggregateFunction,
      aggregateField: 'view.flutter_build_time',
      staticFilters: [
        { attributeFilter: { eq: { key: 'session.type', value: 'user' } } },
      ],
    }).then((nextResult) => {
      const widgetBuildTime = nextResult?.[0]?.aggregates[0] ?? 0;
      updateBuildTime(widgetBuildTime);
      return widgetBuildTime;
    });
  };

  const onSelection = (newDate: DateSelection) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };

  const rumQueries = (aggregateField: string, aggregateFunction: string) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
      staticFilters: [
        { attributeFilter: { eq: { key: 'session.type', value: 'user' } } },
      ],
    },
    eventType,
    queryType: 'rum-performance',
  });

  return [
    [
      {
        charts: [
          {
            key: 'widgetBuildTimeP99',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Widget Build Time P99',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('view.flutter_build_time', 'percentile99')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.flutter_build_time',
                  aggregateFunction: 'percentile99',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p99']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateWidgetBuildTime(date, 'percentile99');
            },
            enableCreateAlert: true,
          },
          {
            key: 'widgetBuildTimeP95',
            colorMap: {
              p95: '#2BAF7F',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Widget Build Time P95',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('view.flutter_build_time', 'percentile95')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.flutter_build_time',
                  aggregateFunction: 'percentile95',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p95']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateWidgetBuildTime(date, 'percentile95');
            },
            enableCreateAlert: true,
          },
          {
            key: 'widgetBuildTimeP90',
            colorMap: {
              p90: '#6F53B7',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Widget Build Time P90',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('view.flutter_build_time', 'percentile90')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.flutter_build_time',
                  aggregateFunction: 'percentile90',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p90']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateWidgetBuildTime(date, 'percentile90');
            },
            enableCreateAlert: true,
          },
          {
            key: 'widgetBuildTimeP75',
            colorMap: {
              p75: '#cd863c',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Widget Build Time P75',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('view.flutter_build_time', 'percentile75')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.flutter_build_time',
                  aggregateFunction: 'percentile75',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p75']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateWidgetBuildTime(date, 'percentile75');
            },
            enableCreateAlert: true,
          },
          {
            key: 'widgetBuildTimeP50',
            colorMap: {
              p50: '#A8D08D',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Widget Build Time P50',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('view.flutter_build_time', 'percentile50')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.flutter_build_time',
                  aggregateFunction: 'percentile50',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p50']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateWidgetBuildTime(date, 'percentile50');
            },
            enableCreateAlert: true,
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RUMWidgetBuildTime = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    dateState,
    filtersState,
  } = rumState;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const [date, setDate] = dateState;
  const search = useSearch();
  const [widgetBuildTime, setWidgetBuildTime] = useState<number>(0);

  const updateBuildTime = (newBuildTime: number) => {
    setWidgetBuildTime(newBuildTime);
  };

  const rows = useMemo(
    () =>
      getRows({
        applicationFilter,
        date,
        facetRegex: facetRegexState.state,
        setDate,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        filters: filtersState.state,
        search,
        updateBuildTime,
        eventType: RumEventType.VIEW,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicationFilter,
      date,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <div className="rum__main overflow-auto">
      <div className="rum__performance-charts">
        <ChartGridV2.ChartGrid date={date} rows={rows} eventType="VIEW" />
        {widgetBuildTime !== null && widgetBuildTime !== undefined && (
          <div className="rum__performance-charts-overlay">
            <div className="rum__performance-charts-overlay-text">
              {widgetBuildTime}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RUMWidgetBuildTime;
