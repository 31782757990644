import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRequest } from 'hooks';
import { getGrafanaAlertsRulesByFolder, getGrafanaFolders } from 'requests';
import { formatGrafanaAlertsRules } from 'screens/NewAlerts/utils';
import { DateSelection, GrafanaFolderProps } from 'types';

import ServiceAdvanceFunctions from './ServiceAdvanceFunctions';
import ServiceAlertsDetailsSidebar from './ServiceAlertsDetailsSidebar';
import ServiceAlertsStatusAPM from './ServiceAlertsStatusAPM';
import { apmErrors } from 'utils/error/apmErrors';
import ErrorMessage from 'components/ErrorMessage';

const ServiceAlertsStatus = ({
  asmMatcher,
  date,
  kfSource,
  serviceName,
  serviceDistinctLabels,
  serviceHash,
  serviceLabels,
}: {
  asmMatcher: string;
  date: DateSelection;
  kfSource?: string;
  serviceName: string;
  serviceDistinctLabels: Record<string, string>;
  serviceHash: string;
  serviceLabels: Record<string, string>;
}): ReactElement => {
  const [error, setError] = useState({
    getGrafanaAlertsRulesByFolder: null,
    getGrafanaFolders: null,
  });
  const [activeSmartAlert, setActiveSmartAlert] = useState(null);
  const requestGrafanaAlertsRules = useRequest(
    (args) =>
      getGrafanaAlertsRulesByFolder(args).then((data) =>
        formatGrafanaAlertsRules(data, []),
      ),
    true,
    true,
  );
  const ALERT_FOLDER_NAME = `${serviceName}_${serviceHash}`;

  const grafanaFoldersRequest = useRequest(getGrafanaFolders, true, true);

  const getGraphanaAlertsRules = async () => {
    let folderList = [];
    if (grafanaFoldersRequest.result) {
      folderList = grafanaFoldersRequest.result;
    } else {
      grafanaFoldersRequest
        .call()
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({
              ...prevError,
              getGrafanaFolders: null,
            }));

            folderList = nextResult;
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getGrafanaFolders: { message: apmErrors.getGrafanaFolders },
          }));
        });
    }
    const folder = folderList.find(
      (item: GrafanaFolderProps) => item.title === ALERT_FOLDER_NAME,
    );

    if (folder) {
      requestGrafanaAlertsRules
        .call({
          folderName: ALERT_FOLDER_NAME,
          folderUid: folder.uid,
        })
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({
              ...prevError,
              getGrafanaAlertsRulesByFolder: null,
            }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getGrafanaAlertsRulesByFolder: {
              message: apmErrors.getGrafanaAlertsRulesByFolder,
            },
          }));
        });
    }
  };

  useEffect(() => {
    getGraphanaAlertsRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const isDockerService = useMemo(() => {
    return serviceDistinctLabels?.['kf_platform'] === 'docker';
  }, [serviceDistinctLabels]);

  const isKFSourceKnight = useMemo(() => {
    return kfSource === 'knight';
  }, [kfSource]);

  return (
    <div className="flex items-center">
      {/*Temporarily hiding SLO feature
        https://github.com/kloudfuse/ui/issues/2360
      <ServiceSLOInfo
        isServiceUnique={isServiceUnique}
        kfSource={kfSource}
        serviceName={serviceName}
      /> */}

      <ErrorMessage
        message={error?.getGrafanaAlertsRulesByFolder?.message}
        className="justify-end pr-[14px] pl-[14px]"
      />

      <ErrorMessage
        message={error?.getGrafanaFolders?.message}
        className="justify-end pr-[14px] pl-[14px]"
      />

      {!isDockerService && (
        <ServiceAdvanceFunctions
          asmMatcher={asmMatcher}
          date={date}
          kfSource={kfSource}
          serviceName={serviceName}
          setActiveSmartAlert={setActiveSmartAlert}
          grafanaFoldersRequest={grafanaFoldersRequest}
          getGraphanaAlertsRules={getGraphanaAlertsRules}
        />
      )}
      {!isKFSourceKnight && (
        <ServiceAlertsStatusAPM
          serviceName={serviceName}
          serviceDistinctLabels={serviceDistinctLabels}
          serviceHash={serviceHash}
          serviceLabels={serviceLabels}
          setActiveSmartAlert={setActiveSmartAlert}
          requestGrafanaAlertsRules={requestGrafanaAlertsRules}
        />
      )}
      {activeSmartAlert && (
        <ServiceAlertsDetailsSidebar
          asmMatcher={asmMatcher}
          close={() => setActiveSmartAlert(null)}
          rule={activeSmartAlert}
          title={`${activeSmartAlert.name}`}
          kfSource={kfSource}
          serviceHash={serviceHash}
        />
      )}
    </div>
  );
};

export default ServiceAlertsStatus;
