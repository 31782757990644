import { ToastProps } from 'components/Toasts/types';
import { getLogMetricsResultWithKfuseQl } from 'requests';
import { DateSelection, QueryDataPropsRange, QueryLangType } from 'types';
import {
  DataFrame,
  DataFrameMeta,
  DataTransformerConfig,
  logsForecastDataTransformer,
} from 'utils/DataTransformer';
import { getForecastLookbackAndPredictDate } from 'utils/MetricsQueryBuilder';

const FORECAST_MIN_TIME_RANGE = 3600;

const kfuseqlForecastLoader = async ({
  addToast,
  date,
  kfuseQl,
  meta,
  transformer,
}: {
  addToast: (toast: ToastProps) => void;
  date: DateSelection;
  kfuseQl: string;
  meta: DataFrameMeta;
  transformer: DataTransformerConfig[];
}): Promise<QueryDataPropsRange[]> => {
  const seconds = date.endTimeUnix - date.startTimeUnix;
  const { startTimeUnix, endTimeUnix } = date;
  const diffInSeconds = endTimeUnix - startTimeUnix;
  if (diffInSeconds < FORECAST_MIN_TIME_RANGE) {
    addToast({
      text: 'By default, minimum 1 hour time range is set for forecasting',
      status: 'info',
    });
  }
  const dateForForecast = getForecastLookbackAndPredictDate({
    forecastDuration: `${seconds}s`,
    lookbackDuration: `${seconds}s`,
    date,
    type: 'logs',
  });
  const { lookbackDate, predictedDate, step } = dateForForecast;
  const forecastStep = meta.customStep || step;

  transformer.pop();
  transformer.push({
    id: 'logsLinearforecastDataTransformer',
    func: (dataFrame: DataFrame) =>
      logsForecastDataTransformer({
        dataFrame,
        forecastStartTimeUnix: lookbackDate.endTimeUnix,
      }),
  });

  return new Promise((resolve, reject) => {
    getLogMetricsResultWithKfuseQl({
      date: lookbackDate,
      kfuseQl,
      meta: { ...meta, step: forecastStep },
      transformer,
      queryLangType: QueryLangType.KFUSEQL,
    })
      .then(resolve)
      .catch(reject);
  });
};

export default kfuseqlForecastLoader;
