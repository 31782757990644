import classNames from 'classnames';
import { AutocompleteV2, Button, Input, TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { MetricLabelOperatorOptions } from 'utils';

import { LabelValueChipProps } from './types';
import { IoMdClose } from 'react-icons/io';
import AutocompleteV2Creatable from 'components/Autocomplete/AutocompleteV2Creatable';

const LabelValueChip = ({
  errors,
  isLabelLoading,
  isValueLoading,
  label,
  labelOptions,
  onLabelChange,
  onLabelClick,
  onLabelValueChipDelete,
  operator,
  operatorOptions = MetricLabelOperatorOptions,
  onOperatorChange,
  value,
  onValueChange,
  valueOptions,
}: LabelValueChipProps): ReactElement => {
  return (
    <>
      <AutocompleteV2Creatable
        className={classNames({
          'autocomplete-container--no-border': true,
          'autocomplete__fixed-height-30': true,
          'button-group__item__autocomplete--value': true,
          'autocomplete-container--fit-content': true,
          'metrics__query-builder__series__builder__placeholder--highlighted':
            !!label,
          'metrics__query-builder__series__builder--error': errors?.label,
          'pl-1': true,
        })}
        isClearable={false}
        isLoading={isLabelLoading}
        isSearchable={true}
        onChange={onLabelChange}
        onCreateOption={(inputValue) => {
          onLabelChange(inputValue, true);
        }}
        onMenuOpen={onLabelClick}
        options={labelOptions || []}
        placeholder={label ? label : undefined}
        value={label}
        dataTestId="label"
      />
      <AutocompleteV2
        className={classNames({
          'autocomplete-container--no-border': true,
          'autocomplete__fixed-height-30': true,
          'button-group__item__autocomplete--value': true,
          'metrics__query-builder__series__builder--operator': true,
          'px-1': true,
        })}
        isClearable={false}
        isSearchable={false}
        onChange={onOperatorChange}
        options={operatorOptions}
        value={operator}
        dataTestId="operator"
      />
      {operator === '=~' || operator === '!~' ? (
        <Input
          autoFocus
          className="input--no-border metrics__query-builder__series__builder--input"
          onChange={onValueChange}
          type="text"
          value={value as string}
          size="2"
          data-testid="value"
        />
      ) : (
        <AutocompleteV2Creatable
          className={classNames({
            'autocomplete-container--no-border': true,
            'autocomplete__fixed-height-30': true,
            'button-group__item__autocomplete--value': true,
            'pl-1 w-full': true,
            'metrics__query-builder__series__builder--error': errors?.value,
            'metrics__query-builder__series__builder__placeholder--highlighted':
              !!value,
          })}
          isClearable={false}
          isLoading={isValueLoading}
          onChange={onValueChange}
          options={valueOptions || []}
          placeholder={value ? (value as string) : undefined}
          value={value as string}
          dataTestId="value"
        />
      )}
      <TooltipTrigger tooltip="Remove Label">
        <Button
          className="h-full metrics__function-builder__item__close"
          size="sm"
          variant="ghost"
        >
          <IoMdClose onClick={onLabelValueChipDelete} size={18} />
        </Button>
      </TooltipTrigger>
    </>
  );
};

export default LabelValueChip;
