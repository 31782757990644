import { useToaster } from 'components';
import { getFiltersStateFromArrayStateItem } from 'hooks/useFiltersState';
import { useState } from 'react';
import {
  getLogMetricsTimeSeriesLogQLV2,
  getLogMetricsResultWithKfuseQl,
} from 'requests';
import {
  calcAgileRobustAnomalyTimeRange,
  convertSecondToReadable,
  DataFrame,
  DataFrameMeta,
  filterLogqlFormulaMatchingLabels,
  getLogQLWithMetaToLoad,
  getRollupByVisualization,
  getRollupToSecond,
  getUsedQueriesLabelForFormula,
  kfuseqlDataTransformer,
  kfuseqlDataTransformerInstant,
  logsAnomalyDataTransformer,
  logsDataTransformer,
  kfuseqlForecastLoader,
  sortAndLimitTimeseries,
} from 'utils';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';
import {
  DashboardPanelType,
  DateSelection,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  LogQLWithMetaProps,
  QueryDataProps,
  QueryLangType,
  QueryDataPropsRange,
} from 'types';

const useLogsMetricsQueryData = ({
  chartWidth,
  customerFilter,
  date,
  dataFormat,
  isRange,
  minStep,
  queryLangType,
  setQueries,
  userActionRef,
}: {
  chartWidth?: number;
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  dataFormat?: DashboardPanelType;
  isRange: boolean;
  minStep: string;
  queryLangType: string;
  setQueries: (queries: LogsMetricQueryProps[]) => void;
  userActionRef: React.MutableRefObject<{
    ignoreDateChangeReload: boolean;
    ignoreInitialLoading: boolean;
  }>;
}) => {
  const { addToast } = useToaster();
  const [logsChartData, setLogsChartData] = useState<QueryDataProps>({});
  const rangeKey = isRange ? 'range' : 'instant';

  const handleLoading = (
    logQLWithMeta: LogQLWithMetaProps[],
    isLoading: boolean,
  ) => {
    setLogsChartData((prev) => {
      const newState = { ...prev };
      logQLWithMeta.forEach(({ queryType, meta }) => {
        const queryId = `${queryType}_${meta.refId}`;
        newState[queryId] = { [rangeKey]: null, isLoading, meta };
      });
      return newState;
    });
  };

  const handleError = (logQLWithMeta: LogQLWithMetaProps, error: string) => {
    setLogsChartData((prev) => {
      const newState = { ...prev };
      const { queryType, meta } = logQLWithMeta;
      const queryId = `${queryType}_${meta.refId}`;
      newState[queryId] = { [rangeKey]: null, isLoading: false, meta, error };
      return newState;
    });
  };

  const loadMultipleLogsChartData = async ({
    formulas,
    formulaOnly = false,
    queries,
    chartWidth,
  }: {
    formulas: LogsMetricForumlaProps[];
    formulaOnly?: boolean;
    queries: LogsMetricQueryProps[];
    chartWidth: number;
  }) => {
    const logQLWithMeta = getLogQLWithMetaToLoad({
      customerFilter,
      dataFormat,
      date,
      formulas,
      formulaOnly,
      queries,
      instant: !isRange,
      queryLangType,
    });

    try {
      handleLoading(logQLWithMeta, true);
      const tempLogsChartData = { ...logsChartData };
      const tempLabelKeys: { [key: string]: string[] } = {};

      for (let idx = 0; idx < logQLWithMeta.length; idx++) {
        const logQL = logQLWithMeta[idx];
        const { meta, queryType } = logQL;
        const { isAnomaly, isForecast, isOutlier } = meta;
        const isAdvanceFunc = isAnomaly || isForecast || isOutlier;
        const transformer = logsDataTransformer(false);
        if (logQL.limit && !isAdvanceFunc) {
          transformer.splice(1, 0, {
            id: 'sortAndLimitTimeseries',
            func: (dataFrame: DataFrame) =>
              sortAndLimitTimeseries(dataFrame, logQL.limit),
          });
        }

        if (logQL.queryType === 'formula') {
          const labelBitmap = getUsedQueriesLabelForFormula({
            formulaExpression: logQL.meta.formulaExpression,
            tempLogsChartData,
            tempLabelKeys,
          });
          if (labelBitmap) {
            transformer.splice(2, 0, {
              id: 'filterLogqlFormulaMatchingLabels',
              func: (dataFrame: DataFrame) =>
                filterLogqlFormulaMatchingLabels({
                  dataFrame,
                  labelBitmap,
                }),
            });
          }
        }

        const loadAnomalyData = async () => {
          transformer.pop();
          transformer.push({
            id: 'logsAnomalyDataTransformer',
            func: logsAnomalyDataTransformer,
          });

          const { agileRobustAnomalyDate, anomalyStep } =
            calcAgileRobustAnomalyTimeRange(
              logQL.meta.anomalySeasonality,
              date,
            );

          return await getLogMetricsResultWithKfuseQl({
            date: logQL.meta.anomalySeasonality ? agileRobustAnomalyDate : date,
            kfuseQl: logQL.logql,
            meta: logQL.meta.anomalySeasonality
              ? { ...logQL.meta, step: anomalyStep }
              : logQL.meta,
            transformer,
            queryLangType,
          }).catch((e) => handleError(logQL, e));
        };

        let data = undefined;

        if (
          queryLangType === QueryLangType.KFUSEQL ||
          queryLangType === QueryLangType.ADVANCED_KFUSEQL_SEARCH
        ) {
          transformer[0] = {
            id: 'kfuseqlDataTransformer',
            func: kfuseqlDataTransformer,
          };

          if (logQL.meta.isAnomaly) {
            data = await loadAnomalyData();
          } else if (logQL.meta.isForecast) {
            data = await kfuseqlForecastLoader({
              addToast,
              date,
              kfuseQl: logQL.logql,
              meta: logQL.meta,
              transformer,
            }).catch((e) => handleError(logQL, e));
          } else {
            data = await getLogMetricsResultWithKfuseQl({
              date,
              kfuseQl: logQL.logql,
              meta: logQL.meta,
              transformer,
              queryLangType,
            }).catch((e) => handleError(logQL, e));
          }
        } else {
          data = await getLogMetricsTimeSeriesLogQLV2({
            date,
            logQL: logQL.logql,
            meta: logQL.meta,
            transformer,
            width: chartWidth,
          }).catch((e) => handleError(logQL, e));
        }

        if (!data) return;
        const queryId = `${queryType}_${meta.refId}`;
        tempLogsChartData[queryId] = {
          [rangeKey]: data,
          isLoading: false,
          meta,
        };
        tempLabelKeys[queryId] = logQL.meta.labels;
        updateLogsChartData({ queryId, data, meta });
      }
    } catch (e) {}
  };

  const updateLogsChartData = ({
    queryId,
    data,
    meta,
  }: {
    queryId: string;
    data: QueryDataPropsRange | QueryDataPropsRange[];
    meta: DataFrameMeta;
  }) => {
    const { isAnomaly, isForecast, forecastSeasonality } = meta;
    setLogsChartData((prev) => {
      const newState = { ...prev };
      const deleteKeys = ['_upper', '_lower', ''];
      deleteKeys.forEach((key) => {
        delete newState[`${queryId}_anomaly${key}`];
        delete newState[`${queryId}_forecast${key}`];
      });
      delete newState[queryId];

      if (isAnomaly && Array.isArray(data) && data.length === 3) {
        const anomalyQueryId = `${queryId}_anomaly`;
        ['_upper', '_lower', ''].forEach((key, index) => {
          newState[`${anomalyQueryId}${key}`] = {
            [rangeKey]: data[index],
            isLoading: false,
            meta,
          };
        });
      } else if (isForecast && Array.isArray(data)) {
        const forecastQueryId = `${queryId}_forecast`;
        const queryKeys = forecastSeasonality ? ['_upper', '_lower', ''] : [''];
        queryKeys.forEach((key, index) => {
          newState[`${forecastQueryId}${key}`] = {
            [rangeKey]: data[index],
            isLoading: false,
            meta,
          };
        });
      } else {
        newState[queryId] = { [rangeKey]: data, isLoading: false, meta };
      }

      return newState;
    });
  };

  const loadInstantMultipleLogsChartData = async ({
    formulas,
    formulaOnly,
    queries,
    chartWidth,
  }: {
    formulas: LogsMetricForumlaProps[];
    formulaOnly?: boolean;
    queries: LogsMetricQueryProps[];
    chartWidth: number;
  }) => {
    const logQLWithMeta = getLogQLWithMetaToLoad({
      chartWidth,
      customerFilter,
      dataFormat,
      date,
      formulas,
      formulaOnly,
      queries,
      instant: true,
      queryLangType,
    });

    handleLoading(logQLWithMeta, true);
    const tempLogsChartData = { ...logsChartData };
    const tempLabelKeys: { [key: string]: string[] } = {};

    for (let idx = 0; idx < logQLWithMeta.length; idx++) {
      const logQL = logQLWithMeta[idx];
      const transformer = logsDataTransformer(true);
      if (logQL.limit) {
        transformer.splice(2, 0, {
          id: 'sortAndLimitTimeseries',
          func: (dataFrame: DataFrame) =>
            sortAndLimitTimeseries(dataFrame, logQL.limit),
        });
      }

      if (logQL.queryType === 'formula') {
        const labelBitmap = getUsedQueriesLabelForFormula({
          formulaExpression: logQL.meta.formulaExpression,
          tempLogsChartData,
          tempLabelKeys,
        });

        if (labelBitmap) {
          transformer.push({
            id: 'filterLogqlFormulaMatchingLabels',
            func: (dataFrame: DataFrame) =>
              filterLogqlFormulaMatchingLabels({
                dataFrame,
                labelBitmap,
              }),
          });
        }
      }

      let dataset = undefined;
      if (
        queryLangType === QueryLangType.KFUSEQL ||
        queryLangType === QueryLangType.ADVANCED_KFUSEQL_SEARCH
      ) {
        transformer[0] = {
          id: 'kfuseqlDataTransformer',
          func: kfuseqlDataTransformerInstant,
        };
        dataset = await getLogMetricsResultWithKfuseQl({
          date,
          instant: true,
          kfuseQl: logQL.logql,
          meta: logQL.meta,
          queryLangType,
          transformer,
        }).catch((e) => handleError(logQL, e));
      } else {
        dataset = await getLogMetricsTimeSeriesLogQLV2({
          date,
          instant: true,
          logQL: logQL.logql,
          meta: logQL.meta,
          transformer,
          width: chartWidth,
        }).catch((e) => handleError(logQL, e));
      }

      if (!dataset) return;
      const { meta, queryType } = logQL;
      const queryId = `${queryType}_${meta.refId}`;
      tempLabelKeys[queryId] = logQL.meta.labels;
      tempLogsChartData[queryId] = { instant: dataset, isLoading: false, meta };

      setLogsChartData((prev) => {
        const newState = { ...prev };
        newState[queryId] = { instant: dataset, isLoading: false, meta };
        return newState;
      });
    }
  };

  const getQueriesWithFilterState = (queries: LogsMetricQueryProps[]) => {
    return queries.map((query, i) => ({
      ...query,
      filtersState: getFiltersStateFromArrayStateItem({
        key: 'filters',
        index: i,
        setState: setQueries,
        state: queries,
      }),
    }));
  };

  const loadLogqlQuery = async (query: LogsMetricQueryProps) => {
    const queriesWithFilterState = getQueriesWithFilterState([query]);
    if (isRange) {
      loadMultipleLogsChartData({
        formulas: [],
        queries: queriesWithFilterState,
        chartWidth,
      });
    } else {
      loadInstantMultipleLogsChartData({
        formulas: [],
        queries: queriesWithFilterState,
        chartWidth,
      });
    }
  };

  const loadFuseQlQuery = async (query: LogsMetricQueryProps) => {
    const queriesWithFilterState = getQueriesWithFilterState([query]);
    if (isRange) {
      loadMultipleLogsChartData({
        formulas: [],
        queries: queriesWithFilterState,
        chartWidth,
      });
    } else {
      loadInstantMultipleLogsChartData({
        formulas: [],
        queries: queriesWithFilterState,
        chartWidth,
      });
    }
  };

  const getUpdatedFunctionWindowStep = (
    functions: LogsMetricQueryProps['functions'],
    step: string,
  ) => {
    return functions.map((func) => {
      if (func.name === KfuseQlFunction.ANOMALIES) {
        return {
          ...func,
          params: func.params.map((param) => {
            if (param.name === 'window') {
              return { ...param, value: step };
            }
            return param;
          }),
        };
      }
      return func;
    });
  };

  const handleLoadingLogsChartOnDataChange = ({
    date,
    formulaOnly,
    formulas,
    queries,
  }: {
    date: DateSelection;
    formulaOnly?: boolean;
    formulas: LogsMetricForumlaProps[];
    queries: LogsMetricQueryProps[];
  }) => {
    const step = getRollupByVisualization(date, 'bar');
    const minStepSeconds = getRollupToSecond(minStep);
    const stepSeconds = Math.max(minStepSeconds, step);
    setQueries(() => {
      const newQueries = [...queries];
      newQueries.forEach((query) => {
        const queryStep = convertSecondToReadable(stepSeconds);
        query.step = queryStep;
        if (query.functions && query.functions.length > 0) {
          const updatedFunc = getUpdatedFunctionWindowStep(
            query.functions,
            queryStep,
          );
          if (updatedFunc) {
            query.functions = updatedFunc;
          }
        }
      });

      if (userActionRef.current.ignoreDateChangeReload) {
        userActionRef.current.ignoreDateChangeReload = false;
        return newQueries;
      }

      userActionRef.current.ignoreInitialLoading = false;

      if (isRange) {
        loadMultipleLogsChartData({
          formulas,
          formulaOnly,
          queries: newQueries,
          chartWidth,
        });
      } else {
        loadInstantMultipleLogsChartData({
          formulas,
          queries: newQueries,
          chartWidth,
        });
      }
      return newQueries;
    });
  };

  return {
    handleLoadingLogsChartOnDataChange,
    loadMultipleLogsChartData,
    loadInstantMultipleLogsChartData,
    logsChartData,
    loadLogqlQuery,
    loadFuseQlQuery,
    setLogsChartData,
  };
};

export default useLogsMetricsQueryData;
