import React, { ReactElement } from 'react';
import { Loader, TopList } from 'components';
import useRumUserSessionByVersion from '../hooks/useRumUserSessionByVersion';
import { RumEventType } from '../types';
import { useSearch } from 'hooks';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { TooltipTrigger, useModalsContext } from 'components';
import { TimeseriesExplorer } from 'components';
import { LimitTo } from 'types/useSearch';
import { MdNotificationAdd } from 'react-icons/md';
import { onCreateAlertRUM } from 'screens/Rum/utils/createAlertRum';

const RUMUserSessionByVersion = ({ rumPageState }: Props): ReactElement => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    dateState,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    filtersState,
  } = rumState;

  const [date] = dateState;
  const filters = filtersState.state;
  const search = useSearch();

  const rumTopListQueries = (
    aggregateField: string,
    aggregateFunction: string,
  ) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
      advancedGroupBys: [
        {
          by: 'version',
          limitToValue: 10,
          limitTo: LimitTo.top,
        },
      ],
      eventType: RumEventType.VIEW,
      queryType: 'rum-performance',
    },
  });

  const rumEventsRequest = useRumUserSessionByVersion({
    applicationFilter,
    date,
    facetRegexState,
    eventType: RumEventType.VIEW,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    chart: {
      rumTopListQueries: [rumTopListQueries('version', 'count')],
      eventType: RumEventType.VIEW,
      enableCreateAlert: true,
    },
    aggregateField: 'session.id',
    aggregateFunction: 'distinctcount',
    groupBy: [
      {
        by: 'version',
        limitToValue: 10,
        sortAggregation: 'count',
        sortField: '*',
        limitTo: LimitTo.top,
      },
    ],
  });

  const data = rumEventsRequest?.result || [];
  const transformData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((series) => {
      const count = parseFloat(series?.aggregates?.[0]) || 0;
      const version = series?.dimensions?.version;

      return {
        label: version,
        count: count,
        evaluatedValue: count.toString(),
      };
    });
  };

  const transformedData = transformData(data).sort((a, b) => b.count - a.count);
  const modal = useModalsContext();
  const parentWidth = window.innerWidth * 0.8;
  const onExploreClick = async () => {
    modal.push(
      <TimeseriesExplorer
        activeQueries={[rumTopListQueries('session.id', 'distinctcount')]}
        chartData={null}
        date={date}
        queryType="rum-performance"
        onClose={modal.pop}
        unit=""
        eventType={RumEventType.VIEW}
      />,
    );
  };
  const chartQueries = [rumTopListQueries('session.id', 'distinctcount').query];
  const onClickCreateAlertRUM = () => {
    onCreateAlertRUM({
      date,
      queryItem: { queries: chartQueries },
      aggregateField: 'session.id',
      aggregateFunction: 'distinctcount',
    });
  };

  return (
    <div className="rum__main overflow-auto">
      <div className="mb-4 ml-2 flex items-center justify-between p-1">
        <div className="text-[11px] font-medium">
          Total User Sessions by Version
        </div>
        <div>
          <TooltipTrigger tooltip="Create Alert">
            <div
              className="chart-grid__item__header__right__explorer"
              onClick={onClickCreateAlertRUM}
            >
              <MdNotificationAdd size={14} />
            </div>
          </TooltipTrigger>
          <TooltipTrigger tooltip="Explore Metric">
            <div
              className="chart-grid__item__header__right__explorer cursor-pointer"
              onClick={onExploreClick}
            >
              <BsArrowsFullscreen size={12} />
            </div>
          </TooltipTrigger>
        </div>
      </div>

      <Loader isLoading={rumEventsRequest.isLoading}>
        <div className="overflow-hidden p-2">
          {data.length > 0 ? (
            <div>
              <TopList
                data={transformedData}
                height={250}
                width={parentWidth * 0.8}
              />
            </div>
          ) : (
            <div className="text-center text-gray-500">No data available</div>
          )}
        </div>
      </Loader>
    </div>
  );
};

export default RUMUserSessionByVersion;
