import {
  ChartToolbar,
  SizeObserver,
  TimeseriesRenderer,
  TimeseriesExplorer,
  useModalsContext,
  useThemeContext,
  DashboardExport,
  useToaster,
} from 'components';
import { mapChartTypeWithDashboard } from 'components/AnalyticsChart';
import React, { ReactElement, useMemo } from 'react';
import {
  DashboardPanelType,
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
  LegendTypes,
  QueryDataProps,
  UplotExtended,
} from 'types';
import {
  combineRangeQueryData,
  getDefaultAnalyticsChartTypes,
  getMetricsExportPanelTargets,
  getPromqlQueryByIndex,
} from 'utils';
import { setDateRangeOnChartZoom } from 'utils';

import MetricsChartsRightToolbar from './MetricsChartsRightToolbar';
import { hasMetricsChartAdvanceVisualization, onCreateAlert } from './utils';

const MetricsChartsCombined = ({
  date,
  setDate,
  formulas,
  queries,
  queryData,
}: {
  date: DateSelection;
  setDate: (date: DateSelection) => void;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  queryData: QueryDataProps;
}): ReactElement => {
  const { addToast } = useToaster();
  const { darkModeEnabled } = useThemeContext();
  const chartModal = useModalsContext();
  const combinedData = useMemo(
    () =>
      combineRangeQueryData({
        formulas,
        queries,
        queryData,
        darkModeEnabled,
        combineTimestamp: true,
        combineLabelWithQueryKey: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryData, darkModeEnabled],
  );

  const hasAdvanceVisualization = useMemo(() => {
    return hasMetricsChartAdvanceVisualization({
      queries,
      queryData,
    });
  }, [queries, queryData]);

  const onViewFullscreen = (activeChart: string, unit: string) => {
    const allPromqlQueries = [];

    queries.forEach(({ metric, steps, isActive }, index) => {
      if (!isActive) return;
      const promql = getPromqlQueryByIndex(
        {
          formulas,
          queries,
          queryIndex: index,
          type: 'query',
          returnType: 'array',
        },
        date,
      );
      allPromqlQueries.push({ promql, metricName: metric, steps });
    });

    formulas.forEach(({ isActive }, index) => {
      if (!isActive) return;
      const promql = getPromqlQueryByIndex(
        { formulas, queries, queryIndex: index, type: 'formula' },
        date,
      );
      allPromqlQueries.push({ promql, metricName: promql });
    });

    chartModal.push(
      <TimeseriesExplorer
        activeQueries={allPromqlQueries}
        activeChartType={activeChart}
        chartData={combinedData}
        hasAdvanceFunction={hasAdvanceVisualization}
        date={date}
        onClose={() => chartModal.pop()}
        queryType="promql"
        unit={unit}
      />,
    );
  };

  const onCreateAlertCombined = () => {
    onCreateAlert(date, {
      formulas,
      queries,
      queryIndex: 0,
      type: 'query',
      queryKey: queries[0].queryKey,
    });
  };

  const onExportClick = (activeChart: string) => {
    const drawStyle = mapChartTypeWithDashboard[activeChart];
    const { targets, mappings } = getMetricsExportPanelTargets({
      queries,
      formulas,
      date,
    });
    if (targets.length === 0) {
      addToast({ text: 'No metrics selected to export', status: 'error' });
      return;
    }
    chartModal.push(
      <DashboardExport
        closeModal={chartModal.pop}
        date={date}
        datasourceType="prometheus"
        drawStyle={drawStyle}
        mappings={mappings}
        panel={{
          targets,
          type: DashboardPanelType.TIMESERIES,
        }}
      />,
    );
  };

  const defaultChartType = useMemo(() => {
    const dataPointLength = combinedData?.data[1]?.filter(Boolean)?.length;
    return getDefaultAnalyticsChartTypes({
      hasAdvanceVisualization,
      dataPointLength,
    });
  }, [combinedData?.data, hasAdvanceVisualization]);

  const hooks = useMemo(() => {
    return [
      ...(combinedData.hooks || []),
      {
        hook: (u: UplotExtended) => setDateRangeOnChartZoom(u, setDate),
        type: 'setSelect',
      },
    ];
  }, [combinedData.hooks, setDate]);

  const queryAndFormulaLength = useMemo(() => {
    return formulas.length + queries.length;
  }, [formulas, queries]);

  return (
    <SizeObserver>
      {({ width: chartWidth }) => (
        <TimeseriesRenderer
          bands={combinedData.bands || []}
          chartData={combinedData}
          chartTypes={defaultChartType}
          date={date}
          hooks={hooks}
          isLoading={combinedData.isLoading}
          legend={{
            legendType: LegendTypes.COMPACT_ONE_LINE,
            legendHeight: 150,
          }}
          renderToolbar={({
            activeChart,
            activeStroke,
            setActiveChart,
            setActiveStroke,
            unit,
          }) => (
            <ChartToolbar
              chartTypes={defaultChartType}
              activeChart={activeChart}
              activeStroke={activeStroke}
              setActiveChart={setActiveChart}
              setActiveStroke={setActiveStroke}
              toolbar={{
                rightToolbar: (
                  <MetricsChartsRightToolbar
                    onCreateAlert={
                      queryAndFormulaLength === 1
                        ? onCreateAlertCombined
                        : undefined
                    }
                    onViewFullscreen={() => onViewFullscreen(activeChart, unit)}
                    onExportClick={() => onExportClick(activeChart)}
                    queryAndFormulaLength={queryAndFormulaLength}
                    hasAdvanceFunction={hasAdvanceVisualization}
                  />
                ),
                toolbarMenuType: 'dropdown',
              }}
            />
          )}
          size={{ width: chartWidth, height: 360 }}
          tooltipType="compact"
          unit="number"
        />
      )}
    </SizeObserver>
  );
};

export default MetricsChartsCombined;
