import dayjs from 'dayjs';
import { SearchState } from 'hooks';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  TimeSeries,
} from 'types';
import { DataFrameMeta, DataTransformerConfig } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';

type Args = {
  applicationFilter: string;
  advancedGroupBys?: SearchState['advancedGroupBys'];
  date: DateSelection;
  facetName?: string;
  facetRegex?: FacetRegexTerm[];
  filterByFacets?: { [key: string]: boolean };
  idSearch?: string;
  searchTerms?: string[];
  selectedFacetRangeByName?: SelectedFacetRangeByName;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  rollupSeconds: number;
  aggregateField: string;
  aggregateFunction: string;
  groupByField?: string;
  groupByLimit: number;
  transformer: DataTransformerConfig[];
  meta: DataFrameMeta;
  eventType: string;
  staticFilters?: string;
};

const rumTimeSeries = async ({
  applicationFilter,
  advancedGroupBys = [],
  date,
  facetRegex,
  idSearch,
  rollupSeconds,
  aggregateField,
  aggregateFunction,
  transformer,
  meta,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  eventType,
  staticFilters,
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  meta.executedDate = date;

  return queryRumService<any, 'timeSeries'>(`
  query {
    timeSeries(
      eventType: ${eventType},
      timestamp: "${endTime.format()}",
      durationSecs: ${durationSecs},
      aggregate: { field: "${aggregateField}", aggregation: "${aggregateFunction}" }
      filter: ${buildRumFilter({ applicationFilter, facetRegex, idSearch, selectedFacetRangeByName, selectedFacetValuesByName, staticFilters })},
      rollupSeconds: ${rollupSeconds},
      groupBy: ${
        advancedGroupBys.length > 0 && advancedGroupBys[0].by !== '*'
          ? `[${advancedGroupBys.map(
              (groupBy) =>
                `{
                 field: "${groupBy.by}",
                 limit: ${groupBy.limitToValue},
                 sort: {
                   order: "${groupBy.limitTo}", 
                   aggregation: "${groupBy.sortAggregation ? groupBy.sortAggregation : 'count'}",
                   field: "${groupBy.sortField ? groupBy.sortField : '*'}" 
                 }
               }`,
            )}
         ]`
          : '[]'
      }
    ) {
      dimensions,
      points {
        ts,
        value,
      }
    }
  }
  `).then((data) => {
    const initialData = { datasets: data.timeSeries, meta };
    const transformed = transformer.reduce(
      (prevData, item) => item.func(prevData),
      initialData,
    );
    return transformed as unknown;
  });
};

export default rumTimeSeries;
