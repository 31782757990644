import { ApplicationsInfo } from '../hooks/useRumState';
import { RumEventType } from '../types';
import sidebarFacets, { MOBILE_APPLICATION_CATEGORY } from './sidebarFacets';

type Args = {
  applicationInfo: ApplicationsInfo;
  eventType: RumEventType;
};

const getLabels = (
  type: RumEventType,
  applicationInfo: ApplicationsInfo,
): string[] => {
  const applicationType = applicationInfo?.type;
  switch (type) {
    case 'VIEW':
      return sidebarFacets
        .filter((facet) => facet.view_facet)
        .filter((facet) => {
          if (!applicationType || applicationType === 'web') {
            return facet.category !== MOBILE_APPLICATION_CATEGORY;
          }
          return true;
        })
        .map((facet) => facet.field);
    case 'ACTION':
      return sidebarFacets
        .filter((facet) => facet.action_facet)
        .filter((facet) => {
          if (!applicationType || applicationType === 'web') {
            return facet.category !== MOBILE_APPLICATION_CATEGORY;
          }
          return true;
        })
        .map((facet) => facet.field);
    case 'LONGTASK':
      return sidebarFacets
        .filter((facet) => facet.long_task_facet)
        .filter((facet) => {
          if (!applicationType || applicationType === 'web') {
            return facet.category !== MOBILE_APPLICATION_CATEGORY;
          }
          return true;
        })
        .map((facet) => facet.field);
    case 'RESOURCE':
      return sidebarFacets
        .filter((facet) => facet.resource_facet)
        .filter((facet) => {
          if (!applicationType || applicationType === 'web') {
            return facet.category !== MOBILE_APPLICATION_CATEGORY;
          }
          return true;
        })
        .map((facet) => facet.field);
    case 'SESSION':
      return sidebarFacets
        .filter((facet) => facet.session_facet)
        .filter((facet) => {
          if (!applicationType || applicationType === 'web') {
            return facet.category !== MOBILE_APPLICATION_CATEGORY;
          }
          return true;
        })
        .map((facet) => facet.field);
    case 'ERROR':
      return sidebarFacets
        .filter((facet) => facet.error_facet)
        .filter((facet) => {
          if (!applicationType || applicationType === 'web') {
            return facet.category !== MOBILE_APPLICATION_CATEGORY;
          }
          return true;
        })
        .map((facet) => facet.field);

    default:
      return [];
  }
};

const rumLabels = async ({
  applicationInfo,
  eventType,
}: Args): Promise<string[]> => {
  return Promise.resolve(getLabels(eventType, applicationInfo));
};

export default rumLabels;
