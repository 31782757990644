import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Play, Plus, Search } from 'react-feather';
import { VscJson } from 'react-icons/vsc';
import { MdDevices, MdOutlineDashboardCustomize } from 'react-icons/md';
import {
  MdNotificationsOff as MuteIcon,
  MdOutlineManageSearch,
} from 'react-icons/md';
import { AiFillStar, AiOutlineLineChart, AiOutlineTable } from 'react-icons/ai';
import { RxDragHandleDots1 } from 'react-icons/rx';
import { IoAlert, IoFingerPrintOutline } from 'react-icons/io5';
import { FiAlertTriangle } from 'react-icons/fi';
import { GrDocumentPerformance } from 'react-icons/gr';
import {
  MdErrorOutline,
  MdMiscellaneousServices,
  MdNotes,
  MdOutlineAccountTree,
  MdOutlineFunctions,
  MdOutlineSpaceDashboard,
} from 'react-icons/md';
import {
  TbAlarm,
  TbAppWindow,
  TbDeviceAnalytics,
  TbFiles,
  TbHexagons,
  TbSitemap,
} from 'react-icons/tb';
import { DashboardPanelType, TracesTab } from 'types';
import { pickUrlSearchParamsByKeys } from 'utils';

import { SubMenuProps } from '../types';
import { SiKubernetes } from 'react-icons/si';
import { FaChartBar, FaRegFolderOpen } from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import {
  RiContactsBook2Line,
  RiUserSettingsLine,
  RiProfileLine,
} from 'react-icons/ri';
import { TbFileSettings, TbFileDatabase } from 'react-icons/tb';
import { ImTable } from 'react-icons/im';
import { RumEventType, RumTab } from 'screens/Rum';
import {
  useAlertsPageStateContext,
  useRumPageStateContext,
} from 'context/PageStateProvider';
import { BsFilterLeft, BsPieChartFill } from 'react-icons/bs';
import { BsReverseListColumnsReverse } from 'react-icons/bs';

export const metricsSubmenu = (navigate: NavigateFunction): SubMenuProps[] => [
  {
    key: 'Summary',
    icon: <MdNotes size={16} />,
    label: 'Summary',
    route: '/metrics/summary',
  },
  {
    key: 'Explorer',
    icon: <TbAppWindow size={16} />,
    label: 'Explorer',
    route: '/metrics',
  },
  {
    key: 'cardinality-analytics',
    icon: <AiOutlineLineChart size={16} />,
    label: 'Cardinality Analytics',
    route: '/metrics/cardinality-analytics',
  },
];

export const eventsSubmenu = (navigate: NavigateFunction): SubMenuProps[] => [
  {
    key: 'List',
    icon: <BsReverseListColumnsReverse size={16} />,
    label: 'List',
    route: '/events/list',
  },
  {
    key: 'Analytics',
    icon: <AiOutlineLineChart size={16} />,
    label: 'Analytics',
    route: '/events/timeseries',
  },
  {
    key: 'Top List',
    icon: <BsFilterLeft size={16} />,
    label: 'Top List',
    route: '/events/toplist',
    hideInSubMenu: true,
  },
  {
    key: 'Table',
    icon: <ImTable size={16} />,
    label: 'Table',
    route: '/events/table',
    hideInSubMenu: true,
  },

  {
    key: 'Pie Chart',
    icon: <BsPieChartFill size={16} />,
    label: 'Pie Chart',
    route: '/events/piechart',
    hideInSubMenu: true,
  },
];

export const grafanaSubmenu =
  (showKfuseProfiler: boolean) =>
  (navigate: NavigateFunction): SubMenuProps[] =>
    [
      {
        key: 'grafana-metrics',
        icon: <FaChartBar />,
        label: 'Metrics',
        route: '/grafana-metrics',
      },
      {
        key: 'grafana-logs',
        icon: <TbFiles size={16} />,
        label: 'Logs',
        route: '/grafana-logs',
      },
      {
        key: 'grafana-trace',
        icon: <MdOutlineAccountTree size={16} />,
        label: 'Traces',
        route: '/grafana-trace',
      },
      showKfuseProfiler
        ? {
            key: 'grafana-profiler',
            icon: <RiProfileLine size={16} />,
            label: 'Profiler',
            route: '/grafana-profiler',
          }
        : null,
    ].filter(Boolean);

export const apmSubmenu =
  (
    enableTraceSaveMetrics: boolean,
    alertsPageState: ReturnType<typeof useAlertsPageStateContext>,
    showHeatmap?: boolean,
  ) =>
  (
    navigate: NavigateFunction,
    urlSearchParams: URLSearchParams,
  ): SubMenuProps[] =>
    [
      {
        key: 'services',
        icon: <MdMiscellaneousServices size={16} />,
        label: 'Services',
        route: `/apm/services`,
      },
      {
        key: 'service-map',
        icon: <TbSitemap size={16} />,
        label: 'Service Map',
        route: `/apm/services/service-map`,
      },
      {
        key: 'traces',
        icon: <MdOutlineAccountTree size={16} />,
        label: 'Traces',
        route: `/apm/traces/${TracesTab.list}`,
      },
      enableTraceSaveMetrics
        ? {
            key: 'saved-trace-metrics',
            icon: <AiOutlineTable size={16} />,
            label: 'Derived Metrics',
            route: '/apm/saved-trace-metrics',
          }
        : null,
      {
        key: 'traces-analytics',
        icon: <TbDeviceAnalytics size={16} />,
        label: 'Trace Analytics',
        route: `/apm/traces/${TracesTab.timeseries}`,
      },
      showHeatmap
        ? {
            key: 'traces-heatmap',
            icon: (
              <RxDragHandleDots1
                size={16}
                style={{ transform: 'rotate(90deg)' }}
              />
            ),
            label: 'Trace Heatmap',
            route: `/apm/traces/${TracesTab.heatmap}`,
          }
        : null,
      {
        key: 'traces-cardinality-analytics',
        icon: <AiOutlineLineChart size={16} />,
        label: 'Cardinality Analytics',
        route: `/apm/traces/cardinality-analytics`,
      },
      {
        key: 'slo',
        icon: <TbHexagons size={16} />,
        label: 'SLOs',
        route: `/slo`,
      },
      {
        key: 'errors',
        icon: <MdErrorOutline size={16} />,
        label: 'Errors',
        route: `/apm/errors/all`,
      },
      {
        key: 'analytics',
        icon: <MdOutlineSpaceDashboard size={16} />,
        label: 'APM Dashboard',
        route: `/kfuse/kfuse-apm/apm-dashboard`,
      },
      {
        key: 'apm-alerts',
        icon: <FiAlertTriangle size={16} />,
        label: 'Alerts',
        route: `/alerts`,
        onClick: () => {
          alertsPageState.selectedFacetValuesByNameState.setState({
            type: {
              apm: 1,
            },
          });
        },
      },
    ].filter(Boolean);

export const cicdSubmenu = (
  navigate: NavigateFunction,
  urlSearchParams: URLSearchParams,
): SubMenuProps[] => [
  {
    key: 'pipelines',
    label: 'Pipelines',
    route: '/cicd',
  },
  {
    key: 'pipelineExecutions',
    label: 'Pipeline Executions',
    route: '/cicd',
  },
];

export const infrastructureSubmenu =
  (showServerless: boolean) =>
  (navigate: NavigateFunction): SubMenuProps[] =>
    [
      showServerless
        ? {
            key: 'infrastructure',
            icon: <MdOutlineFunctions size={16} />,
            label: 'Serverless',
            route: '/serverless',
          }
        : null,
      {
        key: 'infrastructure',
        icon: <SiKubernetes size={16} />,
        label: 'Kubernetes',
        route: '/kubernetes',
      },
    ].filter(Boolean);

export const logsSubmenu =
  (
    workbooks: any[],
    enableLogsSaveMetrics: boolean,
    alertsPageState: ReturnType<typeof useAlertsPageStateContext>,
  ) =>
  (navigate: NavigateFunction): SubMenuProps[] => [
    {
      key: 'search',
      icon: <Search size={16} />,
      label: 'Search',
      route: '/logs?search=true',
    },
    {
      key: 'fingerprints',
      icon: <IoFingerPrintOutline size={16} />,
      label: 'Fingerprints',
      route: '/logs/fingerprints',
    },
    {
      key: 'live-tail',
      icon: <Play size={16} />,
      label: 'Live Tail',
      route: '/logs?live-tail=true',
    },
    // {
    //   key: 'transactions',
    //   icon: <CgArrowsExchangeAlt size={16} />,
    //   label: 'Transactions',
    //   route: '/transactions',
    // },
    {
      key: 'logs-analytics',
      icon: <AiOutlineLineChart size={16} />,
      label: 'Logs Analytics',
      route: `/logs/${DashboardPanelType.TIMESERIES}`,
    },

    ...(enableLogsSaveMetrics
      ? [
          {
            key: 'derived-metrics',
            icon: <AiOutlineTable size={16} />,
            label: 'Derived Metrics',
            route: '/logs/derived-metrics',
          },
        ]
      : []),
    ...(workbooks.length
      ? [
          {
            key: 'saved-queries',
            icon: <AiFillStar size={16} />,
            label: 'Saved Queries',
            route: '/logs/saved-queries',
          },
        ]
      : []),
    {
      key: 'logs-alerts',
      icon: <FiAlertTriangle size={16} />,
      label: 'Alerts',
      onClick: () => {
        alertsPageState.selectedFacetValuesByNameState.setState({
          type: { logs: 1 },
        });
      },
      route: `/alerts`,
    },
    {
      key: 'logs-cardinality-analytics',
      icon: <AiOutlineLineChart size={16} />,
      label: 'Cardinality Analytics',
      route: `/logs/cardinality-analytics`,
    },
    {
      key: 'facet-explorer',
      icon: <MdOutlineManageSearch size={16} />,
      label: 'Facet Explorer',
      route: `/logs/facet-explorer`,
    },
    {
      key: 'logs-hydration',
      icon: <TbFileDatabase size={16} />,
      label: 'Hydration',
      route: `/logs/hydration/archives`,
    },
  ];

export const alertsSubmenu = (navigate: NavigateFunction): SubMenuProps[] => [
  {
    key: 'rule',
    icon: <IoAlert size={16} />,
    label: 'Alert Rules',
    route: '/alerts',
  },
  {
    key: 'alerts/contacts',
    icon: <RiContactsBook2Line size={16} />,
    label: 'Contact Points',
    route: '/alerts/contacts',
  },
  {
    key: 'alerts/silences',
    icon: <MuteIcon size={16} />,
    label: 'Alert Silences',
    route: '/alerts/silences',
  },
  {
    key: 'alerts/mute-timing',
    icon: <TbAlarm size={16} />,
    label: 'Mute Timing',
    route: '/alerts/mute-timing',
  },
  {
    key: 'alerts/new',
    icon: <Plus size={16} />,
    label: 'Create New Alert',
    route: '/alerts/list',
  },
];

export const kfuseSubmenu = (navigate: NavigateFunction): SubMenuProps[] => [
  {
    key: 'control-plane-overview',
    label: 'Kloudfuse Overview',
    route: '/kfuse/overview',
  },
  {
    key: 'control-plane-metrics',
    label: 'Metrics',
    route: '/kfuse/metrics',
  },
  {
    key: 'control-plane-events',
    label: 'Events',
    route: '/kfuse/events',
  },
  {
    key: 'control-plane-logs',
    label: 'Logs',
    route: '/kfuse/logs',
  },
  {
    key: 'control-plane-traces',
    label: 'Traces',
    route: '/kfuse/traces',
  },
  {
    key: 'control-plane-system',
    label: 'System',
    route: '/kfuse/system',
  },
  {
    key: 'control-plane-pinot',
    label: 'Pinot',
    route: '/kfuse/pinot',
  },
  {
    key: 'control-plane-kafka',
    label: 'Kafka',
    route: '/kfuse/kafka',
  },
  {
    key: 'control-plane-rum',
    label: 'RUM',
    route: '/kfuse/rum',
  },
];

export const helpSubmenu = (navigate: NavigateFunction): SubMenuProps[] => [
  {
    key: 'product-tour',
    label: 'Product Tour',
    route: '/apm/services?pT=true&new=true',
  },
  {
    key: 'documentation',
    label: 'Documentation',
    onClick: () => window.open('https://docs.kloudfuse.com', '_blank'), // Opens external URL
  },
  {
    key: 'customer-support',
    label: 'Customer Support',
    onClick: () => window.open('mailto:support@kloudfuse.com', '_blank'), // Opens mailto link
  },
];

export const advancedServiceMonitoringFilter = `selectedFacetValuesByName=${encodeURIComponent(
  JSON.stringify({ kf_source: { knight: 1 } }),
)}`;

export const dashboardSubmenu = (
  navigate: NavigateFunction,
): SubMenuProps[] => [
  {
    key: 'dashboard',
    icon: <MdOutlineSpaceDashboard size={16} />,
    label: 'Dashboard List',
    route: '/dashboards/lists',
  },
  {
    key: 'dashboard/new',
    icon: <MdOutlineDashboardCustomize size={16} />,
    label: 'Create New Dashboard',
    route: '/dashboards/new',
  },
  {
    key: 'dashboard-import',
    icon: <VscJson size={16} />,
    label: 'Import Dashboard JSON',
    route: '/dashboards/import?jsonFile=none',
  },
];

export const adminSubmenu = (isAdmin: boolean): SubMenuProps[] => {
  const items = [
    isAdmin && {
      key: 'admin/users',
      icon: <RiUserSettingsLine size={16} />,
      label: 'User Management',
      route: '/admin/users',
    },
    {
      key: 'admin/folders',
      icon: <FaRegFolderOpen size={16} />,
      label: 'Folder Management',
      route: '/admin/folders',
    },
    {
      key: 'admin/groups',
      icon: <FaUserGroup size={16} />,
      label: 'Group Management',
      route: '/admin/groups',
    },
    isAdmin && {
      key: 'admin/policies',
      icon: <HiOutlineClipboardDocumentCheck size={16} />,
      label: 'Policy Management',
      route: '/admin/policies',
    },
    isAdmin && {
      key: 'admin/policy-config',
      icon: <TbFileSettings size={16} />,
      label: 'Policy Config Management',
      route: '/admin/policy-config',
    },
  ];

  return items.filter(Boolean);
};

export const rumSubMenu = (
  rumPageState: ReturnType<typeof useRumPageStateContext>,
  alertsPageState: ReturnType<typeof useAlertsPageStateContext>,
): SubMenuProps[] => [
  {
    key: 'rum-views',
    icon: <ImTable size={16} />,
    label: 'RUM List',
    route: `/rum/${RumTab.list}`,
  },
  {
    key: 'rum-view-analytics',
    icon: <TbDeviceAnalytics size={16} />,
    label: 'RUM Analytics',
    route: `/rum/${RumTab.timeseries}`,
  },
  {
    key: 'rum-performance-monitoring',
    icon: <GrDocumentPerformance size={16} />,
    label: 'RUM Performance Monitoring',
    route: `/rum/${RumTab.performanceMonitoring}/overview`,
  },
  {
    key: 'rum-error',
    icon: <MdErrorOutline size={16} />,
    label: 'RUM Error',
    route: `/rum/${RumTab.list}`,
    onClick: () => {
      rumPageState.rumState.setEventType(RumEventType.ERROR);
    },
  },
  {
    key: 'rum-session',
    icon: <MdDevices size={16} />,
    label: 'RUM Session',
    route: `/rum/${RumTab.list}`,
    onClick: () => {
      rumPageState.rumState.setEventType(RumEventType.SESSION);
    },
  },
  {
    key: 'rum-alerts',
    icon: <FiAlertTriangle size={16} />,
    label: 'Alerts',
    route: `/alerts`,
    onClick: () => {
      alertsPageState.selectedFacetValuesByNameState.setState({
        type: {
          rum: 1,
        },
      });
    },
  },
];
