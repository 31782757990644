import {
  ConfirmationModal,
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  Paginator,
  ServiceWithLabels,
  Table,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
} from 'components';
import React, { ReactElement, useMemo, useState } from 'react';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Service } from 'types';
import { SLOProps } from 'types/SLO';
import { resolveColorFromMap, sloErrors } from 'utils';
import { useSLOState } from '../hooks';
import { SLODetails } from '../SLODetails';
import { SLOQueryTemplates } from '../types';
import { filterSLOList } from '../utils';

const SLOListColumns = ({
  onDeleteSLO,
  onEditSLO,
  colorsByServiceHash,
  serviceByHash,
}: {
  onDeleteSLO: (val: SLOProps) => void;
  onEditSLO: (val: SLOProps) => void;
  colorsByServiceHash: Record<string, string>;
  serviceByHash: Record<string, Service>;
}) => [
  { key: 'name', label: 'Name' },
  { key: 'type', label: 'Type' },
  {
    key: 'service.name',
    label: 'Service',
    renderCell: ({ row }: { row: SLOProps }) => {
      const label = row.service.hash;
      const service = serviceByHash?.[label];

      if (!service) return '---';
      return (
        <div className="max-w-72">
          <ServiceWithLabels
            color={resolveColorFromMap(colorsByServiceHash, service?.hash)}
            distinctLabels={service?.distinctLabels || {}}
            labels={service?.labels || {}}
            name={service?.name || ''}
          />
        </div>
      );
    },
  },
  {
    key: 'objective',
    label: 'Objective',
    renderCell: ({ row }: { row: SLOProps }) => `${row.objective}%`,
  },
  {
    key: 'statusErrorBudget.status',
    label: 'Status',
    renderCell: ({ row }: { row: SLOProps }) => {
      if (!row.statusErrorBudget) return null;

      return (
        <span style={{ color: row.statusErrorBudget.statusColor }}>
          {row.statusErrorBudget.status}
        </span>
      );
    },
  },
  {
    key: 'statusErrorBudget.errorBudget',
    label: 'Remaining Error Budget',
    renderCell: ({ row }: { row: SLOProps }) => {
      if (!row.statusErrorBudget) return null;

      return (
        <span style={{ color: row.statusErrorBudget.errorBudgetColor }}>
          {row.statusErrorBudget.errorBudget}
        </span>
      );
    },
  },
  {
    label: 'Actions',
    key: 'actions',
    renderCell: ({ row }: { row: SLOProps }) => {
      return (
        <div className="alerts__contacts__table__actions">
          <div
            className="table__cell__actions__item--blue"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onEditSLO(row);
            }}
          >
            <TooltipTrigger tooltip="Edit">
              <MdModeEdit
                className="alerts__contacts__table__actions__icon--edit"
                size={18}
              />
            </TooltipTrigger>
          </div>
          <div
            className="table__cell__actions__item--red"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDeleteSLO(row);
            }}
          >
            <TooltipTrigger tooltip="Delete">
              <MdDelete
                className="alerts__contacts__table__actions__icon--delete"
                size={18}
              />
            </TooltipTrigger>
          </div>
        </div>
      );
    },
  },
];

type Props = {
  colorsByServiceHash: Record<string, string>;
  serviceByHash: Record<string, Service>;
  sloState: ReturnType<typeof useSLOState>;
  queryTemplates: {
    latency: SLOQueryTemplates;
    availability: SLOQueryTemplates;
  };
  sloError: Error;
  setSloListError: React.Dispatch<
    React.SetStateAction<{
      deleteSlo: { message: string };
    }>
  >;
};

const SLOList = ({
  serviceByHash,
  sloState,
  queryTemplates,
  colorsByServiceHash,
  setSloListError,
}: Props): ReactElement => {
  const navigate = useNavigate();
  const modal = useModalsContext();
  const [error, setError] = useState({
    deleteSlo: null,
  });
  const [detailsScreen, setDetailsScreen] = useState<SLOProps>(null);
  const {
    deleteSloRequest,
    loadSLOList,
    selectedFacetValuesByNameState,
    sloList,
    sloListRequest,
    setSloFilterProperties,
    sloError,
  } = sloState;

  const filteredSLOList = useMemo(() => {
    if (!sloList) return [];
    const {
      sloList: newSloList,
      servicehash,
      status,
      type,
    } = filterSLOList(sloList, selectedFacetValuesByNameState.state);

    setSloFilterProperties((prevState) => ({
      ...prevState,
      servicehash,
      type,
    }));

    return newSloList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacetValuesByNameState.state, sloList]);

  const onDeleteSLO = (val: SLOProps) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={`Are you sure you want to delete ${val.name}?`}
        onCancel={() => modal.pop()}
        onConfirm={() => {
          modal.pop();
          deleteSloRequest
            .call(val.id)
            .then((res) => {
              if (res) {
                setSloListError((prevError) => ({
                  ...prevError,
                  deleteSlo: null,
                }));
              }

              loadSLOList(queryTemplates);
            })
            .catch(() => {
              setSloListError((prevError) => ({
                ...prevError,
                deleteSlo: { message: sloErrors.deleteSlo },
              }));
            });
        }}
        title="Delete SLO"
        errorMessage={error.deleteSlo}
      />,
    );
  };

  const onEditSLO = (val: SLOProps) => {
    navigate(
      `/slo/edit?sloType=${val.type?.toLowerCase()}&kf_source=apm&serviceHash=${
        val.service.hash
      }`,
      { state: val },
    );
  };

  const tableSearch = useTableSearch({ rows: filteredSLOList });
  const tableSort = useTableSort({
    columns: SLOListColumns({
      onDeleteSLO: null,
      onEditSLO: null,
      colorsByServiceName: null,
      serviceByHash: null,
    }),
    rows: tableSearch.searchedRows,
  });
  const paginator = usePaginator({ rows: tableSort.sortedRows });

  const overlayMessageProps: OverlayMessageProps =
    sloError?.getSloList?.message || sloError.promqlQuery?.message
      ? {
          isActive: true,
          iconName: 'warning',
          minHeight: sloError?.getSloList?.message ? 346 : null,
          message: (
            <>
              {sloError?.getSloList?.message ||
                error?.deleteSlo?.message ||
                sloError.promqlQuery?.message ||
                'Error'}
            </>
          ),
        }
      : { isActive: false };

  return (
    <div className="slos__list__table">
      <Loader
        isLoading={deleteSloRequest.isLoading || sloListRequest.isLoading}
      >
        <OverlayMessage {...overlayMessageProps}>
          <Table
            className="table--bordered table--bordered-cells slos__list__table__table"
            columns={SLOListColumns({
              onDeleteSLO,
              onEditSLO,
              colorsByServiceHash,
              serviceByHash,
            })}
            onRowClick={({ row }) => setDetailsScreen(row)}
            externalTableSort={tableSort}
            isSortingEnabled
            rows={paginator.paginatedRows}
          />
        </OverlayMessage>

        {sloListRequest.result && sloList.length === 0 && (
          <div className="mt-4 flex justify-center">
            <h2>No SLOs Created</h2>
          </div>
        )}
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
        {detailsScreen && (
          <SLODetails
            close={() => setDetailsScreen(null)}
            sloData={detailsScreen}
            title={detailsScreen.name}
            queryTemplates={queryTemplates}
          />
        )}
      </Loader>
    </div>
  );
};

export default SLOList;
