export const apmErrors = {
  aggregateTimeSeriesMultiple: 'Failed to aggregate timeseries',
  getGrafanaFolders: 'Failed to fetch grafana folders',
  getGrafanaDashboard: 'Failed to fetch grafana dashboard',
  getFacetValuesError: 'Failed to fetch facet values',
  describeTraces: 'Failed to fetch trace information',
  getDatasources: 'Failed to get datasources',
  traceLabelValuesV2: 'Failed to fetch trace label values',
  getServiceMapData: 'Failed to fetch service map data',
  traces: 'Failed to fetch traces',
  getGrafanaAlertsRulesByFolder: 'Failed to get grafana alert rules by folder',
  getAsmAutoAlert: 'Failed to get asm auto alert',
  getService: 'Failed to fetch service',
  getServices: 'Failed to fetch services list',
};
