import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  TimeSeries,
} from 'types';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';
import { RumEventType } from '../types';
import { SearchState } from 'hooks';

type Args = {
  applicationFilter: string;
  advancedGroupBys?: SearchState['advancedGroupBys'];
  aggregation: string;
  aggregationField: string;
  date: DateSelection;
  errorGroupingKey?: string;
  eventType: RumEventType;
  facetRegex?: FacetRegexTerm[];
  idSearch?: string;
  instant?: boolean;
  multiAggregations?: { measure: string | null; operation: string }[];
  returnQueryString?: boolean;
  rollUpSeconds: number;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const aggregateRumTimeSeries = async ({
  applicationFilter,
  advancedGroupBys = [],
  aggregation,
  aggregationField,
  date,
  errorGroupingKey,
  eventType,
  facetRegex,
  idSearch,
  instant = false,
  multiAggregations,
  returnQueryString = false,
  rollUpSeconds,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
}: Args): Promise<TimeSeries[] | string> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const mappedOperations = multiAggregations?.map(
    (agg) => `{
      field: "${agg.measure || '*'}",
      aggregation: "${agg.measure ? agg.operation : 'count'}",
    }`,
  );

  const aggregate = mappedOperations
    ? mappedOperations
    : `{ field: "${aggregationField}", aggregation: "${aggregation}", }`;

  const queryStr = `
  query {
    timeSeries(
      eventType: ${eventType},
      timestamp: "${endTime.format()}",
      durationSecs: ${durationSecs},
      filter: ${buildRumFilter({ applicationFilter, errorGroupingKey, facetRegex, idSearch, selectedFacetRangeByName, selectedFacetValuesByName })},
      rollupSeconds: ${instant ? 0 : rollUpSeconds},
      aggregate: ${aggregate},
      groupBy: ${
        advancedGroupBys.length > 0 && advancedGroupBys[0].by !== '*'
          ? `[${advancedGroupBys.map(
              (groupBy) =>
                `{
                 field: "${groupBy.by}",
                 limit: ${groupBy.limitToValue},
                 sort: {
                   order: "${groupBy.limitTo}", 
                   aggregation: "${groupBy.sortAggregation ? groupBy.sortAggregation : 'count'}",
                   field: "${groupBy.sortField ? groupBy.sortField : '*'}" 
                 }
               }`,
            )}
         ]`
          : '[]'
      }
    ) {
      dimensions,
      points {
        ts,
        value,
      }
    }
  }
  `;

  if (returnQueryString) return queryStr;

  return queryRumService<TimeSeries[], 'timeSeries'>(queryStr).then(
    (data) => data?.timeSeries,
    onPromiseError,
  );
};

export default aggregateRumTimeSeries;
