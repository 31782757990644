import { FormulaState, SearchState, useRequest } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { aggregateTimeSeriesMultipleV2, getServices } from 'requests';
import {
  DashboardPanelType,
  DateSelection,
  QueryDataProps,
  SelectedFacetValuesByName,
  Service,
  SpanFilter,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import {
  combineRangeQueryData,
  DataFrame,
  replaceServiceHashWithLabel,
  traceDataFrameToQueryData,
  traceDataTransformer,
} from 'utils';
import { AlertsChart } from '../AlertsChart';
import { ConditionProps } from '../AlertsCreateCondition';

const AlertsCreateTracesChart = ({
  condition,
  date,
  formulas,
  queries,
  serviceByHash: providedServiceByHash,
  spanFilter,
}: {
  condition: ConditionProps;
  date: DateSelection;
  formulas: FormulaState[];
  queries: SearchState[];
  serviceByHash?: Record<string, Service>;
  spanFilter: SpanFilter;
}): ReactElement => {
  const aggregateTimeSeriesMultipleV2Request = useRequest(
    (args) =>
      aggregateTimeSeriesMultipleV2(args).then(traceDataFrameToQueryData),
    true,
    true,
  );
  const getServicesRequest = useRequest(getServices, true, true);

  const serviceByHash = useMemo(() => {
    if (providedServiceByHash) {
      return providedServiceByHash;
    }
    return getServicesRequest.result?.reduce(
      (obj, service) => ({ ...obj, [service.hash]: service }),
      {},
    );
  }, [getServicesRequest.result, providedServiceByHash]);

  const loadTraceTimeseries = () => {
    const baseTransformers = traceDataTransformer();
    baseTransformers.splice(1, 0, {
      id: 'replaceServiceHashWithLabel',
      func: (dataFrame: DataFrame) =>
        replaceServiceHashWithLabel(dataFrame, serviceByHash),
    });

    aggregateTimeSeriesMultipleV2Request.call({
      customerFilter: {},
      date,
      dataFormat: DashboardPanelType.TIMESERIES,
      formulas: formulas.map((f) => f.state),
      instant: false,
      queries,
      spanFilter,
      transformer: baseTransformers,
    });
  };

  useDebouncedEffect(
    () => {
      loadTraceTimeseries();
    },
    { timeout: 100 },
    [date, queries, formulas, serviceByHash, spanFilter],
  );

  useDebouncedEffect(
    () => {
      if (providedServiceByHash) return;
      const selectedFacetValuesByName = { span_type: { db: 0 } };
      getServicesRequest.call({
        customerFilter: {},
        date,
        selectedFacetValuesByName:
          selectedFacetValuesByName as SelectedFacetValuesByName,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { ignoreInitialCall: false, timeout: 50 },
    [date],
  );

  const { formulasStatus, queriesStatus } = useMemo(() => {
    const queriesStatus = queries.map((query) => {
      return { isActive: query.isActive, queryKey: query.queryKey };
    });

    const formulasStatus = formulas.map((formula) => {
      return { isActive: formula.isActive, queryKey: formula.queryKey };
    });

    return { formulasStatus, queriesStatus };
  }, [queries, formulas]);

  const { result: traceQueryData, unit } = useMemo(() => {
    const result: QueryDataProps = {};
    let unit = '';
    if (!aggregateTimeSeriesMultipleV2Request.result) {
      queriesStatus.forEach(({ queryKey }) => {
        const queryId = `query_${queryKey}`;
        result[queryId] = {
          isLoading: aggregateTimeSeriesMultipleV2Request.isLoading,
          meta: null,
        };
      });
      return { result, unit };
    }
    queriesStatus.forEach(({ queryKey }) => {
      const queryId = `query_${queryKey}`;
      const data = aggregateTimeSeriesMultipleV2Request.result[queryId];

      if (!data) return;
      result[queryId] = {
        isLoading: aggregateTimeSeriesMultipleV2Request.isLoading,
        meta: data.meta,
        range: data.data,
      };
      unit = data.meta.unit;
    });
    formulasStatus.forEach(({ queryKey }) => {
      const formulaId = `formula_${queryKey}`;
      const data = aggregateTimeSeriesMultipleV2Request.result[formulaId];
      if (!data) return;
      result[formulaId] = {
        isLoading: aggregateTimeSeriesMultipleV2Request.isLoading,
        meta: data.meta,
        range: data.data,
      };
      unit = data.meta.unit;
    });
    return { result, unit };
  }, [
    aggregateTimeSeriesMultipleV2Request.isLoading,
    aggregateTimeSeriesMultipleV2Request.result,
    queriesStatus,
    formulasStatus,
  ]);

  const combinedData = useMemo(() => {
    if (!traceQueryData) return;
    return combineRangeQueryData({
      formulas: formulasStatus,
      queries: queriesStatus,
      queryData: traceQueryData,
      combineTimestamp: true,
      combineLabelWithQueryKey: true,
    });
  }, [traceQueryData, formulasStatus, queriesStatus]);

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={condition.value ? Number(condition.value) : undefined}
      date={date}
      isChartCompact={false}
      enableEvaluationLine={true}
      isLoading={combinedData.isLoading}
      queryData={combinedData}
      unit={unit}
    />
  );
};

export default AlertsCreateTracesChart;
