import { useEffect, useMemo, useState } from 'react';
import useDateState from '../useDateState';
import useLogsAnalyticsChart from 'screens/Logs/LogsAnalytics/hooks/useLogsAnalyticsChart';
import getLogsStateFromFiltersState from './getLogsStateFromFiltersState';
import { buildKfuseQl } from 'utils/KfuseqlBuilder';
import { getRollupByVisualization } from 'utils/rollup';
import { unescapeDoubleQuotes } from 'utils/escapePeriod';
import { parseFacetKey } from 'utils/facets';
import { refreshDate } from 'utils/refreshDate';

type Args = {
  customerFilter?: { key: string; value: string };
  dateState: ReturnType<typeof useDateState>;
  isAnalyticsView: boolean;
  logsAnalytics: ReturnType<typeof useLogsAnalyticsChart>;
};

const useLogsState = ({
  customerFilter,
  dateState,
  isAnalyticsView,
  logsAnalytics,
}: Args) => {
  const { logsMetricsQueryState } = logsAnalytics;
  const { queries, syncSelectedQuery, updateQuery } = logsMetricsQueryState;
  const [refreshFacetGroups, setRefreshFacetGroups] = useState<string>('');

  const [date, setDate] = dateState;

  const setDateZoomed = (nextDatePatch) => {
    setDate((prevDate) => ({
      ...prevDate,
      ...nextDatePatch,
    }));
  };

  const common = {
    customerFilter,
    date,
    dateState,
    removeFilterByFacetByIndex: () => {},
    setDate,
    setDateZoomed,
  };

  const activeQueryIndex = useMemo(() => {
    return isAnalyticsView ? syncSelectedQuery.queryIndex || 0 : 0;
  }, [isAnalyticsView, syncSelectedQuery.queryIndex]);

  const activeSidebarLogsState = useMemo(
    () => {
      const activeQuery = queries[activeQueryIndex];
      return {
        ...getLogsStateFromFiltersState({
          filtersState: activeQuery.filtersState,
        }),
        ...common,
        filtersState: activeQuery.filtersState,
        freeText: activeQuery.fuseql,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeQueryIndex,
      customerFilter,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dateState[0],
      queries,
      syncSelectedQuery,
    ],
  );

  const { filtersState, fuseql } = queries[0];
  const logsState = useMemo(
    () => {
      return {
        ...getLogsStateFromFiltersState({ filtersState }),
        ...common,
        filtersState,
        freeText: fuseql,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerFilter, dateState[0], filtersState, fuseql],
  );

  useEffect(() => {
    const builtQueries = buildKfuseQl({
      customerFilter,
      date,
      queries: [queries[0]],
      step: `${getRollupByVisualization(date, 'bar')}s`,
      instant: false,
    });

    const currentFuseql = queries[0].fuseql || '';
    const currentFuseqlWithOutFilters = currentFuseql
      .split('|')
      .splice(1)
      .join('|');

    const escapedText = builtQueries?.[0]?.kfuseQl || '';
    const unEscapeText = unescapeDoubleQuotes(escapedText);

    const unEscapedWithJustFilters = unEscapeText.split(' | ')[0];

    const fullText = currentFuseqlWithOutFilters
      ? `${unEscapedWithJustFilters} | ${currentFuseqlWithOutFilters}`
      : unEscapedWithJustFilters;

    updateQuery(0, 'fuseql', fullText, {
      ignoreLoading: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState.state]);

  const handleAddFuseQlTableColumn = ({
    facetKey,
    isLogFacet,
  }: {
    facetKey: string;
    isLogFacet: boolean;
  }) => {
    const currentFuseQlFreeText = queries[0].fuseql || '';

    const partsOfFreeText = currentFuseQlFreeText.split('|');
    let columnPartPresentInFreeText = false;
    let newColumnPartText = '';
    if (partsOfFreeText.length > 1) {
      const columnPossiblePart = partsOfFreeText[1];
      if (columnPossiblePart.includes(' as ')) {
        columnPartPresentInFreeText = true;
      }
    }
    if (columnPartPresentInFreeText) {
      const columnToBeAdded = parseFacetKey(facetKey).name;
      const newColumnPartToBeAdded = `${isLogFacet ? '@' : ''}${columnToBeAdded} as ${columnToBeAdded}`;
      if (!partsOfFreeText[1].includes(newColumnPartToBeAdded)) {
        newColumnPartText = `${partsOfFreeText[1]} , ${newColumnPartToBeAdded}`;
      }
    } else {
      const columnToBeAdded = parseFacetKey(facetKey).name;
      newColumnPartText = `${isLogFacet ? '@' : ''} as ${columnToBeAdded}`;
    }
    const shouldIndexBeReplaced = columnPartPresentInFreeText ? 1 : 0;
    partsOfFreeText.splice(1, shouldIndexBeReplaced, newColumnPartText);
    const newFuseql = partsOfFreeText.join('|');
    updateQuery(0, 'fuseql', newFuseql, {
      ignoreLoading: true,
    });
    refreshDate(date, (date) => setDate(date));
  };

  return {
    refreshFacetGroups,
    setRefreshFacetGroups,
    activeQueryIndex,
    activeSidebarLogsState,
    handleAddFuseQlTableColumn,
    logsState,
  };

  // return {
  //   dateState,
  //   filterOrExcludeByFingerprint,
  //   filtersState,
  //   keyExistsState,
  //   searchTermsState,
  //   selectedFacetRangeByNameState,
  //   selectedFacetValuesByNameState,
  //   state,
  // };
};

export default useLogsState;
