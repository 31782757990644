import {
  ConfirmationModal,
  Stepper,
  useModalsContext,
  useToaster,
} from 'components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getRollupByVisualization } from 'utils';

import { useAlertsCreateCondition } from '../AlertsCreateCondition';
import { parseAlertsQueryAndConditions } from '../AlertsCreateMetrics/utils';
import {
  AlertsCreateContacts,
  AlertsCreateDetails,
  CreateRuleButton,
} from '../components';
import { useAlertsCreate } from '../hooks';
import { AlertType, RuleProps } from '../types';
import AlertsCreateLogsChart from './AlertsCreateLogsChart';
import AlertsCreateLogsCondition from './AlertsCreateLogsCondition';
import AlertsCreateLogsDetection from './AlertsCreateLogsDetection';
import AlertsCreateLogsQueryBuilder from './AlertsCreateLogsQueryBuilder';
import { useAlertsCreateLogs } from './hooks';
import { confirmSelectedLogsAlertQueryKey } from './utils';

const AlertsCreateLogs = ({
  baseWidth,
  alertsCreateState,
  conditionState,
}: {
  baseWidth: number;
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const modal = useModalsContext();
  const { addToast } = useToaster();
  const location = useLocation();
  const rule = useMemo(() => location.state as RuleProps, [location.state]);
  const { alertType, evaluate, date, setUpdateAlertsRuleState, setAlertType } =
    alertsCreateState;

  const alertsCreateLogsState = useAlertsCreateLogs(
    alertsCreateState,
    baseWidth,
  );
  const {
    createLogsAlertThreshold,
    createLogsAlertAnomaly,
    createLogsAlertOutlier,
    createLogsAlertForecast,
    logsMetricsQueryState,
    setAdvancedCondition,
  } = alertsCreateLogsState;
  const {
    formulas,
    queries,
    loadMultipleLogsChartData,
    handleLoadingLogsChartOnDataChange,
    setQueryLangType,
    queryLangType,
  } = logsMetricsQueryState;
  const {
    anomalyCondition,
    condition,
    outlierCondition,
    forecastCondition,
    setUpdateConditionState,
    setCondition,
  } = conditionState;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (rule) {
      const newAlertType: AlertType =
        (rule.annotations?.alertType as AlertType) || AlertType.THRESHOLD;

      const parsed = parseAlertsQueryAndConditions({
        data: rule.ruleData,
        annotations: rule.annotations,
      });

      if (
        newAlertType === AlertType.ANOMALY ||
        newAlertType === AlertType.OUTLIERS ||
        newAlertType === AlertType.FORECAST
      ) {
        setAdvancedCondition({
          newAlertType,
          newAnomalyCondition: parsed.anomalyCondition,
          newOutlierCondition: parsed.outlierCondition,
          newForecastCondition: parsed.forecastCondition,
          conditionState,
        });
      }
      if (newAlertType !== alertType) {
        setAlertType({ value: newAlertType });
      }

      if (rule.noData && parsed.condition) {
        parsed.condition.noData = rule.noData;
      }

      if (rule.executionError && parsed.condition) {
        parsed.condition.executionError = rule.executionError;
      }

      const extraData = JSON.parse(rule.annotations?.extraData || '{}');
      if (
        extraData.queryLangType &&
        extraData.queryLangType !== queryLangType
      ) {
        setQueryLangType(extraData.queryLangType);
      }

      setUpdateAlertsRuleState(rule);
      setUpdateConditionState(parsed.condition);
    }
  }, [rule]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (formulas.length > 0) {
      loadMultipleLogsChartData({ queries, formulas, chartWidth: baseWidth });
    }
  }, [formulas]);

  const onConfirmLogsAlertCreate = () => {
    const { queryKey } = condition;
    const confirmSelected = confirmSelectedLogsAlertQueryKey(
      formulas.length,
      queries.length,
      queryKey,
    );
    if (confirmSelected) {
      modal.push(
        <ConfirmationModal
          description={confirmSelected}
          onCancel={() => modal.pop()}
          onConfirm={() => {
            onCreateAlertsConfirmed();
            modal.pop();
          }}
          submitText="Confirm and Create"
          title="Confirm Query Selection"
        />,
      );
    } else {
      onCreateAlertsConfirmed();
    }
  };

  const onCreateAlertsConfirmed = () => {
    if (alertType === AlertType.THRESHOLD) {
      createLogsAlertThreshold(condition);
    } else if (alertType === AlertType.ANOMALY) {
      createLogsAlertAnomaly({ condition, anomalyCondition });
    } else if (alertType === AlertType.OUTLIERS) {
      createLogsAlertOutlier({ condition, outlierCondition });
    } else if (alertType === AlertType.FORECAST) {
      createLogsAlertForecast({ condition, forecastCondition });
    }
  };

  const unit = queries.reduce((acc, query) => {
    if (acc === null) {
      return query.normalizeFunction;
    }
    if (acc !== query.normalizeFunction) {
      return 'number';
    }
    return acc;
  }, null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.hash.substring(10, url.hash.length));
    const hasLogsMetricsQueries = params.has('LogsMetricsQueries');
    const metricsFormulas = params.get('LogsMetricsFormulas');

    if (hasLogsMetricsQueries && !rule) {
      setTimeout(() => {
        addToast({
          text: 'Minimum rollup for logs alerts is 15s. Chart may look different from logs analytics.',
          status: 'info',
          timeout: 3500,
        });
      }, 1000);
    }

    try {
      const metricsFormulasJSON = JSON.parse(metricsFormulas || '[]');
      if (metricsFormulasJSON.length > 0) {
        setCondition((prev) => {
          const newCondition = { ...prev };
          newCondition.queryKey = `Formula (${metricsFormulasJSON[0].queryKey})`;
          return newCondition;
        });
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    handleLoadingLogsChartOnDataChange({ date, formulas, queries });
  }, [date]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const updateQueriesWithRollup = queries.map((query) => ({
      ...query,
      step: `${getRollupByVisualization(date)}s`,
    }));
    loadMultipleLogsChartData({
      queries: updateQueriesWithRollup,
      formulas,
      chartWidth: baseWidth,
    });
  }, [queryLangType]);

  return (
    <div className="alerts__create__logs">
      <div className="alerts__create__logs__chart">
        <AlertsCreateLogsChart
          alertType={alertType}
          baseWidth={baseWidth}
          conditionState={conditionState}
          date={date}
          forWindow={evaluate.for}
          logsMetricsQueryState={logsMetricsQueryState}
          isEditing={alertsCreateState.isEditing}
          unit={unit === 'duration' ? 's' : unit}
        />
      </div>
      <Stepper
        steps={[
          {
            title: 'Choose the detection method',
            component: (
              <AlertsCreateLogsDetection
                selectedAlertType={alertType}
                handleTabClick={(tab) => setAlertType({ value: tab })}
                queryLangType={queryLangType}
              />
            ),
          },
          {
            title: 'Pick log metric',
            component: (
              <AlertsCreateLogsQueryBuilder
                alertsCreateState={alertsCreateState}
                date={date}
                logsMetricsQueryState={logsMetricsQueryState}
              />
            ),
          },
          {
            title: 'Set Condition',
            component: (
              <>
                <AlertsCreateLogsCondition
                  alertsCreateState={alertsCreateState}
                  conditionState={conditionState}
                  logsMetricsQueryState={logsMetricsQueryState}
                  unit={unit === 'duration' ? 's' : unit}
                />
              </>
            ),
          },
          {
            title: 'Add Details',
            component: (
              <AlertsCreateDetails alertsCreateState={alertsCreateState} />
            ),
          },
          {
            title: 'Add Contacts',
            component: (
              <AlertsCreateContacts alertsCreateState={alertsCreateState} />
            ),
          },
        ]}
      />
      <CreateRuleButton
        isEditing={alertsCreateState.isEditing}
        onClick={onConfirmLogsAlertCreate}
      />
    </div>
  );
};

export default AlertsCreateLogs;
