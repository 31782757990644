import { lowerCase } from 'lodash';
import {
  useDateState,
  useRequest,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import { useEffect, useState } from 'react';
import { deleteSlo, getSloList, promqlQuery } from 'requests';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { SLOProps, ValueCount } from 'types';
import {
  getStatusAndErrorBudgetInListView,
  sloErrors,
  transformSLOPromql,
} from 'utils';
import {
  getBudgetQueryFromTemplates,
  getSLOServiceFacetValues,
  getStatusErrorQueryFromTemplates,
} from '../utils';

const useSLOState = () => {
  const [sloError, setSloError] = useState({
    promqlQuery: null,
    getSloList: null,
  });
  const selectedFacetValuesByNameState = useSelectedFacetValuesByNameState();
  const [date, setDate] = useDateState(getDateFromRange('now-1h', 'now'));
  const [sloList, setSloList] = useState([]);
  const [sloFilterProperties, setSloFilterProperties] = useState<{
    [key: string]: ValueCount[];
  }>({
    status: [
      { value: 'OK', count: 0 },
      { value: 'Breached', count: 0 },
    ],
    servicehash: [],
    tags: [],
    telemetrytype: [
      { value: 'apm', count: 0 },
      { value: 'knight', count: 0 },
    ],
    type: [
      { value: 'availability', count: 0 },
      { value: 'latency', count: 0 },
      { value: 'metrics', count: 0 },
    ],
  });

  const sloListRequest = useRequest(getSloList, true, true);
  const promqlQueryRequest = useRequest(promqlQuery, false, true);
  const deleteSloRequest = useRequest(deleteSlo, false, true);

  const getSLOStateFacetValues = (facetName: string) => () => {
    return new Promise((resolve) => {
      resolve(sloFilterProperties[facetName.toLocaleLowerCase()]);
    });
  };

  useEffect(() => {
    console.log(sloError);
  }, [sloError]);

  const loadSLOList = async (queryTemplates: any) => {
    sloListRequest
      .call()
      .then((response: SLOProps[]) => {
        if (response) {
          setSloError((prevError) => ({ ...prevError, getSloList: null }));
        }
        const serviceFacetValues = getSLOServiceFacetValues(response);
        setSloFilterProperties({
          ...sloFilterProperties,
          servicehash: serviceFacetValues,
        });
        response.map(async (slo) => {
          const sloAvailabiltyType = lowerCase(slo.type);

          const budgetQl =
            sloAvailabiltyType === 'latency' ||
            sloAvailabiltyType === 'availability'
              ? queryTemplates[sloAvailabiltyType]?.sloBudgetRemaining
              : getBudgetQueryFromTemplates(queryTemplates[sloAvailabiltyType]);

          const errorPromql =
            sloAvailabiltyType === 'latency' ||
            sloAvailabiltyType === 'availability'
              ? queryTemplates[sloAvailabiltyType].sloStatusQuery
              : getStatusErrorQueryFromTemplates(
                  queryTemplates[sloAvailabiltyType],
                );

          const budgetPromQl = transformSLOPromql({
            promql: budgetQl,
            slo,
            window: '30d',
          });

          const errorRatioPromQl = transformSLOPromql({
            promql: errorPromql,
            slo,
            window: '30d',
          });

          const dataset = await promqlQueryRequest
            .call({
              date,
              promqlQueries: [budgetPromQl, errorRatioPromQl],
            })
            .then((nextResult) => {
              if (nextResult) {
                setSloError((prevError) => ({
                  ...prevError,
                  promqlQuery: null,
                }));
              }
            })
            .catch(() => {
              setSloError((prevError) => ({
                ...prevError,
                promqlQuery: { message: sloErrors.promqlQuery },
              }));
            });

          const statusErrorBudget = getStatusAndErrorBudgetInListView(
            dataset,
            slo.objective,
          );

          if (statusErrorBudget) {
            slo.statusErrorBudget = statusErrorBudget;
            if (statusErrorBudget.statusColor === 'red') {
              sloFilterProperties.status[1].count += 1;
            } else {
              sloFilterProperties.status[0].count += 1;
            }
          }
          setSloList([...response]);
        });
        setSloList(response);
      })
      .catch((error) => {
        setSloError((prevError) => ({
          ...prevError,
          getSloList: { message: sloErrors.getSloList },
        }));
      });
  };

  return {
    deleteSloRequest,
    getSLOStateFacetValues,
    loadSLOList,
    selectedFacetValuesByNameState,
    setSloFilterProperties,
    sloList,
    sloListRequest,
    sloError,
  };
};

export default useSLOState;
