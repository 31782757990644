import classNames from 'classnames';
import { Resizer, ResizerOrientation, Icon } from 'components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { TfiLock } from 'react-icons/tfi';
import SheetCell from './SheetCell';
import SheetHeaderCellMore from './SheetHeaderCellMore';
import { PopoverTriggerV2, PopoverPosition } from '../PopoverTriggerV2';
import { defaultLogsTableColumnsBitmap } from '../../screens/Logs/constants';

const propTypes = {
  column: PropTypes.shape({}).isRequired,
  columnLocked: PropTypes.bool.isRequired,
  disableTableHeaderSorting: PropTypes.string,
  index: PropTypes.number,
  onToggleLock: PropTypes.func.isRequired,
  sheetDimensions: PropTypes.shape({}).isRequired,
  value: PropTypes.string,
};

const getArrow = ({ column, sort }) => {
  const { key } = column;
  const { sortBy, sortOrder } = sort;
  if (!defaultLogsTableColumnsBitmap.hasOwnProperty(key)) {
    return null;
  }
  if (key === sortBy) {
    if (sortOrder === 'Asc') {
      return <Icon icon="chevron-up" size="xs" />;
    }

    return <Icon icon="chevron-down" size="xs" />;
  }

  if (!sortBy && key === 'timestamp') {
    return <Icon icon="chevron-down" size="xs" />;
  }

  return '';
};

class SheetHeaderCell extends Component {
  constructor() {
    super();
    this.onToggleLock = this.onToggleLock.bind(this);
    this.handleSortClick = this.handleSortClick.bind(this);
  }

  onToggleLock() {
    const { columnIndex, onToggleLock } = this.props;
    onToggleLock(columnIndex);
  }

  handleSortClick() {
    const { column, sortByColumn, sort } = this.props;
    const { sortBy, sortOrder } = sort;
    const { key } = column;
    if (defaultLogsTableColumnsBitmap.hasOwnProperty(key)) {
      const newSortOrder =
        sortBy === column.key && sortOrder === 'Asc' ? 'Desc' : 'Asc';
      sortByColumn(column.key, newSortOrder);
    }
  }

  render() {
    const {
      column,
      columnIndex,
      columnLocked,
      columnsState,
      containerRef,
      disableTableHeaderSorting,
      isFirstRightColumn,
      isLastLeftColumn,
      isLocked,
      left,
      onResize,
      scrollX,
      selection,
      sheetDimensions,
      sort,
      sortByColumn,
      value,
      width,
      x,
      y,
    } = this.props;
    const { key, label } = column;
    const isSortingDisabled = disableTableHeaderSorting
      ? true
      : !defaultLogsTableColumnsBitmap.hasOwnProperty(key) || key === 'message';
    const isSorted = defaultLogsTableColumnsBitmap.hasOwnProperty(key);
    const { sheetOffsetX, sheetOffsetY } = sheetDimensions;

    const onMouseMove = ({ deltaX }) => {
      onResize(key, deltaX);
    };

    return (
      <SheetCell
        className={classNames({
          'sheet__cell--locked': isLocked,
          'sheet__cell--locked-left': isLastLeftColumn,
          'sheet__cell--locked-right': isFirstRightColumn,
        })}
        cellWidth={width}
        key={key}
        left={left}
        selection={selection}
        sheetDimensions={sheetDimensions}
        x={x}
        y={y}
      >
        <div className="sheet__cell__text">
          <div
            className={classNames({
              'sheet-header-cell': true,
              'sheet-header-cell--locked': isLocked,
              'sheet-header-cell--sorted': isSorted,
              'sheet-header-cell--unsorted': isSortingDisabled,
            })}
          >
            <div
              className="sheet-header-cell__value"
              onClick={!isSortingDisabled ? this.handleSortClick : undefined}
            >
              <span>{label}</span>
              <div>
                {isSortingDisabled
                  ? null
                  : getArrow({
                      column,
                      sort,
                    })}
              </div>
            </div>

            {columnLocked ? (
              <div className="sheet-header-cell__lock">
                <TfiLock />
              </div>
            ) : null}
            <PopoverTriggerV2
              className="sheet-header-cell__more"
              container={containerRef.current}
              offsetX={-sheetOffsetX + scrollX}
              offsetY={-sheetOffsetY}
              popover={(props) => (
                <SheetHeaderCellMore
                  {...props}
                  column={column}
                  columnLocked={columnLocked}
                  columnsState={columnsState}
                  index={columnIndex}
                  onToggleLock={this.onToggleLock}
                  sortByColumn={sortByColumn}
                />
              )}
              position={PopoverPosition.BOTTOM_RIGHT}
              shouldUseClickOnOutsideClick
              shouldStopMouseDownPropagation
            >
              <FiMoreVertical />
            </PopoverTriggerV2>
          </div>
        </div>
        <Resizer
          onMouseMove={onMouseMove}
          orientation={ResizerOrientation.vertical}
        />
      </SheetCell>
    );
  }
}

SheetHeaderCell.propTypes = propTypes;

export default SheetHeaderCell;
