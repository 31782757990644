import {
  useFacetRegexState,
  useRequest,
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { DateSelection } from 'types';
import { RumEventType } from '../types';
import rumTopListByVersion from '../requests/rumTopListByVersion';

type Args = {
  applicationFilter: string;
  date: DateSelection;
  eventType: RumEventType;
  facetRegexState: ReturnType<typeof useFacetRegexState>;
  selectedFacetRangeByNameState: ReturnType<
    typeof useSelectedFacetRangeByNameState
  >;
  selectedFacetValuesByNameState?: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  chart: any;
  aggregateField: string;
  aggregateFunction: string;
  groupBy: any;
};
const useRumUserSessionByVersion = ({
  applicationFilter,
  date,
  facetRegexState,
  selectedFacetRangeByNameState,
  selectedFacetValuesByNameState,
  chart,
  aggregateField,
  aggregateFunction,
  groupBy,
}: Args) => {
  const pageCursorRef = useRef<string>(null);
  const request = useRequest(rumTopListByVersion, true, true);
  const [result, setResult] = useState({ data: [], error: null });

  const { eventType } = chart;
  const call = () => {
    request
      .call({
        applicationFilter,
        eventType,
        date,
        facetRegex: facetRegexState?.state,
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        aggregateField,
        aggregateFunction,
        groupByLimit: 10,
        advancedGroupBys: groupBy,
      })
      .then((nextResult) => {
        if (nextResult) {
          setResult(nextResult.aggregateTable);
        }
      });
  };

  const onScrollEnd = () => {
    if (pageCursorRef.current) {
      call();
    }
  };

  useEffect(() => {
    pageCursorRef.current = null;
    setResult({ data: [], error: null });
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationFilter,
    date,
    eventType,
    selectedFacetRangeByNameState.state,
    selectedFacetValuesByNameState.state,
  ]);

  return {
    call,
    isLoading: request.isLoading,
    onScrollEnd,
    result,
  };
};

export default useRumUserSessionByVersion;
