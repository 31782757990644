import { AnalyticsChart } from 'components';
import { useSearches } from 'hooks';
import React from 'react';
import { parseErrorFromQueryData } from 'utils';
import { useEventsState, useEventsTimeseries } from '../hooks';

type Props = {
  searches: ReturnType<typeof useSearches>;
  eventsState: ReturnType<typeof useEventsState>;
  tab: string;
};

const EventsTimeseries = ({ searches, eventsState, tab }: Props) => {
  const eventsTimeseries = useEventsTimeseries({
    searches,
    eventsState,
    tab,
  });
  const {
    activeVisualization,
    analyticsChart,
    analyticsData,
    groupBysForTable,
    onViewFullscreen,
    openExportToDashboardModal,
    onCreateEventsAlert,
  } = eventsTimeseries;

  const { setDate } = eventsState;

  const eventsAnalyticsOverlayMessage = parseErrorFromQueryData({
    queryData: analyticsData.chartData,
    queries: analyticsData.chartQueries,
    formulas: [],
    dataType: activeVisualization === 'timeseries' ? 'range' : 'instant',
  });

  return (
    <div className="flex-1 p-2 px-5 overflow-auto w-full events__timeseries">
      <AnalyticsChart
        analyticsChart={analyticsChart}
        chartData={analyticsData.chartData}
        chartFormulas={[]}
        chartQueries={analyticsData.chartQueries}
        defaultActiveChart="Stacked Bar"
        groupBy={groupBysForTable}
        overlayMessage={eventsAnalyticsOverlayMessage}
        rightToolbar={{
          enableExportForInstantQueries: true,
          onViewFullscreen,
          onExportClick: openExportToDashboardModal,
          onCreateAlert: onCreateEventsAlert,
        }}
        setDate={setDate}
        settingOptions={{
          legendType: 'values',
          toolbarChartShowType: 'dropdown',
        }}
      />
    </div>
  );
};

export default EventsTimeseries;
