import { SearchState } from 'hooks/useSearch';
import {
  DateSelection,
  DashboardPanelType,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { getRollupByVisualization, getRollupToSecond } from 'utils/rollup';
import { DataFrameMeta, DataTransformerConfig } from 'utils/DataTransformer';

import { RumEventType } from '../types';
import aggregateRumTimeSeries from './aggregateRumTimeSeries';
import aggregateRumTable from './aggregateRumTable';
import { isDurationFacet } from '../utils';
import { formatTableDuration } from 'utils/DataTransformer/tableTransformer';
import { rumTimeSeriesDataTransformer } from 'utils/DataTransformer/rum';

export type RumAnalyticsFetchMultiProps = {
  applicationFilter?: string;
  date: DateSelection;
  dataFormat: DashboardPanelType;
  eventType: RumEventType;
  facetRegex: FacetRegexTerm[];
  idSearch: string;
  instant: boolean;
  transformer: DataTransformerConfig[];
  query: SearchState;
  returnQueryString?: boolean;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const rumAnalyticsFetchMulti = async ({
  applicationFilter,
  date,
  dataFormat,
  eventType,
  facetRegex,
  idSearch,
  instant,
  transformer,
  query,
  returnQueryString = false,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
}: RumAnalyticsFetchMultiProps) => {
  const step = query.rollUpInSeconds
    ? getRollupToSecond(query.rollUpInSeconds as unknown as string)
    : getRollupByVisualization(date, 'bar');
  const params = {
    ...query,
    applicationFilter,
    aggregation: query.measure ? query.operation : 'count',
    aggregationField: query.measure || '*',
    multiAggregations: query.multiAggregations,
    date,
    eventType,
    facetRegex,
    idSearch,
    rollUpSeconds: step,
    returnQueryString,
    selectedFacetRangeByName: selectedFacetRangeByName,
    selectedFacetValuesByName: selectedFacetValuesByName,
  };

  const metricName =
    query.operation === 'distinctcount' ? 'count' : query.operation;

  const metricNames =
    query.multiAggregations?.map((agg) => {
      const aggregation = agg.measure ? agg.operation : 'count';
      const field = agg.measure ? `( ${agg.measure} )` : '';
      return `${aggregation === 'distinctcount' ? 'count' : aggregation}${field}`;
    }) || [];

  const meta: DataFrameMeta = {
    refId: query.queryKey,
    step,
    type: dataFormat,
    labels: query.advancedGroupBys.map((g) => g.by),
    metricName,
    metricNames,
    aggregate: metricName,
    executedDate: date,
    queryType: 'query',
    unit: isDurationFacet(query?.multiAggregations?.[0]?.measure)
      ? 'ns'
      : 'number',
  };
  const rangeKey = instant ? 'instant' : 'range';
  const fetchData = async () => {
    try {
      return dataFormat === DashboardPanelType.TIMESERIES
        ? await aggregateRumTimeSeries({ ...params })
        : await aggregateRumTable({ ...params });
    } catch (error) {
      return { [rangeKey]: null, error, meta };
    }
  };

  const data = await fetchData();
  if (data.error) return data;

  const initialData = { datasets: data, meta };
  const timeSeriesTransformer = {
    id: 'rumTimeSeriesDataTransformer',
    func: rumTimeSeriesDataTransformer,
  };

  const durationFormatterTransformer = {
    id: 'durationFormatter',
    func: (dataFrame: any) =>
      formatTableDuration({
        data: dataFrame,
        options: {
          isDurationField: isDurationFacet,
          fieldParser: (field: string) => {
            const match = field.match(/\(([^)]+)\)/);
            const result = match ? match[1]?.trim() : null;
            return result;
          },
        },
      }),
  };
  if (!instant && !returnQueryString) {
    transformer.splice(2, 1, timeSeriesTransformer);
  }
  if (
    instant &&
    dataFormat === DashboardPanelType.TABLE &&
    !returnQueryString
  ) {
    transformer.splice(1, 0, durationFormatterTransformer);
  }
  const transformed = transformer.reduce(
    (acc: any, currentTransformer: { func: (arg0: any) => any }) => {
      return currentTransformer.func(acc);
    },
    initialData,
  );
  return transformed;
};

export default rumAnalyticsFetchMulti;
