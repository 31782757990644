import { MutableRefObject } from 'react';
import {
  DashboardLogsWithMetaProps,
  DashboardPanelOverrideProps,
  DashboardPanelProps,
  DashboardPanelType,
  DateSelection,
  QueryLangType,
} from 'types';

import { DashboardTemplateValueProps } from '../types';
import {
  getReplacedTimeFromWithDate,
  replaceLogQLVariables,
} from './common-utils';
import { getQueryBuilderTransformedLogQl } from '../DashboardEdit/utils/logsUtils';
import { getLogQLWithMetaToLoad } from 'utils/MetricsLogsQueryBuilder';
import { getQueryLangType } from './logs-utils';
import {
  DataFrame,
  DataTransformerConfig,
  kfuseqlDataTransformer,
  kfuseqlDataTransformerInstant,
  logqlDashboardDataTransformer,
  logsDataTransformer,
  pieChartTransformer,
  sortAndLimitTimeseries,
  TableTransformerData,
  timeseriesDataTransformer,
  topListTransformer,
} from 'utils/DataTransformer';
import {
  mergeSeriesData,
  organizeColumn,
  overrideColumnsData,
} from 'utils/DataTransformer/tableTransformer';

export const getLogsQueryWithMetaForDashboard = ({
  date,
  panel,
  templateValues,
  userActionRef,
  width,
}: {
  date: DateSelection;
  panel: DashboardPanelProps;
  templateValues: DashboardTemplateValueProps;
  userActionRef: MutableRefObject<{ defaultTimeChanged: boolean }>;
  width: number;
}): {
  logsWithMeta: DashboardLogsWithMetaProps[];
  mainTransformer: DataTransformerConfig[];
  isRange: boolean;
  queryLangType: QueryLangType;
} => {
  const logsWithMeta: DashboardLogsWithMetaProps[] = [];
  const panelDate = getReplacedTimeFromWithDate({
    date,
    panel,
    templateValues,
  });

  const range = panel.type === DashboardPanelType.TIMESERIES;

  const transformedQueries = getQueryBuilderTransformedLogQl(panel);
  const queryLangType = getQueryLangType(panel);

  if (transformedQueries && transformedQueries.queries.length > 0) {
    const logQLWithMetaToLoad = getLogQLWithMetaToLoad({
      customerFilter: transformedQueries.customerFilter,
      queries: transformedQueries.queries,
      formulas: transformedQueries.formulas,
      date: panelDate,
      dataFormat: panel.type,
      formulaOnly: false,
      instant: !range,
      queryLangType,
    });

    const { targets } = panel;
    const target = targets[0];
    logQLWithMetaToLoad.forEach((logQL) => {
      const targetTransformer = logsDataTransformer(!range);

      if (
        queryLangType === QueryLangType.KFUSEQL ||
        queryLangType === QueryLangType.ADVANCED_KFUSEQL_SEARCH
      ) {
        if (range) {
          targetTransformer[0] = {
            id: 'kfuseqlDataTransformer',
            func: kfuseqlDataTransformer,
          };
        } else {
          targetTransformer[0] = {
            id: 'kfuseqlDataTransformerInstant',
            func: kfuseqlDataTransformerInstant,
          };
        }
      }

      targetTransformer.pop(); // remove the last transformer
      if (logQL.limit) {
        targetTransformer.splice(2, 0, {
          id: 'sortAndLimitTimeseries',
          func: (dataFrame: DataFrame) =>
            sortAndLimitTimeseries(dataFrame, logQL.limit),
        });
      }

      const { meta } = logQL;
      const legendFormat =
        target.legendFormat === '__auto' ? '' : target.legendFormat;
      const replacedLogQL = replaceLogQLVariables(logQL.logql, templateValues);
      logsWithMeta.push({
        date: panelDate,
        //Changes Needed Here
        kfuseql: queryLangType === QueryLangType.KFUSEQL ? replacedLogQL : '',
        logql: queryLangType === QueryLangType.LOGQL ? replacedLogQL : '',
        meta: { ...meta, legendFormat },
        transformer: targetTransformer,
      });
    });

    const mainTransformer = [
      {
        id: 'logqlDashboardDataTransformer',
        func: logqlDashboardDataTransformer,
      },
    ];

    let unit = 'number';
    if (
      panel.type === DashboardPanelType.PIECHART ||
      panel.type === DashboardPanelType.TOP_LIST ||
      panel.type === DashboardPanelType.TABLE
    ) {
      const renamedValues: Record<string, string> = {};
      const overrides: DashboardPanelOverrideProps[] = [];
      logsWithMeta.forEach(({ meta }) => {
        meta.labels.forEach((label) => {
          renamedValues[label] = label;
        });
        const valueWithRef = `Value #${meta.refId}`;
        renamedValues[valueWithRef] = meta.aggregate;
        if (meta.unit) {
          unit = meta.unit;
          overrides.push({
            matcher: { id: 'byName', options: valueWithRef },
            properties: [{ id: 'unit', value: meta.unit, decimals: 2 }],
          });
        }
      });
      mainTransformer.push({
        id: 'mergeSeriesData',
        func: (dataFrames: DataFrame) =>
          mergeSeriesData({ dataFrames: [dataFrames], columns: [] }),
      });
      mainTransformer.push({
        id: 'organize',
        func: (data: TableTransformerData) =>
          organizeColumn({
            data,
            options: {
              excludeByName: { Time: true },
              renameByName: renamedValues,
            },
            byField: '',
          }),
      });
      if (overrides.length > 0) {
        mainTransformer.push({
          id: 'override',
          func: (data: TableTransformerData) =>
            overrideColumnsData({ data, overrides }),
        });
      }
    }

    if (panel.type === DashboardPanelType.PIECHART) {
      mainTransformer.push({
        id: 'toPieChart',
        func: (data: TableTransformerData) => {
          const piechart = pieChartTransformer(data);
          return { data: piechart.pieChartData };
        },
      });
    }

    if (panel.type === DashboardPanelType.TOP_LIST) {
      mainTransformer.push({
        id: 'toTopList',
        func: (data: TableTransformerData) =>
          topListTransformer({ data, options: { unit } }),
      });
    }

    return { logsWithMeta, mainTransformer, isRange: range, queryLangType };
  }

  return {
    logsWithMeta: [],
    mainTransformer: [],
    isRange: range,
    queryLangType,
  };
};
