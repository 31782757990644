import { SelectedFacetValuesByName, SLOProps, ValueCount } from 'types';
import { extractPathNameFromURL, filterListWithSelectedSidebar } from 'utils';

export const SLOCoreFilter = [
  { name: 'status', label: 'Status', forceExpanded: false },
  { name: 'servicehash', label: 'Service', forceExpanded: false },
  { name: 'tags', label: 'Tags', forceExpanded: false },
  { name: 'type', label: 'Type', forceExpanded: false },
];

export const parseSLOType = (type: string) => {
  if (!type) return '';
  try {
    const parsedType = JSON.parse(type);
    return parsedType?.value || '';
  } catch (e) {
    return '';
  }
};

export const getDefaultNameForSLOType = ({
  type,
  serviceName,
}: {
  type: string;
  serviceName: string;
}) => {
  if (!type) return '';

  const serviceNameAphaNumericOrUnderscore = serviceName.replace(
    /[^a-zA-Z0-9_]/g,
    '',
  );
  switch (type) {
    case 'availability':
      return `Availability_SLO_for_${serviceNameAphaNumericOrUnderscore}_service`;
    case 'latency':
      return `Latency_SLO_for_${serviceNameAphaNumericOrUnderscore}_service`;
    default:
      return '';
  }
};

export const getSLOServiceFacetValues = (sloList: SLOProps[]) => {
  const serviceFacetValues: ValueCount[] = [];
  sloList.forEach((slo) => {
    const service = slo.service?.hash;
    const serviceFacetValue = serviceFacetValues.find(
      (facetValue) => facetValue.value === service,
    );
    if (serviceFacetValue) {
      serviceFacetValue.count += 1;
    } else {
      serviceFacetValues.push({ value: service, count: 1 });
    }
  });
  return serviceFacetValues;
};

/**
 * Find the source of the KF in the expression
 * @param expression
 * example - sum(rate(edge_latency_count{kf_source="apm",service_name="shippingservice"} [{{.window}}]))
 * @returns apm
 * example - sum(rate(edge_latency_count{kf_source=~"apm|knight",service_name="shippingservice"} [{{.window}}]))
 * @returns apm|knight
 */
const findKfSourceInExpression = (expression: string) => {
  const regex = /kf_source="([^"]*)"/g;
  const matches = regex.exec(expression);
  if (matches) {
    return matches[1];
  }
  return 'apm';
};

export const filterSLOList = (
  sloList: SLOProps[],
  selectedFacetValuesByName: SelectedFacetValuesByName,
): {
  sloList: SLOProps[];
  status: ValueCount[];
  servicehash: ValueCount[];
  tags: ValueCount[];
  telemetrytype: ValueCount[];
  type: ValueCount[];
} => {
  const newSLOList = sloList.map((slo) => {
    const kfSource = findKfSourceInExpression(slo.totalExpr);

    return {
      ...slo,
      status: slo.statusErrorBudget?.statusColor === 'red' ? 'Breached' : 'OK',
      tags: Object.keys(slo?.labels || {}).map((key) => {
        return `${key}:${slo.labels[key]}`;
      }),
      service: slo.service || { hash: '', name: '' },
      servicehash: slo.service?.hash || '',
      serviceName: slo.service?.name || '',
      telemetrytype: kfSource,
    };
  });

  const filteredSLOList = filterListWithSelectedSidebar(
    newSLOList,
    selectedFacetValuesByName,
  );

  const status: { [key: string]: number } = {};
  const services: { [key: string]: number } = {};
  const tags: { [key: string]: number } = {};
  const telemetryType: { [key: string]: number } = {};
  const type: { [key: string]: number } = {};
  filteredSLOList.forEach((slo: SLOProps) => {
    if (services[slo.service?.hash] === undefined) {
      services[slo.service?.hash] = 1;
    } else {
      services[slo.service?.hash] += 1;
    }

    if (status[slo.status] === undefined) {
      status[slo.status] = 1;
    } else {
      status[slo.status] += 1;
    }

    slo.tags.forEach((tag) => {
      if (status[tag] === undefined) {
        tags[tag] = 1;
      } else {
        tags[tag] += 1;
      }
    });

    if (telemetryType[slo.telemetrytype] === undefined) {
      telemetryType[slo.telemetrytype] = 1;
    } else {
      telemetryType[slo.telemetrytype] += 1;
    }

    if (type[slo.type] === undefined) {
      type[slo.type] = 1;
    } else {
      type[slo.type] += 1;
    }
  });

  const statusFacetValues: ValueCount[] = [];
  Object.keys(status).forEach((key) => {
    statusFacetValues.push({ value: key, count: status[key] });
  });

  const serviceFacetValues: ValueCount[] = [];
  Object.keys(services).forEach((key) => {
    serviceFacetValues.push({ value: key, count: services[key] });
  });

  const tagsFacetValues: ValueCount[] = [];
  Object.keys(tags).forEach((key) => {
    if (key.includes(':')) {
      tagsFacetValues.push({ value: key, count: tags[key] });
    }
  });

  const telemetryTypeFacetValues: ValueCount[] = [];
  Object.keys(telemetryType).forEach((key) => {
    telemetryTypeFacetValues.push({ value: key, count: telemetryType[key] });
  });

  if (telemetryTypeFacetValues.length === 0) {
    telemetryTypeFacetValues.push({ value: 'apm', count: 0 });
    telemetryTypeFacetValues.push({ value: 'knight', count: 0 });
  }

  const typeFacetValues: ValueCount[] = [];
  Object.keys(type).forEach((key) => {
    typeFacetValues.push({ value: key, count: type[key] });
  });

  return {
    sloList: filteredSLOList,
    status: statusFacetValues,
    servicehash: serviceFacetValues,
    tags: tagsFacetValues,
    type: typeFacetValues,
    telemetrytype: telemetryTypeFacetValues,
  };
};
