import { useModalsContext, useToaster } from 'components';
import { useKeyExistsState } from 'hooks';
import React, { ReactElement } from 'react';
import { Search, XCircle, Eye, Clipboard, Plus } from 'react-feather';
import { MdOutlineFavoriteBorder as FavoriteEmptyIcon } from 'react-icons/md';
import { getFacetKey } from 'utils';

import { LogsFacetExplorerEditFavorite } from '../LogsFacetExplorer';
import { useLogsWorkbooksState } from './hooks';

type Props = {
  close: () => void;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  enableKeyExistsFilter: boolean;
  isLogFacet?: boolean;
  fpHash: string;
  logFacet: any;
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
  shouldDisableFilterActions?: boolean;
  source: string;
  setRefreshFacetGroups?: (component: string) => void;
};

const LogsSelectedLogAttributePanel = ({
  close,
  customColumnsState,
  disableAddCustomColumn = false,
  enableKeyExistsFilter,
  isLogFacet,
  fpHash,
  logFacet,
  logsState,
  shouldDisableFilterActions,
  source,
  setRefreshFacetGroups,
}: Props): ReactElement => {
  const modal = useModalsContext();
  const { addToast } = useToaster();
  const { name, type, value } = logFacet;
  const { filterOnlyFacetValueFromLogEventDetail, toggleKeyExists } = logsState;

  const onExcludeFacetValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    filterOnlyFacetValueFromLogEventDetail({
      exclude: true,
      name,
      source,
      type,
      value,
    });
    close();
  };

  const onSelectOnlyFacetValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    filterOnlyFacetValueFromLogEventDetail({ name, source, type, value });
    close();
  };

  const onShowWhereKeyExists = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleKeyExists({ component: source, name, type });
    close();
  };

  const onAddCustomColumn = customColumnsState
    ? (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        close();
        customColumnsState.setKeyExists(
          getFacetKey({ component: source, name, type }),
          isLogFacet ? 1 : 0,
        );
      }
    : null;

  const facetNameAndValue = (
    <span className="logs__selected-log__attribute__panel__item__value">
      {`${logFacet.name}:${logFacet.value}`}
    </span>
  );

  const isAddColumnEnabled = Boolean(
    onAddCustomColumn &&
      name !== 'source' &&
      !disableAddCustomColumn &&
      !shouldDisableFilterActions,
  );

  return (
    <div className="logs__selected-log__attribute__panel">
      {shouldDisableFilterActions ? null : (
        <>
          <button
            className="logs__selected-log__attribute__panel__item"
            onClick={onSelectOnlyFacetValue}
          >
            <Search
              className="logs__selected-log__attribute__panel__item__icon"
              size={14}
            />
            <span className="logs__selected-log__attribute__panel__item__label">
              {'Filter By '}
            </span>
            {facetNameAndValue}
          </button>
          <button
            className="logs__selected-log__attribute__panel__item"
            onClick={onExcludeFacetValue}
          >
            <XCircle
              className="logs__selected-log__attribute__panel__item__icon"
              size={14}
            />
            <span className="logs__selected-log__attribute__panel__item__label">
              {'Exclude '}
            </span>
            {facetNameAndValue}
          </button>
          {enableKeyExistsFilter ? (
            <button
              className="logs__selected-log__attribute__panel__item"
              onClick={onShowWhereKeyExists}
            >
              <Eye
                className="logs__selected-log__attribute__panel__item__icon"
                size={14}
              />
              <span className="logs__selected-log__attribute__panel__item__label">
                {'Show where '}
              </span>
              <span className="logs__selected-log__attribute__panel__item__value text--weight-bold">
                {logFacet.name}
              </span>
              <span className="logs__selected-log__attribute__panel__item__label">
                {' exists'}
              </span>
            </button>
          ) : null}
        </>
      )}
      {isAddColumnEnabled ? (
        <button
          className="logs__selected-log__attribute__panel__item"
          onClick={onAddCustomColumn}
        >
          <Plus
            className="logs__selected-log__attribute__panel__item__icon"
            size={14}
          />
          <span className="logs__selected-log__attribute__panel__item__label">
            {'Add '}
          </span>
          <span className="logs__selected-log__attribute__panel__item__value text--weight-bold">
            {logFacet.name}
          </span>
          <span className="logs__selected-log__attribute__panel__item__label">
            {' as a table column'}
          </span>
        </button>
      ) : null}
      {setRefreshFacetGroups ? (
        <button
          className="logs__selected-log__attribute__panel__item"
          onClick={(e) => {
            e.stopPropagation();
            modal.push(
              <LogsFacetExplorerEditFavorite
                closeModal={modal.pop}
                facetName={logFacet.name}
                facetType={logFacet.type}
                facetSource={source}
                onSaveFavoriteSuccess={(group) => {
                  setRefreshFacetGroups(group);
                }}
              />,
            );
          }}
        >
          <FavoriteEmptyIcon
            className="logs__selected-log__attribute__panel__item__icon"
            size={14}
          />
          <span className="logs__selected-log__attribute__panel__item__label">
            {'Mark '}
          </span>
          <span className="logs__selected-log__attribute__panel__item__value">
            {logFacet.name}
          </span>
          <span className="logs__selected-log__attribute__panel__item__label">
            {' facet as favorite'}
          </span>
        </button>
      ) : null}
      <button
        className="logs__selected-log__attribute__panel__item"
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard.writeText(logFacet.value);
          addToast({ status: 'success', text: 'Copied to clipboard' });
          close();
        }}
      >
        <Clipboard
          className="logs__selected-log__attribute__panel__item__icon"
          size={14}
        />
        <span className="logs__selected-log__attribute__panel__item__label">
          {'Copy '}
        </span>
        <span className="logs__selected-log__attribute__panel__item__value">
          {logFacet.value}
        </span>
      </button>
    </div>
  );
};

export default LogsSelectedLogAttributePanel;
