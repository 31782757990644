import {
  Button,
  ErrorMessage,
  MultiselectV2,
  TooltipTrigger,
  useModalsContext,
} from 'components';
import { useRequest } from 'hooks';
import { default as React, ReactElement, useEffect, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { MdGroupAdd } from 'react-icons/md';
import { getGrafanaAlertManager } from 'requests';
import { alertCreateErrors, sloErrors } from 'utils';
import { AlertsContactsCreate } from '../../AlertsContacts';
import { useAlertsCreate } from '../hooks';

const AlertsCreateContacts = ({
  alertsCreateState,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
}): ReactElement => {
  const modal = useModalsContext();
  const grafanaAlertManagerRequest = useRequest(
    getGrafanaAlertManager,
    true,
    true,
  );
  const { contactPoints, setContactPoints } = alertsCreateState;

  const [error, setError] = useState({
    getGrafanaAlertManager: null,
  });

  useEffect(() => {
    grafanaAlertManagerRequest
      .call('contact-point')
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({
            ...prevError,
            getGrafanaAlertManager: null,
          }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getGrafanaAlertManager: { message: sloErrors.getGrafanaAlertManager },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateContactModal = () => {
    modal.push(
      <div className="alerts__create__contacts__new-contact overflow-y-scroll">
        <AlertsContactsCreate
          onClose={(name) => {
            if (!name) {
              modal.pop();
              return;
            }
            setContactPoints((prev) => [...prev, name]);
            grafanaAlertManagerRequest
              .call('contact-point')
              .then((nextResult) => {
                if (nextResult) {
                  setError((prevError) => ({
                    ...prevError,
                    getGrafanaAlertManager: null,
                  }));
                }
              })
              .catch(() => {
                setError((prevError) => ({
                  ...prevError,
                  getGrafanaAlertManager: {
                    message: sloErrors.getGrafanaAlertManager,
                  },
                }));
              });
            modal.pop();
          }}
        />
      </div>,
      false,
    );
  };

  return (
    <div className="alerts__create__section">
      <div className="alerts__create__contacts-points">
        <div className="alerts__create__details__container__item">
          {grafanaAlertManagerRequest.error && (
            <div className="flex gap-[4px] w-full justify-end pr-[14px]">
              <IoIosWarning
                className="overlay-message__icon-and-message__icon"
                size={16}
              />
              <div className="text-red-500">
                {alertCreateErrors.grafanaAlertManager}
              </div>
            </div>
          )}

          <div
            className="alerts__create__details__container__item__textbox whitespace-nowrap"
            data-testid="contact-points-container"
          >
            <MultiselectV2
              onChange={(val) => setContactPoints(val)}
              options={grafanaAlertManagerRequest.result || []}
              placeholder="Choose contact points"
              value={contactPoints}
            />
          </div>
          <div className="alerts__create__details__container__item__action">
            <TooltipTrigger tooltip="Create new contact points">
              <Button
                variant="icon-primary"
                className="ml-2 rounded-full p-2"
                onClick={openCreateContactModal}
              >
                <MdGroupAdd />
              </Button>
            </TooltipTrigger>
            <ErrorMessage
              message={error?.getGrafanaAlertManager?.message}
              className="justify-start pl-[12px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsCreateContacts;
