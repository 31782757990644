import {
  AnomalyAlgorithmType,
  DateSelection,
  FunctionParamsProps,
  FunctionProps,
  LogsMetricQueryProps,
} from 'types';
import {
  getForecastLookbackAndPredictDate,
  getFunctionParams,
} from 'utils/MetricsQueryBuilder';

import { KfuseQlFunction } from './types';

export const buildKfuseQlForFunction = ({
  date,
  functions,
  step,
  groupOperation,
}: {
  date: DateSelection;
  functions: LogsMetricQueryProps['functions'];
  step: string;
  groupOperation: string;
}): {
  functionsKfuseQl: string;
  operation: string;
} => {
  const functionsKfuseQl: string[] = [];
  let operation = groupOperation;
  functions?.forEach((fn) => {
    const functionKfuseQl = getKfuseQlForFunction({
      date,
      fn,
      step,
      operation,
    });
    if (functionKfuseQl.functionStr) {
      functionsKfuseQl.push(functionKfuseQl.functionStr);
    }
    if (functionKfuseQl.operation) {
      operation = functionKfuseQl.operation;
    }
  });

  return {
    functionsKfuseQl: functionsKfuseQl.join(' | '),
    operation,
  };
};

export const seasonalityNumToText: { [key: number]: string } = {
  1: 'hourly',
  2: 'daily',
  3: 'weekly',
};

export const buildKfuseQlForAnomaly = ({
  fn,
  operation,
  step,
}: {
  fn: FunctionProps;
  operation: string;
  step: string;
}): {
  functionStr: string;
  operation: string;
} => {
  const { params } = fn;
  const [anomaly, _, bounds] = params;

  if (anomaly.value === 'agile-robust') {
    const [_, seasonality] = params;
    return {
      functionStr: `anomaly (${operation}) by ${step}, model=agileRobust, seasonality=${seasonalityNumToText[seasonality.value]}, bounds=${bounds.value}, band=3`,
      operation,
    };
  }

  if (anomaly.value === 'robust') {
    const [_, window] = params;
    return {
      functionStr: `anomaly (${operation}) by ${step}, model=robust, seasonality=daily, bounds=${bounds.value}, trend=additive, window=${window.value}, band=3`,
      operation,
    };
  }

  if (anomaly.value === 'agile') {
    const [_, bounds] = params;
    return {
      functionStr: `anomaly (${operation}) by ${step}, model=agile, bounds=${bounds.value}, band=3`,
      operation,
    };
  }

  if (anomaly.value === 'basic') {
    const [_, window] = params;
    return {
      functionStr: `anomaly (${operation}) by ${step}, model=basic, bounds=${bounds.value}, window=${window.value}, band=3`,
      operation,
    };
  }

  return { functionStr: ``, operation };
};

export const getKfuseQlForAnomalyAlerts = ({
  algorithm,
  band,
  bounds,
  operation,
  query,
  step,
  window,
}: {
  algorithm: AnomalyAlgorithmType;
  band: string;
  bounds: string;
  operation: string;
  query: string;
  step: string;
  window: string;
}): string => {
  if (algorithm === AnomalyAlgorithmType.BASIC) {
    return `${query} | anomaly (${operation}) by ${step}, model=basic, bounds=${bounds}, window=${window}, band=${band}`;
  }

  if (algorithm === AnomalyAlgorithmType.AGILE) {
    return `${query} | anomaly (${operation}) by ${step}, model=agile, bounds=${bounds}, band=${band}`;
  }

  return '';
};

export const buildKfuseQlForForecast = ({
  date,
  fn,
  step,
  operation,
}: {
  date: DateSelection;
  fn: FunctionProps;
  step: string;
  operation: string;
}): {
  functionStr: string;
  operation: string;
} => {
  const { params } = fn;
  const [algorithm, seasonality] = params;
  const forecastTime = date.endTimeUnix - date.startTimeUnix;
  const { predictedDate } = getForecastLookbackAndPredictDate({
    forecastDuration: `${forecastTime}s`,
    lookbackDuration: `${forecastTime}s`,
    date,
    type: 'logs',
  });
  const duration = predictedDate.endTimeUnix - predictedDate.startTimeUnix;
  if (algorithm.value === 'linear') {
    return {
      functionStr: `predict (${operation}) by ${step}, model=linear, forecast=${duration}s`,
      operation,
    };
  }

  if (algorithm.value === 'seasonal') {
    return {
      functionStr: `predict (${operation}) by ${step}, model=seasonal, seasonality=${seasonalityNumToText[seasonality.value]}, forecast=${duration}s`,
      operation,
    };
  }

  return { functionStr: ``, operation };
};

export const getKfuseQlForForecastAlerts = ({
  algorithm,
  operation,
  query,
  step,
  seasonality,
  forecastDuration,
  band,
}: {
  algorithm: string;
  operation: string;
  query: string;
  step: string;
  seasonality: number;
  forecastDuration: string;
  band: string;
}): string => {
  if (algorithm === 'linear') {
    return `${query} | predict (${operation}) by ${step}, model=linear, forecast=${forecastDuration}, band=${band}`;
  }

  if (algorithm === 'seasonal') {
    return `${query} | predict (${operation}) by ${step}, model=seasonal, seasonality=${seasonalityNumToText[seasonality]}, forecast=${forecastDuration}, band=${band}`;
  }

  return '';
};

export const buildKfuseQlForOutlier = ({
  fn,
  step,
  operation,
}: {
  fn: FunctionProps;
  step: string;
  operation: string;
}): {
  functionStr: string;
  operation: string;
} => {
  const { params } = fn;
  const [outlier, tolerance] = params;
  return {
    functionStr: `outlier (${operation}) by ${step}, model=${outlier.value.toLowerCase()}, eps=${tolerance.value}`,
    operation,
  };
};

export const getKfuseQlForOutlierAlerts = ({
  algorithm,
  band,
  tolerance,
  operation,
  query,
  step,
}: {
  algorithm: string;
  band?: number;
  tolerance: string;
  operation: string;
  query: string;
  step: string;
}): string => {
  if (algorithm === 'DBSCAN') {
    return `${query} | outlier (${operation}) by ${step}, model=dbscan, eps=${tolerance} ${band ? `, band=${band}` : ''}`;
  }
  return '';
};

export const getLogsFunctionNameParams = (
  functionName: string,
  step: string,
): FunctionParamsProps[] | null => {
  switch (functionName) {
    case KfuseQlFunction.ANOMALIES:
    case KfuseQlFunction.FORECAST:
    case KfuseQlFunction.OUTLIERS:
      return getFunctionParamsForAdvanceFunc(functionName, step);
    case KfuseQlFunction.ABS:
    case KfuseQlFunction.ACOS:
    case KfuseQlFunction.ASIN:
    case KfuseQlFunction.ATAN:
    case KfuseQlFunction.CEIL:
    case KfuseQlFunction.COS:
    case KfuseQlFunction.COSH:
    case KfuseQlFunction.EXP:
    case KfuseQlFunction.FLOOR:
    case KfuseQlFunction.LOG:
    case KfuseQlFunction.ROUND:
    case KfuseQlFunction.SIN:
    case KfuseQlFunction.SINH:
    case KfuseQlFunction.SQRT:
    case KfuseQlFunction.CBRT:
    case KfuseQlFunction.TAN:
    case KfuseQlFunction.TANH:
    case KfuseQlFunction.EXPM1:
      return null;
    default:
      return [];
  }
};

const getKfuseQlForFunction = ({
  date,
  fn,
  step,
  operation,
}: {
  date: DateSelection;
  fn: FunctionProps;
  step: string;
  operation: string;
}): { functionStr: string; operation: string } => {
  const functionName = fn.name;
  switch (functionName) {
    case KfuseQlFunction.ABS:
    case KfuseQlFunction.ACOS:
    case KfuseQlFunction.ASIN:
    case KfuseQlFunction.ATAN:
    case KfuseQlFunction.CEIL:
    case KfuseQlFunction.COS:
    case KfuseQlFunction.COSH:
    case KfuseQlFunction.EXP:
    case KfuseQlFunction.FLOOR:
    case KfuseQlFunction.LOG:
    case KfuseQlFunction.ROUND:
    case KfuseQlFunction.SIN:
    case KfuseQlFunction.SINH:
    case KfuseQlFunction.SQRT:
    case KfuseQlFunction.CBRT:
    case KfuseQlFunction.TAN:
    case KfuseQlFunction.TANH:
    case KfuseQlFunction.EXPM1:
      return {
        functionStr: `${functionName}(${operation}) as _${functionName}`,
        operation: `_${functionName}`,
      };
    case KfuseQlFunction.ANOMALIES:
      return buildKfuseQlForAnomaly({ fn, step, operation });
    case KfuseQlFunction.FORECAST:
      return buildKfuseQlForForecast({ date, fn, step, operation });
    case KfuseQlFunction.OUTLIERS:
      return buildKfuseQlForOutlier({ fn, step, operation });
    default:
      return { functionStr: '', operation: '' };
  }
};

const getFunctionParamsForAdvanceFunc = (
  functionName: string,
  step: string,
) => {
  switch (functionName) {
    case KfuseQlFunction.ANOMALIES:
      const hash = window.location.hash;
      const isAlertsPage = hash.startsWith('#/alerts/');
      const EXCLUDE_ANOMALY_ALGO = isAlertsPage
        ? ['agile-robust', 'robust']
        : [];
      const anomalyParams = getFunctionParams(KfuseQlFunction.ANOMALIES);
      const anomalyIndex = anomalyParams.findIndex(
        (param) => param.name === 'anomaly',
      );
      anomalyParams[anomalyIndex].options = anomalyParams[anomalyIndex].options;
      anomalyParams[anomalyIndex].value = 'basic';
      anomalyParams[anomalyIndex].default = 'basic';

      const windowIndex = anomalyParams.findIndex(
        (param) => param.name === 'window',
      );
      anomalyParams[windowIndex].value = step;

      const algoOptions = anomalyParams[0].options;
      anomalyParams[0].options = algoOptions.filter(
        (option) => !EXCLUDE_ANOMALY_ALGO.includes(option.value),
      );

      return anomalyParams;
    case KfuseQlFunction.FORECAST:
    case KfuseQlFunction.OUTLIERS:
      return getFunctionParams(functionName);
    default:
      return null;
  }
};

const getWindowOptions = (min: number, max: number, increment: number) => {
  const windowOptions = [];
  windowOptions.push({ label: `${min}`, value: `${min}` });
  for (let i = min + increment; i <= max; i += increment) {
    windowOptions.push({ label: `${i - 1}`, value: `${i - 1}` });
  }
  windowOptions.push({ label: `${max}`, value: `${max}` });
  return windowOptions;
};
