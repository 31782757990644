import {
  ChartLegendTableColumn,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { Filter, useSearch } from 'hooks';
import React, { useMemo, useState } from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ChartGridV2 } from 'components';
import multipleQueryRangeWithLabelsForRUM from 'utils/chartGrid/multipleQueryRangeWithLabelsForRUM';
import { formatLatencyYAxisTick } from 'utils';
import rumAggregateInstants from '../requests/rumAggregateInstants';
import { RumEventType } from '../types';

type GetRowArgs = {
  applicationFilter: string;
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  setDate: (date: DateSelection) => void;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  filters: Filter[];
  search: ReturnType<typeof useSearch>;
  updateLCP: (newLCP: number) => void;
  eventType: RumEventType;
};

export const getRows = ({
  applicationFilter,
  date,
  facetRegex,
  setDate,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  filters,
  search,
  updateLCP,
  eventType,
}: GetRowArgs) => {
  const instantQueryWithOverviewLCP = (
    date: DateSelection,
    aggregateFunction: string,
  ) => {
    return rumAggregateInstants({
      applicationFilter,
      endTimeUnix: date.endTimeUnix,
      facetRegex,
      startTimeUnix: date.startTimeUnix,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      aggregateFunction,
    }).then((nextResult) => {
      const LCPValue =
        nextResult?.[0]?.data?.['view.largest_contentful_paint'] ?? 0;
      updateLCP(LCPValue);
      return LCPValue;
    });
  };

  const onSelection = (newDate: DateSelection) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };

  const rumQueries = (aggregateField: string, aggregateFunction: string) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
    },
    queryType: 'rum-performance',
    eventType,
  });

  return [
    [
      {
        charts: [
          {
            key: 'p99Latency',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P99',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile99'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile99',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p99']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return instantQueryWithOverviewLCP(date, 'percentile99');
            },
            enableCreateAlert: true,
          },
          {
            key: 'p95Latency',
            colorMap: {
              p95: '#2BAF7F',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P95',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile95'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile95',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p95']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return instantQueryWithOverviewLCP(date, 'percentile95');
            },
            enableCreateAlert: true,
          },
          {
            key: 'p90Latency',
            colorMap: {
              p90: '#6F53B7',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P90',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile90'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile90',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p90']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return instantQueryWithOverviewLCP(date, 'percentile90');
            },
            enableCreateAlert: true,
          },
          {
            key: 'p75Latency',
            colorMap: {
              p75: '#cd863c',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P75',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile75'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile75',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p75']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return instantQueryWithOverviewLCP(date, 'percentile75');
            },
            enableCreateAlert: true,
          },
          {
            key: 'p50Latency',
            colorMap: {
              p50: '#A8D08D',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P50',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile50'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile50',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p50']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return instantQueryWithOverviewLCP(date, 'percentile50');
            },
            enableCreateAlert: true,
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RumLargestContentfulPaintOverview = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    dateState,
    filtersState,
  } = rumState;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const [date, setDate] = dateState;
  const search = useSearch();
  const [overviewLCP, setOverviewLCP] = useState<number>(0);

  const updateLCP = (newLCP: number) => {
    setOverviewLCP(newLCP);
  };

  const rows = useMemo(
    () =>
      getRows({
        applicationFilter,
        date,
        facetRegex: facetRegexState?.state,
        setDate,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        filters: filtersState.state,
        search,
        updateLCP,
        eventType: RumEventType.VIEW,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicationFilter,
      date,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <div className="rum__main overflow-auto">
      <div className="rum__performance-charts">
        <ChartGridV2.ChartGrid date={date} rows={rows} />
        {overviewLCP !== null && overviewLCP !== undefined && (
          <div className="rum__performance-charts-overlay">
            <div className="rum__performance-charts-overlay-text">
              {overviewLCP}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RumLargestContentfulPaintOverview;
