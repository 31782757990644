import React from 'react';
import { IoIosWarning } from 'react-icons/io';

export type OverlayMessageProps = {
  children?: React.ReactNode;
  minHeight?: number;
  maxHeight?: number;
} & (
  | {
      isActive: true;
      message: React.ReactNode;
      iconName: 'warning';
    }
  | {
      isActive?: false;
    }
);

const iconByName: { warning: React.ReactNode } = {
  warning: (
    <IoIosWarning
      className="overlay-message__icon-and-message__icon"
      size={60}
    />
  ),
};

const OverlayMessage = (props: OverlayMessageProps) => {
  // pass minHeight as null in OverlayMessageProps object if the overlay needs to be 100%
  const { children, minHeight = 346, maxHeight = null } = props;

  return (
    <div className="position--relative" style={{ minHeight, maxHeight }}>
      {props.isActive && (
        <div className="overlay-message__body">
          <div className="overlay-message__icon-and-message">
            {iconByName[props.iconName]}
            <div className="overlay-message__icon-and-message__message text-red-500">
              {props.message}
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default OverlayMessage;
