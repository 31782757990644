import { Loader, SizeObserver } from 'components';
import { Datepicker } from 'composite';
import React, { useEffect } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { alertCreateErrors } from 'utils/error';

import { AlertsCreateEvents } from '../AlertsCreateEvents';
import { AlertsCreateAPM } from '../AlertsCreateAPM';
import { useAlertsCreateCondition } from '../AlertsCreateCondition';
import { AlertsCreateLogs } from '../AlertsCreateLogs';
import { AlertsCreateMetrics } from '../AlertsCreateMetrics';
import { useAlertsCreate } from '../hooks';
import { AlertType, RuleType } from '../types';
import AlertsCreateSLO from './AlertsCreateSLO';
import { AlertsCreateTraces } from '../AlertsCreateTraces';
import { AlertsCreateRum } from '../AlertsCreateRUM';

const AlertsCreate = () => {
  const alertsCreateState = useAlertsCreate();

  const {
    alertsDetails,
    alertType,
    isEditing,
    isSaving,
    date,
    getGrafanaAlertsFoldersRequest,
    ruleType,
    setDate,
  } = alertsCreateState;

  const conditionState = useAlertsCreateCondition({
    alertsCreateState,
  });

  useEffect(() => {
    getGrafanaAlertsFoldersRequest.call();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAlertEditHeading = () => {
    const editOrCreate = isEditing ? 'Edit' : 'Create';
    if (ruleType === RuleType.METRICS) {
      return `${editOrCreate} Metric Alert`;
    }
    if (ruleType === RuleType.LOGS) {
      return `${editOrCreate} Log Alert`;
    }
    if (ruleType === RuleType.SLO) {
      return `${editOrCreate} SLO Alert`;
    }
    if (ruleType === RuleType.APM) {
      return `${editOrCreate} APM Alert`;
    }
    if (ruleType === RuleType.RUM) {
      return `${editOrCreate} RUM Alert`;
    }
    return '';
  };

  return (
    <div className="alerts__create">
      <SizeObserver>
        {({ width: baseWidth }) => (
          <Loader isLoading={isSaving}>
            <div className="alerts__create__header">
              <div
                className="text-lg font-semibold whitespace-nowrap"
                data-testid="alert-create-edit-title"
              >
                {getAlertEditHeading()} &nbsp; &nbsp;
                {isEditing && alertsDetails?.ruleName}
              </div>

              <div className="flex gap-[16px] items-center">
                <div className="flex justify-end gap-[8px]">
                  {getGrafanaAlertsFoldersRequest?.error && (
                    <div className="flex gap-[4px] justify-end pr-[14px]">
                      <IoIosWarning
                        className="overlay-message__icon-and-message__icon"
                        size={16}
                      />
                      <div className="text-red-500">
                        {alertCreateErrors.getGrafanaAlertsFolders}
                      </div>
                    </div>
                  )}
                </div>

                <div className="alerts__create__datepicker whitespace-nowrap">
                  {alertType !== AlertType.FORECAST && (
                    <Datepicker
                      className="logs__search__datepicker"
                      hasStartedLiveTail={false}
                      onChange={setDate}
                      startLiveTail={null}
                      value={date}
                    />
                  )}
                </div>
              </div>
            </div>
            {ruleType === RuleType.METRICS && (
              <AlertsCreateMetrics
                alertsCreateState={alertsCreateState}
                conditionState={conditionState}
                evaluationWindowTime={date.startLabel}
                baseWidth={baseWidth}
              />
            )}
            {ruleType === RuleType.LOGS && (
              <AlertsCreateLogs
                alertsCreateState={alertsCreateState}
                conditionState={conditionState}
                baseWidth={baseWidth}
              />
            )}
            {ruleType === RuleType.SLO && (
              <AlertsCreateSLO alertsCreateState={alertsCreateState} />
            )}
            {ruleType === RuleType.APM && (
              <AlertsCreateAPM
                alertsCreateState={alertsCreateState}
                conditionState={conditionState}
                baseWidth={baseWidth}
              />
            )}
            {ruleType === RuleType.RUM && (
              <AlertsCreateRum
                alertsCreateState={alertsCreateState}
                conditionState={conditionState}
                baseWidth={baseWidth}
              />
            )}
            {ruleType === RuleType.TRACES && (
              <AlertsCreateTraces
                alertsCreateState={alertsCreateState}
                conditionState={conditionState}
                baseWidth={baseWidth}
              />
            )}
            {ruleType === RuleType.EVENTS && (
              <AlertsCreateEvents
                alertsCreateState={alertsCreateState}
                conditionState={conditionState}
                baseWidth={baseWidth}
              />
            )}
          </Loader>
        )}
      </SizeObserver>
    </div>
  );
};

export default AlertsCreate;
