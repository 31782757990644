import {
  Badge,
  Button,
  ErrorMessage,
  Loader,
  Paginator,
  Table,
  TableOptionsPopover,
  TableSearch,
  TooltipTrigger,
  useColumnsState,
  useModalsContext,
  usePaginator,
  useTableOptions,
  useTableSearch,
  useTableSort,
  useThemeContext,
  useToaster,
} from 'components';
import dayjs from 'dayjs';
import { dateTimeFormat, MILLISECONDS } from 'kfuse-constants';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { findUnitCategoryFormatById, getColorRandom } from 'utils';

import LogsHydrationArchivesHydrate from './LogsHydrationArchivesHydrate';
import { ArchiveInfoProps, HydrationCloudType } from './types';
import useLogsHydrationState from './useLogsHydrationState';
import { logsErrors } from 'utils/error/logsErrors';
import { getInCloudLabel } from './utils';

const generateS3Url = (region: string, bucketName: string): string => {
  // https://us-west-2.console.aws.amazon.com/s3/buckets/{{bucketName}}
  return `https://${region}.console.aws.amazon.com/s3/buckets/${bucketName}`;
};

const archivesColumns = ({
  cloudType,
  onHydrate,
  utcTimeEnabled,
}: {
  cloudType: HydrationCloudType;
  onHydrate: (archive: ArchiveInfoProps) => void;
  utcTimeEnabled: boolean;
}) => [
  {
    key: 'FirstArchivedRecordUTCTsMs',
    label: 'First Archived Record',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      if (row.FirstArchivedRecordUTCTsMs < 0) {
        return <div className="flex items-center justify-center">-</div>;
      }
      const timestamp = row.FirstArchivedRecordUTCTsMs / MILLISECONDS;
      const timestampDayjs = utcTimeEnabled
        ? dayjs.unix(timestamp).utc()
        : dayjs.unix(timestamp);
      return <div>{timestampDayjs.format(dateTimeFormat)}</div>;
    },
  },
  {
    key: 'FirstObjectCreatedUTCTsMs',
    label: 'First Object Created',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      if (row.FirstObjectCreatedUTCTsMs < 0) {
        return <div className="flex items-center justify-center">-</div>;
      }
      const timestamp = row.FirstObjectCreatedUTCTsMs / MILLISECONDS;
      const timestampDayjs = utcTimeEnabled
        ? dayjs.unix(timestamp).utc()
        : dayjs.unix(timestamp);
      return <div>{timestampDayjs.format(dateTimeFormat)}</div>;
    },
  },
  { key: 'name', label: 'Name' },
  {
    key: 'approxSizeBytes',
    label: 'Approx Size',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      if (!row.ExistsInBucket) {
        return <div className="flex items-center justify-center">-</div>;
      }
      if (row.approxSizeBytes < 0) return 'Calculating...';

      const formatValue = findUnitCategoryFormatById('bytes');
      const formattedValue = formatValue.fn(row.approxSizeBytes);
      return <div>{`${formattedValue.text}${formattedValue.suffix}`}</div>;
    },
  },
  {
    key: 'ExistsInArchiveConfig',
    label: 'In Config',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => (
      <div>{row.inconfig}</div>
    ),
  },
  {
    key: 'ExistsInBucket',
    label: getInCloudLabel(cloudType),
    renderCell: ({ row }: { row: ArchiveInfoProps }) => (
      <div>{row.incloud}</div>
    ),
  },
  {
    key: 'location',
    label: 'Location',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      if (!row.ExistsInBucket) return;
      const bucketName = row.location.split('://')[1];
      const s3Url = generateS3Url(row.Region, bucketName);
      return (
        <TooltipTrigger tooltip={row.location}>
          <Link to={s3Url} target="_blank" className="link">
            <div className="max-w-[200px] truncate">{row.location}</div>
          </Link>
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'filters',
    label: 'Filters',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      if (!row.filters) return;
      return (
        <div className="max-w-[200px]">
          {row.filters.map((filter) => {
            return (
              <Badge
                key={`${filter.Matcher}-${filter.Operator}-${filter.Value}`}
                className="py-0.25 mb-1 mr-1 flex-wrap truncate rounded-sm px-1 text-xs font-medium text-white"
                variant="outline"
                style={{ backgroundColor: getColorRandom() }}
              >
                {filter.Matcher}
                <span className="mx-1 text-text-secondary">
                  {filter.Operator}
                </span>
                {filter.Value}
              </Badge>
            );
          })}
        </div>
      );
    },
  },
  {
    key: 'actions',
    label: '',
    renderCell: ({ row }: { row: ArchiveInfoProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '80px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider table__row__actions__slider--no-color">
              <div className="alerts__contacts__table__actions">
                <div className="mr-1.5 flex items-center py-1">
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() => onHydrate(row)}
                  >
                    Hydrate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const LogsHydrationArchives = ({
  logsHydrationState,
}: {
  logsHydrationState: ReturnType<typeof useLogsHydrationState>;
}): ReactElement => {
  const [error, setError] = useState({
    getArchivesList: null,
  });
  const {
    cloudType,
    getArchivesListRequest,
    setArchivesList,
    filteredHydrationArchivesList,
  } = logsHydrationState;
  const { addToast } = useToaster();
  const { utcTimeEnabled } = useThemeContext();
  const modal = useModalsContext();
  const tableOptions = useTableOptions({ shouldUseLocalStorage: false });

  const onHydrate = (archive: ArchiveInfoProps) => {
    modal.push(
      <LogsHydrationArchivesHydrate
        archive={archive}
        close={() => modal.pop()}
        onSuccess={(jobId: string) => {
          addToast({
            text: `Archive hydration initiated. Job ID: ${jobId}`,
            status: 'success',
          });
        }}
      />,
    );
  };

  const columns = useMemo(
    () => {
      const cols = archivesColumns({ cloudType, onHydrate, utcTimeEnabled });
      if (cloudType === HydrationCloudType.AZURE) {
        return cols.filter((col) => col.key !== 'location');
      }
      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cloudType, utcTimeEnabled],
  );
  const tableSearch = useTableSearch({ rows: filteredHydrationArchivesList });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    key: 'logs-hydration-archives',
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  const columnsState = useColumnsState({
    columns: columns.filter((col) => col.key !== 'actions'),
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        FirstArchivedRecordUTCTsMs: 1,
        FirstObjectCreatedUTCTsMs: 1,
        name: 1,
        approxSizeBytes: 1,
        ExistsInArchiveConfig: 1,
        ExistsInBucket: 1,
        location: 1,
        filters: 1,
      },
    },
    key: 'logs-hydration-archives-columns',
    shouldUseLocalStorage: false,
  });

  const actionsColumnsState = useMemo(() => {
    const renderedColumns = columnsState.renderedColumns;
    const actionsColumns = columns.filter((col) => col.key === 'actions');
    return [...renderedColumns, ...actionsColumns];
  }, [columnsState.renderedColumns, columns]);

  useEffect(() => {
    getArchivesListRequest
      .call()
      .then((res) => {
        if (res) {
          setError((prevError) => ({ ...prevError, getArchivesList: null }));
        }
        const formatted = res.map((archive) => {
          return {
            ...archive,
            incloud: archive.ExistsInBucket ? 'Yes' : 'No',
            inconfig: archive.ExistsInArchiveConfig ? 'Yes' : 'No',
          };
        });
        setArchivesList(formatted);
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getArchivesList: { message: logsErrors.getArchivesList },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-2">
      <ErrorMessage
        message={error?.getArchivesList?.message}
        className="justify-end pr-[14px]"
      />

      <div className="flex pb-1">
        <TableOptionsPopover
          columnsState={columnsState}
          tableOptions={tableOptions}
          shouldHideLinesToShow
        />
      </div>
      <TableSearch
        className="dashboard__list__search"
        placeholder="Search Archives..."
        tableSearch={tableSearch}
        dataTestId="dashboard-list-search"
      />
      <Loader isLoading={getArchivesListRequest.isLoading}>
        <Table
          columns={actionsColumnsState}
          rows={paginator.paginatedRows}
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default LogsHydrationArchives;
