import { MetricsQueryBuilder } from 'components';
import React, { ReactElement, useState } from 'react';
import { useCreateSLOState } from '../hooks';
import SLOCreateBuilderThreshold from './SLOCreateBuilderThreshold';
import useRequest from 'hooks/useRequest';
import useDebouncedEffect from 'use-debounced-effect';
import promqlLabels from 'requests/promqlLabels';

const SLOCreateNumerator = ({
  createSLOState,
}: {
  createSLOState: ReturnType<typeof useCreateSLOState>;
}): ReactElement => {
  const [error, setError] = useState({
    getMetadata: null,
  });
  const promqlLabelsRequest = useRequest(promqlLabels, true, true);

  const [metricType, setMetricType] = useState<string | null>(null);
  const { date, numeratorQueryState } = createSLOState;
  const { formulas, queries } = numeratorQueryState;

  const firstMetric = queries[0]?.metric || '';

  useDebouncedEffect(
    () => {
      if (firstMetric) {
        if (firstMetric.endsWith('_bucket')) {
          setMetricType('histogram');
        } else {
          promqlLabelsRequest
            .call({ date, matcher: `${firstMetric}{}` })
            .then((nextResult) => {
              if (nextResult) {
                setError((prevError) => ({ ...prevError, getMetadata: null }));
                const doesLabelsContainLe = nextResult.find(
                  (label) => label === 'le',
                );

                if (doesLabelsContainLe) {
                  setMetricType('histogram');
                } else {
                  setMetricType(null);
                  createSLOState.setSloCountThresold((prev) => ({
                    ...prev,
                    numerator: {
                      ...prev.numerator,
                      threshold: null,
                    },
                  }));
                }
              }
            })
            .catch(() => {
              setError((prevError) => ({
                ...prevError,
                getMetadata: { message: 'Failed to fetch metadata' },
              }));
            });
        }
      }
    },
    {
      ignoreInitialCall: false,
      timeout: 100,
    },
    [firstMetric],
  );

  return (
    <div className="slo__create__source--nume">
      <p>Good events (numerator)</p>

      <MetricsQueryBuilder
        onlyOneFunction
        blockedFunctionsCategories={[
          'Algorithms',
          'Rank',
          'Arithmetic',
          'Count',
          'Time',
          'Rate',
          'Exclusion',
          'Rollup',
          'Regression',
          'Smoothing',
          'Trigonometric',
        ]}
        blockedFunctionsNames={[
          'Average',
          'Count',
          'Maximum',
          'Minimum',
          'Standard Variance',
          'Standard Deviation',
          'Quantile',
        ]}
        customAggregateFunctions={[
          {
            label: 'sum',
            value: 'sum',
          },
        ]}
        ComponentAfterFrom={
          metricType === 'histogram' && (
            <SLOCreateBuilderThreshold
              createSLOState={createSLOState}
              type="numerator"
            />
          )
        }
        metricsQueryState={numeratorQueryState}
      />
      {queries.length > 1 || formulas.length > 0 ? (
        <p className="slo__create__source__warn-text">
          The SLO will be calculated based on the first active query or formula.
        </p>
      ) : null}
    </div>
  );
};

export default SLOCreateNumerator;
