import { ChartQueryArgs, DashboardPanelType, QueryDataProps } from 'types';
import {
  calculateRateIntervalVariableValue,
  combineRangeQueryData,
  legendCustomColorTimeseries,
} from 'utils';
import rumDataTransformer from 'utils/DataTransformer/rumDataTransformer';
import {
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types/selectedFacets';
import rumSlowRenderTimeSeries from 'screens/Rum/requests/rumSlowRenderTimeSeries';

type RumQueryArgs = {
  applicationFilter: string;
  facetRegex?: FacetRegexTerm[];
  groupByLimit: number;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  selectedFacetRangeByName?: SelectedFacetRangeByName;
  groupByField?: string;
  groupBySortField?: string;
  groupBySortAggregation?: string;
  advancedGroupBys?: {
    by: string;
    limitTo: string;
    limitToValue: number;
    sortAggregation?: string;
    sortField?: string;
  }[];
};

const multipleQueryRangeWithLabelsForRUMSlowRender =
  (
    queries: (() => RumQueryArgs)[],
    legendFormats: (string | ((metric: Record<string, any>) => string))[][],
  ) =>
  ({ chart, date }: ChartQueryArgs) => {
    const { colorMap, chartType, eventType } = chart;
    const { stepInMs } = calculateRateIntervalVariableValue(date, chartType);

    const rumQueryArgs = queries.map((query) => query());
    const applicationFilter = rumQueryArgs[0].applicationFilter;
    const selectedFacetValuesByName = rumQueryArgs[0].selectedFacetValuesByName;
    const selectedFacetRangeByName = rumQueryArgs[0].selectedFacetRangeByName;
    const facetRegex = rumQueryArgs[0].facetRegex;

    return Promise.all(
      rumQueryArgs.map((query, idx) => {
        const transformer = rumDataTransformer();
        transformer.push({
          id: 'legendCustomColorTimeseries',
          func: (rangeData: { data: QueryDataProps['query']['range'] }) =>
            legendCustomColorTimeseries(rangeData.data, colorMap),
        });

        return rumSlowRenderTimeSeries({
          applicationFilter,
          date,
          facetRegex,
          rollupSeconds: stepInMs / 1000,
          transformer,
          meta: {
            step: stepInMs / 1000,
            refId: 'a',
            type: DashboardPanelType.TIMESERIES,
          },
          selectedFacetValuesByName,
          selectedFacetRangeByName,
          eventType,
          advancedGroupBys: query.groupByField
            ? [
                {
                  by: query.groupByField,
                  limitToValue: query.groupByLimit,
                  sortAggregation: query.groupBySortAggregation || 'count',
                  sortField: query.groupBySortField || '*',
                  limitTo: 'desc',
                },
              ]
            : [],
        });
      }),
    ).then((data) => {
      const meta = {
        refId: 'a',
        step: stepInMs / 1000,
        type: DashboardPanelType.TIMESERIES,
      };

      const queryData: QueryDataProps = {};
      const queriesBasic: { queryKey: string; isActive: boolean }[] = [];
      data.forEach((item, index) => {
        const refId = 'a';
        queryData[`query_${refId}`] = {
          range: item,
          meta: meta,
          isLoading: false,
        };
        queriesBasic.push({ queryKey: refId, isActive: true });
      });

      const combinedData = combineRangeQueryData({
        queries: queriesBasic,
        formulas: [],
        queryData: queryData,
        combineTimestamp: false,
      });
      return combinedData;
    });
  };

export default multipleQueryRangeWithLabelsForRUMSlowRender;
