import React from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import RumCumulativeLayoutShiftOverview from './RumCoreWebVitals/RumCumulativeLayoutShiftOverview';
import RumInteractionToNextPaintOverview from './RumCoreWebVitals/RumInteractionToNextPaintOverview';
import RumLargestContentfulPaintOverview from './RumCoreWebVitals/RumLargestContentfulPaintOverview';
import RUMAppStartupTime from './RUMMobileVitals/RUMAppStartupTime';
import RUMMemoryUsage from './RUMMobileVitals/RUMMemoryUsage';
import RUMWidgetBuildTime from './RUMMobileVitals/RUMWidgetBuildTime';
import RUMRefreshRate from './RUMMobileVitals/RUMRefreshRate';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RumPerformanceOverview = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const { applicationInfo } = rumState;

  return (
    <div>
      {applicationInfo?.type === 'web' && (
        <div>
          <div className="rum__performance-subtitle">Core Web Vitals</div>
          <div className="rum-performance-overview-panel-content">
            <div className="rum-performance-overview">
              <div className="rum-performance-overview-item">
                <RumLargestContentfulPaintOverview
                  rumPageState={rumPageState}
                />
              </div>
              <div className="rum-performance-overview-item">
                <RumCumulativeLayoutShiftOverview rumPageState={rumPageState} />
              </div>
              <div className="rum-performance-overview-item">
                <RumInteractionToNextPaintOverview
                  rumPageState={rumPageState}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {applicationInfo?.type !== 'web' && (
        <div className="rum-performance-overview-panel">
          <div className="rum__performance-subtitle">Mobile Vitals</div>
          <div className="rum-performance-overview-panel-content">
            <div className="rum-performance-overview">
              <div className="rum-performance-overview-item">
                <RUMAppStartupTime rumPageState={rumPageState} />
              </div>
              <div className="rum-performance-overview-item">
                <RUMMemoryUsage rumPageState={rumPageState} />
              </div>
              <div className="rum-performance-overview-item">
                <RUMWidgetBuildTime rumPageState={rumPageState} />
              </div>
              {/* this is for Android */}
              {/* <div className="rum-performance-overview-item">
              <RUMRefreshRate rumPageState={rumPageState} />
            </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RumPerformanceOverview;
