import { AutocompleteV2, Input } from 'components';
import React, { ReactElement } from 'react';
import { components } from 'react-select';

import { useCreateSLOState } from '../hooks';
import { countOptions, thesholdOperatorOptions } from '../utils';

const SLOCreateBuilderThreshold = ({
  createSLOState,
  type,
}: {
  createSLOState: ReturnType<typeof useCreateSLOState>;
  type: 'numerator' | 'denominator';
}): ReactElement => {
  const { sloCountThresold, setSloCountThresold } = createSLOState;

  return (
    <>
      <div className="slo__create__threshold__unit">Latency Threshold</div>
      <Input
        className="input--no-border metrics__function-builder__item__params__input metrics__function-builder__latency"
        onChange={(val) => {
          setSloCountThresold({
            ...sloCountThresold,
            [type]: { ...sloCountThresold[type], threshold: val },
          });
        }}
        type="number"
        value={sloCountThresold[type].threshold || ''}
      />
      <div className="search__button-group__divider" />
    </>
  );
};

export default SLOCreateBuilderThreshold;
