import { AutocompleteOption } from 'components';
import {
  ExplorerQueryProps,
  FormulaProps,
  MetricsQueryItemProps,
  DateSelection,
  DashboardPanelType,
  QueryCombinedStatusProps,
} from 'types';
import { parsePromqlAndBuildQuery, DataFrame } from 'utils';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';

import { getMetricsExplorerDefaultQuery } from '..';
import { buildPromqlWithFunctions } from './query-utils';

/**
 * Transform for series list
 * @returns
 * example: [{"name": "value", "age": 21}, { "name": "some", "age": 22}] => ["name:value", "age:21", "name:some", "age:22"]
 */
export const transformSeriesList = (
  grafanaSeries: any,
): {
  labelsListOptions: AutocompleteOption[];
  seriesListOptions: AutocompleteOption[];
  seriesValuesOptions: { [key: string]: AutocompleteOption[] };
} => {
  const labels: { [key: string]: { [key: string]: boolean } } = {};
  grafanaSeries.map((series: any) => {
    delete series.__name__;
    Object.keys(series).map((item) => {
      if (!labels[item]) {
        labels[item] = {};
      }
      labels[item][series[item]] = true;
    });
  });

  const labelList = Object.keys(labels);
  const seriesValuesOptions: { [key: string]: AutocompleteOption[] } = {};
  const labelsListOptions: AutocompleteOption[] = [];
  const seriesListOptions: AutocompleteOption[] = [];

  labelList.map((label) => {
    labelsListOptions.push({ label, value: label });
    seriesListOptions.push({ label, value: `${label}=""` });

    const seriesValues = Object.keys(labels[label]).map((value) => {
      return { label: value, value };
    });
    seriesValuesOptions[label] = seriesValues;
  });

  return { labelsListOptions, seriesListOptions, seriesValuesOptions };
};

export const buildFormulaQuery = (
  promqlQueries: string[],
  queryKeys: string[],
  formulas: FormulaProps[],
): string[] => {
  const formulaQueries: string[] = [];
  const promqlBitmap: { [key: string]: string } = {};
  promqlQueries.forEach((query, index) => {
    promqlBitmap[queryKeys[index]] = query;
  });

  formulas.forEach((formula: FormulaProps) => {
    if (formula.isValid) {
      let expression = '';
      const formulaLength = formula.expression.length;
      for (let i = 0; i < formulaLength; i++) {
        const char = formula.expression.charAt(i);
        if (queryKeys.includes(char)) {
          expression += `( ${promqlBitmap[char]} )`;
        } else {
          expression += char;
        }
      }

      // replace + with %2B if exists
      expression = expression.replaceAll(/\+/g, '%2B');
      formulaQueries.push(expression);
    }
  });

  return formulaQueries;
};

export const getMetricsExplorerUrl = (
  metricName: string,
  tagValue?: string,
): string => {
  const defaultQuery = getMetricsExplorerDefaultQuery(metricName, 0);
  if (tagValue) {
    const splitTag = tagValue.split(':');
    const tag = splitTag[0];
    const value = splitTag.slice(1).join(':');
    const newSeries = [`${tag}="${value}"`];
    defaultQuery['series'] = newSeries;
  }
  const url = `/#/metrics?metricsQueries=${encodeURIComponent(
    JSON.stringify([defaultQuery]),
  )}`;

  return url;
};

export const getMetricsExplorerUrlByPromql = (promql: string): string => {
  const { queries, formulas } = parsePromqlAndBuildQuery([promql]);
  const queriesURI = encodeURIComponent(JSON.stringify(queries));
  const formulasURI = encodeURIComponent(JSON.stringify(formulas));
  const url = `/#/metrics?metricsQueries=${queriesURI}&metricsFormulas=${formulasURI}`;

  return url;
};

export const decodePromqlToReadable = (promql: string): string => {
  if (!promql) return promql;

  let encodedPromql = decodeURI(promql);

  // replace all the %2B with +
  if (encodedPromql.includes('%2B')) {
    encodedPromql = encodedPromql.replaceAll(/%2B/g, '+');
  }

  // replace all the %2F with /
  if (encodedPromql.includes('%2F')) {
    encodedPromql = encodedPromql.replaceAll(/%2F/g, '/');
  }

  return encodedPromql;
};

export const getPromqlQueryByIndex = (
  queryItem: MetricsQueryItemProps,
  date?: DateSelection,
): string | string[] => {
  const { formulas, queries, queryIndex, type, returnType } = queryItem;
  if (type === 'query') {
    const query = { ...queries[queryIndex] };
    if (query.showInput) {
      return query.promql;
    }

    if (!query.metric) {
      return '';
    }

    const promqlQuery = buildPromqlWithFunctions(query, returnType, date);
    if (promqlQuery) {
      return promqlQuery;
    }
  }

  if (type === 'formula') {
    const formula = formulas[queryIndex];
    const queriesForFormula: string[] = [];
    queries.forEach((query) => {
      const promqlQuery = buildPromqlWithFunctions(query);
      queriesForFormula.push(promqlQuery);
    });
    const queryKeys = queries.map((query) => query.queryKey);
    const promqlFormula = buildFormulaQuery(queriesForFormula, queryKeys, [
      formula,
    ]);

    if (promqlFormula[0]) {
      return promqlFormula[0];
    }
  }

  return '';
};

export const isMetricsQueryHasAdvanceFunc = (query: ExplorerQueryProps) => {
  if (!query || !query.functions)
    return { isAnomaly: false, isForecast: false, isOutlier: false };
  const isAnomaly = query.functions?.some(
    (func) => func.name === KfuseQlFunction.ANOMALIES,
  );
  const isForecast = query.functions?.some(
    (func) => func.name === KfuseQlFunction.FORECAST,
  );
  const isOutlier = query.functions?.some(
    (func) => func.name === KfuseQlFunction.OUTLIERS,
  );
  return { isAnomaly, isForecast, isOutlier };
};

export const combineInstantQueriesData = ({
  dataFormat,
  formulas,
  queries,
  queryData,
}: {
  dataFormat: DashboardPanelType;
  formulas: QueryCombinedStatusProps[];
  queries: QueryCombinedStatusProps[];
  queryData: { [key: string]: DataFrame };
}) => {
  const chartKeys = Object.keys(queryData);
  let newData: any = [];
  const uniqueLabels: string[] = [];
  let isLoading = false;
  const tableRows = [];

  chartKeys.forEach((key) => {
    if (!queryData[key]) return;
    if (queryData[key].isLoading) {
      isLoading = true;
    }

    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }

    if (!query || !query.isActive || !queryData[key]?.data) return;
    const { data, labels, tableData, type: queryType } = queryData[key];

    if (queryType === 'range') {
      return;
    }

    if (Array.isArray(data)) {
      newData = [...newData, ...data];
    } else if (typeof data === 'object') {
      newData = data;
    }

    tableRows.push(...(tableData || []));
    uniqueLabels.push(...(labels || []));
  });

  return {
    data: newData,
    isLoading,
    labels: uniqueLabels,
    tableData: tableRows,
  };
};
