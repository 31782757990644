import { useRequest, useSearches } from 'hooks';
import { useEffect } from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import { DashboardPanelType } from 'types';

import { ConditionProps } from '../../AlertsCreateCondition';
import { logsAlertValidatorForCreate } from '../../AlertsCreateValidators';
import { useAlertsCreate } from '../../hooks';
import { AlertType, RuleType } from '../../types';
import { getGraphQLQueryStringForTracesAlert } from '../../AlertsCreateTraces/utils';
import {
  RumEventType,
  rumLabels,
  useRumState,
  rumAnalyticsFetchMulti,
} from 'screens/Rum';
import { getUrlParamByKey, parseUrlParamByKey } from 'utils/url';
import { customOperationForMeasure } from 'screens/Rum/utils/customOperationForMeasure';
import { useLocation } from 'react-router-dom';

const useAlertsCreateRum = ({
  alertsCreateState,
  baseWidth,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  baseWidth: number;
}) => {
  const {
    alertsDetails,
    addToast,
    date,
    mutateAlertsRule,
    setIsSaving,
    setErrorHandling,
  } = alertsCreateState;

  const { searches } = useSearches({ customOperationForMeasure });
  const url = useLocation();
  const params = new URLSearchParams(url.search);
  const rumEventType = params.get('rumEventType');
  const rumApplicationFilter = params.get('rumApplicationFilter');
  const rumState = useRumState({
    dateState: [date, () => {}],
    initialApplicationFilter: getUrlParamByKey('rumApplicationFilter'),
    initialEventType: getUrlParamByKey('rumEventType') || RumEventType.VIEW,
    initialFilters: parseUrlParamByKey('rumFilters'),
    shouldWriteToUrl: false,
    urlStateKey: 'rumFilters',
  });
  const { applicationInfo, applicationFilter, state } = rumState;
  const queries = searches.map((s) => s.state);
  const rumLabelNamesRequest = useRequest(rumLabels);

  useEffect(() => {
    const currentAggregations = searches[0].state.multiAggregations;
    if (currentAggregations?.length > 1) {
      searches[0].setOnlyOneMultiAggregationBy(
        currentAggregations[0].measure,
        currentAggregations[0].operation,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebouncedEffect(
    () => {
      rumLabelNamesRequest.call({
        applicationInfo,
        eventType: rumState.eventType,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 200,
      ignoreInitialCall: false,
    },
    [applicationInfo, rumState.eventType],
  );

  const createRumAlertThreshold = async (condition: ConditionProps) => {
    const { queryKey } = condition;
    const validation = logsAlertValidatorForCreate({
      alertsDetails,
      condition,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const extraData = {
      queryType: 'query',
      queries,
      formulas: [],
      queryKey,
      rumSpecificData: {
        eventType: rumState.eventType,
        applicationFilter,
        idSearch: state.idSearch,
        selectedFacetValuesByName: state.selectedFacetValuesByName,
        facetRegex: state.facetRegex,
        selectedFacetRangeByName: state.selectedFacetRangeByNameState,
        searches,
        filterState: rumState.filtersState,
      },
    };

    const queryStrings = await rumAnalyticsFetchMulti({
      returnQueryString: true,
      applicationFilter,
      date,
      eventType: rumState.eventType,
      dataFormat: DashboardPanelType.TIMESERIES,
      formulas: [],
      instant: false,
      query: queries[0],
      transformer: [],
      selectedFacetValuesByName: state.selectedFacetValuesByName,
      facetRegex: state.facetRegex,
      selectedFacetRangeByName: state.selectedFacetRangeByNameState,
      idSearch: state.idSearch,
    });

    const queryString = getGraphQLQueryStringForTracesAlert({
      queryStrings: [queryStrings],
      queryType: 'query',
    });

    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'rum',
      promqlQuery: queryString,
      ruleAnnotations: {
        ruleType: RuleType.RUM,
        extraData: JSON.stringify(extraData),
        alertType: AlertType.THRESHOLD,
      },
    });
  };

  return {
    createRumAlertThreshold,
    queries,
    searches,
    rumState,
    rumLabelNamesRequest,
  };
};

export default useAlertsCreateRum;
