import classnames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { AiOutlineLineChart } from 'react-icons/ai';
import { BsFilterLeft, BsPieChartFill } from 'react-icons/bs';
import { HiTableCells } from 'react-icons/hi2';
import { ImTable } from 'react-icons/im';
import { RiFingerprintLine } from 'react-icons/ri';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DashboardPanelType } from 'types/Dashboard';

type Args = {
  navigate: ReturnType<typeof useNavigate>;
  params: URLSearchParams;
  isAdvancedFuseQlSearch: boolean;
  isAnalyticsViewEnabledForAdvancedFuseQlSearch: boolean;
  isTimeseriesTabEnabledForAdvancedFuseQlSearch: boolean;
  tab: string;
};

const getItems = ({
  navigate,
  params,
  isAdvancedFuseQlSearch,
  isAnalyticsViewEnabledForAdvancedFuseQlSearch,
  isTimeseriesTabEnabledForAdvancedFuseQlSearch,
  tab,
}: Args) => {
  const removeAnalyticsTabs =
    isAdvancedFuseQlSearch && !isAnalyticsViewEnabledForAdvancedFuseQlSearch;

  const removeTimeseriesTab =
    isAdvancedFuseQlSearch && !isTimeseriesTabEnabledForAdvancedFuseQlSearch;
  const result = [
    {
      key: 'logs',
      icon: <ImTable size={14} />,
      isActive: tab === undefined || tab === 'live-tail',
      label: 'Logs',
      onClick: () => {
        navigate(`/logs?${params.toString()}`);
      },
    },
    isAdvancedFuseQlSearch
      ? null
      : {
          key: 'fingerprints',
          icon: <RiFingerprintLine size={14} />,
          isActive: tab === 'fingerprints',
          label: 'Fingerprints',
          onClick: () => {
            navigate(`/logs/fingerprints?${params.toString()}`);
          },
        },
    // {
    //   key: 'transactions',
    //   icon: <CgArrowsExchangeAlt size={16} />,
    //   isActive: tab === 'transactions',
    //   label: 'Transactions',
    //   onClick: () => {
    //     navigate(`/logs/transactions?${params.toString()}`);
    //   },
    // },
    removeTimeseriesTab
      ? null
      : {
          key: DashboardPanelType.TIMESERIES,
          icon: <AiOutlineLineChart size={14} />,
          isActive: tab === DashboardPanelType.TIMESERIES,
          label: 'Time Series',
          onClick: () => {
            navigate(
              `/logs/${DashboardPanelType.TIMESERIES}?${params.toString()}`,
            );
          },
        },
    removeAnalyticsTabs
      ? null
      : {
          key: DashboardPanelType.TOP_LIST,
          icon: <BsFilterLeft size={14} />,
          isActive: tab === DashboardPanelType.TOP_LIST,
          label: 'Top List',
          onClick: () => {
            navigate(
              `/logs/${DashboardPanelType.TOP_LIST}?${params.toString()}`,
            );
          },
        },
    removeAnalyticsTabs
      ? null
      : {
          key: DashboardPanelType.TABLE,
          icon: <HiTableCells size={14} />,
          isActive: tab === DashboardPanelType.TABLE,
          label: 'Table',
          onClick: () => {
            navigate(`/logs/${DashboardPanelType.TABLE}?${params.toString()}`);
          },
        },
    removeAnalyticsTabs
      ? null
      : {
          key: DashboardPanelType.PIECHART,
          icon: <BsPieChartFill size={14} />,
          isActive: tab === DashboardPanelType.PIECHART,
          label: 'Pie Chart',
          onClick: () => {
            navigate(
              `/logs/${DashboardPanelType.PIECHART}?${params.toString()}`,
            );
          },
        },
  ].filter(Boolean);

  return result;
};

const LogsTabs = ({
  hasAdvanceFunction,
  isAdvancedFuseQlSearch,
  isAnalyticsViewEnabledForAdvancedFuseQlSearch,
  isTimeseriesTabEnabledForAdvancedFuseQlSearch,
}: {
  hasAdvanceFunction: boolean;
  isAdvancedFuseQlSearch: boolean;
  isAnalyticsViewEnabledForAdvancedFuseQlSearch: boolean;
  isTimeseriesTabEnabledForAdvancedFuseQlSearch: boolean;
}): ReactElement => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [params] = useSearchParams();

  const items = useMemo(() => {
    const tabs = getItems({
      navigate,
      params,
      isAdvancedFuseQlSearch,
      isAnalyticsViewEnabledForAdvancedFuseQlSearch,
      isTimeseriesTabEnabledForAdvancedFuseQlSearch,
      tab,
    });
    if (hasAdvanceFunction) {
      return tabs.filter(
        (tab) =>
          tab.key !== DashboardPanelType.TABLE &&
          tab.key !== DashboardPanelType.TOP_LIST &&
          tab.key !== DashboardPanelType.PIECHART,
      );
    }
    return tabs;
  }, [
    navigate,
    params,
    isAdvancedFuseQlSearch,
    isAnalyticsViewEnabledForAdvancedFuseQlSearch,
    isTimeseriesTabEnabledForAdvancedFuseQlSearch,
    tab,
    hasAdvanceFunction,
  ]);

  return (
    <div className="logs__tabs">
      {items.map((item) => (
        <button
          className={classnames({
            logs__tabs__item: true,
            'logs__tabs__item--active': item.isActive,
          })}
          key={item.key}
          onClick={item.onClick}
        >
          <div className="logs__tabs__item__icon">{item.icon}</div>
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default LogsTabs;
