import { Button, useModalsContext, useColumnsState } from 'components';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useLogsState, useRequest } from 'hooks';
import LogsSaveQueryFolderExport from './LogsSaveQueryFolderExport';

const LogsSaveQueryPopover = ({
  customColumnsState,
  getWorkbooksRequest,
  logsState,
}: {
  customColumnsState: ReturnType<typeof useColumnsState>;
  getWorkbooksRequest: ReturnType<typeof useRequest>;
  logsState: ReturnType<typeof useLogsState>;
}): ReactElement => {
  const { tab } = useParams();
  const model = useModalsContext();

  const onAddNewDashboard = () => {
    model.push(
      <LogsSaveQueryFolderExport
        customColumnsState={customColumnsState}
        closeModal={model.pop}
        getWorkbooksRequest={getWorkbooksRequest}
        logsState={logsState}
      />,
    );
  };

  if (tab !== undefined && tab !== 'fingerprints') return null;

  return (
    <Button
      className="mx-2"
      onClick={onAddNewDashboard}
      size="xs"
      variant="outline-secondary"
    >
      Save Query
    </Button>
  );
};

export default LogsSaveQueryPopover;
