import {
  useSearches,
  useSearchFormulas,
  useRequest,
  useTracesState,
} from 'hooks';
import { useMemo } from 'react';
import {
  aggregateTimeSeriesMultipleV2,
  traceLabelNamesBase,
  getServices,
} from 'requests';
import { SelectedFacetValuesByName } from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import { parseUrlParamByKey } from 'utils/url';

import { ConditionProps } from '../AlertsCreateCondition';
import { useAlertsCreate } from '../hooks';
import {
  getGraphQLQueryStringForTracesAlert,
  getQueriesAndFormulasFromAnnotations,
  getSelectedTracesQueryOrFormula,
} from './utils/alertsCreateTraces';
import { logsAlertValidatorForCreate } from '../AlertsCreateValidators';
import { AlertType, RuleProps, RuleType } from '../types';

const useAlertsCreateTraces = ({
  alertsCreateState,
  rule,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  rule: RuleProps;
}) => {
  const {
    addToast,
    alertsDetails,
    date,
    mutateAlertsRule,
    setErrorHandling,
    setIsSaving,
  } = alertsCreateState;
  const traceSearches = parseUrlParamByKey('alertsTraceSearches');
  const {
    queries: initialQueries,
    formulas: initialFormulas,
    spanFilter: initialSpanFilter,
  } = getQueriesAndFormulasFromAnnotations(rule?.annotations);
  const { searches } = useSearches({
    shouldWriteToUrl: false,
    initialSearches: traceSearches || initialQueries,
  });
  const queries = useMemo(
    () => searches.map((search) => search.state),
    [searches],
  );
  const searchesFormulas = useSearchFormulas(queries, initialFormulas);
  const { formulas } = searchesFormulas;
  const tracesPageState = useTracesState({
    shouldWriteToUrl: false,
    initialSpanFilter,
  });
  const { spanFilters } = tracesPageState;
  const traceLabelNamesRequest = useRequest(traceLabelNamesBase, true, true);
  const getServicesRequest = useRequest(getServices, true, true);

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  useDebouncedEffect(
    () => {
      const selectedFacetValuesByName = { span_type: { db: 0 } };
      getServicesRequest.call({
        customerFilter: {},
        date,
        selectedFacetValuesByName:
          selectedFacetValuesByName as SelectedFacetValuesByName,
      });
      traceLabelNamesRequest.call({ date });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { ignoreInitialCall: false, timeout: 50 },
    [date],
  );

  const createTracesAlertThreshold = async (condition: ConditionProps) => {
    const { queryKey } = condition;
    const selectedQueryOrFormula = getSelectedTracesQueryOrFormula(
      formulas,
      queries,
      queryKey,
    );
    if (!selectedQueryOrFormula) {
      addToast({ status: 'error', text: 'No query or formula selected' });
      return;
    }

    const { formulas: selectedFormulas, queries: selectedQueries } =
      selectedQueryOrFormula;

    const validation = logsAlertValidatorForCreate({
      alertsDetails,
      condition,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const queryType = queryKey.includes('Query') ? 'query' : 'formula';
    const extraData = {
      queryType,
      queries: selectedQueries,
      formulas: selectedFormulas,
      queryKey,
      spanFilter: spanFilters.spanFilter,
    };

    const queryStrings = await aggregateTimeSeriesMultipleV2({
      date,
      queries: selectedQueries,
      formulas: selectedFormulas,
      returnQueryString: true,
      spanFilter: spanFilters.spanFilter,
      transformer: [],
    });

    const graphQLQueryString = getGraphQLQueryStringForTracesAlert({
      queryStrings,
      queryType,
    });

    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'traces',
      promqlQuery: graphQLQueryString,
      ruleAnnotations: {
        ruleType: RuleType.TRACES,
        alertType: AlertType.THRESHOLD,
        extraData: JSON.stringify(extraData),
      },
    });
  };

  return {
    createTracesAlertThreshold,
    formulas,
    getServicesRequest,
    queries,
    searches,
    searchesFormulas,
    serviceByHash,
    traceLabelNamesRequest,
    tracesPageState,
  };
};

export default useAlertsCreateTraces;
