import dayjs from 'dayjs';
import {
  useKeyExistsState,
  useRequest,
  useSubscriptionRequest,
  useUrlState,
} from 'hooks';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getLogsV2, buildQuery } from 'requests';
import { snakeCase } from 'snake-case';
import { QueryLangType } from 'types/kfuseQl';
import {
  getFacetKey,
  onPromiseError,
  parseAndTransformQueryFromAggregationToList,
  tryJsonParse,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';

const formatSortBy = (s: string) => {
  const parts = s.split('.');
  const key = parts[parts.length - 1];

  if (key === 'timestamp') {
    return 'ts';
  }

  return snakeCase(key);
};

const getShouldUseSubscription = (sort) => {
  if (
    (sort.sortBy === 'timestamp' || sort.sortBy === null) &&
    sort.sortOrder === 'Desc'
  ) {
    return true;
  }

  return false;
};

const queryBuilder = (...args) => {
  const { cursor = null, limit = 200, logsState, sort } = args[0];
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;

  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const logQuery = buildQuery({
    ...logsState,
    logLevel: null,
  });
  return `
    subscription {
      getLogsV2Stream(
        cursor: ${cursor ? `"${cursor}"` : 'null'},
        ${logQuery !== '{}' ? `query: ${logQuery},` : ''}
        limit: ${limit},
        timestamp: "${endTime.format()}",
        durationSecs: ${durationSecs}
        ${
          sort?.sortBy && sort?.sortOrder
            ? `sortBy: "${formatSortBy(sort.sortBy)}"`
            : ''
        }
        ${sort?.sortBy && sort?.sortOrder ? `sortOrder: ${sort.sortOrder}` : ''}
      ) {
        cursor
        events {
          timestamp
          logLine
          fpString
          fpHash
          level
          labels
          facets {
            name
            dataType
            content
          }
        }
      }
    }
  `;
};

const queryBuilderWithFuseQlStream = (...args) => {
  const { cursor = null, limit = 200, logsState, sort, freeText } = args[0];
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;

  const startTime = dayjs.unix(startTimeUnix);
  const endTime = dayjs.unix(endTimeUnix);

  const parsedQueryForList = parseAndTransformQueryFromAggregationToList({
    query: freeText || '',
    sort,
  });
  return `
    subscription {
      getLogsWithFuseQlStream(
        cursor: ${cursor ? `"${cursor}"` : 'null'},
        query: "${parsedQueryForList}",
        startTs:  "${startTime.format()}",
        endTs: "${endTime.format()}",
      ) {
        TableResult
        ColumnHeaders
        Cursor
      }
    }
  `;
};

const useLogsTable = ({
  queryLangType,
  customColumnsState,
}: {
  queryLangType?: QueryLangType;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
}) => {
  const appendLogsSubscriptionRequest = useSubscriptionRequest({
    queryBuilder,
    initialState: [],
    key: 'getLogsV2Stream',
    merge: () => {},
    onReceivedMessagePayload: (next) => {
      const { cursor, events } = next;
      cursorRef.current = cursor;

      setLogs((prevLogs) => [
        ...prevLogs,
        ...events.map((event) => ({
          ...event,
          message: event.logLine,
          extraFacets: event.facets,
          fpPattern: event.fpString,
        })),
      ]);
    },
  });

  const appendLogsWithFuseQlSubscriptionRequest = useSubscriptionRequest({
    queryBuilder: queryBuilderWithFuseQlStream,
    initialState: [],
    key: 'getLogsWithFuseQlStream',
    merge: () => {},
    onReceivedMessagePayload: (next) => {
      const { ColumnHeaders, Cursor, events, TableResult } = next;
      cursorRef.current = Cursor;

      const startIndexOfColumnsToBeAdded = ColumnHeaders.findIndex(
        (header: string) => header === 'logLine',
      );
      const endIndexOfColumnsToBeAdded = ColumnHeaders.findIndex(
        (header: string) => header === 'fpString',
      );

      const columnsToBeAdded = ColumnHeaders.slice(
        startIndexOfColumnsToBeAdded + 1,
        endIndexOfColumnsToBeAdded,
      );

      const resultInObjectForm = TableResult
        ? TableResult.map((row) => {
            const rowResp = row.reduce((acc, cell, index) => {
              if (ColumnHeaders[index] === 'labels') {
                acc[ColumnHeaders[index]] = tryJsonParse(cell);
                return acc;
              }
              if (ColumnHeaders[index] === 'facets') {
                const facets = (cell || []).map((facet) => ({
                  ...facet,
                  name: facet.facetName,
                }));
                acc['facets'] = facets;
                return acc;
              }
              if (ColumnHeaders[index] === 'logLine') {
                acc['message'] = cell;
              }
              acc[ColumnHeaders[index]] = cell;
              return acc;
            }, {});
            rowResp['labels']['source'] = rowResp['source'] || '';
            return rowResp;
          })
        : [];

      setLogs((prevLogs) => [...prevLogs, ...resultInObjectForm]);
    },
  });

  const appendLogsBasicRequest = useRequest(getLogsV2);

  const logsForEntitySubscriptionRequest = useSubscriptionRequest({
    queryBuilder,
    initialState: [],
    key: 'getLogsV2Stream',
    merge: () => {},
    onReceivedMessagePayload: (next) => {
      const { cursor, events } = next;
      cursorRef.current = cursor;

      const nextLogs = events
        .filter((event) => event)
        .map((event) => ({
          ...event,
          message: event.logLine,
          extraFacets: event.facets,
          fpPattern: event.fpString,
        }));

      const nextContextIndex = nextLogs.findIndex((event) => {
        return (
          event.timestamp === contextTimestamp &&
          event.logLine === contextMessage
        );
      });

      setContextIndex(nextContextIndex);
      setLogs((prevLogs) => [...prevLogs, ...nextLogs]);
    },
  });

  const logsForEntitySubscriptionRequestWithFuseQl = useSubscriptionRequest({
    queryBuilder: queryBuilderWithFuseQlStream,
    initialState: [],
    key: 'getLogsWithFuseQlStream',
    merge: () => {},
    onReceivedMessagePayload: (next) => {
      const { Cursor, events, TableResult, ColumnHeaders } = next;
      cursorRef.current = Cursor;

      const startIndexOfColumnsToBeAdded = ColumnHeaders.findIndex(
        (header: string) => header === 'logLine',
      );
      const endIndexOfColumnsToBeAdded = ColumnHeaders.findIndex(
        (header: string) => header === 'fpString',
      );

      const columnsToBeAdded = ColumnHeaders.slice(
        startIndexOfColumnsToBeAdded + 1,
        endIndexOfColumnsToBeAdded,
      );

      const keys = columnsToBeAdded.map((column) => {
        return getFacetKey({
          component: 'Cloud',
          name: column,
          type: 'STRING',
        });
      });

      customColumnsState.setKeysExists(keys);

      const resultInObjectForm = TableResult
        ? TableResult.map((row) => {
            const rowResp = row.reduce((acc, cell, index) => {
              if (ColumnHeaders[index] === 'labels') {
                acc[ColumnHeaders[index]] = tryJsonParse(cell);
                return acc;
              }
              if (ColumnHeaders[index] === 'facets') {
                const facets = (cell || []).map((facet) => ({
                  ...facet,
                  name: facet.facetName,
                }));
                acc['facets'] = facets;
                return acc;
              }
              if (ColumnHeaders[index] === 'logLine') {
                acc['message'] = cell;
              }
              acc[ColumnHeaders[index]] = cell;
              return acc;
            }, {});
            rowResp['labels']['source'] = rowResp['source'] || '';
            return rowResp;
          })
        : [];

      const nextContextIndex = resultInObjectForm.findIndex((event) => {
        return (
          event.timestamp === contextTimestamp &&
          event.logLine === contextMessage
        );
      });

      setContextIndex(nextContextIndex);
      setLogs((prevLogs) => [...prevLogs, ...resultInObjectForm]);
    },
  });

  const logsForEntityBasicRequest = useRequest(getLogsV2);

  const cursorRefBitMapRef = useRef<{ [key: string]: number }>({});
  const cursorRef = useRef(null);
  const idRef = useRef(uuidv4());
  const [searchParams] = useSearchParams();
  const contextTimestamp = searchParams.get('contextTimestamp');
  const contextMessage = searchParams.get('contextMessage');
  const [contextIndex, setContextIndex] = useState(null);

  const [logs, setLogs] = useState([]);

  const [sort, setSort] = useUrlState('sort', {
    sortBy: null,
    sortOrder: 'Desc',
  });

  const appendLogs = (logsState: any, freeText?: string) => {
    const shouldUseSubscription = getShouldUseSubscription(sort);
    const logsForEntityRequest =
      queryLangType === QueryLangType.ADVANCED_KFUSEQL_SEARCH
        ? logsForEntitySubscriptionRequestWithFuseQl
        : shouldUseSubscription
          ? logsForEntitySubscriptionRequest
          : logsForEntityBasicRequest;

    const appendLogsRequest =
      queryLangType === QueryLangType.ADVANCED_KFUSEQL_SEARCH
        ? appendLogsWithFuseQlSubscriptionRequest
        : shouldUseSubscription
          ? appendLogsSubscriptionRequest
          : appendLogsBasicRequest;

    const activeCursor = cursorRef.current;

    const onSuccess = shouldUseSubscription
      ? () => {}
      : ({ cursor, events }) => {
          cursorRef.current = cursor;
          setLogs((prevLogs) => [
            ...prevLogs,
            ...events
              .filter((event) => event)
              .map((event) => ({
                ...event,
                message: event.logLine,
                extraFacets: event.facets,
                fpPattern: event.fpString,
              })),
          ]);
        };
    if (
      !logsForEntityRequest.isLoading &&
      !appendLogsRequest.isLoading &&
      activeCursor &&
      !cursorRefBitMapRef.current[activeCursor]
    ) {
      cursorRefBitMapRef.current[activeCursor] = 1;
      appendLogsRequest
        .call({
          cursor: activeCursor,
          freeText,
          logsState,
          sort,
        })
        .then(onSuccess, onPromiseError);
    }
  };

  const fetchLogs = ({
    freeText,
    logsState,
    limit = 200,
    enableWebSocket = true,
  }: {
    freeText: string;
    logsState: any;
    limit?: number;
    enableWebSocket?: boolean;
  }) => {
    const shouldUseSubscription = getShouldUseSubscription(sort);
    const logsForEntityRequest =
      enableWebSocket && queryLangType === QueryLangType.ADVANCED_KFUSEQL_SEARCH
        ? logsForEntitySubscriptionRequestWithFuseQl
        : shouldUseSubscription && enableWebSocket
          ? logsForEntitySubscriptionRequest
          : logsForEntityBasicRequest;

    const onSuccess =
      enableWebSocket && queryLangType === QueryLangType.ADVANCED_KFUSEQL_SEARCH
        ? () => {}
        : shouldUseSubscription && enableWebSocket
          ? () => {}
          : ({ cursor, events }) => {
              cursorRef.current = cursor;

              const nextLogs = events
                .filter((event) => event)
                .map((event) => ({
                  ...event,
                  message: event.logLine,
                  extraFacets: event.facets,
                  fpPattern: event.fpString,
                }));
              const nextContextIndex = nextLogs.findIndex((event) => {
                return (
                  event.timestamp === contextTimestamp &&
                  event.logLine === contextMessage
                );
              });

              setContextIndex(nextContextIndex);
              setLogs(nextLogs);
            };

    setLogs([]);
    return logsForEntityRequest
      .call({ freeText, logsState, sort, limit })
      .then(onSuccess, onPromiseError);
  };

  const sortByColumn = (nextSortBy: string, nextSortOrder: string) => {
    cursorRefBitMapRef.current = {};
    setSort((prevSort) => ({
      sortBy: nextSortBy,
      sortOrder: nextSortOrder,
    }));
  };

  const generateNewId = () => {
    idRef.current = uuidv4();
    cursorRefBitMapRef.current = {};
  };

  const shouldUseSubscription = getShouldUseSubscription(sort);
  const isAppending = shouldUseSubscription
    ? appendLogsSubscriptionRequest.isLoading
    : appendLogsBasicRequest.isLoading;

  const isLoading = shouldUseSubscription
    ? logsForEntitySubscriptionRequest.isLoading
    : logsForEntityBasicRequest.isLoading;

  return {
    appendLogs,
    context: {
      index: contextIndex,
      message: contextMessage,
      timestamp: contextTimestamp,
    },
    cursor: cursorRef.current,
    fetchLogs,
    generateNewId,
    id: idRef.current,
    isAppending,
    isLoading,
    logs,
    sort,
    sortByColumn,
  };
};

export default useLogsTable;
