import {
  DateSelection,
  DashboardPanelType,
  ExplorerQueryProps,
  FormulaProps,
  MetricsQueryItemProps,
  PromqlWithMetaProps,
} from 'types';
import { getPromqlQueryByIndex, getRollupByVisualization } from 'utils';
import {
  decodePromqlToReadable,
  isMetricsQueryHasAdvanceFunc,
} from './metric-utils';

export const getPromqlWithMetaToLoad = ({
  dataFormat,
  date,
  formulas = [],
  queries = [],
  formulaOnly = false,
  returnActiveOnly = true,
}: {
  dataFormat: DashboardPanelType;
  date: DateSelection;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  formulaOnly?: boolean;
  returnActiveOnly?: boolean;
}): PromqlWithMetaProps[] => {
  const promqlWithMeta: PromqlWithMetaProps[] = [];
  const defaultStep = getRollupByVisualization(date);

  if (!formulaOnly) {
    queries.forEach((query, index) => {
      // ignore if not active
      if (returnActiveOnly && !query.isActive) return;
      const advanceFunc = isMetricsQueryHasAdvanceFunc(query);
      const { isAnomaly, isForecast } = advanceFunc;
      const queryItem: MetricsQueryItemProps = {
        formulas,
        queries,
        queryIndex: index,
        returnType: isAnomaly || isForecast ? 'array' : 'string',
        type: 'query',
      };
      const promql = getPromqlQueryByIndex(queryItem, date);
      if (!promql) return;
      const meta: PromqlWithMetaProps['meta'] = {
        refId: query.queryKey,
        step: query.steps ? Number(query.steps) : defaultStep,
        customStep: query.steps ? Number(query.steps) : undefined,
        type: dataFormat,
        legendFormat: query.legendFormat,
        metricName: query.metric,
        isActive: query.isActive,
        ...advanceFunc,
      };
      let forecastSeasonality = undefined;
      if (isForecast) {
        const forecastFuncParams = query.functions.find(
          (func) => func.name === 'forecast',
        )?.params;
        if (forecastFuncParams && forecastFuncParams[0].value === 'seasonal') {
          forecastSeasonality = forecastFuncParams[1].value;
        }
      }

      promqlWithMeta.push({
        promql,
        meta,
        isAnomaly,
        isForecast,
        forecastSeasonality,
        queryType: 'query',
      });
    });
  }

  formulas.forEach((formula, index: number) => {
    // ignore if not active
    if (returnActiveOnly && !formula.isActive) return;
    const formulaItem: MetricsQueryItemProps = {
      formulas,
      queries,
      queryIndex: index,
      type: 'formula',
    };
    const promql = getPromqlQueryByIndex(formulaItem, date) as string;
    const meta: PromqlWithMetaProps['meta'] = {
      refId: formula.queryKey,
      step: defaultStep,
      type: dataFormat,
      metricName: decodePromqlToReadable(promql),
      isActive: formula.isActive,
    };
    promqlWithMeta.push({ promql, meta, queryType: 'formula' });
  });

  return promqlWithMeta;
};

export const getPromqlWithMetaToLoadByQueryIndex = ({
  dataFormat,
  date,
  formulas,
  queries,
  queryIndex,
  queryType = 'query',
}: {
  dataFormat: DashboardPanelType;
  date: DateSelection;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  queryIndex: number;
  queryType?: 'query' | 'formula';
}): PromqlWithMetaProps[] => {
  if (queryType === 'query') {
    return getPromqlWithMetaToLoad({
      dataFormat,
      date,
      formulas: [],
      queries: [queries[queryIndex]],
    });
  }

  if (queryType === 'formula') {
    return getPromqlWithMetaToLoad({
      dataFormat,
      date,
      formulas: [formulas[queryIndex]],
      queries,
      formulaOnly: true,
    });
  }
};
