import React, { forwardRef, HTMLProps, MutableRefObject } from 'react';
import ReactInputAutosize from 'react-input-autosize';

type Props = HTMLProps<HTMLInputElement> & {
  minWidth?: number;
  onEscape?: VoidFunction;
  onEnter?: VoidFunction;
  onChange: (nextValue: string) => void;
  value: string;
  dataTestId?: string;
};

const AutosizeInput = forwardRef(
  (
    { onEnter, onEscape, onChange, value, dataTestId, ...rest }: Props,
    ref: MutableRefObject<any>,
  ) => {
    const onChangeHandler = (e) => {
      onChange(e.target.value);
    };

    const onKeyUp = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        if (onEnter) {
          onEnter();
        }
      } else if (e.keyCode === 27) {
        if (onEscape) {
          onEscape();
        }
      }
    };

    return (
      <ReactInputAutosize
        {...rest}
        ref={ref}
        onChange={onChangeHandler}
        onKeyUp={onKeyUp}
        value={value}
        dataTestId={dataTestId}
      />
    );
  },
);

AutosizeInput.displayName = `forwardRef(${
  ReactInputAutosize.displayName ?? ReactInputAutosize.name
})`;

export default AutosizeInput;
