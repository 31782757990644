import { useToaster } from 'components/Toasts';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { promqlQueryRangeV3, promqlQueryV2 } from 'requests';
import {
  DashboardPanelType,
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
  PromqlWithMetaProps,
  QueryDataProps,
  QueryDataPropsInstant,
  QueryDataPropsRange,
} from 'types';
import {
  anomalyDataTransformer,
  getPromqlWithMetaToLoad,
  getPromqlWithMetaToLoadByQueryIndex,
  metricsDataTransformer,
  metricsForecastLoader,
} from 'utils';

import { buildPromqlInstantQueryData, buildPromqlQueryData } from './utils';

const useMetricsDataState = ({
  date,
  dataFormat = DashboardPanelType.TIMESERIES,
  isRange,
  preReloadQuery,
}: {
  date: DateSelection;
  dataFormat?: DashboardPanelType;
  isRange?: boolean;
  preReloadQuery?: (promql: string | string[], metricName: string) => string;
}) => {
  const { addToast } = useToaster();
  const [queryData, setQueryData] = useState<QueryDataProps>({});

  const handleError = (error: Error, promqlWithMeta: PromqlWithMetaProps) => {
    setQueryData((prev) => {
      const newState = { ...prev };
      const queryId = `${promqlWithMeta.queryType}_${promqlWithMeta.meta.refId}`;
      newState[queryId] = {
        ...newState[queryId],
        error: error.message,
        isLoading: false,
        range: null,
        instant: null,
      };
      return newState;
    });
  };

  const loadMultipleRangeQueries = useCallback(
    async (promqlWithMeta: PromqlWithMetaProps[]) => {
      const promqlWithMetaPreloaded = promqlWithMeta
        .map((item) => {
          const { promql, meta } = item;
          const preLoadPromql = preReloadQuery
            ? preReloadQuery(promql, meta.metricName)
            : promql;
          if (!preLoadPromql) return;
          return { ...item, promql: preLoadPromql };
        })
        .filter((item) => item?.promql);

      const loadAnomalyDatasets = async (
        promql: string[],
        meta: PromqlWithMetaProps['meta'],
        isSplitRequired: boolean,
      ) => {
        return Promise.all(
          promql.map((p, idx) => {
            const baseTransformer = metricsDataTransformer();
            if (idx === 0 && isSplitRequired) {
              baseTransformer.pop();
              baseTransformer.push({
                id: 'anomalyBandTransformer',
                func: anomalyDataTransformer,
              });
            }
            return promqlQueryRangeV3({
              date,
              promqlQuery: p,
              meta,
              transformer: baseTransformer,
            });
          }),
        );
      };

      const loadDatasets = async (item: PromqlWithMetaProps) => {
        const { promql, meta, isAnomaly, isForecast } = item;
        const isEncodedingNeeded = promql?.includes('+');
        if (isAnomaly && Array.isArray(promql)) {
          const isSplitRequired = promql.length === 2;
          const anomalyDatasets = await loadAnomalyDatasets(
            promql,
            meta,
            isSplitRequired,
          );
          return isSplitRequired ? anomalyDatasets.flat() : anomalyDatasets;
        } else if (isForecast && Array.isArray(promql)) {
          const forecastDatasets = await metricsForecastLoader({
            addToast,
            date,
            forecastSeasonality: item.forecastSeasonality,
            meta,
            promql,
          });
          return forecastDatasets;
        } else {
          return promqlQueryRangeV3({
            addEncoding: isEncodedingNeeded,
            date,
            promqlQuery: promql as string,
            meta,
            transformer: metricsDataTransformer(),
          }).catch((err) => handleError(err, item));
        }
      };

      try {
        hanldeLoading(promqlWithMetaPreloaded, true);
        const datasets = await Promise.all(
          promqlWithMetaPreloaded.map(loadDatasets),
        );
        const newQueryData = buildPromqlQueryData(
          promqlWithMetaPreloaded,
          datasets as unknown as QueryDataPropsRange[],
        );
        setQueryData((prev) => {
          const newState = { ...prev };
          promqlWithMetaPreloaded.forEach((item) => {
            const queryId = `${item.queryType}_${item.meta.refId}`;
            const queryKeys = ['_upper', '_lower', ''];
            delete newState[queryId];
            queryKeys.forEach((key) => {
              delete newState[`${queryId}_anomaly${key}`];
              delete newState[`${queryId}_forecast${key}`];
            });

            delete newState[`${queryId}_forecast`];
          });
          return { ...newState, ...newQueryData };
        });
      } catch (error) {
        // TODO: handle error
        hanldeLoading(promqlWithMetaPreloaded, false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, preReloadQuery],
  );

  const loadMultipleInstantQueries = async (
    promqlWithMeta: PromqlWithMetaProps[],
  ) => {
    const promqlWithMetaPreloaded = promqlWithMeta
      .map((item) => {
        const { promql, meta } = item;
        const preLoadPromql = preReloadQuery
          ? preReloadQuery(promql, meta.metricName)
          : promql;
        if (!preLoadPromql) return;
        return { ...item, promql: preLoadPromql };
      })
      .filter((item) => item.promql);

    try {
      hanldeLoading(promqlWithMetaPreloaded, true);
      const datasets = await Promise.all(
        promqlWithMetaPreloaded.map((item) => {
          const { promql, meta } = item;
          return promqlQueryV2({
            promqlQuery: promql as string,
            meta,
            transformer: metricsDataTransformer(true),
          });
        }),
      );

      const newInstantQueryData = buildPromqlInstantQueryData(
        promqlWithMetaPreloaded,
        datasets as unknown as QueryDataPropsInstant[],
      );

      setQueryData((prev) => ({ ...prev, ...newInstantQueryData }));
    } catch (error) {
      hanldeLoading(promqlWithMetaPreloaded, false);
    }
  };

  const reloadOneQuery = useCallback(
    ({
      queryIndex,
      queries,
      formulas,
    }: {
      queryIndex: number;
      queries: ExplorerQueryProps[];
      formulas: FormulaProps[];
    }) => {
      const promqlWithMeta = getPromqlWithMetaToLoadByQueryIndex({
        dataFormat,
        date,
        formulas: formulas,
        queries: queries,
        queryIndex,
      });

      if (isRange) {
        loadMultipleRangeQueries(promqlWithMeta);
      } else {
        loadMultipleInstantQueries(promqlWithMeta);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, preReloadQuery],
  );

  const debouncedloadMultipleRangeQueries = useMemo(
    () => debounce(loadMultipleRangeQueries, 2000),
    [loadMultipleRangeQueries],
  );

  const hanldeLoading = (
    promqlWithMeta: PromqlWithMetaProps[],
    isLoading: boolean,
  ) => {
    setQueryData((prevQueryData) => {
      const newQueryData = { ...prevQueryData };
      promqlWithMeta.forEach(({ meta, queryType, isAnomaly, isForecast }) => {
        let queryId = `${queryType}_${meta.refId}`;
        if (isForecast) {
          queryId += '_forecast';
        }
        if (isAnomaly) {
          queryId += '_anomaly';
        }
        newQueryData[queryId] = {
          meta: null,
          range: null,
          instant: null,
          isLoading,
        };
      });
      return newQueryData;
    });
  };

  const callOnePromqlQuery = ({
    formulas,
    queries,
    queryIndex,
    type,
    callType = 'normal',
    date,
  }: {
    formulas: FormulaProps[];
    queries: ExplorerQueryProps[];
    queryIndex?: number;
    type?: 'query' | 'formula';
    callType?: 'debounce' | 'normal';
    date?: DateSelection;
  }) => {
    const promqlWithMeta = getPromqlWithMetaToLoad({
      formulas: type === 'formula' ? [formulas[queryIndex]] : [],
      dataFormat,
      date,
      queries: type === 'query' ? [queries[queryIndex]] : queries,
    });

    if (!isRange) {
      loadMultipleInstantQueries(promqlWithMeta);
      return;
    }

    if (callType === 'debounce') {
      debouncedloadMultipleRangeQueries(promqlWithMeta);
    } else {
      loadMultipleRangeQueries(promqlWithMeta);
    }
  };

  const callMultiplePromqlQueries = (
    newQueries: ExplorerQueryProps[],
    newFormulas: FormulaProps[],
    date?: DateSelection,
  ) => {
    const promqlWithMeta = getPromqlWithMetaToLoad({
      dataFormat,
      date,
      formulas: newFormulas,
      queries: newQueries,
    });

    if (isRange) {
      loadMultipleRangeQueries(promqlWithMeta);
    } else {
      loadMultipleInstantQueries(promqlWithMeta);
    }
  };

  const removeQueryData = (queryId: string) => {
    setQueryData((prev) => {
      const prevQueryData = { ...prev };
      const anomalyQueryId = `${queryId}_anomaly`;
      const forecastQueryId = `${queryId}_forecast`;
      if (prevQueryData[anomalyQueryId]) {
        delete prevQueryData[anomalyQueryId];
        delete prevQueryData[`${queryId}_anomaly_lower`];
        delete prevQueryData[`${queryId}_anomaly_upper`];
        delete prevQueryData[`${queryId}_anomaly_lower`];
        delete prevQueryData[`${queryId}_anomaly_upper`];
      } else if (prevQueryData[forecastQueryId]) {
        delete prevQueryData[forecastQueryId];
      } else {
        delete prevQueryData[queryId];
      }

      return prevQueryData;
    });
  };

  return {
    callOnePromqlQuery,
    callMultiplePromqlQueries,
    loadMultipleRangeQueries,
    loadMultipleInstantQueries,
    queryData,
    reloadOneQuery,
    removeQueryData,
    setQueryData,
  };
};

export default useMetricsDataState;
