import {
  getDateFromSecondRange,
  getMetricsExplorerDefaultQuery,
  getRollupToSecond,
  parsePromqlAndBuildQuery,
} from 'utils';

import {
  defaultAnomalyCondition,
  defaultForecastCondition,
  alertParseConditionValue,
  AnomalyConditionProps,
  ForecastConditionProps,
  MetricChangeConditionProps,
  OutlierConditionProps,
} from '../../AlertsCreateCondition';
import { parseEditAlertAnomalyPromql } from './alertsCreateMetricsAnomaly';
import { parseChangePromql } from './alertsCreateMetricsChange';
import { parseForecastPromql } from './alertsCreateMetricsForecast';
import { parseOutlierPromql } from './alertsCreateMetricsOutlier';
import { parseLogsAlertsQuery } from '../../AlertsCreateLogs/utils';
import { parseTracesAlertQuery } from '../../AlertsCreateTraces/utils';
import { parseRumAlertQuery } from '../../AlertsCreateRUM/utils';
import { parseEventsAlertQuery } from '../../AlertsCreateEvents/utils';
import {
  AlertQueryItem,
  AlertType,
  AlertsMetricsParsedProps,
  RuleType,
} from '../../types';

export const parseAlertsQueryAndConditions = ({
  data,
  annotations,
}: {
  data: AlertQueryItem[];
  annotations: any;
}): AlertsMetricsParsedProps => {
  const result: AlertsMetricsParsedProps = {};

  const ruleType = annotations?.ruleType;
  const alertType = annotations?.alertType;

  if (ruleType === RuleType.LOGS) {
    return parseLogsAlertsQuery({ data, annotations });
  }

  if (ruleType === RuleType.TRACES) {
    return parseTracesAlertQuery({ data, annotations });
  }

  if (ruleType === RuleType.RUM) {
    return parseRumAlertQuery({ data, annotations });
  }

  if (ruleType === RuleType.EVENTS) {
    return parseEventsAlertQuery({ data, annotations });
  }

  if (!alertType) {
    const thresholdParsed = getThresholdAlertEditPromql(data);
    Object.assign(result, thresholdParsed);
    return result;
  }

  if (alertType === AlertType.THRESHOLD) {
    const thresholdParsed = getThresholdAlertEditPromql(data);
    Object.assign(result, thresholdParsed);
  }

  if (alertType === AlertType.CHANGE) {
    const changeParsed = getChangeAlertEditPromql(data);
    Object.assign(result, changeParsed);
  }

  if (alertType === AlertType.OUTLIERS) {
    const outlierParsed = getOutlierAlertEditPromql(data);
    Object.assign(result, outlierParsed);
  }

  if (alertType === AlertType.ANOMALY) {
    const step = Number(result?.anomalyCondition?.step) || null;
    const anomalyParsed = getAnomalyAlertEditPromql({
      data,
      step,
      annotations,
    });
    Object.assign(result, anomalyParsed);
  }

  if (alertType === AlertType.FORECAST) {
    const forecastParsed = getForecastAlertEditPromql(data);
    Object.assign(result, forecastParsed);
  }

  const step = Number(result?.anomalyCondition?.step) || null;

  const { queries, formulas } = parsePromqlAndBuildQuery(result.promqls, step);
  if (queries.length === 0) {
    const defaultQuery = getMetricsExplorerDefaultQuery('');

    queries.push({
      ...defaultQuery,
      showInput: true,
      promql: result.promqls[0],
    });
  }

  if (formulas.length > 0) {
    result.condition.queryKey = `Formula (1)`;
    queries.map((_, idx) => {
      queries[idx].isActive = false;
    });
  } else {
    result.condition.queryKey = `Query (a)`;
  }

  return { ...result, queries, formulas };
};

const getThresholdAlertEditPromql = (data: AlertQueryItem[]) => {
  const promqls: string[] = [];
  const condition = alertParseConditionValue(data);
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;
  promqls.push(model.expr);

  return { condition, promqls, relativeTimeRange };
};

const getChangeAlertEditPromql = (data: AlertQueryItem[]) => {
  const promqls: string[] = [];
  const condition = alertParseConditionValue(data);
  const changeCondition: MetricChangeConditionProps = {};
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;

  const { change, promql, time } = parseChangePromql(model.expr);
  const comparedTime = getDateFromSecondRange(
    relativeTimeRange.from,
    relativeTimeRange.to,
  );
  changeCondition.time = time;
  changeCondition.change = change;
  changeCondition.comparedTime = comparedTime.startLabel;
  promqls.push(promql);

  return { condition, promqls, changeCondition, relativeTimeRange };
};

const getOutlierAlertEditPromql = (data: AlertQueryItem[]) => {
  const promqls: string[] = [];
  const condition = alertParseConditionValue(data);
  const outlierCondition: OutlierConditionProps = {};
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;

  const { promql, algorithm, tolerance } = parseOutlierPromql(model.expr);
  outlierCondition.algorithm = algorithm;
  outlierCondition.tolerance = tolerance;
  promqls.push(promql);

  return { condition, promqls, outlierCondition, relativeTimeRange };
};

export const getAnomalyAlertEditPromql = ({
  data,
  step,
  annotations,
}: {
  data: AlertQueryItem[];
  step: number;
  annotations: any;
}) => {
  const promqls: string[] = [];
  const condition = alertParseConditionValue(data);
  const anomalyCondition: AnomalyConditionProps = {
    ...defaultAnomalyCondition,
  };
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;

  const { promql, anomalyCondition: newAnomalyCondition } =
    parseEditAlertAnomalyPromql({ promql: model.expr, step, annotations });
  promqls.push(promql);

  Object.assign(anomalyCondition, newAnomalyCondition);

  return { condition, promqls, anomalyCondition, relativeTimeRange };
};

const getForecastAlertEditPromql = (data: AlertQueryItem[]) => {
  const promqls: string[] = [];
  const condition = alertParseConditionValue(data);
  const forecastCondition: ForecastConditionProps = {
    ...defaultForecastCondition,
  };
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;

  const {
    promql,
    forecastCondition: newForecastCondition,
    of,
  } = parseForecastPromql(model.expr);
  promqls.push(promql);
  Object.assign(forecastCondition, newForecastCondition);

  if (newForecastCondition.forecastAlgorithm === 'seasonal') {
    condition.of = of;
    const interval = getAlertQueryInterval(data);
    forecastCondition.interval = `${getRollupToSecond(interval)}`;
  }

  return { condition, promqls, forecastCondition, relativeTimeRange };
};

const getAlertQueryInterval = (data: AlertQueryItem[]) => {
  const [queryModel] = data;
  const { model } = queryModel;
  return model.interval;
};
