import { Stepper } from 'components';
import classNames from 'classnames';
import { useRequest, useSearches } from 'hooks';
import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardStreamType,
} from 'types/Dashboard';
import { QueryDataProps } from 'types';
import {
  combineInstantQueryData,
  combineRangeQueryData,
  eventsDataTransformer,
} from 'utils';
import useDebouncedEffect from 'use-debounced-effect';

import { useDashboardEditState } from './hooks';
import {
  DashboardPanelModalFooter,
  DashboardPanelModalHeader,
} from '../components';
import DashboardEditMetricsVisualType from './DashboardEditMetricsVisualType';
import DashboardEditPanelName from './DashboardEditPanelName';
import DashboardEditStreamType from './DashboardEditStreamType';
import {
  DashboardEditGraphSunburst,
  DashboardEditGraphTable,
  DashboardEditGraphTimeseries,
  DashboardEditGraphToplist,
} from './DashboardEditGraph';
import {
  aggregateEventTimeSeries,
  EventsQuerySearchAnalytics,
  useEventsState,
} from 'screens/Events';
import { defaultTransformations } from './utils';
import { getEventPanelExprToQueryAndFormula } from './utils/eventUtils';

const DashboardEditEvents = ({
  baseWidth,
  close,
  dashboardEditState,
  panel,
  panelType,
}: {
  baseWidth: number;
  close: () => void;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
  panelType?: DashboardPanelType;
}): ReactElement => {
  const { date, editPanel, onSaveClickEvents, setDate } = dashboardEditState;
  const { queries: parsedQueries } = getEventPanelExprToQueryAndFormula(panel);
  const { searches } = useSearches({
    shouldWriteToUrl: false,
    initialSearches: parsedQueries,
  });
  const queries = searches.map((search) => search.state);
  const eventsState = useEventsState({ urlKeysToSync: [] });
  const eventQueryRequest = useRequest(aggregateEventTimeSeries, true, true);

  const loadEventTimeseries = () => {
    const instant = panelType !== DashboardPanelType.TIMESERIES;
    const baseTransformers = eventsDataTransformer(instant);

    const preparedQueries = searches
      .map((s) => (s.searchBarState ? s.state : undefined))
      .filter(Boolean);
    eventQueryRequest.call({
      date,
      dataFormat: panelType,
      instant,
      queries: preparedQueries,
      transformer: baseTransformers,
    });
  };

  useDebouncedEffect(
    () => {
      loadEventTimeseries();
    },
    100,
    [panelType, date, searches],
  );

  const { queriesStatus } = useMemo(() => {
    const queriesStatus = queries.map((query) => {
      return { isActive: query.isActive, queryKey: query.queryKey };
    });

    return { queriesStatus };
  }, [queries]);

  const { result: eventQueryData, refId } = useMemo(() => {
    const result: QueryDataProps = {};
    let refId = '';
    if (!eventQueryRequest.result) {
      queriesStatus.forEach(({ queryKey }) => {
        const queryId = `query_${queryKey}`;
        result[queryId] = {
          isLoading: eventQueryRequest.isLoading,
          meta: null,
        };
      });
      return { result, refId };
    }
    const rangeKey =
      panelType === DashboardPanelType.TIMESERIES ? 'range' : 'instant';
    eventQueryRequest.result.forEach((data) => {
      const { meta } = data;
      const queryId = `query_${meta.refId}`;
      result[queryId] = {
        isLoading: eventQueryRequest.isLoading,
        meta,
        [rangeKey]: rangeKey === 'range' ? data.data : data,
      };
      refId = meta.refId;
    });

    return { result, refId };
  }, [
    eventQueryRequest.isLoading,
    eventQueryRequest.result,
    panelType,
    queriesStatus,
  ]);

  const dataFormatterRange = useCallback(() => {
    if (editPanel.type !== DashboardPanelType.TIMESERIES) return null;
    return combineRangeQueryData({
      formulas: [],
      queries: queriesStatus,
      queryData: eventQueryData,
      combineTimestamp: true,
      combineLabelWithQueryKey: true,
    });
  }, [editPanel.type, eventQueryData, queriesStatus]);

  const dataFormatterInstant = useCallback(() => {
    if (editPanel.type === DashboardPanelType.TIMESERIES) return null;
    return combineInstantQueryData({
      dataFormat: editPanel.type,
      queries: queriesStatus,
      queryData: eventQueryData,
      formulas: [],
      transformations: defaultTransformations({
        dataFormat: editPanel.type,
        stream: DashboardStreamType.TRACE,
      }),
    });
  }, [editPanel.type, eventQueryData, queriesStatus]);

  return (
    <>
      <DashboardPanelModalHeader
        close={close}
        modalDate={date}
        setModalDate={setDate}
        title={!panel ? 'Add Panel' : editPanel.title || 'Edit Panel'}
      />
      <div className={classNames({ 'dashboard-edit__metric__body': true })}>
        {editPanel.type === DashboardPanelType.TIMESERIES && (
          <DashboardEditGraphTimeseries
            baseWidth={baseWidth}
            onDateChange={setDate}
            panel={editPanel}
            dataFormatter={dataFormatterRange}
          />
        )}
        {editPanel.type === DashboardPanelType.TOP_LIST && (
          <DashboardEditGraphToplist
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
          />
        )}
        {editPanel.type === DashboardPanelType.TABLE && (
          <DashboardEditGraphTable dataFormatter={dataFormatterInstant} />
        )}
        {editPanel.type === DashboardPanelType.PIECHART && (
          <DashboardEditGraphSunburst
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
            panel={editPanel}
          />
        )}
        <Stepper
          steps={[
            {
              title: 'Choose Visualization',
              component: (
                <DashboardEditMetricsVisualType
                  dashboardEditState={dashboardEditState}
                  hideVisualizationTypes={[
                    DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
                  ]}
                  extendVisualizationTypes={[]}
                />
              ),
            },
            {
              title: 'Graph your data',
              component: (
                <>
                  {!panel && (
                    <div className="dashboard__widget__metrics-query-builder">
                      <DashboardEditStreamType
                        setStream={dashboardEditState.setStream}
                        stream={dashboardEditState.stream}
                      />
                    </div>
                  )}
                  <EventsQuerySearchAnalytics
                    searches={searches}
                    eventsState={eventsState}
                    tab={panelType}
                    dontUsePageFilterState={true}
                  />
                </>
              ),
            },
            {
              title: 'Enter chart title',
              component: (
                <DashboardEditPanelName
                  dashboardEditState={dashboardEditState}
                />
              ),
            },
          ]}
        />
      </div>
      <DashboardPanelModalFooter
        close={close}
        onSave={() => onSaveClickEvents({ queries })}
      />
    </>
  );
};

export default DashboardEditEvents;
