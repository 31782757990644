import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement } from 'react';
import {
  FunctionName,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  LogsState,
} from 'types';

import LogsMetricsQueryBuilderFormula from './LogsMetricsQueryBuilderFormula';
import LogsMetricsQueryBuilderQuery from './LogsMetricsQueryBuilderQuery';

const LogsMetricsQueryBuilder = ({
  activeVisualization,
  allowMultipleQueries = false,
  blockedFunctionsCategories = [],
  blockedFunctionsNames = [],
  disableSearch = false,
  handleRunFreeText,
  logsMetricsQueryState,
  logsState,
  minStep = '1s',
  isAdvancedFuseQlSearch,
}: {
  activeVisualization?: string;
  allowMultipleQueries?: boolean;
  blockedFunctionsCategories?: string[];
  blockedFunctionsNames?: Array<FunctionName>;
  disableSearch?: boolean;
  handleRunFreeText?: () => void;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  logsState: LogsState;
  minStep?: string;
  isAdvancedFuseQlSearch: boolean;
}): ReactElement => {
  const { formulas, queries } = logsMetricsQueryState;

  const { date, customerFilter } = logsState;

  const queryKeys = queries.map(
    (query: LogsMetricQueryProps) => query.queryKey,
  );
  return (
    <div className="query-builder__logs">
      {queries.map((query: LogsMetricQueryProps, queryIndex: number) => {
        return (
          <LogsMetricsQueryBuilderQuery
            activeVisualization={activeVisualization}
            allowMultipleQueries={allowMultipleQueries}
            blockedFunctionsCategories={blockedFunctionsCategories}
            blockedFunctionsNames={blockedFunctionsNames}
            customerFilter={customerFilter}
            date={date}
            disableSearch={disableSearch}
            handleRunFreeText={handleRunFreeText}
            key={queryIndex}
            logsMetricsQueryState={logsMetricsQueryState}
            minStep={minStep}
            isAdvancedFuseQlSearch={isAdvancedFuseQlSearch}
            queryIndex={queryIndex}
            query={query}
          />
        );
      })}
      {formulas.map((formula: LogsMetricForumlaProps, formulaIndex: number) => {
        return (
          <LogsMetricsQueryBuilderFormula
            activeVisualization={activeVisualization}
            key={formulaIndex}
            formula={formula}
            index={formulaIndex}
            logsMetricsQueryState={logsMetricsQueryState}
            queryKeys={queryKeys}
          />
        );
      })}
    </div>
  );
};

export default LogsMetricsQueryBuilder;
