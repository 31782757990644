export type KfuseQlResult = {
  TableResult: [string | number][];
  ColumnHeaders: string[];
};

export enum QueryLangType {
  KFUSEQL = 'kfuseql',
  LOGQL = 'logql',
  ADVANCED_KFUSEQL_SEARCH = 'advanced_kfuseql_search',
}
