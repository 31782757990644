import {
  Loader,
  Table,
  TooltipTrigger,
  TableSearch,
  useTableSearch,
} from 'components';
import React, { ReactElement } from 'react';
import { convertNumberToReadableUnit } from 'utils/formatNumber';

import useLogsCardinalityState from './useLogsCardinalityState';
import MetricsCardinalityBarChart from '../MetricsCardinality/MetricsCardinalityBarChart';

const columns = ({
  isValueChartLoading,
  isValueOneHourLoading,
}: {
  isValueChartLoading: boolean;
  isValueOneHourLoading: boolean;
}) => [
  {
    key: 'Attribute',
    label: 'Label',
    renderCell: ({ row }: { row: any }) => row.tags['key'],
  },
  {
    key: 'valueOneHour',
    label: 'Value Count (1h)',
    renderCell: ({ row }: { row: any }) => {
      if (isValueOneHourLoading) return <div className="spinner" />;
      return (
        <TooltipTrigger tooltip={row.Value > 1000 ? row.Value : ''}>
          {convertNumberToReadableUnit(row.valueOneHour)}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'valueCount',
    label: 'Value Count',
    renderCell: ({ row }: { row: any }) => {
      return (
        <TooltipTrigger tooltip={row.Value > 1000 ? row.Value : ''}>
          {convertNumberToReadableUnit(row.Value)}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'valueChart',
    label: 'Value Chart',
    renderCell: ({ row }: { row: any }) => {
      if (isValueChartLoading) return <div className="spinner" />;
      return <MetricsCardinalityBarChart data={row.valueSeries || []} />;
    },
  },
];

const LogsCardinalityTable = ({
  logsCardinalityState,
}: {
  logsCardinalityState: ReturnType<typeof useLogsCardinalityState>;
}): ReactElement => {
  const {
    labelCardinalityRows,
    labelCardinalityRequest,
    labelCardinalityOneHourRequest,
    labelCardinalityChartRequest,
    labelCardinalityOverallRequest,
  } = logsCardinalityState;

  const tableSearch = useTableSearch({
    rows: labelCardinalityRows || [],
    shouldWriteToUrl: false,
  });

  return (
    <>
      <div className=" text--h3 pt-4" data-testid="all-cardinality-label">
        All cardinality: {labelCardinalityOverallRequest.result || ''}
      </div>
      <Loader
        className="mb-8 mt-2"
        isLoading={labelCardinalityRequest.isLoading}
      >
        <TableSearch
          className="uplot__value-legends__search-bar"
          placeholder="Search labels..."
          tableSearch={tableSearch}
          dataTestId="label-search-bar"
        />
        <Table
          className="table--bordered table--bordered-cells metrics-cardinality-table"
          columns={columns({
            isValueChartLoading: labelCardinalityChartRequest.isLoading,
            isValueOneHourLoading: labelCardinalityOneHourRequest.isLoading,
          })}
          rows={tableSearch.searchedRows}
          dataTestId="cardinality-table"
        />
      </Loader>
    </>
  );
};

export default LogsCardinalityTable;
