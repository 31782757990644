import classnames from 'classnames';
import { TooltipTrigger } from 'components';
import React from 'react';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  message: string;
  className?: string;
  fitContent?: boolean;
  showTooltip?: boolean;
};

const ErrorMessage = ({
  message,
  className,
  fitContent,
  showTooltip,
}: Props) => {
  if (!message) return null;

  return (
    <div
      className={classnames({
        'w-full': !fitContent,
        'w-fit': fitContent,
        'flex gap-[4px]': true,
        [className]: className,
      })}
    >
      <TooltipTrigger tooltip={showTooltip && message}>
        <IoIosWarning
          className="overlay-message__icon-and-message__icon"
          size={16}
        />
      </TooltipTrigger>

      {!showTooltip && <div className="text-red-500">{message}</div>}
    </div>
  );
};

export default ErrorMessage;
