import { parseFiltersFromLogsState } from 'hooks';
import {
  LogsMetricQueryProps,
  SelectedFacetValuesByName,
  ValueCount,
} from 'types';
import { LogsState } from 'types/logs-workbook';
import {
  defaultLogsQuery,
  filterListWithSelectedSidebar,
  getFacetKey,
  mergeTwoSidebarCountsValues,
  parseFacetKey,
} from 'utils';

const logsStateParams = [
  {
    key: 'date',
    isNonEmpty: (date) => Object.keys(date).length,
  },
  {
    key: 'customColumns',
    isNonEmpty: (customColumns) => Object.keys(customColumns).length,
  },
];

export const convertSavedQueryToUrlSearchParams = (logsStateString: string) => {
  const logsState = logsStateString
    ? (JSON.parse(logsStateString) as LogsState)
    : ({} as LogsState);
  const freeText = logsState?.freeText;

  let filters = parseFiltersFromLogsState(logsState);
  const customerFilter = logsState?.customerFilter;

  if (filters) {
    const filterAdjusted = filters.map((filter) => {
      const facet = filter.value?.facet;

      if (facet) {
        const parsedFacet = parseFacetKey(facet);
        if (parsedFacet.displayName === undefined) {
          const facetKey = getFacetKey({ ...parsedFacet, displayName: '' });
          return { ...filter, value: { ...filter.value, facet: facetKey } };
        }
      }
      return filter;
    });
    filters = filterAdjusted;
  }
  const queries: LogsMetricQueryProps[] = [
    { ...defaultLogsQuery, filters, fuseql: freeText },
  ];
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('LogsMetricsQueries', JSON.stringify(queries));

  logsStateParams.forEach(({ key, isNonEmpty }) => {
    const logStateParamValue = logsState[key];
    if (logStateParamValue && isNonEmpty(logStateParamValue)) {
      urlSearchParams.set(key, JSON.stringify(logStateParamValue));
    } else {
      urlSearchParams.delete(key);
    }
  });

  return { customerFilter, urlSearchParams, queries };
};

export const LogsSavedViewsFacet = [
  { name: 'foldername', label: 'Folder', forceExpanded: false },
  { name: 'subfoldername', label: 'Sub Folder', forceExpanded: false },
  { name: 'freetext', label: 'Free Text', forceExpanded: false },
];

const getCountForSidebar = (
  list: Array<any>,
): {
  folders: { [key: string]: number };
  subfolders: { [key: string]: number };
  freeText: { [key: string]: number };
} => {
  const folders: { [key: string]: number } = {};
  const subfolders: { [key: string]: number } = {};
  const freeText: { [key: string]: number } = {};

  list.forEach((item: any) => {
    if (folders[item.foldername] === undefined) {
      folders[item.foldername] = 1;
    } else {
      folders[item.foldername] += 1;
    }

    if (subfolders[item.subfoldername] === undefined) {
      subfolders[item.subfoldername] = 1;
    } else {
      subfolders[item.subfoldername] += 1;
    }

    if (freeText[item.freetext] === undefined) {
      freeText[item.freetext] = 1;
    } else {
      freeText[item.freetext] += 1;
    }
  });

  return { folders, subfolders, freeText };
};

export const filterDashboardList = (
  list: Array<any>,
  selectedFacetValuesByName: SelectedFacetValuesByName,
): {
  filteredList: Array<any>;
  folders: ValueCount[];
  subfolders: ValueCount[];
  freeText: ValueCount[];
} => {
  const newList = list.map((item: any) => {
    return {
      ...item,
      foldername: item.folderName || 'General',
      subfoldername: item.subfolderName || 'General',
      freetext: item.isFreeText ? 'Yes' : 'No',
    };
  });
  const filteredList = filterListWithSelectedSidebar(
    newList,
    selectedFacetValuesByName,
  );

  const countFromList = getCountForSidebar(newList);

  const countFromFilteredList = getCountForSidebar(filteredList);

  const mergedCount = mergeTwoSidebarCountsValues(
    countFromList,
    countFromFilteredList,
  );
  const { folders, subfolders, freeText } = mergedCount;

  const folderFacetValues: ValueCount[] = [];
  Object.keys(folders).forEach((key) => {
    folderFacetValues.push({ value: key, count: folders[key] });
  });

  const subFolderFacetValues: ValueCount[] = [];
  Object.keys(subfolders).forEach((key) => {
    subFolderFacetValues.push({ value: key, count: subfolders[key] });
  });

  const freeTextFacetValues: ValueCount[] = [];
  Object.keys(freeText).forEach((key) => {
    freeTextFacetValues.push({ value: key, count: freeText[key] });
  });

  return {
    filteredList,
    folders: folderFacetValues,
    subfolders: subFolderFacetValues,
    freeText: freeTextFacetValues,
  };
};
