import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { ApplicationList } from '../types';

const getApplicationFilter = async (): Promise<ApplicationList> => {
  return queryRumService<ApplicationList, 'applicationList'>(`
  query {
    applicationList {
      name
      id
      type
    }
  }
  `).then((data) => data?.applicationList || null, onPromiseError);
};

export default getApplicationFilter;
