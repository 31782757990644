import { useRequest } from 'hooks';
import React, { useEffect, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { promqlMetadata } from 'requests';

const MetricsQueryBuilderActiveMetricSummary = ({
  activeMetric,
}: {
  activeMetric: string;
}) => {
  const [error, setError] = useState({
    getMetadata: null,
  });
  const promqlMetadataRequest = useRequest(promqlMetadata, true, true);

  const metadata = promqlMetadataRequest?.result?.[activeMetric] || [];
  const { type, unit, help } = metadata?.[0] || {};

  useEffect(() => {
    promqlMetadataRequest
      .call(activeMetric)
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getMetadata: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getMetadata: { message: 'Failed to fetch metadata' },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMetric]);

  return (
    <>
      {error.getMetadata ? (
        <div className="m-[2px] flex w-full justify-start gap-[4px] pr-[14px]">
          <IoIosWarning
            className="overlay-message__icon-and-message__icon"
            size={16}
          />
          <div className="text-red-500">{error.getMetadata.message}</div>
        </div>
      ) : (
        <div className="metrics-summary__body__details__metadata__metadata m-2">
          <div className="metrics-summary__body__subtitle">{activeMetric}</div>
          {promqlMetadataRequest.result && !promqlMetadataRequest.isLoading && (
            <>
              {type && (
                <div>
                  Metric Type: <span className="chip">{type}</span>
                </div>
              )}
              {unit && (
                <div>
                  Unit: <span className="chip">{unit}</span>
                </div>
              )}
              {help && (
                <div className="mt-1 whitespace-pre-wrap">
                  Description: {help}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MetricsQueryBuilderActiveMetricSummary;
