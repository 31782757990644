import { QueryDataPropsRange } from 'types/QueryData';

const transformSeriesForShowAndHide = (
  queryData: QueryDataPropsRange,
  seriesBitmap: { [key: string]: boolean },
): QueryDataPropsRange => {
  const { series } = queryData;

  const newSeries = series.map((item) => {
    const { label } = item;
    const show = seriesBitmap[label] !== undefined ? seriesBitmap[label] : true;
    return { ...item, show };
  });

  return { ...queryData, series: newSeries };
};

export default transformSeriesForShowAndHide;
