import { DashboardPanelType, KfuseQlResult } from 'types';
import { fillTimestampForWindow } from 'utils';

import { DataFrame } from '../types';

const TIMESLICE_KEY = '_timeslice';

const mergeFilledTimestampAndDatasetTimestamps = (
  timestampBitmap: { [key: number]: boolean },
  dataTimestampsBitmap: { [key: number]: boolean },
) => {
  const mergedTimestampBitmap = { ...timestampBitmap, ...dataTimestampsBitmap };
  return Object.keys(mergedTimestampBitmap)
    .map(Number)
    .sort((a, b) => a - b);
};

const getConstantLabelForEmptyLabel = (
  emptyLabel: Record<string, string | number>,
) => {
  const keys = Object.keys(emptyLabel);
  if (keys.length > 1) {
    return emptyLabel;
  }

  if (typeof emptyLabel[keys[0]] === 'string' && emptyLabel[keys[0]] === '') {
    emptyLabel[keys[0]] = `${keys[0]}: {{empty_value}}`;
  }

  return emptyLabel;
};

const getLabelFromTableResult = ({
  columnHeaders,
  instant,
  kfuseQlOperation,
  tableResult,
}: {
  columnHeaders: string[];
  instant: boolean;
  kfuseQlOperation: string;
  tableResult: (string | number)[];
}) => {
  const emptyLabel: Record<string, string | number> = {};
  columnHeaders.forEach((c, idx) => {
    if (c === TIMESLICE_KEY) return;
    // Handle _count for instant, or send as kfuseOperation to be fixed
    if (instant && c === '_count') return;
    if (c === kfuseQlOperation) return;
    if (!instant && c.startsWith('_')) return; // skip _labels for non-instant
    if (c === 'anomaly_both') return;
    if (c.startsWith('_')) {
      const labelWithPrefix = c.split('_')[1];
      emptyLabel[labelWithPrefix] = tableResult[idx];
    } else {
      if (c === 'outlier') {
        emptyLabel[c] = `${tableResult[idx]}`;
      } else {
        emptyLabel[c] = tableResult[idx];
      }
    }
  });

  return getConstantLabelForEmptyLabel(emptyLabel);
};

const getLabelForAnomalyBand = ({
  columnHeaders,
  tableResult,
  kfuseQlOperation,
  type,
}: {
  columnHeaders: string[];
  tableResult: (string | number)[];
  kfuseQlOperation: string;
  type: string;
}): { label: string; value: string | number } => {
  const labelBand = getLabelFromTableResult({
    columnHeaders,
    instant: false,
    kfuseQlOperation: `${kfuseQlOperation}_${type}`,
    tableResult,
  });
  const valueIndex = getValueColumnIndex({
    columnHeaders,
    instant: false,
    kfuseQlOperation: `${kfuseQlOperation}_${type}`,
  });
  labelBand['result_type'] = type;
  return { label: JSON.stringify(labelBand), value: tableResult[valueIndex] };
};

const getLabelForForecastBand = ({
  columnHeaders,
  tableResult,
  kfuseQlOperation,
  seasonality,
  type,
}: {
  columnHeaders: string[];
  tableResult: (string | number)[];
  kfuseQlOperation: string;
  seasonality: string;
  type: string;
}): { label: string; value: string | number } => {
  if (!seasonality) {
    const operation = `${kfuseQlOperation}_${type}`;
    const valueIndex = getValueColumnIndex({
      columnHeaders,
      instant: false,
      kfuseQlOperation: operation,
    });
    const label = getLabelFromTableResult({
      columnHeaders,
      instant: false,
      kfuseQlOperation: operation,
      tableResult,
    });
    label['result_type'] = type;
    return {
      label: JSON.parse(JSON.stringify(label)),
      value: tableResult[valueIndex],
    };
  }

  const labelBand = getLabelFromTableResult({
    columnHeaders,
    instant: false,
    kfuseQlOperation: `${kfuseQlOperation}_${type}`,
    tableResult,
  });
  const valueIndex = getValueColumnIndex({
    columnHeaders,
    instant: false,
    kfuseQlOperation: `${kfuseQlOperation}_${type}`,
  });
  labelBand['result_type'] = type;
  return {
    label: JSON.parse(JSON.stringify(labelBand)),
    value: tableResult[valueIndex],
  };
};

const getValueColumnIndex = ({
  columnHeaders,
  instant,
  kfuseQlOperation,
}: {
  columnHeaders: string[];
  instant: boolean;
  kfuseQlOperation?: string;
}) => {
  if (kfuseQlOperation) {
    return columnHeaders.findIndex((c) => c === kfuseQlOperation);
  }

  let lastValue = -1;
  columnHeaders.forEach((c, idx) => {
    if (instant && c.startsWith('_')) {
      lastValue = idx;
    }
    if (!instant && c.startsWith('_') && c !== TIMESLICE_KEY) {
      lastValue = idx;
    }
  });

  if (lastValue === -1) {
    return 0;
  }
  return lastValue;
};

export const kfuseqlDataTransformer = ({
  datasets,
  meta,
}: {
  datasets: KfuseQlResult;
  meta: DataFrame['meta'];
}): DataFrame => {
  let maxValue = -Infinity;
  let minValue = Infinity;

  const { executedDate } = meta;
  const filledTimestamp = fillTimestampForWindow({
    executedDate,
    step: meta.step,
  });
  if (!datasets || datasets.TableResult.length === 0) {
    return {
      data: [],
      meta,
      minValue,
      maxValue,
      timestamps: filledTimestamp.timestamps,
    };
  }

  const labelBitmap: Record<string, Record<number, number | undefined>> = {};
  const dataTimestampBitmap: Record<number, boolean> = {};
  const { TableResult, ColumnHeaders } = datasets;

  const timesliceIndex = ColumnHeaders.findIndex((c) => c === TIMESLICE_KEY);
  const valueIndex = getValueColumnIndex({
    columnHeaders: ColumnHeaders,
    instant: false,
    kfuseQlOperation: meta.kfuseQlOperation,
  });
  if (timesliceIndex === -1 || valueIndex === -1) return;

  TableResult.forEach((row) => {
    const timestamp = Number(row[timesliceIndex]) / 1000;
    dataTimestampBitmap[timestamp] = true;
    const value = Number(row[valueIndex]);
    if (value > maxValue) maxValue = value;
    if (value < minValue) minValue = value;
    const label = getLabelFromTableResult({
      columnHeaders: ColumnHeaders,
      instant: false,
      kfuseQlOperation: meta.kfuseQlOperation,
      tableResult: row,
    });
    const labelString = JSON.stringify(label);
    labelBitmap[labelString] = labelBitmap[labelString] || {};
    labelBitmap[labelString][timestamp] = value;

    if (meta.isAnomaly) {
      ['upper', 'lower'].forEach((type) => {
        const { label, value } = getLabelForAnomalyBand({
          columnHeaders: ColumnHeaders,
          tableResult: row,
          kfuseQlOperation: meta.kfuseQlOperation,
          type,
        });
        labelBitmap[label] = labelBitmap[label] || {};
        labelBitmap[label][timestamp] = value;
      });
    }

    if (meta.isForecast) {
      if (meta.isForecast) {
        const forecastTypes = meta.forecastSeasonality
          ? ['upper', 'lower', 'forecast']
          : ['forecast'];

        forecastTypes.forEach((type) => {
          const { label, value } = getLabelForForecastBand({
            columnHeaders: ColumnHeaders,
            tableResult: row,
            kfuseQlOperation: meta.kfuseQlOperation,
            seasonality: meta.forecastSeasonality,
            type,
          });

          const labelString = JSON.stringify(label);
          labelBitmap[labelString] = labelBitmap[labelString] || {};
          labelBitmap[labelString][timestamp] = value;
        });
      }
    }
  });

  const timestamps = mergeFilledTimestampAndDatasetTimestamps(
    filledTimestamp.timestampBitmap,
    dataTimestampBitmap,
  );
  const data = Object.entries(labelBitmap).map(([label, valuesMap]) => {
    const values = timestamps.map((timestamp) => {
      const value = valuesMap[timestamp];
      if (value !== undefined) {
        if (value > maxValue) maxValue = value;
        if (value < minValue) minValue = value;
      }
      return value;
    });
    return { label: JSON.parse(label), values };
  });

  return { data, meta, minValue, maxValue, timestamps };
};

export const kfuseqlDataTransformerInstant = ({
  datasets,
  meta,
}: {
  datasets: KfuseQlResult;
  meta: DataFrame['meta'];
}): DataFrame => {
  const data: DataFrame['data'] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;

  if (!datasets || datasets.TableResult.length === 0) {
    return { data: [], meta, minValue, maxValue, timestamps: [] };
  }

  const { TableResult, ColumnHeaders } = datasets;
  const valueIndex = getValueColumnIndex({
    columnHeaders: ColumnHeaders,
    instant: true,
    kfuseQlOperation: meta.kfuseQlOperation,
  });
  if (valueIndex === -1) return;

  TableResult.forEach((row) => {
    const value = Number(row[valueIndex]);
    if (value > maxValue) maxValue = value;
    if (value < minValue) minValue = value;
    const label = getLabelFromTableResult({
      columnHeaders: ColumnHeaders,
      instant: true,
      kfuseQlOperation: meta.kfuseQlOperation,
      tableResult: row,
    });

    if (Object.keys(label).length === 0) {
      label['label'] = meta.metricName;
    }

    if (meta.type === DashboardPanelType.TOP_LIST) {
      Object.keys(label).forEach((key) => {
        if (typeof label[key] === 'number') {
          label[key] = `${key}: ${label[key]}`;
        }
      });
    }

    data.push({ label, values: [value] });
  });

  return { data, meta, minValue, maxValue };
};
