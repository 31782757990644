import {
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  Table,
  TableSearch,
  TooltipTrigger,
  useTableSearch,
} from 'components';
import useTracesOverlayMessage from 'hooks/useTracesOverlayMessage';
import React, { ReactElement } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { convertNumberToReadableUnit } from 'utils/formatNumber';
import MetricsCardinalityBarChart from '../MetricsCardinality/MetricsCardinalityBarChart';
import useTracesCardinality from './useTracesCardinality';

const columns = ({
  isValueChartLoading,
  isValueOneHourLoading,
}: {
  isValueChartLoading: boolean;
  isValueOneHourLoading: boolean;
}) => [
  {
    key: 'Attribute',
    label: 'Label',
    renderCell: ({ row }: { row: any }) => row.GroupVal['key'],
  },
  {
    key: 'valueOneHour',
    label: 'Value Count (1h)',
    renderCell: ({ row }: { row: any }) => {
      if (isValueOneHourLoading) return <div className="spinner" />;
      return (
        <TooltipTrigger tooltip={row.Value > 1000 ? row.Value : ''}>
          {convertNumberToReadableUnit(row.valueOneHour)}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'valueCount',
    label: 'Value Count',
    renderCell: ({ row }: { row: any }) => {
      return (
        <TooltipTrigger tooltip={row.Value > 1000 ? row.Value : ''}>
          {convertNumberToReadableUnit(row.Value)}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'valueChart',
    label: 'Value Chart',
    renderCell: ({ row }: { row: any }) => {
      if (isValueChartLoading) return <div className="spinner" />;
      return <MetricsCardinalityBarChart data={row.valueSeries || []} />;
    },
  },
];

type Props = {
  tracesCardinality: ReturnType<typeof useTracesCardinality>;
  error: {
    getTracesCardinality: { message: string };
  };
};

const TracesCardinalityTable = ({
  tracesCardinality,
  error,
}: Props): ReactElement => {
  const {
    overallTraceCardinalityRequest,
    tracesCardinalityRows,
    traceCardinalityRequest,
    traceCardinalityRequestOneHour,
    traceCardinalityChartRequest,
  } = tracesCardinality;

  const overlayMessage = useTracesOverlayMessage({
    data: traceCardinalityRequest,
  });

  const tableSearch = useTableSearch({
    rows: tracesCardinalityRows,
    shouldWriteToUrl: false,
  });

  const overlayMessageProps: OverlayMessageProps = error.getTracesCardinality
    ? {
        isActive: true,
        iconName: 'warning',
        message: (
          <>
            {overlayMessage || 'Error.'} Please double check your query and try
            again.
          </>
        ),
      }
    : { isActive: false };

  return (
    <>
      <div className="flex justify-start items-center gap-[4px] pt-4">
        <div
          className="text--h3 whitespace-nowrap"
          data-testid="all-cardinality-label"
        >
          All cardinality: {overallTraceCardinalityRequest.result || ''}
        </div>
        {error.getTracesCardinality && (
          <div className="flex gap-[4px] w-full justify-end pr-[14px]">
            <IoIosWarning
              className="overlay-message__icon-and-message__icon"
              size={16}
            />
            <div className="text-red-500">
              {error.getTracesCardinality.message}
            </div>
          </div>
        )}
      </div>
      <Loader
        className="mb-8 mt-2"
        isLoading={traceCardinalityRequest.isLoading}
      >
        <TableSearch
          className="uplot__value-legends__search-bar"
          placeholder="Search labels..."
          tableSearch={tableSearch}
          dataTestId="label-search-bar"
        />

        <OverlayMessage {...overlayMessageProps}>
          <Table
            className="table--bordered table--bordered-cells metrics-cardinality-table"
            columns={columns({
              isValueChartLoading: traceCardinalityChartRequest.isLoading,
              isValueOneHourLoading: traceCardinalityRequestOneHour.isLoading,
            })}
            rows={tableSearch.searchedRows}
            dataTestId="cardinality-table"
          />
        </OverlayMessage>
      </Loader>
    </>
  );
};

export default TracesCardinalityTable;
