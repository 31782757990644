import classNames from 'classnames';
import { Button, CodeEditor, TooltipTrigger } from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { LogsMetricQueryProps } from 'types';

const LogsMetricsQueryBuilderInput = ({
  handleRunFreeText,
  logsMetricsQueryState,
  type = 'logql',
  query,
  queryIndex,
  queryKeyClickHandler,
}: {
  handleRunFreeText?: () => void;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  type?: 'logql' | 'fuseql';
  query: LogsMetricQueryProps;
  queryIndex: number;
  queryKeyClickHandler: () => void;
}): ReactElement => {
  const value = useMemo(
    () => query[type as keyof LogsMetricQueryProps] as string,
    [query, type],
  );
  const { updateQuery, loadLogqlQuery, loadFuseQlQuery } =
    logsMetricsQueryState;

  const handleUpdateQuery = (val: string) => {
    updateQuery(queryIndex, type, val, {
      ignoreLoading: true,
    });
  };

  const handleRun = () => {
    if (type === 'logql') {
      loadLogqlQuery(query);
    }
    if (type === 'fuseql') {
      loadFuseQlQuery(query);
      handleRunFreeText?.();
    }
  };

  return (
    <div className="metrics__query-builder__query-item">
      <div className="metrics__query-builder__query-item__code">
        <TooltipTrigger tooltip={query.isActive ? 'Hide Chart' : 'Show Chart'}>
          <div
            onClick={queryKeyClickHandler}
            className={classNames({
              'query-builder__logs__item__query-key': true,
              'query-builder__logs__item__query-key--inactive': !query.isActive,
            })}
          >
            {query.queryKey}
          </div>
        </TooltipTrigger>
        <CodeEditor
          onChange={(val: string) => handleUpdateQuery(val)}
          value={value}
        />
        <Button
          className="h-full"
          variant="default"
          size="sm"
          onClick={handleRun}
        >
          Run
        </Button>
      </div>
    </div>
  );
};

export default LogsMetricsQueryBuilderInput;
