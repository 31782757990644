import { DateSelection, LogsMetricQueryProps } from 'types';
import { parseUrlParamByKey } from 'utils/url';
import { getInitialState } from 'hooks/useSearch/utils';

export const getMetricAlertTypeFromQuery = (
  query: LogsMetricQueryProps,
): string => {
  const { functions } = query;
  if (!functions || functions.length === 0) return 'THRESHOLD';
  const lastFunction = functions[functions.length - 1];
  if (!lastFunction) return 'THRESHOLD';
  return 'THRESHOLD';
};

export const onCreateAlertRUM = ({
  date,
  queryItem,
  aggregateField,
  aggregateFunction,
}: {
  date: DateSelection;
  queryItem: {
    queries: LogsMetricQueryProps[];
    queryIndex?: number;
  };
  aggregateField?: string;
  aggregateFunction?: string;
}) => {
  const { queries } = queryItem;
  const queryType = queries?.[0].queryType;
  const advancedGroupBys = queries[0].advancedGroupBys;
  const groupBys = queries[0].groupBys;
  const searches = getInitialState();
  searches.advancedGroupBys = advancedGroupBys;
  searches.groupBys = groupBys;
  const filters = queries?.[0].searchBarState.filters;
  const staticFilters = queries[0].staticFilters;
  const combinedFilters = [];
  if (staticFilters) {
    staticFilters.forEach((sf) => {
      combinedFilters.push({
        disabled: false,
        type: 'selectedFacetValue',
        value: {
          facet: sf.attributeFilter.eq.key,
          operator: '=',
          values: { [sf.attributeFilter.eq.value]: 1 },
        },
      });
    });
  }
  if (filters) {
    combinedFilters.push(...filters);
  }

  const queryIndex = 0;
  let newQueries = queries.map((query) => ({
    ...query,
    filters: [...(query.filters || [])],
  }));
  newQueries = [newQueries[queryIndex]];
  if (newQueries.length === 1) {
    newQueries[0] = { ...newQueries[0], queryKey: 'a' };
  }

  const alertType = getMetricAlertTypeFromQuery(newQueries[queryIndex]);
  const ruleTypeEncoded = encodeURIComponent(JSON.stringify({ value: 'rum' }));
  const alertTypeEncoded = encodeURIComponent(
    JSON.stringify({ value: alertType }),
  );
  const applicationFilter = parseUrlParamByKey('rumApplicationFilter');
  const eventType = queries[0].eventType;
  const modifiedSearches = [searches];
  modifiedSearches[0].multiAggregations = [
    {
      measure: aggregateField || null,
      operation: aggregateFunction || null,
    },
  ];

  let url = `/#/alerts/create?ruleType=${ruleTypeEncoded}&alertType=${alertTypeEncoded}&rumApplicationFilter=${applicationFilter}&date=${encodeURIComponent(
    JSON.stringify(date),
  )}&rumEventType=${eventType}&rumFilters=${encodeURIComponent(
    JSON.stringify(combinedFilters),
  )}&searches=${encodeURIComponent(JSON.stringify(modifiedSearches))}`;

  if (queryType) {
    url += `&queryType=${encodeURIComponent(queryType)}`;
  }

  window.open(url, '_blank');
};
