import dayjs from 'dayjs';
import { useRequest, useSubscriptionRequest, useToggle } from 'hooks';
import { useMemo } from 'react';
import {
  formatLogMetricsResultWithFuseQlStreamResult,
  formatSeriesToLogCountsWithStepSize,
  getLogMetricsResultWithFuseQlStream,
  getLogStackedBarCountsUsingMetrics,
} from 'requests';
import { DateSelection } from 'types';
import getTimeline from './getTimeline';
import { getBucketSecs } from '../utils';

const getCompareUnit = (date: DateSelection) => {
  const { startTimeUnix, endTimeUnix } = date;
  const diff = endTimeUnix - startTimeUnix;

  if (diff > 60 * 60 * 24 * 365) {
    return null;
  }

  if (diff > 60 * 60 * 24 * 30) {
    return 'year';
  }

  if (diff > 60 * 60 * 24 * 7) {
    return 'month';
  }

  if (diff > 60 * 60 * 24) {
    return 'week';
  }

  return 'day';
};

const useCompareTimeline = (
  logsState: any,
  width: number,
  isAdvancedFuseQlSearch?: boolean,
) => {
  const getLogStackedBarCountsUsingMetricsRequest = useRequest(
    getLogStackedBarCountsUsingMetrics,
  );

  const getLogStackedBarCountsUsingMetricsRequestFuseQl =
    useSubscriptionRequest({
      queryBuilder: getLogMetricsResultWithFuseQlStream,
      merge: (prevResult, update, callArgs) => {
        const { bucketSecs } = callArgs[0];
        const anchorTs = prevResult.length ? prevResult[0].bucketStart : null;
        const stepMs = bucketSecs * 1000;
        const formattedResult =
          formatLogMetricsResultWithFuseQlStreamResult(update);
        return [
          ...prevResult,
          ...formatSeriesToLogCountsWithStepSize({
            anchorTs,
            stepMs,
            timeseries: formattedResult,
          }),
        ];
      },
      key: 'getLogMetricsResultWithFuseQlStream',
      initialState: [],
    });

  const isEnabledToggle = useToggle();

  const {
    customerFilter,
    filterByFacets,
    filterOrExcludeByFingerprint,
    freeText,
    keyExists,
    searchTerms,
    selectedFacetValues,
  } = logsState;

  const unit = getCompareUnit(logsState.date);

  const startTimeUnix = dayjs
    .unix(logsState.date.startTimeUnix)
    .subtract(1, unit)
    .unix();
  const endTimeUnix = dayjs
    .unix(logsState.date.endTimeUnix)
    .subtract(1, unit)
    .unix();

  const date = {
    ...logsState.date,
    startTimeUnix,
    endTimeUnix,
  };

  const fetchIfNeeded = () => {
    if (isAdvancedFuseQlSearch) {
      const bucketSecs = getBucketSecs(date, width);
      getLogStackedBarCountsUsingMetricsRequestFuseQl.call({
        bucketSecs,
        logsState: {
          customerFilter,
          date,
          filterByFacets,
          filterOrExcludeByFingerprint,
          keyExists,
          searchTerms,
          selectedFacetValues,
        },
        query: freeText,
      });
    } else {
      if (!getLogStackedBarCountsUsingMetricsRequest.calledAtLeastOnce) {
        const bucketSecs = getBucketSecs(date, width);

        getLogStackedBarCountsUsingMetricsRequest.call({
          bucketSecs,
          logsState: {
            customerFilter,
            date,
            filterByFacets,
            filterOrExcludeByFingerprint,
            keyExists,
            searchTerms,
            selectedFacetValues,
          },
        });
      }
    }
  };

  const logCounts = getLogStackedBarCountsUsingMetricsRequest.result || [];

  const logsCountsFromFuseQl =
    getLogStackedBarCountsUsingMetricsRequestFuseQl.result || [];

  const compareTimeline = useMemo(
    () => {
      const logsCountToUse = isAdvancedFuseQlSearch
        ? logsCountsFromFuseQl
        : logCounts;
      return getTimeline(date, logsCountToUse, null, width);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isAdvancedFuseQlSearch,
      getLogStackedBarCountsUsingMetricsRequestFuseQl.result,
      getLogStackedBarCountsUsingMetricsRequest.result,
      width,
    ],
  );

  const reset = () => {
    getLogStackedBarCountsUsingMetricsRequest.clear();
    isEnabledToggle.off();
  };

  const toggle = () => {
    if (!isEnabledToggle.value) {
      fetchIfNeeded();
    }

    isEnabledToggle.toggle();
  };

  return {
    compareTimeline,
    enabled: isEnabledToggle.value,
    reset,
    toggle,
  };
};

export default useCompareTimeline;
