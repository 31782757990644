import { useToaster } from 'components/Toasts';
import { useDateState, useRequest } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import {
  aggregateTimeSeriesFormula,
  aggregateTimeSeriesMultiple,
  promqlQueryRangeV3,
  getLogMetricsTimeSeriesLogQL,
  getLogMetricsResultWithKfuseQl,
} from 'requests';
import {
  DashboardPanelType,
  PromqlWithMetaProps,
  QueryDataProps,
  QueryDataPropsRange,
} from 'types';
import {
  eventsDataTransformer,
  eventsDataFrameToQuery,
  getRollupByVisualization,
  getRollupToSecond,
  rumDataTransformer,
  kfuseqlDataTransformer,
  logsDataTransformer,
  metricsDataTransformer,
  transformSeriesForShowAndHide,
  DataFrameMeta,
  addPrefixToSeriesAndDash,
  anomalyDataTransformer,
  metricsForecastLoader,
  logsAnomalyDataTransformer,
  kfuseqlForecastLoader,
  DataTransformerConfig,
} from 'utils';

import {
  TimeseriesExplorerProps,
  TimeseriesExplorerPromqlProps,
  MetricsExplorerFunctionListProps,
} from './types';
import {
  getCompareToPreviousList,
  getPredefinedFunctions,
  getPreviousTimeWithLabel,
  getPreviousTimeRangeWithPromql,
  transformLogqlExplorerData,
  getFunctionListWithPromql,
  convertArrayDatasetsToObject,
  getFunctionListWithKfuseQl,
} from './utils';
import aggregateEventTimeSeries from 'screens/Events/requests/aggregateEventTimeSeries';
import fetchRumTimeseriesData from 'screens/Rum/requests/fetchRumTimeseriesData';
import fetchRumErrorsSeries from 'screens/Rum/requests/fetchRumErrorsSeries';
import fetchRumViewCrashesData from 'screens/Rum/requests/fetchRumViewCrashesData';
import fetchRumFrozenFramesData from 'screens/Rum/requests/fetchRumFrozenFramesData';
import fetchRumMemoryByVersionData from 'screens/Rum/requests/fetchRumMemoryByVersionData';
import fetchRumSlowRenderData from 'screens/Rum/requests/fetchRumSlowRenderData';
import fetchRumCrashByVersionData from 'screens/Rum/requests/fetchRumCrashByVersionData';

const useTimeseriesExplorer = ({
  activeChartType,
  activeQueries,
  date: prevDate,
  chartData: prevChartData,
  queryType,
  eventType,
}: Omit<TimeseriesExplorerProps | 'unit'>) => {
  const { addToast } = useToaster();
  const [error, setError] = useState({ aggregateTimeSeriesMultiple: null });
  const [date, setDate] = useDateState(prevDate);
  const [compareToPrev, setCompareToPrev] = useState(
    getCompareToPreviousList(),
  );
  const userActionRef = useRef({ defaultTimeChanged: false });
  const [functionList, setFunctionList] = useState<
    MetricsExplorerFunctionListProps[]
  >(getPredefinedFunctions());
  const logqlQueryRangeRequest = useRequest(
    getLogMetricsTimeSeriesLogQL,
    false,
  );

  const traceQueryRequest = useRequest(
    aggregateTimeSeriesMultiple,
    false,
    true,
  );

  const eventQueryRequest = useRequest(
    (args) => aggregateEventTimeSeries(args).then(eventsDataFrameToQuery),
    false,
  );
  const rumPerformanceQueryRequest = useRequest(
    (args) => fetchRumTimeseriesData(args),
    false,
  );
  const rumTopListErrorQueryRequest = useRequest(
    (args) => fetchRumErrorsSeries(args),
    false,
  );
  const rumTopListViewCrashesQueryRequest = useRequest(
    (args) => fetchRumViewCrashesData(args),
    false,
  );
  const rumFrozenFrameQueryRequest = useRequest(
    (args) => fetchRumFrozenFramesData(args),
    false,
  );
  const rumSlowRenderQueryRequest = useRequest(
    (args) => fetchRumSlowRenderData(args),
    false,
  );
  const rumMemoryByVersionRequest = useRequest(
    (args) => fetchRumMemoryByVersionData(args),
    false,
  );
  const rumCrashByVersionRequest = useRequest(
    (args) => fetchRumCrashByVersionData(args),
    false,
  );

  const [chartData, setChartData] = useState(
    prevChartData || { data: [], series: [] },
  );
  const [chartSize, setChartSize] = useState({ width: 800, height: 300 });
  const [seriesBitmap, setSeriesBitmap] = useState({});
  const [yScale, setYScale] = useState<'log' | 'linear'>('linear');
  const [init, setInit] = useState(false);

  const getStep = (steps: string | number) => {
    if (userActionRef.current.defaultTimeChanged) {
      return getRollupByVisualization(date, activeChartType);
    }
    if (typeof steps === 'string') {
      return getRollupToSecond(steps);
    }
    if (typeof steps === 'number') {
      return steps;
    }
    return getRollupByVisualization(date, activeChartType);
  };

  const handleLoading = (promqlQueries: TimeseriesExplorerPromqlProps[]) => {
    const queryData: QueryDataProps = {};
    promqlQueries.forEach((item, idx) => {
      const queryKey = String.fromCharCode(97 + idx);
      const queryId = `query_${queryKey}`;
      queryData[queryId] = { isLoading: true, meta: undefined, range: null };
    });
    setChartData(queryData);
  };

  const buildPromqlForCompareAndFunctions = () => {
    let promqlQueries: TimeseriesExplorerPromqlProps[] = [];
    activeQueries.forEach(
      ({ legendFormat, logql, metricName, promql, steps }) => {
        promqlQueries.push({
          date,
          legendFormat,
          logql,
          metricName,
          promql,
          steps,
        });
        const compareToPrevQueries = getPreviousTimeRangeWithPromql(
          compareToPrev,
          date,
          promql,
          logql,
        );

        if (compareToPrevQueries.length > 0) {
          compareToPrevQueries.forEach((item) => {
            promqlQueries.push({ ...item, legendFormat, metricName, steps });
          });
        }
      },
    );

    const activeFunctionList = functionList.filter((item) => item.isActive);
    if (activeFunctionList.length > 0) {
      const functionListWithPromql = getFunctionListWithPromql({
        activeFunctionList,
        promqlQueries,
      });
      promqlQueries = functionListWithPromql;
    }

    return promqlQueries;
  };

  const callPromqlQueryRange = async () => {
    const promqlQueries = buildPromqlForCompareAndFunctions();

    const loadAnomalyDatasets = async (
      promql: string[],
      meta: PromqlWithMetaProps['meta'],
    ) => {
      const queryId = `query_${meta.refId}`;
      const datasets = await Promise.all(
        promql.map(async (p, idx) => {
          const transformer = metricsDataTransformer();
          if (idx === 0) {
            const transformerToTimeseriesIndex = transformer.findIndex(
              (item) => item.id === 'transformToTimeseries',
            );
            transformer[transformerToTimeseriesIndex] = {
              id: 'anomalyBandTransformer',
              func: anomalyDataTransformer,
            };
          }

          const data = await promqlQueryRangeV3({
            date,
            promqlQuery: p,
            meta,
            transformer,
          });

          if (idx === 0) {
            return {
              [`${queryId}_anomaly_upper`]: {
                range: data[0],
                meta,
                isLoading: false,
              },
              [`${queryId}_anomaly_lower`]: {
                range: data[1],
                meta,
                isLoading: false,
              },
            };
          }

          return {
            [`${queryId}_anomaly`]: { range: data, meta, isLoading: false },
          };
        }),
      );
      return convertArrayDatasetsToObject(datasets);
    };

    handleLoading(promqlQueries);
    const datasets = await Promise.all(
      promqlQueries.map(
        async (
          { date, legendFormat, label, metricName, promql, steps, ...rest },
          queryIndex,
        ) => {
          const meta: DataFrameMeta = {
            refId: String.fromCharCode(97 + queryIndex),
            legendFormat,
            metricName,
            step: getStep(steps),
            type: DashboardPanelType.TIMESERIES,
          };

          const queryId = `query_${meta.refId}`;
          if (!promql) return { data: [], series: [] };
          const transformer = metricsDataTransformer(false);
          if (typeof promql === 'string') {
            transformer.push({
              id: 'transformSeriesForShowAndHide',
              func: (queryData: QueryDataPropsRange) =>
                transformSeriesForShowAndHide(queryData, seriesBitmap),
            });
            if (label) {
              transformer.push({
                id: 'addPrefixToSeries',
                func: (queryData: QueryDataPropsRange) =>
                  addPrefixToSeriesAndDash(
                    queryData,
                    (seriesLabel) => `${label}(${seriesLabel})`,
                  ),
              });
            }
            const data = await promqlQueryRangeV3({
              date,
              promqlQuery: promql,
              transformer,
              meta,
            });
            return {
              [queryId]: { range: data, meta, isLoading: false },
            };
          } else if (Array.isArray(promql)) {
            const { isAnomaly, isForecast, forecastSeasonality } = rest;
            if (isAnomaly) {
              return await loadAnomalyDatasets(promql, meta);
            }
            if (isForecast) {
              const forecastDatasets = await metricsForecastLoader({
                addToast,
                date,
                forecastSeasonality,
                meta,
                promql,
              });
              if (Array.isArray(forecastDatasets)) {
                const queryKeys = ['_upper', '_lower', ''];
                const queryData = {};
                queryKeys.forEach((key, idx) => {
                  queryData[`${queryId}_forecast${key}`] = {
                    range: forecastDatasets[idx],
                    meta,
                    isLoading: false,
                  };
                });
                return queryData;
              } else {
                return {
                  [`${queryId}_forecast`]: {
                    range: forecastDatasets,
                    meta,
                    isLoading: false,
                  },
                };
              }
            }
          }
        },
      ),
    );

    const transformedData = convertArrayDatasetsToObject(datasets);
    setChartData(transformedData);
  };

  const callLogqlQueryRange = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const logqlQueries: TimeseriesExplorerPromqlProps[] = activeQueries.map(
      (item) => ({ ...item, date }),
    );
    activeQueries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        logqlQueries.push({ ...item, date: prevDate, label });
      });
    });

    const datasets = await Promise.all(
      logqlQueries.map(({ date, logql, steps }, idx: number) =>
        logqlQueryRangeRequest.call({
          date,
          format: 'timeseries',
          instant: false,
          logQlQuery: {
            logQL: logql,
            step: getStep(steps) * 1000,
            queryKey: `query_${idx}`,
          },
          width: chartSize.width,
        }),
      ),
    );
    const transformedData = transformLogqlExplorerData(
      datasets,
      logqlQueries,
      seriesBitmap,
    );

    setChartData(transformedData);
  };

  const callKfuseqlQueryRange = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    let kfuseqlQueries: TimeseriesExplorerPromqlProps[] = activeQueries.map(
      (item) => ({ ...item, date }),
    );

    const activeFunctionList = functionList.filter((item) => item.isActive);
    if (activeFunctionList.length > 0) {
      kfuseqlQueries = getFunctionListWithKfuseQl({
        activeFunctionList,
        kfuseqlQueries,
      });
    }

    activeQueries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        kfuseqlQueries.push({ ...item, date: prevDate, label });
      });
    });

    const loadKfuseqlAnomalyData = async (
      kfuseQl: string,
      meta: DataFrameMeta,
      transformer: DataTransformerConfig[],
    ) => {
      transformer.pop();
      transformer.push({
        id: 'logsAnomalyDataTransformer',
        func: logsAnomalyDataTransformer,
      });
      meta.isAnomaly = true;
      const data = await getLogMetricsResultWithKfuseQl({
        date,
        kfuseQl,
        transformer,
        meta,
      });

      const queryData: QueryDataProps = {};
      const queryKeys = ['_upper', '_lower', ''];
      const queryId = `query_${meta.refId}`;
      queryKeys.forEach((key, idx) => {
        queryData[`${queryId}_anomaly${key}`] = {
          range: data[idx],
          meta,
          isLoading: false,
        };
      });

      return queryData;
    };

    const loadKfuseqlForecastData = async (
      kfuseQl: string,
      meta: DataFrameMeta,
      transformer: DataTransformerConfig[],
    ) => {
      meta.isForecast = true;
      const forecastDatasets = await kfuseqlForecastLoader({
        addToast,
        date,
        kfuseQl,
        meta,
        transformer,
      });

      const { refId, forecastSeasonality } = meta;
      const queryId = `query_${refId}`;
      const queryKeys = forecastSeasonality ? ['_upper', '_lower', ''] : [''];
      const queryData = {};
      queryKeys.forEach((key, idx) => {
        queryData[`${queryId}_forecast${key}`] = {
          range: forecastDatasets[idx],
          meta,
          isLoading: false,
        };
      });
      return queryData;
    };

    handleLoading(kfuseqlQueries);
    const datasets = await Promise.all(
      kfuseqlQueries.map(
        async ({ date, logql, label, steps, ...rest }, idx: number) => {
          const transformer = logsDataTransformer(false);
          transformer[0] = {
            id: 'kfuseqlDataTransformer',
            func: kfuseqlDataTransformer,
          };

          const { isAnomaly, isForecast, forecastSeasonality, metricName } =
            rest;
          const meta: DataFrameMeta = {
            refId: String.fromCharCode(97 + idx),
            step: getStep(steps),
            type: DashboardPanelType.TIMESERIES,
            metricName,
            isAnomaly,
            kfuseQlOperation: `_${metricName}`,
          };
          if (isAnomaly) {
            return await loadKfuseqlAnomalyData(logql, meta, transformer);
          }

          if (isForecast) {
            meta.forecastSeasonality = forecastSeasonality;
            return await loadKfuseqlForecastData(logql, meta, transformer);
          }

          if (!isAnomaly && !isForecast) {
            transformer.push({
              id: 'transformSeriesForShowAndHide',
              func: (queryData: QueryDataPropsRange) =>
                transformSeriesForShowAndHide(queryData, seriesBitmap),
            });
            if (label) {
              transformer.push({
                id: 'addPrefixToSeries',
                func: (queryData: QueryDataPropsRange) =>
                  addPrefixToSeriesAndDash(
                    queryData,
                    (seriesLabel) => `${label}(${seriesLabel})`,
                  ),
              });
            }
          }

          const data: QueryDataPropsRange =
            await getLogMetricsResultWithKfuseQl({
              date,
              kfuseQl: logql,
              transformer,
              meta,
            });
          const queryId = `query_${meta.refId}`;
          return {
            [queryId]: { range: data, meta, isLoading: false },
          };
        },
      ),
    );

    const transformedData = convertArrayDatasetsToObject(datasets);
    setChartData(transformedData);
  };

  const callTraceQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const traceQueries: TimeseriesExplorerPromqlProps[] = activeQueries.map(
      (item) => ({ ...item, date }),
    );
    const queries: any = [];
    const formulas: any = [];
    traceQueries.map((item) => {
      if (item.type === 'formula') {
        formulas.push(item);
      } else {
        queries.push(item);
      }
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });

    formulas.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        formulas.push({ ...item, date: prevDate, label });
      });
    });

    const datasets = await Promise.all(
      queries.map(async ({ date, filter, query }) =>
        traceQueryRequest.call({
          date,
          ...filter,
          queries: [query],
          resultFormat: 'timeseries',
        }),
      ),
    );

    const datasetsFormula = await Promise.all(
      formulas.map(({ date, formula, filter, queries }) =>
        aggregateTimeSeriesFormula({
          date,
          ...filter,
          formula,
          queries,
          resultFormat: 'timeseries',
        }),
      ),
    );

    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(dataset[0] || {});
    });
    datasetsFormula.map((dataset) => {
      queryDatasets.push(dataset || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries, ...formulas],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callEventsQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const eventQueries: TimeseriesExplorerPromqlProps[] = activeQueries.map(
      (item) => ({ ...item, date }),
    );
    const queries: any = [];
    eventQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });

    const baseTransformers = eventsDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        eventQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
        }),
      ),
    );

    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0]?.data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumPerformanceQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const rumPerformanceQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumPerformanceQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        rumPerformanceQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
        }),
      ),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0] || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumTopListErrorQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const rumTopListQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumTopListQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        rumTopListErrorQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
        }),
      ),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0].data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumTopListViewCrashesQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const rumTopListQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumTopListQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        rumTopListViewCrashesQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
        }),
      ),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0].data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumFrozenFrameQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const rumTopListQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumTopListQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        rumFrozenFrameQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
        }),
      ),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0].data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumSlowRenderQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const rumTopListQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumTopListQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        rumSlowRenderQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
        }),
      ),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0].data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumMemoryByVersionQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const staticFilters = activeQueries[0].query.staticFilters;
    const rumTopListQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumTopListQueries.map((item) => {
      queries.push(item);
    });
    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) => {
        return rumMemoryByVersionRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
          staticFilters: staticFilters,
        });
      }),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0].data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumCrashByVersionQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const staticFilters = activeQueries[0].query.staticFilters;
    const rumTopListQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumTopListQueries.map((item) => {
      queries.push(item);
    });
    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) => {
        return rumCrashByVersionRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
          staticFilters: staticFilters,
        });
      }),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0].data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const loadQueryCall = () => {
    if (queryType === 'logql') {
      callLogqlQueryRange();
    } else if (queryType === 'trace') {
      callTraceQuery();
    } else if (queryType === 'events') {
      callEventsQuery();
    } else if (queryType === 'rum-performance') {
      callRumPerformanceQuery();
    } else if (queryType === 'rum-toplist-error') {
      callRumTopListErrorQuery();
    } else if (queryType === 'rum-toplist-view-crashes') {
      callRumTopListViewCrashesQuery();
    } else if (queryType === 'rum-frozen-frames') {
      callRumFrozenFrameQuery();
    } else if (queryType === 'rum-slow-renders') {
      callRumSlowRenderQuery();
    } else if (queryType === 'rum-memory-by-version') {
      callRumMemoryByVersionQuery();
    } else if (queryType === 'rum-crash-by-version') {
      callRumCrashByVersionQuery();
    } else if (queryType === 'kfuseql') {
      callKfuseqlQueryRange();
    } else {
      callPromqlQueryRange();
    }
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
      return;
    }
    userActionRef.current.defaultTimeChanged = true;
    loadQueryCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (prevChartData) return;
    loadQueryCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevChartData]);

  return {
    loadQueryCall,
    callRumPerformanceQuery,
    callRumTopListErrorQuery,
    callRumTopListViewCrashesQuery,
    callRumMemoryByVersionQuery,
    callRumCrashByVersionQuery,
    callEventsQuery,
    callLogqlQueryRange,
    callKfuseqlQueryRange,
    callPromqlQueryRange,
    callTraceQuery,
    chartData,
    chartSize,
    compareToPrev,
    date,
    functionList,
    logqlQueryRangeRequest,
    rumPerformanceQueryRequest,
    traceQueryRequest,
    queryType,
    seriesBitmap,
    setChartData,
    setChartSize,
    setCompareToPrev,
    setDate,
    setFunctionList,
    setSeriesBitmap,
    setYScale,
    yScale,
    error,
  };
};

export default useTimeseriesExplorer;
