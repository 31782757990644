import { TooltipTrigger } from 'components';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { BsQuestionCircleFill } from 'react-icons/bs';

import { AlertsDetectionPopup } from '../components';

const getDescriptionOfDetection = (data: {
  message: string;
  title: string;
  description: string;
}) => {
  if (!data) return <div></div>;
  const { message, title, description } = data;

  return (
    <div className="alerts__create__tab__description">
      {description}
      <div>
        <TooltipTrigger
          tooltip={() => (
            <AlertsDetectionPopup title={title} message={message} />
          )}
        >
          <BsQuestionCircleFill className="blue-question-circle" />
        </TooltipTrigger>
      </div>
    </div>
  );
};

const AlertsCreateDetection = ({
  activeDetection,
  detectionList,
  detectionDescriptionMap = {},
  setActiveDetection,
}: {
  activeDetection: string;
  detectionList: { label: string; value: string }[];
  detectionDescriptionMap?: {
    [key: string]: { title: string; description: string; message: string };
  };
  setActiveDetection?: (activeDetection: string) => void;
}): ReactElement => {
  const descDetails = detectionDescriptionMap[activeDetection];
  return (
    <div>
      <div className="alerts__create__metrics__detection__tabs">
        {detectionList.map(({ label, value }) => (
          <button
            className={classNames({
              alerts__tabs__item: true,
              'alerts__tabs__item--active': value === activeDetection,
            })}
            key={label}
            onClick={() => setActiveDetection(value)}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="alert__create__metrics__detaction_desciption">
        {getDescriptionOfDetection(descDetails)}
      </div>
    </div>
  );
};

export default AlertsCreateDetection;
