import dayjs from 'dayjs';
import queryRumService from './queryRumService';
import {
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types/selectedFacets';
import { buildRumFilter } from './utils';
import { onPromiseError } from 'utils';

type Args = {
  applicationFilter: string;
  endTimeUnix: number;
  facetRegex?: FacetRegexTerm[];
  startTimeUnix: number;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  aggregateFunction: string;
  aggregateField: string;
  staticFilters?: any;
  eventType: string;
};

const rumMobileInstant = async ({
  applicationFilter,
  endTimeUnix,
  facetRegex,
  startTimeUnix,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  aggregateFunction,
  aggregateField,
  staticFilters,
  eventType,
}: Args): Promise<any> => {
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const filter = buildRumFilter({
    applicationFilter,
    facetRegex,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    staticFilters,
  });

  return queryRumService<any, 'aggregateTable'>(`
      query {
        aggregateTable(
          eventType: ${eventType},
          timestamp: "${endTime.format()}",
          durationSecs: ${durationSecs},
          aggregates: [
            { field: "${aggregateField}", aggregation: "${aggregateFunction}" }
          ],
          filter: ${filter},
          groupBy: []
        ) {
          dimensions
          aggregates
        }
      }
    `).then((data) => data?.aggregateTable || [], onPromiseError);
};

export default rumMobileInstant;
