import React, { ReactElement, useMemo } from 'react';
//eslint-disable-next-line
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { AutocompleteOption } from './types';
import AutocompleteMenuListV2 from './AutocompleteMenuListV2';

const AutocompleteV2Creatable = ({
  className,
  components,
  onChange,
  options,
  optimize,
  placeholder,
  value,
  fallBackOptions,
  dataTestId,
  ...rest
}: {
  className?: string;
  onChange: (value: string) => void;
  options: AutocompleteOption[];
  optimize?: boolean;
  placeholder?: string;
  value: string;
  fallBackOptions?: AutocompleteOption[];
  dataTestId?: string;
} & CreatableProps<any, any, any>): ReactElement => {
  const foundLabel = useMemo(() => {
    if (value) {
      const foundOption = options?.find((option) => option.value === value);
      if (foundOption) {
        return foundOption;
      }
      const foundFallBackOption = fallBackOptions?.find(
        (option) => option.value === value,
      );
      if (foundFallBackOption) {
        return foundFallBackOption;
      }
      return null;
    }
    return null;
  }, [fallBackOptions, options, value]);

  const newComponents = useMemo(() => {
    if (optimize) {
      return { MenuList: AutocompleteMenuListV2, ...components };
    }
    return components;
  }, [optimize, components]);
  return (
    <div className={className} data-testid={dataTestId}>
      <CreatableSelect
        className="autocomplete-container"
        classNamePrefix="autocomplete-container"
        components={newComponents}
        formatCreateLabel={(inputValue) => `"${inputValue}"`}
        onChange={(e) => onChange(e.value)}
        options={options}
        placeholder={placeholder}
        value={foundLabel}
        {...rest}
      />
    </div>
  );
};

export default AutocompleteV2Creatable;
