import { SearchState, useRequest } from 'hooks';
import React, { useMemo } from 'react';
import { getServices } from 'requests';
import useDebouncedEffect from 'use-debounced-effect';
import { DateSelection, SelectedFacetValuesByName } from 'types';

import { RuleProps } from '../types';
import { getFiltersStateFromArrayStateItem } from 'hooks/useFiltersState';
import { AlertsTracesParsedProps } from '../AlertsCreateTraces/type';
import { getTracesTags } from 'screens/Traces';
import { getRollupByVisualization } from 'utils/rollup';

const AlertsDetailsPropertiesTraces = ({
  date,
  parsedMetadata,
  rule,
}: {
  date: DateSelection;
  parsedMetadata: AlertsTracesParsedProps;
  rule: RuleProps;
}) => {
  const getServicesRequest = useRequest(getServices, true, true);
  const { spanFilter } = parsedMetadata;

  const serviceByHash = useMemo(() => {
    return getServicesRequest.result?.reduce(
      (obj, service) => ({ ...obj, [service.hash]: service }),
      {},
    );
  }, [getServicesRequest.result]);
  const { queries, formulas } = parsedMetadata;

  useDebouncedEffect(
    () => {
      const selectedFacetValuesByName = { span_type: { db: 0 } };
      getServicesRequest.call({
        customerFilter: {},
        date,
        selectedFacetValuesByName:
          selectedFacetValuesByName as SelectedFacetValuesByName,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { ignoreInitialCall: false, timeout: 50 },
    [date],
  );

  const queriesWithFiltersState = useMemo(
    () => {
      const baseQueries = queries.map((query: SearchState) => ({
        ...query,
        filtersState: query.searchBarState.filters,
      }));

      return queries.map((query: SearchState, i: number) => ({
        ...query,
        filtersState: getFiltersStateFromArrayStateItem({
          key: 'filtersState',
          index: i,
          setState: () => {},
          state: baseQueries,
        }),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queries],
  );

  const tagsWithQueries = useMemo(
    () => {
      return queriesWithFiltersState.map((query) => {
        const filtersState = query.filtersState;
        return getTracesTags({
          colorsByServiceName: {},
          filtersState,
          serviceByHash: serviceByHash || {},
          setTraceIdSearch: () => {},
          traceIdSearch: '',
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queriesWithFiltersState, serviceByHash],
  );

  return (
    <div className="alerts__details__properties__summary__item">
      <div className="text-sm font-bold text-text-secondary">
        Span Filter: {spanFilter}
      </div>
      {tagsWithQueries.map((tags, index) => {
        const query = queriesWithFiltersState[index];
        return (
          <div key={index} className="flex flex-col pt-2">
            <div className="mr-2 text-text-secondary font-bold">
              Query ({query.queryKey})
            </div>
            {tags.length > 0 && (
              <div className="flex flex-row items-center">
                <div className="mr-2 text-text-secondary">Filters:</div>
                <div>
                  {tags.map((t) => (
                    <div className="chip" key={index}>
                      {t.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex flex-row items-center">
              <div className="mr-2 text-text-secondary">Limit to:</div>
              <div>{query.limitToValue}</div>, &nbsp;
              <div className="mr-2 text-text-secondary">Sort direction:</div>
              <div>{query.limitTo}</div>, &nbsp;
              <div className="mr-2 text-text-secondary">Rollup:</div>
              <div>
                {query.rollUpInSeconds ||
                  `${getRollupByVisualization(date, 'bar')}s`}
              </div>
              , &nbsp;
              <div className="mr-2 text-text-secondary">Group by:</div>
              {query.groupBys.map((groupBy) => (
                <div className="chip" key={groupBy}>
                  {groupBy === '*'
                    ? 'Everything'
                    : groupBy === 'service_hash'
                      ? 'service'
                      : groupBy}
                </div>
              ))}
            </div>
          </div>
        );
      })}
      {formulas.length > 0 && (
        <>
          <div className="pt-2 text-sm font-bold text-text-secondary">
            Formulas
          </div>{' '}
          {formulas.map((formula) => {
            return (
              <div key={formula.queryKey} className="flex flex-row">
                <div className="mr-2 text-text-secondary">Expression:</div>
                <div className="rounded-md border px-2 py-0.5">
                  <pre className="code-preview">{formula.expression}</pre>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AlertsDetailsPropertiesTraces;
