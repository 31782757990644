export type SLOCountValueThreshold = {
  count?: string;
  operator?: string;
  threshold: number;
};

export enum SLOType {
  AVAILABILITY = 'availability',
  LATENCY = 'latency',
}

export type SLOQueryTemplates = {
  sliBadEventsPromQuery: string;
  sliTotalEventsPromQuery: string;
  sliBadEventsPromRecQuery: string;
  sliTotalEventsPromRecQuery: string;
  sloBudgetRemaining: string;
  sloCurrentBurnRate: string;
  sloStatusQuery: string;
  sloAggregate: string;
  sloAggregateBy: string;
};
