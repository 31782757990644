import { ConfirmationModal, Stepper, useModalsContext } from 'components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { DashboardPanelType } from 'types/Dashboard';

import {
  AlertsCreateContacts,
  AlertsCreateDetails,
  CreateRuleButton,
} from '../components';
import {
  AlertsCreateCondition,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import AlertsCreateEventsChart from './AlertsCreateEventsChart';
import { useAlertsCreate } from '../hooks';
import { EventsQuerySearchAnalytics } from 'screens/Events';
import useAlertsCreateEvents from './useAlertsCreateEvents';
import { RuleProps } from '../types';
import { confirmSelectedLogsAlertQueryKey } from '../AlertsCreateLogs/utils';
import { getQueryAndFormulaKeysLabel } from '../utils';
import { parseAlertsQueryAndConditions } from '../AlertsCreateMetrics/utils';

const AlertsCreateEvents = ({
  baseWidth,
  alertsCreateState,
  conditionState,
}: {
  baseWidth: number;
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const modal = useModalsContext();
  const location = useLocation();
  const rule = useMemo(() => location.state as RuleProps, [location.state]);

  const { date, setUpdateAlertsRuleState } = alertsCreateState;
  const { condition, setUpdateConditionState } = conditionState;
  const alertsCreateEventsState = useAlertsCreateEvents({
    alertsCreateState,
    rule,
  });

  const { eventsState, createEventsAlertThreshold, queries, searches } =
    alertsCreateEventsState;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (rule) {
      const parsed = parseAlertsQueryAndConditions({
        data: rule.ruleData,
        annotations: rule.annotations,
      });

      if (rule.noData && parsed.condition) {
        parsed.condition.noData = rule.noData;
      }

      if (rule.executionError && parsed.condition) {
        parsed.condition.executionError = rule.executionError;
      }

      setUpdateAlertsRuleState(rule);
      setUpdateConditionState(parsed.condition);
    }
  }, [rule]);

  const queryAndFormulaKeysLabel = useMemo(
    () => getQueryAndFormulaKeysLabel(queries, []),
    [queries],
  );

  const onConfirmEventsAlertCreate = () => {
    const { queryKey } = condition;
    const confirmSelected = confirmSelectedLogsAlertQueryKey(
      0,
      queries.length,
      queryKey,
    );
    if (confirmSelected) {
      modal.push(
        <ConfirmationModal
          description={confirmSelected}
          onCancel={() => modal.pop()}
          onConfirm={() => {
            createEventsAlertThreshold(condition);
            modal.pop();
          }}
          submitText="Confirm and Create"
          title="Confirm Query Selection"
        />,
      );
    } else {
      createEventsAlertThreshold(condition);
    }
  };

  useEffect(() => {
    const isQueryKey = queries.some(
      (q) => `Query (${q.queryKey})` === condition.queryKey,
    );
    if (!isQueryKey) {
      setUpdateConditionState({
        ...condition,
        queryKey: `Query (${queries[0].queryKey})`,
      });
    }
  }, []);

  return (
    <div className="alerts__create__logs">
      <div className="alerts__create__logs__chart">
        <AlertsCreateEventsChart
          condition={condition}
          date={date}
          queries={searches}
        />
      </div>
      <Stepper
        steps={[
          {
            title: 'Pick Events metrics',
            component: (
              <EventsQuerySearchAnalytics
                searches={searches}
                eventsState={eventsState}
                tab={DashboardPanelType.TIMESERIES}
                dontUsePageFilterState={true}
              />
            ),
          },
          {
            title: 'Set Condition',
            component: (
              <AlertsCreateCondition
                conditionState={conditionState}
                queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
                alertsCreateState={alertsCreateState}
              />
            ),
          },
          {
            title: 'Add Details',
            component: (
              <AlertsCreateDetails alertsCreateState={alertsCreateState} />
            ),
          },

          {
            title: 'Add Contacts',
            component: (
              <AlertsCreateContacts alertsCreateState={alertsCreateState} />
            ),
          },
        ]}
      />
      <CreateRuleButton
        isEditing={alertsCreateState.isEditing}
        onClick={onConfirmEventsAlertCreate}
      />
    </div>
  );
};

export default AlertsCreateEvents;
