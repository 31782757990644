import { getLegacyFiltersFromFiltersState, SearchState } from 'hooks';
import { DashboardPanelType, DateSelection } from 'types';
import {
  DataFrameMeta,
  DataTransformerConfig,
  getRollupByVisualization,
  onPromiseError,
} from 'utils';
import rumTimeSeries from './rumTimeSeries';
import { getMappedGroupByLabels } from 'screens/Events/utils';
import rumDataTransformer from 'utils/DataTransformer/rumDataTransformer';
import { legendCustomColorTimeseries } from 'utils';
type Args = {
  dataFormat?: DashboardPanelType;
  date: DateSelection;
  instant?: boolean;
  queries: SearchState[];
  transformer: DataTransformerConfig[];
  eventType: string;
};

const fetchRumTimeseriesData = async ({
  dataFormat,
  date,
  queries,
  eventType,
}: Args) => {
  const datasetsQueries = await Promise.all(
    queries.map(async (query) => {
      const searchBarState = query.searchBarState;
      const isInstant = dataFormat === 'timeseries';
      const transformer = rumDataTransformer(!isInstant);
      const { colorMap } = queries[0];

      transformer.push({
        id: 'legendCustomColorTimeseries',
        func: (rangeData: { data: QueryDataProps['query']['range'] }) =>
          legendCustomColorTimeseries(rangeData.data, colorMap),
      });
      if (!searchBarState) return undefined;

      const { groupBys, limitToValue, rollUpInSeconds } = query;
      const metricName =
        query.operation === 'distinctcount' ? 'count' : query.operation;

      const meta: DataFrameMeta = {
        aggregate: metricName,
        refId: query.queryKey,
        step: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        type: dataFormat,
        metricName: metricName,
        executedDate: date,
        labels: query.advancedGroupBys
          ? query.advancedGroupBys.map((g) => g.by)
          : getMappedGroupByLabels(groupBys),
        unit: 'number',
      };

      const {
        facetRegex,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      } = getLegacyFiltersFromFiltersState(query.searchBarState.filters);
      const { applicationFilter, idSearch } = query.searchBarState;

      const data = await rumTimeSeries({
        advancedGroupBys: query.advancedGroupBys,
        applicationFilter,
        date,
        facetRegex,
        idSearch,
        rollupSeconds: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        aggregateField: query.aggregateField,
        aggregateFunction: query.aggregateFunction,
        groupByLimit: limitToValue,
        transformer: transformer as DataTransformerConfig[],
        meta,
        selectedFacetValuesByName,
        selectedFacetRangeByName,
        eventType,
        staticFilters: query?.staticFilters,
      }).catch(onPromiseError);
      return data;
    }),
  );
  const datasets = [...datasetsQueries];
  return datasets;
};
export default fetchRumTimeseriesData;
