import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { getUrlParamByKey } from 'utils';
import { useQuerySchedulerEffect } from '../hooks';
import LogsSheet from '../LogsSheet';
import { tableColumns } from '../constants';
import { LogsTableProps } from '../types';

const LogsTableLogs = ({
  clearHoveredLogDateUnix,
  columnsState,
  customColumnsState,
  disableOnlySaveQueryInToolBar,
  disableOnlyAddToDashboardInToolBar,
  getLogStackedBarCountsUsingMetricsRequest,
  getLogsStackedBarChartUsingMetricsRequestWithKfuseql,
  getWorkbooksRequest,
  isAdvancedFuseQlSearch,
  freeText,
  logsTable,
  logsState,
  logsWorkbooksState,
  queryScheduler,
  selectedLog,
  selectedLogFromContext,
  setHoveredLogDateUnix,
  setSelectedLog,
  bindKeyHandlersRef,
  tableOptions,
  tabFetchedRef,
}: LogsTableProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const logEventIndex = getUrlParamByKey('logEventIndex');
  const {
    appendLogs,
    cursor,
    context,
    generateNewId,
    id,
    isAppending,
    isLoading,
    logs,
    sort,
    sortByColumn,
  } = logsTable;

  const {
    clearSelectedLogFromContext,
    date,
    filterByFacets,
    filterOrExcludeByFingerprint,
    keyExists,
    selectedFacetValues,
    searchTerms,
  } = logsState;

  const fetchLogs = () => {
    getLogStackedBarCountsUsingMetricsRequest.close();
    getLogsStackedBarChartUsingMetricsRequestWithKfuseql.close();
    generateNewId();
    return logsTable.fetchLogs({ logsState, freeText });
  };

  const onScrollEnd = () => {
    appendLogs(logsState, freeText);
  };

  useQuerySchedulerEffect({
    cb: fetchLogs,
    isAdvancedFuseQlSearch,
    logsState,
    queryScheduler,
    sort,
    tab: 'logs',
    tabFetchedRef,
  });

  const columns = useMemo(
    () =>
      tableColumns({
        containerRef,
        columnsState,
        customColumnsState,
        logsState,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsState.state, customColumnsState.state],
  );

  const onEnter = ({ selection }) => {
    const { initY, startY, endY } = selection;
    const y = initY > startY ? startY : endY;
    const index = Math.max(y, 0);
    setSelectedLog({ index, log: logs[index] });
  };

  useEffect(() => {
    const logEventIndexNum = parseInt(logEventIndex);
    if (
      typeof logEventIndexNum === 'number' &&
      logs.length > 0 &&
      logs[logEventIndexNum]
    ) {
      setSelectedLog({ index: logEventIndexNum, log: logs[logEventIndexNum] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs, logEventIndex]);

  return (
    <LogsSheet
      activeRowIndex={context.index}
      bindKeyHandlersRef={bindKeyHandlersRef}
      columns={columns}
      columnsState={columnsState}
      disableOnlySaveQueryInToolBar={disableOnlySaveQueryInToolBar}
      disableOnlyAddToDashboardInToolBar={disableOnlyAddToDashboardInToolBar}
      customColumnsState={customColumnsState}
      date={date}
      getWorkbooksRequest={getWorkbooksRequest}
      key={id}
      isLoading={isLoading || isAppending}
      logs={logs}
      logsState={logsState}
      onEnter={onEnter}
      onScrollEnd={onScrollEnd}
      ref={containerRef}
      setSelectedLog={setSelectedLog}
      sort={sort}
      sortByColumn={sortByColumn}
      tableOptions={tableOptions}
    />
  );
};

export default LogsTableLogs;
