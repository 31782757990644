import { MutableRefObject } from 'react';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardTemplateValueProps,
  DateSelection,
} from 'types';
import {
  DataFrame,
  DataTransformerConfig,
  rumDataTransformer,
  TableTransformerData,
  topListTransformer,
  pieChartTransformer,
  treeMapTransformer,
  geomapTransformer,
} from 'utils/DataTransformer';
import { getReplacedTimeFromWithDate } from './common-utils';

import {
  mergeSeriesData,
  organizeColumn,
  overrideColumnsData,
} from 'utils/DataTransformer/tableTransformer';
import { RumAnalyticsFetchMultiProps } from 'screens/Rum/requests';
import { DashboardPanelOverrideProps } from '../types';

export const getRumWithMetaForDashboard = ({
  date,
  panel,
  templateValues,
  userActionRef,
  width,
}: {
  date: DateSelection;
  panel: DashboardPanelProps;
  templateValues: DashboardTemplateValueProps;
  userActionRef: MutableRefObject<{ defaultTimeChanged: boolean }>;
  width: number;
}): {
  rumQuery: RumAnalyticsFetchMultiProps;
  mainTransformer: DataTransformerConfig[];
  isRange: boolean;
} => {
  const { expr } = panel.targets[0];
  const parsedExpr = JSON.parse(expr || '{}');
  if (!parsedExpr) {
    return { rumQuery: undefined, mainTransformer: [], isRange: false };
  }

  const panelDate = getReplacedTimeFromWithDate({
    date,
    panel,
    templateValues,
  });
  const {
    applicationFilter,
    queries,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    unit,
  } = parsedExpr;
  const query = queries ? queries[0] : parsedExpr.query;
  const panelUnit = unit || 'number';
  const isRange = panel.type === DashboardPanelType.TIMESERIES;
  const targetTransformer = rumDataTransformer(!isRange);

  if (
    panel.type === DashboardPanelType.PIECHART ||
    panel.type === DashboardPanelType.TOP_LIST ||
    panel.type === DashboardPanelType.TABLE ||
    panel.type === DashboardPanelType.TREEMAP ||
    panel.type === DashboardPanelType.GEOMAP
  ) {
    const renamedValues: Record<string, string> = {};
    const overrides: DashboardPanelOverrideProps[] = [];

    const valueWithRef = `Value #${query.queryKey}`;
    targetTransformer.push({
      id: 'mergeSeriesData',
      func: (dataFrames: DataFrame) => {
        renamedValues[valueWithRef] = dataFrames.meta?.aggregate;
        return mergeSeriesData({ dataFrames: [dataFrames], columns: [] });
      },
    });

    targetTransformer.push({
      id: 'organize',
      func: (data: TableTransformerData) =>
        organizeColumn({
          data,
          options: {
            excludeByName: {
              Time: true,
              [valueWithRef]: panel.type === DashboardPanelType.TABLE,
            },
            renameByName: renamedValues,
          },
          byField: '',
        }),
    });

    if (overrides.length > 0) {
      targetTransformer.push({
        id: 'override',
        func: (data: TableTransformerData) =>
          overrideColumnsData({ data, overrides }),
      });
    }

    if (panel.type === DashboardPanelType.TOP_LIST) {
      targetTransformer.push({
        id: 'toTopList',
        func: (data: TableTransformerData) =>
          topListTransformer({
            data,
            options: { unit: panelUnit, decimals: 2 },
          }),
      });
    }

    if (panel.type === DashboardPanelType.PIECHART) {
      targetTransformer.push({
        id: 'toPieChart',
        func: (data: TableTransformerData) => {
          const piechart = pieChartTransformer(data);
          return { data: piechart.pieChartData };
        },
      });
    }

    if (panel.type === DashboardPanelType.TREEMAP) {
      targetTransformer.push({
        id: 'toTreeMap',
        func: (data: TableTransformerData) => {
          const treeMap = treeMapTransformer({
            data,
            options: { unit: panelUnit, decimals: 2 },
          });
          return { data: treeMap.treeChartData };
        },
      });
    }

    if (panel.type === DashboardPanelType.GEOMAP) {
      targetTransformer.push({
        id: 'geomap',
        func: (data: TableTransformerData) =>
          geomapTransformer({
            data,
            options: { unit: panelUnit, decimals: 2 },
          }),
      });
    }
  }

  const rumQuery = {
    date: panelDate,
    applicationFilter,
    dataFormat: panel.type,
    eventType: parsedExpr.eventType,
    facetRegex: parsedExpr.facetRegex,
    idSearch: parsedExpr.idSearch,
    query,
    instant: !isRange,
    transformer: targetTransformer,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
  };

  return { rumQuery, mainTransformer: [], isRange };
};
