import React from 'react';
import classnames from 'classnames';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import RumLargestContentfulPaint from './RumMonitorPerformanceMetrics/RumLargestContentfulPaint';
import RumCumulativeLayoutShift from './RumMonitorPerformanceMetrics/RumCumulativeLayoutShift';
import RumInteractionToNextPaint from './RumMonitorPerformanceMetrics/RumInteractionToNextPaint';
import RumPerformanceOverviewTopPages from './RumCoreWebVitals/RumPerformanceOverviewTopPages';
import RUMUserSessionByVersion from './RUMMobileVitals/RUMUserSessionByVersion';
import RUMMemoryByVersion from './RUMMobileVitals/RUMMemoryByVersion';
import RUMCPUTicksByVersion from './RUMMobileVitals/RUMCPUTicksByVersion';
import RUMFrozenFrames from './RUMMobileVitals/RUMFrozenFrames';
import RUMSlowRenders from './RUMMobileVitals/RUMSlowRenders';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RumPerformanceGrid = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const { applicationInfo } = rumState;
  return (
    <div>
      <div className={classnames('rum')}>
        <h1 className="rum__performance-subtitle">
          Monitor Performance Metrics
        </h1>
        {applicationInfo?.type === 'web' && (
          <div>
            <div className="rum__performance-grid">
              <RumLargestContentfulPaint rumPageState={rumPageState} />
              <RumCumulativeLayoutShift rumPageState={rumPageState} />
              <RumInteractionToNextPaint rumPageState={rumPageState} />
            </div>
            <h2 className="rum__subtitle">Performance Overview of Top Pages</h2>
            <div>
              <RumPerformanceOverviewTopPages rumState={rumState} />
            </div>
          </div>
        )}

        {applicationInfo?.type !== 'web' && (
          <div>
            <div className="rum-performance-top">
              <div className="rum-performance__errors-item rum-performance__errors-item--top flex-1">
                <RUMUserSessionByVersion rumPageState={rumPageState} />
              </div>
              <div className="rum-performance__errors-item rum-performance__errors-item--top flex-1">
                <RUMMemoryByVersion rumPageState={rumPageState} />
              </div>
              <div className="rum-performance__errors-item rum-performance__errors-item--top flex-1">
                <RUMCPUTicksByVersion rumPageState={rumPageState} />
              </div>
            </div>

            <div className="rum-performance-bottom">
              <div className="rum-performance__errors-item rum-performance__errors-item--bottom">
                <RUMFrozenFrames rumPageState={rumPageState} />
              </div>
              <div className="rum-performance__errors-item rum-performance__errors-item--bottom">
                <RUMSlowRenders rumPageState={rumPageState} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RumPerformanceGrid;
