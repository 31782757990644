import { useRequest, SearchState } from 'hooks';
import React, { ReactElement } from 'react';
import {
  DashboardPanelType,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';

import { rumDataTransformer } from 'utils';
import { ConditionProps } from '../AlertsCreateCondition';
import { rumAnalyticsFetchMulti, RumEventType } from 'screens/Rum';
import { AlertsChart } from '../AlertsChart';

const AlertsCreateRumChart = ({
  applicationFilter,
  condition,
  date,
  eventType,
  facetRegex,
  idSearch,
  queries,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  isEditing = false,
}: {
  applicationFilter: string;
  condition: ConditionProps;
  date: DateSelection;
  eventType: RumEventType;
  facetRegex: FacetRegexTerm[];
  idSearch: string;
  queries: SearchState[];
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  isEditing?: boolean;
}): ReactElement => {
  const rumAnalyticsFetchMultiRequest = useRequest(rumAnalyticsFetchMulti);

  const loadRumTimeseries = () => {
    if (!applicationFilter) return;
    const baseTransformers = rumDataTransformer();

    const query = queries[0];

    rumAnalyticsFetchMultiRequest.call({
      applicationFilter,
      date,
      eventType,
      dataFormat: DashboardPanelType.TIMESERIES,
      formulas: [],
      instant: false,
      query,
      transformer: baseTransformers,
      selectedFacetValuesByName,
      facetRegex,
      selectedFacetRangeByName,
      idSearch,
    });
  };

  useDebouncedEffect(
    () => {
      loadRumTimeseries();
    },
    { timeout: 200, ignoreInitialCall: false },
    [
      applicationFilter,
      date,
      eventType,
      idSearch,
      queries,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={condition.value ? Number(condition.value) : undefined}
      date={date}
      isChartCompact={false}
      enableEvaluationLine={true}
      isLoading={rumAnalyticsFetchMultiRequest.isLoading}
      queryData={rumAnalyticsFetchMultiRequest.result}
      unit={undefined}
    />
  );
};

export default AlertsCreateRumChart;
