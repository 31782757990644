import dayjs from 'dayjs';
import { SearchState } from 'hooks';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  TimeSeries,
} from 'types';
import { onPromiseError, DataTransformerConfig, DataFrameMeta } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';

type Args = {
  applicationFilter: string;
  advancedGroupBys?: SearchState['advancedGroupBys'];
  date: DateSelection;
  facetName?: string;
  facetRegex?: FacetRegexTerm[];
  filterByFacets?: { [key: string]: boolean };
  idSearch?: string;
  searchTerms?: string[];
  selectedFacetRangeByName?: SelectedFacetRangeByName;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  eventType: string;
  rollupSeconds: number;
  transformer?: DataTransformerConfig[];
  meta?: DataFrameMeta;
};

const rumTopListCrashRate = async ({
  applicationFilter,
  advancedGroupBys = [],
  date,
  facetRegex,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  eventType,
  rollupSeconds,
  transformer,
  meta,
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const filterOptions = {
    applicationFilter,
    facetRegex,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
  };

  if (meta) {
    meta.executedDate = date;
  }

  return queryRumService<any, 'aggregateTable'>(`
  query {
    crashSeries(
      eventType: ${eventType},
      timestamp: "${endTime.format()}",
      durationSecs: ${durationSecs},
      rollupSeconds: ${rollupSeconds},
      filter: ${buildRumFilter(filterOptions)},
      groupBy: ${
        advancedGroupBys.length > 0 && advancedGroupBys[0].by !== '*'
          ? `[${advancedGroupBys.map(
              (groupBy) =>
                `{
                 field: "${groupBy.by}",
                 limit: ${groupBy.limitToValue},
                 sort: {
                   order: "${groupBy.limitTo}", 
                   aggregation: "${groupBy.sortAggregation ? groupBy.sortAggregation : 'count'}",
                   field: "${groupBy.sortField ? groupBy.sortField : '*'}" 
                 }
               }`,
            )}
         ]`
          : '[]'
      }
    ) {
      errorPercent
      dimensions
      points {
        ts
        value
      }
    }
  }
 `).then((data) => {
    // If 'meta' exists, transform 'data' for timeseries chart display
    if (meta) {
      const initialData = { datasets: data.crashSeries, meta };
      const transformed = transformer.reduce(
        (prevData, item) => item.func(prevData),
        initialData,
      );
      return transformed as unknown;
    }

    // Otherwise, return 'data' for top list display
    return data;
  }, onPromiseError);
};

export default rumTopListCrashRate;
