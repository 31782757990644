import { useSelectedFacetValuesByNameState } from 'hooks';
import React, { ReactElement } from 'react';
import { LogsSavedViewsFacet } from './utils';
import LogsSavedViewsSidebarFacetGroup from './LogsSavedViewsSidebarFacetGroup';
import { ValueCount } from 'types/generated';

const LogsSavedViewsSidebar = ({
  filterProperties,
  request,
  selectedFacetValuesByNameState,
}: {
  filterProperties: { [key: string]: ValueCount[] };
  request: any;

  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
}): ReactElement => {
  return (
    <div className="events__sidebar">
      <LogsSavedViewsSidebarFacetGroup
        filterProperties={filterProperties}
        forceExpanded
        facetNames={LogsSavedViewsFacet}
        selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        request={request}
      />
    </div>
  );
};

export default LogsSavedViewsSidebar;
