import {
  ChartLegendTableColumn,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { Filter, useSearch } from 'hooks';
import React, { useMemo } from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ChartGridV2 } from 'components';
import { formatLatencyYAxisTick } from 'utils';
import { RumEventType } from '../types';
import multipleQueryRangeWithLabelsForRUMFrozenFrames from 'utils/chartGrid/multipleQueryRangeWithLabelsForRUMFrozenFrames';

type GetRowArgs = {
  applicationFilter: string;
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  setDate: (date: DateSelection) => void;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  filters: Filter[];
  search: ReturnType<typeof useSearch>;
  eventType: RumEventType;
};

export const getRows = ({
  applicationFilter,
  date,
  facetRegex,
  setDate,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  filters,
  search,
  eventType,
}: GetRowArgs) => {
  const onSelection = (newDate: DateSelection) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };

  const rumQueries = () => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      advancedGroupBys: [
        {
          by: 'view.name',
          limitTo: 'top',
          limitToValue: 5,
        },
      ],
      groupBys: ['view.name'],
    },
    queryType: 'rum-frozen-frames',
    eventType,
  });

  return [
    [
      {
        charts: [
          {
            key: 'frozenFrames',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Frozen Frames',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries()],
            query: multipleQueryRangeWithLabelsForRUMFrozenFrames(
              [
                () => ({
                  applicationFilter,
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  groupByField: 'view.name',
                }),
              ],
              [['frozenFrames']],
            ),
            unit: '',
            eventType,
            enableCreateAlert: true,
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RUMFrozenFrames = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    dateState,
    filtersState,
  } = rumState;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const [date, setDate] = dateState;
  const search = useSearch();
  const rows = useMemo(
    () =>
      getRows({
        applicationFilter,
        date,
        facetRegex: facetRegexState?.state,
        setDate,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        filters: filtersState.state,
        search,
        eventType: RumEventType.VIEW,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicationFilter,
      date,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <div className="rum__main overflow-auto">
      <div className="rum__performance-charts">
        <ChartGridV2.ChartGrid date={date} rows={rows} />
      </div>
    </div>
  );
};

export default RUMFrozenFrames;
