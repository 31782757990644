import { AutocompleteV2, ErrorMessage } from 'components';
import classNames from 'classnames';
import { useRequest } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import useRumState, { ApplicationsInfoHash } from './hooks/useRumState';
import getApplicationFilter from './requests/getApplicationFilter';
import { rumErrors } from 'utils/error/rumErrors';

const RumApplicationFilter = ({
  rumState,
  hideHeader = false,
}: {
  rumState: ReturnType<typeof useRumState>;
  hideHeader?: boolean;
}) => {
  const [error, setError] = useState({
    getApplicationFilter: null,
  });
  const { applicationFilter, setApplicationFilter } = rumState;
  const applicationFilterRequest = useRequest(getApplicationFilter, true, true);

  useDebouncedEffect(
    () => {
      applicationFilterRequest
        .call()
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({
              ...prevError,
              getApplicationFilter: null,
            }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getApplicationFilter: { message: rumErrors.getApplicationFilter },
          }));
        });
    },
    {
      ignoreInitialCall: false,
      timeout: 100,
    },
    [],
  );

  const applicationFilterOptions = useMemo(() => {
    return applicationFilterRequest.result?.map((application) => ({
      label: application.name,
      value: application.id,
    }));
  }, [applicationFilterRequest.result]);

  useEffect(() => {
    if (
      applicationFilterRequest.result &&
      applicationFilterRequest.result.length
    ) {
      const applicationsInfoHash = applicationFilterRequest.result.reduce(
        (acc: ApplicationsInfoHash, application) => {
          acc[application.id as string] = {
            name: application.name,
            id: application.id,
            type: application.type,
          };
          return acc;
        },
        {},
      );
      rumState.setApplicationsInfoHash(applicationsInfoHash);
      if (!applicationFilter) {
        rumState.setApplicationFilter(applicationFilterRequest.result[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationFilterRequest.result]);

  return (
    <div
      className={classNames({
        'rum__application-filter ml-2.5 h-auto flex-col items-stretch': true,
        'mb-2.5': !hideHeader,
      })}
    >
      {!hideHeader && (
        <div className="button-group__item button-group__item--label min-h-6	">
          {error?.getApplicationFilter ? (
            <ErrorMessage
              message={error?.getApplicationFilter?.message}
              className="justify-end pr-[14px]"
            />
          ) : (
            <>Application Filter</>
          )}
        </div>
      )}
      <AutocompleteV2
        className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value ms-2"
        isLoading={applicationFilterRequest.isLoading}
        onChange={(value) => {
          setApplicationFilter(value as string);
        }}
        options={applicationFilterOptions}
        value={applicationFilter}
      />
    </div>
  );
};

export default RumApplicationFilter;
