import { Button, TooltipTrigger, useToaster } from 'components';
import React, { ReactElement } from 'react';
import { MdNotificationAdd } from 'react-icons/md';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport } from 'react-icons/bi';
import classNames from 'classnames';

const disabledExportTooltip =
  'Export to dashboard is not available for anomaly, forecast, and outlier when more than one query is present';

const MetricsChartsRightToolbar = ({
  onCreateAlert,
  onViewFullscreen,
  onExportClick,
  queryAndFormulaLength,
  hasAdvanceFunction,
}: {
  onCreateAlert?: () => void;
  onViewFullscreen?: () => void;
  onExportClick?: () => void;
  queryAndFormulaLength?: number;
  hasAdvanceFunction?: boolean;
}): ReactElement => {
  const { addToast } = useToaster();

  const disabledExport = hasAdvanceFunction && queryAndFormulaLength > 1;
  return (
    <div className="new-metrics__chart__right-toolbar">
      {onCreateAlert && (
        <div
          className="new-metrics__chart__right-toolbar__icon"
          onClick={onCreateAlert}
        >
          <TooltipTrigger tooltip="Create Alert">
            <MdNotificationAdd />
          </TooltipTrigger>
        </div>
      )}
      {onExportClick && (
        <div
          className={classNames({
            'new-metrics__chart__right-toolbar__icon': true,
            'opacity-50': disabledExport,
          })}
          onClick={() => {
            if (disabledExport) {
              addToast({ text: disabledExportTooltip, status: 'info' });
              return;
            }
            onExportClick();
          }}
        >
          <TooltipTrigger
            tooltip={disabledExport ? disabledExportTooltip : 'Export'}
          >
            <BiExport />
          </TooltipTrigger>
        </div>
      )}
      {onViewFullscreen && (
        <div
          className="new-metrics__chart__right-toolbar__icon"
          onClick={onViewFullscreen}
        >
          <TooltipTrigger tooltip="View in fullscreen">
            <AiOutlineFullscreen />
          </TooltipTrigger>
        </div>
      )}
    </div>
  );
};

export default MetricsChartsRightToolbar;
