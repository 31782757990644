import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import {
  Modals,
  ModalsContextProvider,
  Popover,
  PopoverContextProvider,
  Toaster,
  PortalProvider,
  PortalContainer,
} from 'components';
import { GlobalFilterProvider, PageStateProvider } from 'context';
import { useFeatureFlag } from 'configcat-react';
import { RefContext, useAuth, useRequest } from 'hooks';
import { version } from 'kfuse-constants';
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { HashRouter, useLocation } from 'react-router-dom';
import { getWorkbooks } from 'requests';
import kfBrowserSdk from 'kf-browser-sdk';
import AppRouter from './AppRouter';
import Header from './Header';
import NoAccessBanner from './Login/NoAccessBanner';

import { initSentry } from 'utils';

type Props = {
  auth: ReturnType<typeof useAuth>;
};

const App = ({ auth }: Props): ReactElement => {
  const appRef = useRef<HTMLDivElement>(null);
  const leftSidebarRef = useRef<HTMLDivElement>(null);
  const bodyDivRef = useRef<HTMLDivElement>(null);
  const { value: datadogrumValue, loading: datadogrumValueLoading } =
    useFeatureFlag('datadogrum', false);
  const { value: datadogrumProxyValue, loading: datadogrumProxyValueLoading } =
    useFeatureFlag('datadogrumproxy', false);
  const {
    value: datadogRumSessionSampleRateValue,
    loading: datadogRumSessionSampleRateLoading,
  } = useFeatureFlag('datadogrumsessionsamplerate', 20);
  const {
    value: datadogRumSessionReplaySampleRateValue,
    loading: datadogRumSessionReplaySampleRateLoading,
  } = useFeatureFlag('datadogrumsessionreplaysamplerate', 20);
  const {
    value: useSelfHostedSentryDsn,
    loading: useSelfHostedSentryDsnLoading,
  } = useFeatureFlag('useSelfHostedSentryDsn', false);
  const {
    value: datadogrumProxyEndpointValue,
    loading: datadogrumProxyEndpointValueLoading,
  } = useFeatureFlag('datadogrumproxyendpoint', '');
  const {
    value: datadogRumApplicationIdValue,
    loading: datadogRumApplicationIdValueLoading,
  } = useFeatureFlag('datadogrumapplicationid', '');
  const {
    value: datadogRumClientTokenValue,
    loading: datadogRumClientTokenValueLoading,
  } = useFeatureFlag('datadogrumclienttoken', '');
  const { value: datadogRumSiteValue, loading: datadogRumSiteValueLoading } =
    useFeatureFlag('datadogrumsite', '');

  const {
    value: ddFrontendProxyEndpointValue,
    loading: ddFrontendProxyEndpointValueLoading,
  } = useFeatureFlag('ddFrontendProxyEndpoint', '');

  const getWorkbooksRequest = useRequest(getWorkbooks, true, true);

  const hostname = window.location.hostname;

  useEffect(() => {
    getWorkbooksRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      datadogRumClientTokenValueLoading ||
      datadogRumSiteValueLoading ||
      ddFrontendProxyEndpointValueLoading ||
      datadogrumValueLoading ||
      datadogrumProxyValueLoading ||
      datadogRumSessionSampleRateLoading ||
      datadogRumSessionReplaySampleRateLoading ||
      datadogrumProxyEndpointValueLoading ||
      datadogRumApplicationIdValueLoading ||
      datadogRumClientTokenValueLoading ||
      datadogRumSiteValueLoading
    ) {
      return;
    }

    if (datadogrumValue) {
      kfBrowserSdk.init({
        config: {
          applicationId: datadogRumApplicationIdValue,
          clientToken: datadogRumClientTokenValue,
          enableLogCollection:
            datadogRumClientTokenValue &&
            datadogRumSiteValue &&
            ddFrontendProxyEndpointValue,
          enableSessionRecording: true,
          env: hostname,
          proxy: datadogrumProxyEndpointValue,
          site: datadogRumSiteValue,
          service: 'kf-frontend',
          sessionSampleRate: datadogRumSessionSampleRateValue,
          trackViewsManually: true,
          version: version,
        },
      });

      kfBrowserSdk.setUser({
        id: auth.user && (auth.user.user || auth.user.email),
        email: auth.user && auth.user.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datadogRumClientTokenValueLoading,
    datadogRumSiteValueLoading,
    ddFrontendProxyEndpointValueLoading,
    datadogrumValueLoading,
    datadogrumProxyValueLoading,
    datadogRumSessionSampleRateLoading,
    datadogRumSessionReplaySampleRateLoading,
    datadogrumProxyEndpointValueLoading,
    datadogRumApplicationIdValueLoading,
    datadogRumClientTokenValueLoading,
    datadogRumSiteValueLoading,
  ]);

  useEffect(() => {
    if (!useSelfHostedSentryDsnLoading) {
      initSentry({
        dsn: useSelfHostedSentryDsn
          ? 'https://62bfbfd50ed8a3e598645f272699c9ac@ui.observe.kloudfuse.io/2'
          : 'https://4b72d6579f354d95a526bd662e7bb68f@o1287559.ingest.sentry.io/6502653',
      });
    }
  }, [useSelfHostedSentryDsn, useSelfHostedSentryDsnLoading]);

  const refContextValue = useMemo(
    () => ({
      appRef,
      leftSidebarRef,
      bodyDivRef,
    }),
    [],
  );

  return (
    <HashRouter>
      <PageStateProvider>
        <PortalProvider>
          <ModalsContextProvider>
            <PopoverContextProvider>
              <RefContext.Provider value={refContextValue}>
                <GlobalFilterProvider>
                  <div
                    className="app flex flex-col font-noto"
                    id="app"
                    ref={appRef}
                  >
                    <Header
                      auth={auth}
                      workbooks={getWorkbooksRequest.result || []}
                    />
                    <div className="body grow" ref={bodyDivRef}>
                      {auth?.user?.scope === 'none' ? (
                        <NoAccessBanner />
                      ) : (
                        <AppRouter
                          auth={auth}
                          getWorkbooksRequest={getWorkbooksRequest}
                        />
                      )}
                    </div>
                    <Modals />
                    <Popover />
                    <Toaster />
                    <PortalContainer />
                  </div>
                </GlobalFilterProvider>
              </RefContext.Provider>
            </PopoverContextProvider>
          </ModalsContextProvider>
        </PortalProvider>
      </PageStateProvider>
    </HashRouter>
  );
};

export default App;
