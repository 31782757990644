import { AnalyticsChart } from 'components';
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from 'screens/Traces/utils';
import { DashboardPanelType, LogsState } from 'types';

import { useQueryScheduler, useQuerySchedulerEffect } from '../hooks';
import { useLogsAnalyticsChart } from './hooks';
import {
  getAgileRobustAnomalyInfoMessage,
  hasQueryAdvanceFunctionCheckForAlert,
} from 'utils/MetricsLogsQueryBuilder';
import { useNavigate, useSearchParams } from 'react-router-dom';

const LogsAnalytics = ({
  isAdvancedFuseQlSearch,
  isAnalyticsViewEnabledForAdvancedFuseQlSearch,
  isTimeseriesTabEnabledForAdvancedFuseQlSearch,
  queryScheduler,
  logsState,
  tabFetchedRef,
  logsAnalytics,
}: {
  isAdvancedFuseQlSearch?: boolean;
  isAnalyticsViewEnabledForAdvancedFuseQlSearch?: boolean;
  isTimeseriesTabEnabledForAdvancedFuseQlSearch?: boolean;
  logsState: LogsState;
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  tabFetchedRef: MutableRefObject<Record<string, number>>;
  logsAnalytics: ReturnType<typeof useLogsAnalyticsChart>;
}): ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    analyticsChart,
    chartFormulas,
    chartQueries,
    groupBysForTable,
    logsMetricsQueryState,
    logsAnalyticsOverlayMessage,
    onClickCreateAlertLogs,
    openExportToDashboardModal,
    onViewFullscreen,
  } = logsAnalytics;
  const { formulas, logsChartData, queries, queryLangType } =
    logsMetricsQueryState;
  const { activeVisualization } = analyticsChart;
  const refQueries = useRef([]);

  useEffect(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        activeVisualization as unknown as DashboardPanelType,
      )
    ) {
      const countOfActiveFormulas = formulas?.filter(
        (formula) => formula.isActive,
      )?.length;
      if (countOfActiveFormulas > 0) {
        logsMetricsQueryState.activateOnlySingleFormula(0);
        logsMetricsQueryState.deactivateAllQueries();
        return;
      }

      const countOfActiveQueries = queries.filter((query) => query.isActive);
      if (countOfActiveQueries.length > 1) {
        logsMetricsQueryState.activateOnlySingleQiery(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVisualization]);

  useEffect(() => {
    refQueries.current = queries;
    const activeFormulas = formulas.filter((formula) => formula.isActive);
    const activeQueries = queries.filter((query) => query.isActive);
    const countOfActiveQueriesOrFormulas =
      activeFormulas?.length + activeQueries?.length;

    if (countOfActiveQueriesOrFormulas < 1) {
      // logsMetricsQueryState.activateOnlySingleQiery(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries, formulas]);

  useQuerySchedulerEffect({
    cb: () => {
      return new Promise((resolve) => {
        resolve();
      });
    },
    logsState,
    queryScheduler,
    sort: undefined,
    tab: 'analytics',
    tabFetchedRef,
  });

  const disableAlerting = useMemo(() => {
    if (isAdvancedFuseQlSearch) {
      return true;
    }
    return hasQueryAdvanceFunctionCheckForAlert(queries, queryLangType);
  }, [isAdvancedFuseQlSearch, queries, queryLangType]);

  useEffect(() => {
    if (
      isAdvancedFuseQlSearch &&
      !isAnalyticsViewEnabledForAdvancedFuseQlSearch
    ) {
      navigate(`/logs?${searchParams.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isAdvancedFuseQlSearch &&
      activeVisualization === DashboardPanelType.TIMESERIES &&
      !isTimeseriesTabEnabledForAdvancedFuseQlSearch
    ) {
      navigate(`/logs?${searchParams.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVisualization]);

  return (
    <div className="logs__analytics">
      <AnalyticsChart
        analyticsChart={analyticsChart}
        chartData={logsChartData}
        chartQueries={chartQueries}
        chartFormulas={chartFormulas}
        defaultActiveChart="Stacked Bar"
        groupBy={groupBysForTable}
        infoMessage={getAgileRobustAnomalyInfoMessage({ queries })}
        overlayMessage={logsAnalyticsOverlayMessage}
        rightToolbar={{
          enableExportForInstantQueries: isAdvancedFuseQlSearch ? false : true,
          onExportClick: isAdvancedFuseQlSearch
            ? null
            : openExportToDashboardModal,
          onViewFullscreen,
          onCreateAlert: disableAlerting ? undefined : onClickCreateAlertLogs,
        }}
        setDate={logsState.setDate}
        settingOptions={{
          legendType: 'values',
          toolbarChartShowType: 'dropdown',
        }}
      />
    </div>
  );
};

export default LogsAnalytics;
