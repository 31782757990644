import { useThemeContext } from 'components';
import React, { ReactElement, useRef, useCallback } from 'react';
import MonacoEditor, { EditorProps } from '@monaco-editor/react';
import { promLanguageDefinition } from 'monaco-promql';
import { editor } from 'monaco-editor';

interface CodeEditorProps {
  completionTokens?: {
    operators: string[];
    misc_operators: string[];
    keywords: string[];
    aggregators: string[];
    percentiles: string[];
    functions: string[];
    math_functions: string[];
    advanced_functions: string[];
  };
  customOptions?: EditorProps['options'];
  value: string;
  onChange: (value: string) => void;
}

const options: EditorProps['options'] = {
  codeLens: false,
  contextmenu: false,
  fixedOverflowWidgets: true,
  folding: false,
  fontSize: 14,
  lineDecorationsWidth: 8, // used as "padding-left "
  lineNumbers: 'off',
  minimap: { enabled: false },
  overviewRulerBorder: false,
  overviewRulerLanes: 0,
  padding: { top: 4, bottom: 5 },
  renderLineHighlight: 'none',
  scrollbar: {
    vertical: 'hidden',
    verticalScrollbarSize: 8, // used as "padding-right"
    horizontal: 'hidden',
    horizontalScrollbarSize: 0,
  },
  scrollBeyondLastLine: false,
  //   suggest: getSuggestOptions(),
  suggestFontSize: 12,
  wordWrap: 'on',
};

const EDITOR_HEIGHT_OFFSET = 2;

const PROMQL_LANG_ID = promLanguageDefinition.id;
// we must only run the promql-setup code once
let PROMQL_SETUP_STARTED = false;

function configureAutocomplete({
  monaco,
  completionTokens,
}: {
  monaco: any;
  completionTokens: CodeEditorProps['completionTokens'];
}) {
  monaco.languages.registerCompletionItemProvider(PROMQL_LANG_ID, {
    provideCompletionItems: () => {
      const suggestions: {
        label: string;
        kind: any;
        insertText: string;
        detail: string;
        insertTextRules?: any;
      }[] = [];

      // Add operators with special kind
      completionTokens.operators.forEach((op) => {
        suggestions.push({
          label: op,
          kind: monaco.languages.CompletionItemKind.Operator,
          insertText: op,
          detail: 'Operator',
        });
      });

      // Add Misc operators
      completionTokens.misc_operators.forEach((op) => {
        suggestions.push({
          label: op,
          kind: monaco.languages.CompletionItemKind.Operator,
          insertText: op,
          detail: 'Misc Operator',
        });
      });

      // Add keywords
      completionTokens.keywords.forEach((keyword) => {
        suggestions.push({
          label: keyword,
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: keyword,
          detail: 'Keyword',
        });
      });

      // Add aggregators
      completionTokens.aggregators.forEach((agg) => {
        suggestions.push({
          label: agg,
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: agg,
          detail: 'Aggregator',
        });
      });

      // Add percentiles
      completionTokens.percentiles.forEach((percentile) => {
        suggestions.push({
          label: percentile,
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: percentile,
          detail: 'Percentile',
        });
      });

      // Add functions with snippets
      completionTokens.functions.forEach((func) => {
        suggestions.push({
          label: func,
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: `${func}($0)`,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Function',
        });
      });

      // Add math functions
      completionTokens.math_functions.forEach((func) => {
        suggestions.push({
          label: func,
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: `${func}($0)`,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Math Function',
        });
      });

      // Add advanced functions
      completionTokens.advanced_functions.forEach((func) => {
        suggestions.push({
          label: func,
          insertText: `${func}($0)`,
          kind: monaco.languages.CompletionItemKind.Function,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Advanced Function',
        });
      });

      return { suggestions };
    },
  });
}

function ensurePromQL({
  completionTokens,
  monaco,
}: {
  completionTokens: CodeEditorProps['completionTokens'];
  monaco: any;
}) {
  if (PROMQL_SETUP_STARTED === false) {
    PROMQL_SETUP_STARTED = true;
    const { aliases, extensions, mimetypes, loader } = promLanguageDefinition;
    monaco.languages.register({
      id: PROMQL_LANG_ID,
      aliases,
      extensions,
      mimetypes,
    });

    loader().then((mod) => {
      monaco.languages.setMonarchTokensProvider(PROMQL_LANG_ID, mod.language);
      monaco.languages.setLanguageConfiguration(
        PROMQL_LANG_ID,
        mod.languageConfiguration,
      );
    });

    if (completionTokens) {
      configureAutocomplete({ completionTokens, monaco });
    }
  }
}

const CodeEditor = ({
  completionTokens,
  customOptions,
  value,
  onChange,
}: CodeEditorProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { darkModeEnabled } = useThemeContext();

  const handleEditorDidMount = useCallback(
    (editor: editor.IStandaloneCodeEditor) => {
      const updateElementHeight = () => {
        const containerDiv = containerRef.current;
        if (containerDiv !== null) {
          const pixelHeight = editor.getContentHeight();
          containerDiv.style.height = `${pixelHeight + EDITOR_HEIGHT_OFFSET}px`;
          containerDiv.style.width = '90%';
          const pixelWidth = containerDiv.clientWidth;
          editor.layout({ width: pixelWidth, height: pixelHeight });
        }
      };

      editor.onDidContentSizeChange(updateElementHeight);
      updateElementHeight();
    },
    [],
  );

  return (
    <div ref={containerRef} className="flex flex-1 grow flex-wrap">
      <MonacoEditor
        language="promql"
        theme={darkModeEnabled ? 'vs-dark' : 'vs-light'}
        value={value}
        onChange={onChange}
        options={{
          ...options,
          ...customOptions,
        }}
        beforeMount={(monaco) =>
          ensurePromQL({
            completionTokens,
            monaco,
          })
        }
        onMount={handleEditorDidMount}
      />
    </div>
  );
};

export default CodeEditor;
