import { AutocompleteOption, useToaster } from 'components';
import { useRequest } from 'hooks';
import { KFUSE_EXCLUDE_FOLDERS } from 'kfuse-constants';
import { useEffect, useState } from 'react';
import {
  getDatasources,
  getGrafanaDashboard,
  getGrafanaDashboardByUid,
  getGrafanaFolders,
  mutateGrafanaDashboard,
} from 'requests';
import {
  DashboardPanelConfigMappingProps,
  DashboardPanelProps,
  DateSelection,
} from 'types';
import { convertTimestampToCode } from 'utils';
import { apmErrors } from 'utils/error/apmErrors';
import {
  dashboardPanelOptions,
  findLargestBottomOfDashboard,
  getNewDashboardJSONModel,
  timeseriesFieldConfig,
} from './utils';

const useDashboardExportState = ({
  annotations,
  closeModal,
  date,
  mappings,
  panel,
  drawStyle = 'line',
  datasourceType = 'loki',
  unit,
}: {
  annotations?: string;
  closeModal: () => void;
  date: DateSelection;
  mappings?: DashboardPanelConfigMappingProps[];
  datasourceType?: string;
  panel: DashboardPanelProps;
  drawStyle?: string;
  unit?: string;
}) => {
  const [error, setError] = useState({
    getGrafanaFolders: null,
    getGrafanaDashboard: null,
    getDatasources: null,
  });
  const { addToast } = useToaster();
  const [dashboardExportType, setDashboardExportType] = useState<string>('new');
  const [existingDashboards, setExistingDashboards] = useState<
    AutocompleteOption[]
  >([]);
  const [selectedDashboard, setSelectedDashboard] = useState<string>('');
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [dashboardDetails, setDashboardDetails] = useState<{
    title: string;
    panelName: string;
  }>({ title: '', panelName: '' });
  const grafanaFoldersRequest = useRequest(
    () =>
      getGrafanaFolders().then((res) =>
        res
          .map((folder) => ({ label: folder.title, value: folder.uid }))
          .filter((folder) => !KFUSE_EXCLUDE_FOLDERS.includes(folder.label)),
      ),
    true,
    true,
  );
  const grafanaDashboardRequest = useRequest(getGrafanaDashboard, true, true);
  const grafanaDatasourceRequest = useRequest(getDatasources, true, true);
  const grafanaDashboardMutateRequest = useRequest(
    mutateGrafanaDashboard,
    true,
    true,
  );

  const grafanaDashboardByUidRequest = useRequest(getGrafanaDashboardByUid);

  const onExportDashboard = async () => {
    const datasource = await grafanaDatasourceRequest
      .call()
      .then((response: any) => {
        if (response) {
          setError((prevError) => ({ ...prevError, getDatasources: null }));
        }

        return response.find(
          (datasource: any) => datasource.type === datasourceType,
        );
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getDatasources: { message: apmErrors.getDatasources },
        }));
      });

    const datasourceUid = datasource?.uid;
    const newPanel = {
      ...panel,
      fieldConfig: timeseriesFieldConfig(drawStyle, unit, mappings),
      datasource: { type: datasourceType, uid: datasourceUid },
      gridPos: { x: 0, y: 0, w: 12, h: 8, i: '0' },
      title: dashboardDetails.panelName,
    };
    if (annotations) {
      newPanel.fieldConfig.defaults.custom.kAnnotations = annotations;
    }

    if (!panel.options) {
      newPanel.options = dashboardPanelOptions;
    }

    if (dashboardExportType === 'new') {
      const timeDiff = convertTimestampToCode(date);
      const jsonModel = getNewDashboardJSONModel({
        dashboardTitle: dashboardDetails.title,
        timeDiff,
      });
      jsonModel.panels.push(newPanel);
      mutateDashboard(jsonModel);
    } else {
      grafanaDashboardByUidRequest
        .call(selectedDashboard)
        .then((response: any) => {
          const dashboard = response.dashboard;
          const panels: DashboardPanelProps[] = dashboard.panels;
          newPanel.gridPos = findLargestBottomOfDashboard(panels);
          if (panels.length > 0) {
            newPanel.id = panels[panels.length - 1].id + 1;
          } else {
            newPanel.id = 1;
          }
          panels.push(newPanel);
          mutateDashboard(dashboard);
        });
    }
  };

  const mutateDashboard = (jsonModel: any) => {
    grafanaDashboardMutateRequest
      .call({
        jsonModel,
        message: 'Exported from Logs Analytics',
        folderUid: selectedFolder,
      })
      .then((res: any) => {
        addToast({
          text: 'Dashboard exported successfully',
          status: 'success',
        });
        closeModal();
      })
      .catch((e: any) => {
        addToast({ text: 'Dashboard export failed', status: 'error' });
      });
  };

  useEffect(() => {
    grafanaDashboardRequest
      .call(`type=dash-db`)
      .then((responseDashboard: any) => {
        const dashboardList: AutocompleteOption[] = [];
        if (responseDashboard) {
          responseDashboard.forEach((dashboard: any) => {
            if (KFUSE_EXCLUDE_FOLDERS.includes(dashboard.folderTitle)) return;
            const dashboardName = dashboard.title;
            dashboardList.push({
              label: dashboard.folderTitle
                ? `${dashboard.folderTitle}/${dashboardName}`
                : `General/${dashboardName}`,
              folderUid: dashboard.folderUid,
              value: dashboard.uid,
            });
          });
          setExistingDashboards(dashboardList);
        }
      })
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({
            ...prevError,
            getGrafanaDashboard: null,
          }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getGrafanaDashboard: { message: apmErrors.getGrafanaDashboard },
        }));
      });
    grafanaFoldersRequest
      .call()
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getGrafanaFolders: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getGrafanaFolders: { message: apmErrors.getGrafanaFolders },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dashboardExportType,
    dashboardDetails,
    existingDashboards,
    grafanaFoldersRequest,
    grafanaDatasourceRequest,
    grafanaDashboardMutateRequest,
    grafanaDashboardByUidRequest,
    onExportDashboard,
    selectedDashboard,
    selectedFolder,
    setDashboardExportType,
    setDashboardDetails,
    setSelectedDashboard,
    setSelectedFolder,
    error,
  };
};

export default useDashboardExportState;
