import { SearchState } from 'hooks/useSearch';
import { DashboardPanelType, DateSelection, LimitTo } from 'types';
import {
  DataTransformerConfig,
  getRollupByVisualization,
  onPromiseError,
} from 'utils';
import rumDataTransformer from 'utils/DataTransformer/rumDataTransformer';
import { getLegacyFiltersFromFiltersState } from 'hooks/useFiltersState';
import { RumEvent, RumEventType } from '../types';
import rumFrozenFrameTimeSeries from './rumFrozenFrameTimeSeries';
import { calculateRateIntervalVariableValue } from 'utils';

type Args = {
  dataFormat?: DashboardPanelType;
  date: DateSelection;
  instant?: boolean;
  queries: SearchState[];
  transformer: DataTransformerConfig[];
  eventType: RumEventType;
};

const fetchRumFrozenFramesData = async ({
  dataFormat,
  date,
  queries,
  eventType,
}: Args): Promise<{ data: RumEvent[]; cursor: string }[]> => {
  const datasetsQueries = await Promise.all(
    queries.map(async (query) => {
      const searchBarState = query.searchBarState;
      const applicationFilter = searchBarState.applicationFilter;
      const isInstant = dataFormat === 'timeseries';
      const baseTransformer = rumDataTransformer(!isInstant);

      if (!searchBarState) return undefined;
      const chartType = 'timeseries';
      const { stepInMs } = calculateRateIntervalVariableValue(date, chartType);
      const { rollUpInSeconds } = query;

      const meta = {
        step: stepInMs / 1000,
        refId: 'a',
        type: DashboardPanelType.TIMESERIES,
      };

      const {
        facetRegex,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      } = getLegacyFiltersFromFiltersState(query.searchBarState.filters);

      const data = await rumFrozenFrameTimeSeries({
        applicationFilter,
        date,
        facetRegex,
        rollupSeconds: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        transformer: baseTransformer as DataTransformerConfig[],
        meta,
        selectedFacetValuesByName: selectedFacetValuesByName,
        selectedFacetRangeByName: selectedFacetRangeByName,
        eventType,
        advancedGroupBys: [
          {
            by: 'view.name',
            limitTo: LimitTo.top,
            limitToValue: 5,
          },
        ],
      }).catch(onPromiseError);
      return data;
    }),
  );
  const datasets = [...datasetsQueries];
  return datasets;
};
export default fetchRumFrozenFramesData;
