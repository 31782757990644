import { Button, ErrorMessage } from 'components';
import classNames from 'classnames';
import { AutocompleteV2, Input, Loader } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { X } from 'react-feather';
import {
  DateSelection,
  DashboardPanelConfigMappingProps,
  DashboardPanelProps,
} from 'types';
import useDashboardExportState from './useDashboardExportState';

const DashboardExport = ({
  annotations,
  closeModal,
  date,
  drawStyle,
  mappings,
  panel,
  title = 'Add panel to dashboard',
  datasourceType,
  unit,
}: {
  annotations?: string;
  closeModal: () => void;
  date: DateSelection;
  drawStyle?: string;
  mappings?: DashboardPanelConfigMappingProps[];
  panel: DashboardPanelProps;
  title?: string;
  datasourceType?: string;
  unit?: string;
}): ReactElement => {
  const logsAnalyticsDashboard = useDashboardExportState({
    annotations,
    closeModal,
    date,
    drawStyle,
    mappings,
    panel,
    datasourceType,
    unit,
  });

  const {
    dashboardExportType,
    dashboardDetails,
    existingDashboards,
    grafanaDatasourceRequest,
    grafanaFoldersRequest,
    grafanaDashboardMutateRequest,
    grafanaDashboardByUidRequest,
    selectedFolder,
    setSelectedFolder,
    onExportDashboard,
    selectedDashboard,
    setDashboardExportType,
    setDashboardDetails,
    setSelectedDashboard,
    error,
  } = logsAnalyticsDashboard;

  const filteredExistingDashboards = useMemo(() => {
    if (!selectedFolder) {
      return existingDashboards.filter((dashboard) => {
        return dashboard.folderUid === undefined;
      });
    }

    return existingDashboards.filter((dashboard) => {
      return dashboard.folderUid === selectedFolder;
    });
  }, [existingDashboards, selectedFolder]);

  const foldersWithGeneral = useMemo(() => {
    const response = [
      { label: 'General', value: '' },
      ...(grafanaFoldersRequest.result || []),
    ];

    return response;
  }, [grafanaFoldersRequest.result]);

  return (
    <div
      className="logs__analytics__export-dashboard"
      data-testid="export-dashboard"
    >
      <div className="logs__analytics__export-dashboard__header">
        <div className="logs__analytics__export-dashboard__header__title">
          {title}
        </div>
        <div
          className="logs__analytics__export-dashboard__header__close"
          onClick={closeModal}
        >
          <X />
        </div>
      </div>

      <Loader
        isLoading={
          grafanaDatasourceRequest.isLoading ||
          grafanaDashboardMutateRequest.isLoading ||
          grafanaDashboardByUidRequest.isLoading
        }
      >
        <div className="logs__analytics__export-dashboard__label">
          Choose where to add the panel
        </div>
        <div className="button-group mb-4">
          <div
            className={classNames({
              'button-group__item': true,
              'button-group__item--active': dashboardExportType === 'new',
            })}
          >
            <button onClick={() => setDashboardExportType('new')}>
              New Dashboard
            </button>
          </div>
          <div
            className={classNames({
              'button-group__item': true,
              'button-group__item--active': dashboardExportType === 'existing',
            })}
          >
            <button onClick={() => setDashboardExportType('existing')}>
              Existing Dashboard
            </button>
          </div>
        </div>
        <div className="pb-4">
          <div>
            <div className="logs__analytics__export-dashboard__label">
              Select the folder
            </div>
          </div>
          <AutocompleteV2
            options={foldersWithGeneral}
            onChange={(val: string) => setSelectedFolder(val)}
            placeholder="General"
            value={selectedFolder}
          />
        </div>
        <div>
          {dashboardExportType === 'new' && (
            <>
              <div className="logs__analytics__export-dashboard__label">
                Enter the name of the new dashboard.
              </div>
              <Input
                onChange={(val) =>
                  setDashboardDetails({ ...dashboardDetails, title: val })
                }
                placeholder="Give dashboard a name"
                type="text"
                value={dashboardDetails.title}
              />
            </>
          )}
        </div>
        <div className="logs__analytics__export-dashboard__select-dashboard">
          {dashboardExportType === 'existing' && (
            <>
              <div className="logs__analytics__export-dashboard__label">
                Select in which dashboard the panel will be created.
              </div>
              <AutocompleteV2
                options={filteredExistingDashboards}
                onChange={(val) => setSelectedDashboard(val)}
                placeholder="Select existing dashboard"
                value={selectedDashboard}
              />
            </>
          )}
        </div>
        <div>
          <div className="logs__analytics__export-dashboard__label">
            Enter the panel name for chart
          </div>
          <Input
            onChange={(val) =>
              setDashboardDetails({ ...dashboardDetails, panelName: val })
            }
            placeholder="Give chart a panel name"
            type="text"
            value={dashboardDetails.panelName}
          />
        </div>

        <div className="logs__analytics__export-dashboard__footer">
          <div className="mt-[24px] flex w-full items-center justify-start gap-[4px]">
            <ErrorMessage
              message={error?.getGrafanaDashboard?.message}
              className="w-fit justify-start"
            />
            <ErrorMessage
              message={error?.getGrafanaFolders?.message}
              className="w-fit justify-start"
            />
            <ErrorMessage
              message={error?.getDatasources?.message}
              className="w-fit justify-start"
            />
          </div>

          <Button variant="outline" size="sm" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="default"
            size="sm"
            className="ml-2"
            onClick={onExportDashboard}
          >
            Export
          </Button>
        </div>
      </Loader>
    </div>
  );
};

export default DashboardExport;
