import {
  ChartLegendTableColumn,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { Filter, useSearch } from 'hooks';
import React, { useMemo, useState } from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ChartGridV2 } from 'components';
import multipleQueryRangeWithLabelsForRUMMobile from 'utils/chartGrid/multipleQueryRangeWithLabelsForRUMMobile';
import { formatLatencyYAxisTick } from 'utils';
import rumMobileInstant from '../requests/rumMobileInstant';
import { RumEventType } from '../types';
import { formatNs } from 'utils/timeNs';
import { TimeUnit } from 'types';

type GetRowArgs = {
  applicationFilter: string;
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  setDate: (date: DateSelection) => void;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  filters: Filter[];
  search: ReturnType<typeof useSearch>;
  updateStartupTime: (newStartupTime: string) => void;
  eventType: RumEventType;
};

export const getRows = ({
  applicationFilter,
  date,
  facetRegex,
  setDate,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  filters,
  search,
  updateStartupTime,
  eventType,
}: GetRowArgs) => {
  const aggregateStartupTime = (
    date: DateSelection,
    aggregateFunction: string,
  ) => {
    return rumMobileInstant({
      eventType,
      applicationFilter,
      endTimeUnix: date.endTimeUnix,
      facetRegex,
      startTimeUnix: date.startTimeUnix,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      aggregateFunction,
      aggregateField: 'action.loading_time',
      staticFilters: [
        {
          attributeFilter: {
            eq: { key: 'action.type', value: 'application_start' },
          },
        },
        { attributeFilter: { eq: { key: 'session.type', value: 'user' } } },
      ],
    }).then((nextResult) => {
      const startupTime = nextResult?.[0]?.aggregates[0] ?? 0;
      const convertedStartupTime = formatNs(startupTime, TimeUnit.SECONDS, 2);
      updateStartupTime(convertedStartupTime);
      return convertedStartupTime;
    });
  };

  const onSelection = (newDate: DateSelection) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };

  const rumQueries = (aggregateField: string, aggregateFunction: string) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
      staticFilters: [
        {
          attributeFilter: {
            eq: { key: 'action.type', value: 'application_start' },
          },
        },
        { attributeFilter: { eq: { key: 'session.type', value: 'user' } } },
      ],
    },
    eventType,
    queryType: 'rum-performance',
  });

  return [
    [
      {
        charts: [
          {
            key: 'appStartupTimeP99',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'App Startup Time P99',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('action.loading_time', 'percentile99')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'action.loading_time',
                  aggregateFunction: 'percentile99',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'action.type', value: 'application_start' },
                      },
                    },
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p99']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateStartupTime(date, 'percentile99');
            },
            enableCreateAlert: true,
          },
          {
            key: 'appStartupTimeP95',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'App Startup Time P95',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('action.loading_time', 'percentile95')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'action.loading_time',
                  aggregateFunction: 'percentile95',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'action.type', value: 'application_start' },
                      },
                    },
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p95']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateStartupTime(date, 'percentile95');
            },
            enableCreateAlert: true,
          },
          {
            key: 'appStartupTimeP90',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'App Startup Time P90',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('action.loading_time', 'percentile90')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'action.loading_time',
                  aggregateFunction: 'percentile90',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'action.type', value: 'application_start' },
                      },
                    },
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p90']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateStartupTime(date, 'percentile90');
            },
            enableCreateAlert: true,
          },
          {
            key: 'appStartupTimeP75',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'App Startup Time P75',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('action.loading_time', 'percentile75')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'action.loading_time',
                  aggregateFunction: 'percentile75',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'action.type', value: 'application_start' },
                      },
                    },
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p75']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateStartupTime(date, 'percentile75');
            },
            enableCreateAlert: true,
          },
          {
            key: 'appStartupTimeP50',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'App Startup Time P50',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('action.loading_time', 'percentile50')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'action.loading_time',
                  aggregateFunction: 'percentile50',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'action.type', value: 'application_start' },
                      },
                    },
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['p50']],
            ),
            unit: 'ns',
            eventType,
            instantQuery: ({ date }: { date: DateSelection }) => {
              return aggregateStartupTime(date, 'percentile50');
            },
            enableCreateAlert: true,
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RUMAppStartupTime = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    dateState,
    filtersState,
  } = rumState;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const [date, setDate] = dateState;
  const search = useSearch();
  const [startupTime, setStartupTime] = useState<string>(0);

  const updateStartupTime = (newStartupTime: string) => {
    setStartupTime(newStartupTime);
  };

  const rows = useMemo(
    () =>
      getRows({
        applicationFilter,
        date,
        facetRegex: facetRegexState?.state,
        setDate,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        filters: filtersState.state,
        search,
        updateStartupTime,
        eventType: RumEventType.ACTION,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicationFilter,
      date,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <div className="rum__main overflow-auto">
      <div className="rum__performance-charts">
        <ChartGridV2.ChartGrid date={date} rows={rows} />
        {startupTime !== null && startupTime !== undefined && (
          <div className="rum__performance-charts-overlay">
            <div className="rum__performance-charts-overlay-text">
              {startupTime}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RUMAppStartupTime;
