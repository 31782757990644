import { timeseriesFieldConfig } from 'components/DashboardExport';
import {
  DashboardPanelType,
  DashboardPanelTargetsProps,
  ExplorerQueryProps,
  FormulaProps,
  DashboardPanelProps,
  DashboardPanelConfigMappingProps,
  DateSelection,
  DashboardAdvanceFunctionMappedName,
} from 'types';
import {
  buildFormulaQuery,
  buildPromqlWithFunctions,
  getMetricsExportPanelTargets,
} from 'utils';

/**
 * Get promql for queries and formulas
 * @param queries
 * @param formulas
 * @returns Array<{ expr: string; hide: boolean }>
 */
export const getPromqlForQueryAndFormula = ({
  queries,
  formulas,
  date,
}: {
  queries: ExplorerQueryProps[];
  formulas: FormulaProps[];
  date: DateSelection;
}) => {
  const promqlQueries: { expr: string; hide: boolean }[] = [];
  queries.map((query: ExplorerQueryProps) => {
    if (query.showInput) {
      promqlQueries.push({ expr: query.promql, hide: !query.isActive });
      return;
    }

    const promql = buildPromqlWithFunctions(query);
    promqlQueries.push({ expr: promql, hide: !query.isActive });
  });

  const promqls = promqlQueries.map((promql) => promql.expr);
  const queryKeys = queries.map((query: ExplorerQueryProps) => query.queryKey);
  formulas.map((formula: FormulaProps) => {
    const promql = buildFormulaQuery(promqls, queryKeys, [formula]);
    if (!promql || promql.length === 0) return;
    promqlQueries.push({ expr: promql[0], hide: !formula.isActive });
  });

  return promqlQueries.map(({ expr, hide }) => ({
    expr: decodeURIComponent(expr),
    hide,
  }));
};

export const nextRefId = (refId: string) => {
  if (!refId) return 'A';
  const refIdChar = refId.charCodeAt(0);
  return String.fromCharCode(refIdChar + 1);
};

/**
 * This function generates an array of targets for a dashboard panel.
 * @param {Object} params - An object containing the following properties:
 *    - formulas: An array of objects representing the formulas for the queries.
 *    - panelType: A string representing the type of the panel.
 *    - prevTargets: An array of objects representing the previous targets of the panel.
 *    - queries: An array of objects representing the queries for the panel.
 *    - uid: A string representing the unique identifier of the panel.
 * @returns {DashboardPanelTargetsProps[]} An array of objects representing the targets of the panel.
 */
const getPanelTargets = ({
  formulas,
  panelType,
  prevTargets,
  queries,
  uid,
  date,
}: {
  formulas: FormulaProps[];
  panelType: DashboardPanelType;
  prevTargets: DashboardPanelTargetsProps[];
  queries: ExplorerQueryProps[];
  uid: string;
  date: DateSelection;
}): {
  targets: DashboardPanelTargetsProps[];
  mappings: DashboardPanelConfigMappingProps[];
} => {
  const newTargets: DashboardPanelTargetsProps[] = [];

  const { targets, mappings } = getMetricsExportPanelTargets({
    dataFormat: panelType,
    date: date,
    formulas,
    queries,
  });
  targets.forEach((target, idx) => {
    const newTarget: DashboardPanelTargetsProps = {
      ...(prevTargets.find((target) => target.refId === target.refId) || {}),
      ...target,
      datasource: { type: 'prometheus', uid },
    };
    newTargets.push(newTarget);
  });

  return { targets: newTargets, mappings };
};

export const getPanelFieldConfig = ({
  prevFieldConfig,
}: {
  prevFieldConfig: DashboardPanelProps['fieldConfig'];
}): DashboardPanelProps['fieldConfig'] => {
  if (prevFieldConfig) {
    return prevFieldConfig;
  }

  return timeseriesFieldConfig('line');
};

export const getEditedPanel = ({
  editedPanel,
  formulas,
  queries,
  uid,
  date,
}: {
  editedPanel: DashboardPanelProps;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  uid: string;
  date: DateSelection;
}): DashboardPanelProps => {
  const { targets, mappings } = getPanelTargets({
    formulas,
    panelType: editedPanel.type,
    prevTargets: editedPanel.targets,
    queries,
    uid,
    date,
  });

  let fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });
  fieldConfig = findAndReplaceMapping(mappings, fieldConfig);

  const datasource = { type: 'prometheus', uid };
  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };
  return {
    ...editedPanel,
    fieldConfig,
    options,
    isEdited: true,
    targets,
    datasource,
  };
};

const findAndReplaceMapping = (
  advanceFuncMappings: DashboardPanelConfigMappingProps[],
  fieldConfig: DashboardPanelProps['fieldConfig'],
) => {
  if (!advanceFuncMappings) return fieldConfig;
  const { defaults } = fieldConfig;
  const { mappings } = defaults;

  if (!mappings) {
    return {
      ...fieldConfig,
      defaults: { ...defaults, mappings: advanceFuncMappings },
    };
  }

  const kfuseFunctionIndex = mappings?.findIndex(
    (m) =>
      m.type === 'value' &&
      m.options[DashboardAdvanceFunctionMappedName.KFUSE_FUNCTION],
  );

  if (kfuseFunctionIndex === -1) {
    return {
      ...fieldConfig,
      defaults: {
        ...defaults,
        mappings: [...mappings, ...advanceFuncMappings],
      },
    };
  }

  const newMappings = [...mappings];
  newMappings[kfuseFunctionIndex] = advanceFuncMappings[0];
  return { ...fieldConfig, defaults: { ...defaults, mappings: newMappings } };
};
