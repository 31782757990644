import { Button, CodeEditor } from 'components';
import React, { ReactElement } from 'react';

const completionTokens = {
  operators: [
    ',',
    '.',
    ':',
    '{',
    '}',
    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    '=~',
    '!~',
    '==',
    '!==',
    '~',
    '*',
    '|',
    '(',
    ')',
    '@',
  ],
  keywords: [
    'or',
    'and',
    '!',
    '+',
    '-',
    '/',
    '%',
    '^',
    'as',
    'by',
    'where',
    'if',
    'in',
  ],
  aggregators: [
    'count',
    'count_unique',
    'first',
    'last',
    'min',
    'max',
    'sum',
    'avg',
    'stddev',
    'stdvar',
  ],
  percentiles: ['p50', 'p75', 'p90', 'p95', 'p99', 'p84', 'p16'],
  misc_operators: [
    'backshift',
    'limit',
    'timeslice',
    'fields',
    'sort',
    'desc',
    'asc',
    'accum',
    'smooth',
    'total',
    'rollingstd',
    'dedup',
    'transpose',
  ],
  functions: [
    'base64Encode',
    'base64Decode',
    'isValidIP',
    'isPublicIP',
    'isPrivateIP',
    'isNull',
    'isEmpty',
    'len',
    'concat',
    'format',
    'formatDate',
    'hash',
    'hexToDec',
    'decToAscii',
    'decToHex',
    'hexToAscii',
    'ipv4ToNumber',
    'getCIDRPrefix',
    'compareCIDRPrefix',
    'maskFromCIDR',
    'num',
    'now',
    'queryStartTime',
    'queryEndTime',
    'queryTimeRange',
    'replace',
    'luhn',
    'substring',
    'trim',
    'urlDecode',
    'urlEncode',
    'toLowerCase',
    'toUpperCase',
    'toBytes',
    'toInt',
    'toFloat',
    'toDuration',
  ],
  math_functions: [
    'abs',
    'acos',
    'asin',
    'atan',
    'atan2',
    'cbrt',
    'ceil',
    'cos',
    'cosh',
    'exp',
    'expm1',
    'floor',
    'hypot',
    'isNan',
    'isInf',
    'log',
    'log10',
    'log1p',
    'round',
    'sin',
    'sinh',
    'sqrt',
    'tan',
    'tanh',
    'toDegrees',
    'toRadians',
  ],
  advanced_functions: [
    'predict',
    'anomaly',
    'outlier',
    'model',
    'forecast',
    'seasonality',
    'bounds',
    'trend',
    'window',
    'eps',
    'band',
    'row',
    'column',
  ],
};

const LogsFreeTextSearch = ({
  onRunQuery,
  onTextChange,
  text,
}: {
  onRunQuery: () => void;
  onTextChange: (text: string) => void;
  text: string;
}): ReactElement => {
  return (
    <div className="metrics__query-builder__query-item">
      <div className="metrics__query-builder__query-item__code">
        <CodeEditor
          completionTokens={completionTokens}
          customOptions={{
            quickSuggestions: {
              other: true,
              comments: false,
              strings: true,
            },
            suggestOnTriggerCharacters: true,
          }}
          onChange={onTextChange}
          value={text}
        />
        <Button
          className="h-full"
          variant="default"
          size="sm"
          onClick={onRunQuery}
        >
          Run
        </Button>
      </div>
    </div>
  );
};

export default LogsFreeTextSearch;
