import { FormulaState, SearchState } from 'hooks';
import {
  DashboardPanelOverrideProps,
  DashboardPanelType,
} from 'types/Dashboard';
import { MutableRefObject } from 'react';
import {
  DashboardPanelProps,
  DashboardTemplateValueProps,
  DateSelection,
} from 'types';
import {
  DataFrame,
  DataTransformerConfig,
  pieChartTransformer,
  TableTransformerData,
  topListTransformer,
  traceDashboardDataTransformer,
  traceDataTransformer,
} from 'utils/DataTransformer';
import { getUsedQueryKeysFromFormula } from 'utils';

import { getReplacedTimeFromWithDate } from './common-utils';
import { areQueriesGroupedByService } from './traceAnalytics';
import {
  mergeSeriesData,
  organizeColumn,
  overrideColumnsData,
} from 'utils/DataTransformer/tableTransformer';

export const getTraceQueryWithMetaForDashboard = ({
  date,
  panel,
  templateValues,
  userActionRef,
  width,
}: {
  date: DateSelection;
  panel: DashboardPanelProps;
  templateValues: DashboardTemplateValueProps;
  userActionRef: MutableRefObject<{ defaultTimeChanged: boolean }>;
  width: number;
}): {
  traceQueryWithMeta: any;
  mainTransformer: DataTransformerConfig[];
  isRange: boolean;
  isGroupedByService: boolean;
} => {
  const { expr } = panel.targets[0];
  const parsedExpr = JSON.parse(expr || '{}');
  if (!parsedExpr) {
    return {
      traceQueryWithMeta: [],
      mainTransformer: [],
      isRange: false,
      isGroupedByService: false,
    };
  }

  const traceQueryWithMeta: any[] = [];
  const panelDate = getReplacedTimeFromWithDate({
    date,
    panel,
    templateValues,
  });

  const isRange = panel.type === DashboardPanelType.TIMESERIES;
  const targetTransformer = traceDataTransformer(!isRange);
  let unit = 'number';
  let refId = '';
  const customerFilter = parsedExpr.customerFilter
    ? { customerFilter: parsedExpr.customerFilter }
    : {};
  const traceQuery = {
    ...customerFilter,
    date: panelDate,
    resultFormat: panel.type,
    transformer: targetTransformer,
    spanFilter: parsedExpr.spanFilter,
    ...(parsedExpr.filter || {}),
  };

  if (parsedExpr.queries && !parsedExpr.query) {
    const activeQueries = parsedExpr.queries.filter(
      (query: SearchState) => query.isActive,
    );
    refId = activeQueries[0]?.queryKey || refId;
    traceQuery.queries = activeQueries;
  }

  if (parsedExpr.formulas && !parsedExpr.formula) {
    const activeFormulas = parsedExpr.formulas.filter(
      (formula: FormulaState) => formula.isActive,
    );
    refId = activeFormulas[0]?.queryKey || refId;
    traceQuery.formulas = activeFormulas;

    // fill used queries in formulas
    activeFormulas.forEach((formula: FormulaState) => {
      const usedQueryKeys = getUsedQueryKeysFromFormula(formula.expression);
      const filteredQueries = parsedExpr.queries?.filter((query: SearchState) =>
        usedQueryKeys.includes(query.queryKey),
      );
      filteredQueries.forEach((query: SearchState) => {
        const isExist = traceQuery.queries?.find(
          (searchState: SearchState) => searchState.queryKey === query.queryKey,
        );
        if (!isExist) {
          traceQuery.queries?.push(query);
        }
      });
    });
  }

  if (parsedExpr.queries || parsedExpr.formulas) {
    traceQueryWithMeta.push(traceQuery);
  }

  if (parsedExpr.query) {
    unit = parsedExpr.query.measure === 'duration_ns' ? 'ns' : 'number';
    refId = parsedExpr.query.queryKey;
    traceQuery.queries = [parsedExpr.query];
    traceQuery.formulas = [];
    traceQueryWithMeta.push(traceQuery);
  }

  if (parsedExpr.formula) {
    refId = parsedExpr.formula.queryKey;
    traceQuery.queries = parsedExpr.queries || [];
    traceQuery.formulas = [parsedExpr.formula];
    traceQueryWithMeta.push(traceQuery);
  }

  const mainTransformer = [
    {
      id: 'traceDashboardDataTransformer',
      func: traceDashboardDataTransformer,
    },
  ];

  if (
    panel.type === DashboardPanelType.PIECHART ||
    panel.type === DashboardPanelType.TOP_LIST ||
    panel.type === DashboardPanelType.TABLE
  ) {
    const renamedValues: Record<string, string> = {};
    const overrides: DashboardPanelOverrideProps[] = [];

    const valueWithRef = `Value #${refId}`;
    if (unit) {
      overrides.push({
        matcher: { id: 'byName', options: valueWithRef },
        properties: [{ id: 'unit', value: unit, decimals: 2 }],
      });
    }
    mainTransformer.push({
      id: 'mergeSeriesData',
      func: (dataFrames: DataFrame) => {
        renamedValues[valueWithRef] = dataFrames.meta?.aggregate;
        return mergeSeriesData({ dataFrames: [dataFrames], columns: [] });
      },
    });
    mainTransformer.push({
      id: 'organize',
      func: (data: TableTransformerData) =>
        organizeColumn({
          data,
          options: {
            excludeByName: { Time: true },
            renameByName: renamedValues,
          },
          byField: '',
        }),
    });
    if (overrides.length > 0) {
      mainTransformer.push({
        id: 'override',
        func: (data: TableTransformerData) =>
          overrideColumnsData({ data, overrides }),
      });
    }
  }

  if (panel.type === DashboardPanelType.TOP_LIST) {
    mainTransformer.push({
      id: 'toTopList',
      func: (data: TableTransformerData) =>
        topListTransformer({ data, options: { unit } }),
    });
  }

  if (panel.type === DashboardPanelType.PIECHART) {
    mainTransformer.push({
      id: 'toPieChart',
      func: (data: TableTransformerData) => {
        const piechart = pieChartTransformer(data);
        return { data: piechart.pieChartData };
      },
    });
  }

  const isGroupedByService = areQueriesGroupedByService({
    queries: parsedExpr.queries || (parsedExpr.query ? [parsedExpr.query] : []),
  });

  return {
    traceQueryWithMeta: traceQueryWithMeta,
    mainTransformer,
    isRange,
    isGroupedByService,
  };
};
