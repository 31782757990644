import { useDateState } from 'hooks';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';
import { useSearches } from '../useSearch';
import useRumState from 'screens/Rum/hooks/useRumState';
import { RumErrorTab, RumEventType } from 'screens/Rum';
import { customOperationForMeasure } from 'screens/Rum/utils/customOperationForMeasure';

type Args = {
  rumDateState: ReturnType<typeof useDateState>;
};

const useRumPageState = ({ rumDateState }: Args) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { searches } = useSearches({ customOperationForMeasure });

  const rumState = useRumState({
    dateState: rumDateState,
    initialApplicationFilter: parseUrlParamByKey('rumApplicationFilter'),
    initialEventType: parseUrlParamByKey('rumEventType') || RumEventType.VIEW,
    initialErrorTab: parseUrlParamByKey('rumErrorTab') || RumErrorTab.ERROR,
    initialFilters: parseUrlParamByKey('rumFilters'),
    shouldWriteToUrl: false,
    urlStateKey: 'rumFilters',
  });

  const date = rumState.dateState[0];

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [
      date,
      rumState.eventType,
      rumState.errorTab,
      searches,
      rumState.filtersState.state,
      rumState.applicationFilter,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      date,
      searches,
      rumState.eventType,
      rumState.errorTab,
      rumState.filtersState.state,
      rumState.applicationFilter,
    ],
  );

  const writeStateToUrl = () => {
    const { filtersState } = rumState;
    params.set(
      'rumApplicationFilter',
      JSON.stringify(rumState.applicationFilter),
    );
    params.set('rumDate', JSON.stringify(rumState.dateState[0]));
    params.set('rumEventType', JSON.stringify(rumState.eventType));
    params.set('rumErrorTab', JSON.stringify(rumState.errorTab));
    params.set('rumFilters', JSON.stringify(filtersState.state));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dependenciesForWriteStateToUrl,
    searches,
    rumState,
    writeStateToUrl,
  };
};

export default useRumPageState;
