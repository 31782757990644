import { delimiter } from 'kfuse-constants';
import { formatFacetName } from 'requests/utils';
import { DashboardPanelProps, DashboardPanelType } from 'types';
import {
  getIsComponentLabel,
  getRollupByVisualization,
  parseFacetKey,
} from 'utils';

export const getBarCount = (width) => Math.ceil(width / 4) + 1;

export const getBucketSecs = (date, width) => {
  return getRollupByVisualization(date, 'Stacked Bar');
};

export const formatFacetNameWithWildcard = (facetKey) => {
  if (facetKey.indexOf(`*${delimiter}`) === 0) {
    return `@${facetKey.split(`*${delimiter}`)[1]}`;
  }

  const parts = facetKey.split(delimiter);
  if (parts.length >= 2) {
    return formatFacetName(parts[0], parts[1]);
  }

  return null;
};

export const formatMilliseconds = (duration: number) => {
  if (duration < 1000) {
    return `${Math.round(duration)}ms`;
  }

  const portions: string[] = [];

  const msInHour = 1000 * 60 * 60;
  const hours = Math.trunc(duration / msInHour);
  if (hours > 0) {
    portions.push(hours + 'h');
    duration = duration - hours * msInHour;
  }

  const msInMinute = 1000 * 60;
  const minutes = Math.trunc(duration / msInMinute);
  if (minutes > 0) {
    portions.push(minutes + 'm');
    duration = duration - minutes * msInMinute;
  }

  const seconds = Math.trunc(duration / 1000);
  if (seconds > 0) {
    portions.push(seconds + 's');
  }

  return portions.join(' ');
};

const niceNum = (range, round) => {
  const exponent = Math.floor(Math.log10(range));
  const fraction = range / Math.pow(10, exponent);
  let niceFraction;

  if (round) {
    if (fraction < 1.5) niceFraction = 1;
    else if (fraction < 3) niceFraction = 2;
    else if (fraction < 7) niceFraction = 5;
    else niceFraction = 10;
  } else {
    if (fraction <= 1) niceFraction = 1;
    else if (fraction <= 2) niceFraction = 2;
    else if (fraction <= 5) niceFraction = 5;
    else niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
};

export const niceScale = (lowerBound, upperBound, maxTicks: number) => {
  const range = niceNum(upperBound - lowerBound, false);
  const tickSpacing = niceNum(range / (maxTicks - 1), true);
  const niceLowerBound = Math.floor(lowerBound / tickSpacing) * tickSpacing;
  const niceUpperBound = Math.ceil(upperBound / tickSpacing) * tickSpacing;

  return { niceLowerBound, niceUpperBound, tickSpacing };
};

export const getTicks = (
  niceUpperBound: number,
  tickSpacing: number,
): number[] => {
  const result = [];

  for (let i = 0; i < niceUpperBound / tickSpacing; i += 1) {
    result.push(i * tickSpacing);
  }

  return result;
};

export const isSelectedLog = ({ selectedLog, selectedLogFromContext, row }) =>
  (selectedLog && selectedLog === row) ||
  (selectedLogFromContext &&
    selectedLogFromContext.message === row.message &&
    selectedLogFromContext.timestamp === row.timestamp);

export const findLargestBottomOfDashboard = (
  panels: DashboardPanelProps[],
): DashboardPanelProps['gridPos'] => {
  // find the largest y of gridPos
  let largestBottom = 0;
  panels.forEach((panel) => {
    const bottom = panel.gridPos.y + panel.gridPos.h;
    if (bottom > largestBottom) {
      largestBottom = bottom;
    }
  });

  return { x: 0, y: largestBottom, w: 12, h: 8, i: '0' };
};

export const findFacetValueFromLogEvent = ({ key, logEvent }) => {
  const { component, name, type } = parseFacetKey(key);

  if (logEvent?.[name]) {
    return logEvent[name];
  }
  const labels = logEvent.labels || {};
  if (getIsComponentLabel(component)) {
    return labels[name] || null;
  }

  const logLineParts = logEvent.logLineParts || [];
  const logLineExtraFacets = logEvent.extraFacets || [];
  const matchingLogLinePart = logLineParts.find(
    (part) =>
      part.facetPart?.name === name && part.facetPart?.dataType === type,
  );

  if (matchingLogLinePart) {
    return matchingLogLinePart.facetPart.content;
  }

  const matchingExtraFacet = logLineExtraFacets.find(
    (part) => part?.name === name && part?.dataType === type,
  );

  if (matchingExtraFacet) {
    return matchingExtraFacet.content;
  }

  return null;
};

export const isTabAnalytics = (tab: string): boolean => {
  const analyticsTabHash = {
    [DashboardPanelType.TIMESERIES]: true,
    [DashboardPanelType.TOP_LIST]: true,
    [DashboardPanelType.TABLE]: true,
    [DashboardPanelType.PIECHART]: true,
  };
  return Boolean(analyticsTabHash[tab as keyof typeof analyticsTabHash]);
};
