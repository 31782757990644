import { MetricsQueryBuilder, Stepper, useThemeContext } from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardStreamType,
  DashboardTemplateValueProps,
  ExplorerQueryProps,
} from 'types';
import { combineRangeQueryData, combineInstantQueryData } from 'utils';

import {
  DashboardPanelModalFooter,
  DashboardPanelModalHeader,
} from '../components';
import {
  DashboardEditGraphHostmap,
  DashboardEditGraphSunburst,
  DashboardEditGraphTable,
  DashboardEditGraphTimeseries,
  DashboardEditGraphToplist,
} from './DashboardEditGraph';
import DashboardEditPanelName from './DashboardEditPanelName';
import DashboardEditMetricsVisualType from './DashboardEditMetricsVisualType';
import DashboardEditStreamType from './DashboardEditStreamType';
import { useDashboardEditState } from './hooks';
import {
  defaultTransformations,
  getQueryBuilderTransformedPromql,
  getTransformedForQueryBuilder,
} from './utils';
import { getPanelStreamType, getPanelWidthHeight } from '../utils';

const isQueriesIncludesAnomalies = (queries: ExplorerQueryProps[]) => {
  return queries.some((query) => {
    const isQueryHasAnomalies = query.functions.some(
      (func) => func.name === 'anomalies',
    );
    return isQueryHasAnomalies;
  });
};
const HiddenVisualizationTypes = [
  DashboardPanelType.TOP_LIST,
  DashboardPanelType.TABLE,
  DashboardPanelType.PIECHART,
  DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
];

const DashboardEditMetrics = ({
  baseWidth,
  close,
  dashboardEditState,
  panel,
  panelType,
  templateValues,
}: {
  baseWidth: number;
  close: () => void;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
  panelType?: DashboardPanelType;
  templateValues?: DashboardTemplateValueProps;
}): ReactElement => {
  const { darkModeEnabled } = useThemeContext();
  const { date, editPanel, stream, onSaveClick, setDate } = dashboardEditState;
  const metricsQueryState = useMetricsQueryStateV2({
    activeQueryType:
      panelType === DashboardPanelType.PIECHART ? 'single' : 'multi',
    date,
    dataFormat: panelType,
    isRange: DashboardPanelType.TIMESERIES === panelType,
    preDefinedValues: Object.keys(templateValues || {}).map((key) => ({
      label: `$${key}`,
      value: `$${key}`,
    })),
    preReloadQuery: (promql) =>
      getTransformedForQueryBuilder({
        date,
        promql,
        templateValues,
        width: baseWidth > 0 ? baseWidth : window.innerWidth,
      }),
    preReloadMatcher: (matcher) =>
      getTransformedForQueryBuilder({
        date,
        promql: matcher,
        templateValues,
        width: baseWidth > 0 ? baseWidth : window.innerWidth,
      }),
    urlCleanUp: true,
  });
  const {
    callMultiplePromqlQueries,
    callOnePromqlQuery,
    formulas,
    queries,
    queryData,
    setQueries,
    setFormulas,
  } = metricsQueryState;

  const setQueryBuilderPromqls = () => {
    if (!panel.type || !panel.targets) return;
    if (getPanelStreamType(panel) !== DashboardStreamType.METRIC) return;
    const queryBuilder = getQueryBuilderTransformedPromql({ panel });

    setQueries(queryBuilder.queries);
    setFormulas(queryBuilder.formulas);
    callMultiplePromqlQueries(
      queryBuilder.queries,
      queryBuilder.formulas,
      date,
    );
  };

  useEffect(() => {
    if (!panel) return;
    setQueryBuilderPromqls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!editPanel.type) return;
    if (editPanel.type === DashboardPanelType.TIMESERIES) {
      return;
    }
    if (editPanel.type === DashboardPanelType.PIECHART) {
      setQueries((prev) => {
        const newQueries = [...prev];
        newQueries.map((query) => (query.isActive = false));
        // keep the first query active
        newQueries[0].isActive = true;
        callOnePromqlQuery({
          formulas,
          queries: newQueries,
          queryIndex: 0,
          type: 'query',
          callType: 'normal',
          date,
        });
        return newQueries;
      });
      setFormulas((prev) => {
        const newFormulas = [...prev];
        newFormulas.map((formula) => (formula.isActive = false));
        return newFormulas;
      });
    } else {
      callMultiplePromqlQueries(queries, formulas, date);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPanel.type]);

  const { formulasStatus, queriesStatus } = useMemo(() => {
    const queriesStatus = queries.map((query) => {
      return { isActive: query.isActive, queryKey: query.queryKey };
    });

    const formulasStatus = formulas.map((formula, idx) => {
      return { isActive: formula.isActive, queryKey: `${idx + 1}` };
    });

    return { formulasStatus, queriesStatus };
  }, [queries, formulas]);

  const dataFormatterRange = useCallback(() => {
    if (editPanel.type !== DashboardPanelType.TIMESERIES) return null;
    return combineRangeQueryData({
      formulas: formulasStatus,
      queries: queriesStatus,
      queryData,
      combineTimestamp: true,
      combineLabelWithQueryKey: true,
    });
  }, [editPanel.type, formulasStatus, queriesStatus, queryData]);

  const dataFormatterInstant = useCallback(() => {
    if (editPanel.type === DashboardPanelType.TIMESERIES) return null;

    return combineInstantQueryData({
      dataFormat: editPanel.type,
      queries: queriesStatus,
      queryData,
      formulas: formulasStatus,
      transformations: defaultTransformations({
        dataFormat: editPanel.type,
        stream: stream,
      }),
    });
  }, [editPanel.type, formulasStatus, queriesStatus, queryData, stream]);

  return (
    <>
      <DashboardPanelModalHeader
        close={close}
        modalDate={date}
        setModalDate={setDate}
        title={!panel ? 'Add Panel' : editPanel.title || 'Edit Panel'}
      />
      <div className="dashboard-edit__metric__body">
        {editPanel.type === DashboardPanelType.TIMESERIES && (
          <DashboardEditGraphTimeseries
            baseWidth={baseWidth}
            onDateChange={setDate}
            panel={editPanel}
            dataFormatter={dataFormatterRange}
          />
        )}
        {editPanel.type === DashboardPanelType.TABLE && (
          <DashboardEditGraphTable dataFormatter={dataFormatterInstant} />
        )}
        {editPanel.type === DashboardPanelType.TOP_LIST && (
          <DashboardEditGraphToplist
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
          />
        )}
        {editPanel.type === DashboardPanelType.GRAFANA_POLYSTAT_PANEL && (
          <DashboardEditGraphHostmap
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
            panel={editPanel}
            stream={DashboardStreamType.METRIC}
          />
        )}
        {editPanel.type === DashboardPanelType.PIECHART && (
          <DashboardEditGraphSunburst
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
            panel={editPanel}
          />
        )}
        <Stepper
          steps={[
            {
              title: 'Choose Visualization',
              component: (
                <DashboardEditMetricsVisualType
                  dashboardEditState={dashboardEditState}
                  hideVisualizationTypes={
                    isQueriesIncludesAnomalies(queries)
                      ? HiddenVisualizationTypes
                      : undefined
                  }
                />
              ),
            },
            {
              title: 'Graph your data',
              component: (
                <div className="dashboard__widget__metrics-query-builder">
                  <DashboardEditStreamType
                    setStream={dashboardEditState.setStream}
                    stream={dashboardEditState.stream}
                  />
                  <MetricsQueryBuilder
                    blockedFunctionsNames={
                      editPanel.type === DashboardPanelType.TIMESERIES
                        ? undefined
                        : ['Anomalies']
                    }
                    metricsQueryState={metricsQueryState}
                  />
                </div>
              ),
            },
            {
              title: 'Enter chart title',
              component: (
                <DashboardEditPanelName
                  dashboardEditState={dashboardEditState}
                />
              ),
            },
          ]}
        />
      </div>
      <DashboardPanelModalFooter
        close={close}
        onSave={() => onSaveClick({ formulas, queries })}
      />
    </>
  );
};

export default DashboardEditMetrics;
