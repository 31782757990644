import { AutocompleteOption, Loader } from 'components';
import { debounce } from 'lodash';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FixedSizeList as List } from 'react-window';
import MetricsQueryBuilderActiveMetricSummary from './MetricsQueryBuilderActiveMetricSummary';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  isLoading: boolean;
  labelsList: {
    [key: string]: { options: AutocompleteOption[]; isLoading: boolean };
  };
  metricsList: AutocompleteOption[];
  onClick: (metricName: string) => void;
  onHover: (metricName: string) => void;
  onTagClick: (metricName: string, tagName: string) => void;
  error: {
    getLabelVlaues: { message: string };
    getLabels: { message: string };
  };
};

const MetricsQueryBuilderMetricPanel = ({
  isLoading,
  labelsList,
  metricsList,
  onClick,
  onHover,
  onTagClick,
  error,
}: Props): ReactElement => {
  const [activeMetric, setActiveMetric] = useState('');
  const isMounted = useRef(false);

  const onHoverMetric = useCallback(
    (metricName: string) => {
      if (!isMounted.current) return;
      setActiveMetric(metricName);
      onHover(metricName);
    },
    [onHover],
  );

  const debouncedHoverMetric = useMemo(
    () => debounce(onHoverMetric, 300),
    [onHoverMetric],
  );

  const metricSummary = useMemo(() => {
    const matcher = `${activeMetric}{}`;
    if (activeMetric && labelsList[matcher]) {
      return labelsList[matcher];
    }
    return { options: [], isLoading: false };
  }, [activeMetric, labelsList]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const ROW_HEIGHT = 35.5;
  const renderMetricRow = ({ index, style }) => {
    const metric = metricsList[index];
    return (
      <div
        className="metrics__query-builder__metric__panel__list__item py-2"
        key={metric.value}
        onMouseDown={() => onClick(metric.value)}
        onMouseEnter={() => debouncedHoverMetric(metric.value)}
        style={style}
      >
        {metric.label}
      </div>
    );
  };

  return (
    <Loader isLoading={isLoading}>
      <div className="metrics__query-builder__metric__panel">
        <div className="metrics__query-builder__metric__panel__list">
          <List
            height={400}
            itemCount={metricsList.length}
            itemSize={ROW_HEIGHT}
            width={400}
          >
            {renderMetricRow}
          </List>
        </div>

        <div className="metrics__query-builder__metric__panel__tags overflow-y-scroll">
          {activeMetric && (
            <>
              <MetricsQueryBuilderActiveMetricSummary
                activeMetric={activeMetric}
              />
              <Loader isLoading={metricSummary.isLoading}>
                <div className="flex flex-wrap">
                  {error.getLabels ? (
                    <div className="flex gap-[4px] w-full justify-start m-[2px] pr-[14px]">
                      <IoIosWarning
                        className="overlay-message__icon-and-message__icon"
                        size={16}
                      />
                      <div className="text-red-500">
                        {error?.getLabels?.message}
                      </div>
                    </div>
                  ) : (
                    <>
                      {metricSummary.options.map(
                        ({ label }: { label: string }) => {
                          return (
                            <div
                              className="chip metrics__query-builder__metric__panel__tags__item"
                              key={label}
                              onMouseDown={() =>
                                onTagClick(activeMetric, label)
                              }
                            >
                              {label}
                            </div>
                          );
                        },
                      )}
                    </>
                  )}
                </div>
              </Loader>
            </>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default MetricsQueryBuilderMetricPanel;
