import { Separator } from 'components/shadcn';
import {
  BasicLogsTable,
  Button,
  TableOptionsPopover,
  useColumnsState,
  useTableOptions,
} from 'components';
import { useKeyExistsState } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { getColumns, MESSAGE } from 'screens/Logs/constants';
import useLogsFacetExplorerState from './useLogsFacetExplorerState';
import { getAnalyticsQuery, getFacetExplorerLogStateFilter } from './utils';

const LogsFacetExplorerSourceFacetsLogs = ({
  logsFacetExplorerState,
  selectedValues,
  width,
}: {
  logsFacetExplorerState: ReturnType<typeof useLogsFacetExplorerState>;
  selectedValues: string[];
  width: number;
}): ReactElement => {
  const { date, selectedSource, selectedFacet } = logsFacetExplorerState;
  const [reload, setReload] = useState(true);

  const tableOptions = useTableOptions({ shouldUseLocalStorage: false });
  const customColumnsState = useKeyExistsState({
    urlStateKey: 'customColumns',
    shouldWriteToUrl: false,
    initalState: {},
  });

  const logsState = useMemo(
    () => {
      const query = getFacetExplorerLogStateFilter({
        facet: selectedFacet,
        selectedValues,
        date,
        selectedSource,
      });
      return query;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, selectedFacet, selectedValues],
  );

  const baseColumns = useMemo(
    () => getColumns({ customColumnsState, logsState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customColumnsState.state],
  );
  const columnsState = useColumnsState({
    columns: baseColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: { [MESSAGE]: 1 },
    },
    key: 'logs-table',
    shouldUseLocalStorage: false,
  });

  const onOpenInLogsAnalytics = () => {
    const query = getAnalyticsQuery({
      facet: selectedFacet,
      selectedValues,
      type: 'new_tab',
    });
    const dateEncoded = encodeURIComponent(JSON.stringify(date));
    const queryEncoded = encodeURIComponent(JSON.stringify([query]));
    const queryUrl = `#/logs?logsDate=${dateEncoded}&LogsMetricsQueries=${queryEncoded}`;
    window.open(queryUrl, '_blank');
  };

  useEffect(() => {
    setReload(true);
  }, [date, selectedFacet, selectedValues]);

  return (
    <div className={`max-h-[200px] w-[740px] pl-2 pt-4`}>
      <Separator className="pt-2" />
      <div className="flex items-center justify-between pb-2">
        <div className="text-lg font-semibold text-text-secondary">
          Facet Logs
        </div>
      </div>
      <div className="flex items-center justify-between pb-1">
        <div className="table-toolbar__left">
          <TableOptionsPopover
            columnsState={columnsState}
            tableOptions={tableOptions}
          />
        </div>
        <Button variant="default" size="sm" onClick={onOpenInLogsAnalytics}>
          Open in Logs page
        </Button>
      </div>

      <Separator className="mt-2" />
      <BasicLogsTable
        columnsState={columnsState}
        customColumnsState={customColumnsState}
        disableAddCustomColumn={true}
        enableWebSocket={false}
        isInView={true}
        isReload={reload}
        onLogsLoaded={() => setReload(false)}
        logsState={logsState}
        shouldDisableFilterActions
        tableOptions={tableOptions}
        limit={10}
      />
    </div>
  );
};

export default LogsFacetExplorerSourceFacetsLogs;
