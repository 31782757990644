import classNames from 'classnames';
import { Button, TooltipTrigger } from 'components';
import { useSearch, useSearches } from 'hooks/useSearch';
import React, { useCallback, useEffect } from 'react';
import { XCircle } from 'react-feather';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from 'screens/Traces/utils';
import { DashboardPanelType } from 'types';
import { EventsTab } from '../types';
import EventSearchGrouper from './EventSearchGrouper';
import { useEventsState } from '../hooks';
import { useFilterState } from 'hooks/useFilterState';
import { EventsSearchBar } from '../components';

type Props = {
  index: number;
  search: ReturnType<typeof useSearch>;
  searches: ReturnType<typeof useSearches>;
  eventsTab: EventsTab;
  eventsState: ReturnType<typeof useEventsState>;
  filterState: ReturnType<typeof useFilterState>;
  dontUsePageFilterState?: boolean;
};

const EventsQuerySearch = ({
  index,
  search,
  searches,
  eventsTab,
  eventsState,
  filterState: pageFilterState,
  dontUsePageFilterState = false,
}: Props) => {
  const { searchBarState } = search;

  const filterState = useFilterState({ shouldWriteToUrl: false });
  const { date } = eventsState;

  const localFilterState = dontUsePageFilterState
    ? filterState
    : index === 0
      ? pageFilterState
      : filterState;

  const { changeActive } = search;

  const handleActiveClick = useCallback(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        eventsTab as unknown as DashboardPanelType,
      )
    ) {
      if (search.isActive) {
        changeActive(!search.isActive);
      } else {
        search.selectOnlySingeQuery();
      }
    } else {
      changeActive(!search.isActive);
    }
  }, [changeActive, search, eventsTab]);

  useEffect(() => {
    filterState.replaceFilterByKey('searchTerms', [
      ...(searchBarState?.filterState?.searchTerms ?? []),
    ]);
    filterState.replaceFilterByKey('filterByFacets', [
      ...(searchBarState?.filterState?.filterByFacets ?? []),
    ]);
    filterState.replaceFilterByKey('sidebarFilters', {
      ...(searchBarState?.filterState?.sidebarFilters ?? {}),
    });
    filterState.replaceFilterByKey('facetRangeFilters', {
      ...(searchBarState?.filterState?.facetRangeFilters ?? {}),
    });
    filterState.replaceFilterByKey('keyExists', {
      ...(searchBarState?.filterState?.keyExists ?? {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!localFilterState.filter) {
      return;
    }
    search.setSearchBarState({
      filterState: localFilterState.filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilterState.filter]);

  return (
    <>
      <div className="flex--direction-col mb-3 flex">
        <div className="flex">
          <div className="width-100-per flex">
            {
              <TooltipTrigger tooltip={'Show Chart'}>
                <div
                  onClick={handleActiveClick}
                  className={classNames({
                    'query-builder__logs__item__query-key': true,
                    'query-builder__logs__item__query-key--fullHeight': true,
                    'query-builder__logs__item__query-key--inactive':
                      !search.isActive,
                  })}
                >
                  {search.queryKey}
                </div>
              </TooltipTrigger>
            }
            <EventsSearchBar
              eventsState={eventsState}
              filterState={localFilterState}
            />
            {Boolean(searches.length > 1 && index !== 0) && (
              <div className="events__analytics__query-action">
                <TooltipTrigger tooltip="Remove Query">
                  <Button
                    className="events__analytics__query-action--delete h-full px-1.5"
                    variant="icon"
                    size="sm"
                  >
                    <XCircle onClick={search?.removeExistingSearch} size={16} />
                  </Button>
                </TooltipTrigger>
              </div>
            )}
          </div>
        </div>
        <EventSearchGrouper
          date={date}
          eventsTab={eventsTab}
          eventsState={eventsState}
          search={search}
        />
      </div>
    </>
  );
};

export default EventsQuerySearch;
