import {
  ChartLegendTableColumn,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { Filter, useSearch } from 'hooks';
import React, { useMemo } from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ChartGridV2 } from 'components';
import { formatLatencyYAxisTick } from 'utils';
import { RumEventType } from '../types';
import multipleQueryRangeWithLabelsForRUMMobile from 'utils/chartGrid/multipleQueryRangeWithLabelsForRUMMobile';

type GetRowArgs = {
  applicationFilter: string;
  facetRegex: FacetRegexTerm[];
  setDate: (date: DateSelection) => void;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  filters: Filter[];
  search: ReturnType<typeof useSearch>;
  eventType: RumEventType;
};
interface RumQuery {
  isActive: boolean;
  type: string;
  queryKey: string;
  query: {
    searchBarState: {
      applicationFilter: string;
      filters: Filter[];
    };
    aggregateField: string;
    aggregateFunction: string;
    groupBys?: string[];
    [key: string]: any;
  };
}

interface ChartConfig {
  key: string;
  colorMap: Record<string, string>;
  yAxisTickFormatter: (value: number) => string;
  label: string;
  legendTableColumns: string[];
  libraryType: string;
  onSelection: (newDate: DateSelection) => void;
  rumQueries: RumQuery[];
  query: ReturnType<typeof multipleQueryRangeWithLabelsForRUMMobile>;
  unit: string;
  eventType: RumEventType;
  chartType?: string;
  enableCreateAlert: boolean;
}

interface RowType {
  charts: ChartConfig[];
}
export const getRows = ({
  applicationFilter,
  facetRegex,
  setDate,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  filters,
  search,
  eventType,
}: GetRowArgs): RowType[][] => {
  const onSelection = (newDate: DateSelection) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };
  const rumQueries = (aggregateField: string, aggregateFunction: string) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
      advancedGroupBys: [
        {
          by: 'version',
          limitTo: 'top',
          limitToValue: 5,
        },
      ],
      groupBys: ['version'],
      staticFilters: [
        { attributeFilter: { eq: { key: 'session.type', value: 'user' } } },
      ],
    },

    eventType,
    queryType: 'rum-performance',
  });

  return [
    [
      {
        charts: [
          {
            key: 'CPUTicksByVersion',
            colorMap: {
              count: '#1f77b4',
            },
            chartType: 'bar',
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'CPU Ticks per Second by Version',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('view.cpu_ticks_per_second', 'avg')],
            query: multipleQueryRangeWithLabelsForRUMMobile(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.cpu_ticks_per_second',
                  aggregateFunction: 'avg',
                  facetRegex,
                  groupByLimit: 100,
                  groupByField: 'version',
                  groupBySortField: '*',
                  groupBySortAggregation: 'count',
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType: RumEventType.VIEW,
                  staticFilters: [
                    {
                      attributeFilter: {
                        eq: { key: 'session.type', value: 'user' },
                      },
                    },
                  ],
                }),
              ],
              [['CPUTicksByVersion']],
            ),
            unit: '',
            eventType,
            enableCreateAlert: true,
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RUMCPUTicksByVersion = ({ rumPageState }: Props): JSX.Element => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    dateState,
    filtersState,
  } = rumState;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const [date, setDate] = dateState;
  const search = useSearch();
  const rows = useMemo(
    () =>
      getRows({
        applicationFilter,
        facetRegex: facetRegexState.state,
        setDate,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        filters: filtersState.state,
        search,
        eventType: RumEventType.VIEW,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicationFilter,
      date,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <div className="rum__main overflow-auto">
      <div className="rum__performance-charts">
        <ChartGridV2.ChartGrid date={date} rows={rows} />
      </div>
    </div>
  );
};

export default RUMCPUTicksByVersion;
