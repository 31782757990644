import { useRequest, SearchState } from 'hooks';
import React, { ReactElement } from 'react';
import {
  DashboardPanelType,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  LimitTo,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';

import { rumDataTransformer } from 'utils';
import { ConditionProps } from '../AlertsCreateCondition';
import { RumEventType } from 'screens/Rum';
import { AlertsChart } from '../AlertsChart';
import { calculateRateIntervalVariableValue } from 'utils';
import { getRollupByVisualization } from 'utils';

const AlertsCreateRumChartGroupBy = ({
  applicationFilter,
  condition,
  date,
  eventType,
  facetRegex,
  idSearch,
  queries,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  isEditing = false,
  queryToCall,
}: {
  applicationFilter: string;
  condition: ConditionProps;
  date: DateSelection;
  eventType: RumEventType;
  facetRegex: FacetRegexTerm[];
  idSearch: string;
  queries: SearchState[];
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  isEditing?: boolean;
  queryToCall?: any;
}): ReactElement => {
  const rumFrozenFrameTimeSeriesRequest = useRequest(queryToCall);
  const loadRumTimeseries = () => {
    if (!applicationFilter) return;
    const baseTransformers = rumDataTransformer();

    const query = queries[0];
    const chartType = 'timeseries';
    const { stepInMs } = calculateRateIntervalVariableValue(date, chartType);
    const { rollUpInSeconds } = query;
    rumFrozenFrameTimeSeriesRequest.call({
      applicationFilter,
      date,
      facetRegex,
      rollupSeconds: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
      transformer: baseTransformers,
      meta: {
        step: stepInMs / 1000,
        refId: 'a',
        type: DashboardPanelType.TIMESERIES,
      },
      selectedFacetValuesByName,
      selectedFacetRangeByName,
      eventType,
      advancedGroupBys: [
        {
          by: 'view.name',
          limitTo: LimitTo.top,
          limitToValue: 5,
        },
      ],
    });
  };

  useDebouncedEffect(
    () => {
      loadRumTimeseries();
    },
    { timeout: 200, ignoreInitialCall: false },
    [
      applicationFilter,
      date,
      eventType,
      idSearch,
      queries,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={condition.value ? Number(condition.value) : undefined}
      date={date}
      isChartCompact={false}
      enableEvaluationLine={true}
      isLoading={rumFrozenFrameTimeSeriesRequest.isLoading}
      queryData={rumFrozenFrameTimeSeriesRequest?.result?.data}
      unit={undefined}
    />
  );
};

export default AlertsCreateRumChartGroupBy;
