import {
  TooltipTrigger,
  Button,
  AutocompleteV2,
  ErrorMessage,
} from 'components';
import { Datepicker } from 'composite';
import { logsTopBottomCountOptions } from 'kfuse-constants';
import React from 'react';
import { Plus, RefreshCw } from 'react-feather';
import { refreshDate } from 'utils';

import LogsCardinalityLabelValue from './LogsCardinalityLabelValue';
import LogsCardinalityTable from './LogsCardinalityTable';
import useLogsCardinalityState from './useLogsCardinalityState';
import { IoIosWarning } from 'react-icons/io';

const LogsCardinality = () => {
  const logsCardinalityState = useLogsCardinalityState();
  const {
    date,
    onDateChange,
    setDate,
    labelCardinalityQuery,
    setLabelCardinalityQuery,
    updateCardinalityQuery,
    error,
  } = logsCardinalityState;
  const { limit } = labelCardinalityQuery;

  return (
    <div className="metrics-cardinality">
      <div className="flex--justify-content-between flex ">
        <div className="new-metrics__header__title">Logs Cardinality</div>

        <div className="new-metrics__header__date-picker">
          <div className="flex gap-[4px]">
            <ErrorMessage message={error?.getLabelCardinality?.message} />
            <ErrorMessage message={error?.getLabelNames?.message} />
          </div>

          <Datepicker
            className="logs__search__datepicker"
            hasStartedLiveTail={false}
            onChange={onDateChange}
            startLiveTail={null}
            quickRangeOptions={[
              { label: 'Last 5 minutes', from: 'now-5m', to: 'now' },
            ]}
            value={date}
          />
          <button
            className="new-metrics__header__refresh-button"
            onClick={() => refreshDate(date, setDate)}
            data-testid="refresh"
          >
            <RefreshCw size={14} />
          </button>
        </div>
      </div>
      <div
        className="search__grouper search__button-group"
        data-testid="search-grouper"
      >
        {labelCardinalityQuery.labels.map((query, index) => (
          <>
            <div className="search__button-group__divider">
              <div />
            </div>
            <LogsCardinalityLabelValue
              key={index}
              logsCardinalityState={logsCardinalityState}
              series={query}
              labels={labelCardinalityQuery.labels}
              labelIndex={index}
            />
          </>
        ))}
        <div className="search__button-group__divider">
          <div />
        </div>
        {labelCardinalityQuery.labels[
          labelCardinalityQuery.labels.length - 1
        ] !== '=""' && (
          <>
            <div className="button-group">
              <TooltipTrigger className="flex" tooltip="Add New Label">
                <Button
                  variant="icon-outline"
                  onClick={() => {
                    setLabelCardinalityQuery((prev) => {
                      const newLabels = [...prev.labels, '=""'];
                      return { ...prev, labels: newLabels };
                    });
                  }}
                >
                  <Plus size={16} />
                </Button>
              </TooltipTrigger>
            </div>
            <div className="search__button-group__divider">
              <div />
            </div>
          </>
        )}
        <div className="button-group">
          <div className="button-group__item button-group__item--label">
            limit to
          </div>
          <div
            className="button-group__item button-group__item--value"
            data-testid="direction-attribute"
          >
            <AutocompleteV2
              className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
              components={{ DropdownIndicator: null }}
              isSearchable={false}
              onChange={(val) =>
                updateCardinalityQuery('limit', { ...limit, direction: val })
              }
              options={[
                { label: 'top', value: 'topk' },
                { label: 'bottom', value: 'bottomk' },
              ]}
              value={limit.direction}
            />
          </div>
          <div
            className="button-group__item button-group__item--value"
            data-testid="size-attributes"
          >
            <AutocompleteV2
              className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
              components={{ DropdownIndicator: null }}
              isSearchable={false}
              onChange={(val) => {
                updateCardinalityQuery('limit', { ...limit, count: val });
              }}
              options={[
                ...logsTopBottomCountOptions,
                { label: 'All', value: 'all' },
              ]}
              value={limit.count}
            />
          </div>
        </div>
      </div>
      <LogsCardinalityTable logsCardinalityState={logsCardinalityState} />
    </div>
  );
};

export default LogsCardinality;
