import { FacetWithIconIcon, TooltipTrigger } from 'components';
import { useOnClickOutside } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { X } from 'react-feather';
import { SearchTag } from './types';
import { AutosizeInput } from 'components';

import { default as ChipBase } from 'components/base/Chip';
import classNames from 'classnames';
import { cn } from 'utils/tailwind';

type SearchTagsProps = {
  tags: SearchTag[];
};

const DecoratedAutosizeInput = ({ isOpen, value, onChange, ...props }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current?.input;

    if (input) {
      input.focus();

      if (value.endsWith('""')) {
        input.setSelectionRange(value.length - 1, value.length - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (value) {
    return (
      <ChipBase onRemove={() => onChange('')}>
        <AutosizeInput
          ref={inputRef}
          value={value}
          onChange={onChange}
          {...props}
        />
      </ChipBase>
    );
  }

  return (
    <AutosizeInput
      ref={inputRef}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

const SearchTags = ({
  clear,
  clearError,
  close,
  containerRef,
  editIndex,
  editTyped,
  inputRef,
  isEditing,
  isOpen,
  onInputEnter,
  onClickOutside,
  placeholder,
  setEditIndex,
  setEditTyped,
  setTyped,
  typed,
  tags,
  width,
}: SearchTagsProps) => {
  const cancel = () => {
    setEditIndex(null);
  };

  const clearErrorOnChangeHandler = (handler) => (nextValue) => {
    clearError();
    handler(nextValue);
  };

  useOnClickOutside({
    onClick: onClickOutside,
    ref: containerRef,
    shouldUseClick: true,
  });

  function stopPropagation(e) {
    e.stopPropagation();
  }

  return (
    <div
      data-label="search tags"
      className={cn(
        'max-h-72',
        'min-w-0',
        'flex',
        'w-full',
        'justify-between',
        'overflow-auto',
        'p-2',
        'border',
        'rounded-r',
        'group relative flex min-h-[36px] w-full min-w-0',
        'focus-within:bg-searchbar-background-focus',
        {
          'search__trigger--editing': isEditing,
        },
      )}
      data-testid="search-tags"
    >
      <div className="flex min-w-0 flex-wrap items-center gap-1">
        {tags.map((tag, i) => {
          const isEditingTag = i === editIndex;
          const isLastTag = i === tags.length - 1;
          if (isEditingTag) {
            return (
              <ChipBase
                key={i}
                onClick={stopPropagation}
                onRemove={tag.onRemove}
              >
                <AutosizeInput
                  autoFocus
                  className="max-w-full"
                  onChange={clearErrorOnChangeHandler(setEditTyped)}
                  onEnter={onInputEnter}
                  onEscape={cancel}
                  inputStyle={{ maxWidth: `${width - 35}px` }}
                  value={editTyped}
                  dataTestId="search-input"
                />
              </ChipBase>
            );
          }

          return (
            <ChipBase
              key={i}
              data-testid="search-tag"
              onClick={stopPropagation}
              className={classNames({
                'min-w-0 max-w-full': true,
                'mr-5': isLastTag,
              })}
              onRemove={tag.onRemove}
            >
              <div
                className="flex max-w-full items-center"
                onClick={() => {
                  setEditTyped(tag.text);
                  setEditIndex(i);
                }}
              >
                {tag?.meta?.type ? (
                  <span className="search__trigger__tag__icon">
                    <FacetWithIconIcon type={tag.meta.type} />
                  </span>
                ) : null}
                <span className="min-w-0 flex-1 truncate">{tag.label}</span>
              </div>
            </ChipBase>
          );
        })}
        {!isEditing ? (
          <DecoratedAutosizeInput
            autoFocus
            className="max-w-full flex-1"
            isOpen={isOpen}
            onChange={clearErrorOnChangeHandler(setTyped)}
            onEnter={onInputEnter}
            placeholder={tags.length ? '' : placeholder}
            ref={inputRef}
            type="text"
            value={typed}
          />
        ) : null}
      </div>
      {clear && tags.length ? (
        <TooltipTrigger
          className="search__trigger__clear"
          tooltip="Remove all filters"
          data-testid="search-clear"
          onClick={() => {
            clear();
          }}
        >
          <X color="#da545b" size={16} />
        </TooltipTrigger>
      ) : null}
    </div>
  );
};

export default SearchTags;
