import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import SheetHeaderCell from './SheetHeaderCell';
import SheetRow from './SheetRow';

const propTypes = {
  columns: PropTypes.array.isRequired,
  disableTableHeaderSorting: PropTypes.string,
  locked: PropTypes.shape({}).isRequired,
  lockedColumns: PropTypes.shape({}).isRequired,
  onResize: PropTypes.func.isRequired,
  onToggleLock: PropTypes.func.isRequired,
  scrollX: PropTypes.number.isRequired,
  sheetDimensions: PropTypes.shape({}).isRequired,
  sortRows: PropTypes.func,
};

const SheetHeader = ({
  columns,
  columnsState,
  disableTableHeaderSorting,
  locked,
  lockedColumns,
  onResize,
  onToggleLock,
  scrollX,
  sheetDimensions,
  sort,
  sortByColumn,
}) => {
  const containerRef = useRef();
  return (
    <div
      className="sheet__header"
      ref={containerRef}
      style={{ height: '32px' }}
    >
      <SheetRow
        columns={columns}
        isHeader
        locked={locked}
        lockedColumns={lockedColumns}
        offsetTop={0}
        renderCellFromSheetRow={(props) => (
          <SheetHeaderCell
            {...props}
            key={props.key}
            columnsState={columnsState}
            containerRef={containerRef}
            disableTableHeaderSorting={disableTableHeaderSorting}
            onResize={onResize}
            onToggleLock={onToggleLock}
            scrollX={scrollX}
            sheetDimensions={sheetDimensions}
            sort={sort}
            sortByColumn={sortByColumn}
          />
        )}
        row={columns.map(({ name }) => name)}
        scrollX={scrollX}
        sheetDimensions={sheetDimensions}
        y={-1}
      />
    </div>
  );
};

SheetHeader.propTypes = propTypes;

export default SheetHeader;
