import {
  DownloadPopover,
  Loader,
  Table,
  TableHeader,
  useColumnsState,
  useTableOptions,
  useTableBESort,
  useModalsContext,
} from 'components';
import {
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import React, { useMemo } from 'react';
import { DownloadType } from 'types';
import useRumTableRequest from './useRumTableRequest';
import { RumTableColumnKey } from './contants';
import { rumResourceTableColumns } from './rumEventTableColumns';
import useSortState from 'hooks/useSortState';
import useRumState from './hooks/useRumState';
import { RumEventType, RumEvent } from './types';
import RumDownloadModal from './RumDownloadModal';
import { RumResourceColumnKey } from './RumTableConstants';
import { extractStartAndTimeUnixFromEvent } from './RumFlameGraph/utils';
import { getIdFilter, isSortingDisabledForRumColumn } from './utils';

type Props = {
  activeRumEvent: RumEvent;
  rumState: ReturnType<typeof useRumState>;
  setActiveRumEvent: (rumEvent: RumEvent) => void;
};

const RumResourcesTable: React.FC<Props> = ({
  activeRumEvent,
  rumState,
  setActiveRumEvent,
}) => {
  const modals = useModalsContext();
  const { applicationFilter, idSearch } = rumState;

  const onRowClick = ({ row }: { row: RumEvent }) => {
    setActiveRumEvent(row);
  };

  const selectedFacetRangeByNameState = useSelectedFacetRangeByNameState();
  const selectedFacetValuesByNameState = useSelectedFacetValuesByNameState();

  const rumResourcesTableSortState = useSortState({
    urlStateKey: 'rumResourcesTableSort',
    initalState: {
      key: RumTableColumnKey.date,
      isAsc: false,
    },
  });
  const resourcesColumns = rumResourceTableColumns();

  const resourcesColumnsState = useColumnsState({
    columns: resourcesColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [RumResourceColumnKey.date]: 1,
        ['resource.id']: 1,
        ['resource.type']: 1,
        ['resource.duration']: 1,
        ['resource.size']: 1,
        ['resource.url_path']: 1,
        ['application.id']: 1,
        ['service']: 1,
        ['device.type']: 1,
        ['device.model']: 1,
        ['device.brand']: 1,
        ['version']: 1,
        ['session.id']: 1,
        ['view.id']: 1,
      },
    },
    key: 'rum-resource-table',
  });

  const dateFilter = useMemo(() => {
    const { startTimeUnix, endTimeUnix } =
      extractStartAndTimeUnixFromEvent(activeRumEvent);
    return { startTimeUnix, endTimeUnix };
  }, [activeRumEvent]);
  const idFilter = getIdFilter({
    activeRumEvent,
    shoulduseActionIds: true,
  });

  const rumEventsRequest = useRumTableRequest({
    applicationFilter,
    columnsState: resourcesColumnsState,
    date: dateFilter,
    eventType: RumEventType.RESOURCE,
    idSearch: '',
    rumTableSortState: rumResourcesTableSortState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    ...idFilter,
    activeRumEvent,
  });

  const columnsState = resourcesColumnsState;
  const columns = resourcesColumns;
  const rows = (rumEventsRequest.result && rumEventsRequest.result.data) || [];
  const tableOptions = useTableOptions({
    isTableColumnsSearchEnabled: true,
  });
  const tableSort = useTableBESort({
    columns,
    initialKey: RumTableColumnKey.date,
    rows: rows,
    onSortChange: ({ sortBy, sortOrder }) => {
      rumResourcesTableSortState.sortBy({ sortBy, sortOrder });
    },
  });

  const openRumDownloadModal = (downloadType: string) => {
    modals.push(
      <RumDownloadModal
        applicationFilter={applicationFilter}
        columnsState={columnsState}
        date={dateFilter}
        downloadType={downloadType}
        eventType={RumEventType.RESOURCE}
        idSearch={idSearch}
        rumTableSortState={rumResourcesTableSortState}
        selectedFacetRangeByNameState={selectedFacetRangeByNameState}
        selectedFacetValuesByNameState={selectedFacetValuesByNameState}
      />,
      true,
    );
  };

  return (
    <>
      <TableHeader
        columnsState={columnsState}
        tableOptions={tableOptions}
        tableOptionsClassName="rum-events__toolbar overflow-auto"
      >
        <div className="flex">
          <div className="ml-3">
            <DownloadPopover
              downloadTypes={[DownloadType.csv, DownloadType.json]}
              openModal={openRumDownloadModal}
            />
          </div>
        </div>
      </TableHeader>
      <Loader
        className="rum-events__table overflow-auto"
        isLoading={rumEventsRequest.isLoading}
        dataTestId="rum-events-table"
      >
        <Table
          className="table--padded table--bordered-cells font-robotoMono"
          columns={columnsState.renderedColumns}
          externalTableSort={tableSort}
          isFullWidth
          isResizingEnabled
          isSortingEnabled
          isSortingDisabledForColumn={isSortingDisabledForRumColumn}
          isStickyHeaderEnabled
          onRowClick={onRowClick}
          onScrollEnd={rumEventsRequest.onScrollEnd}
          rows={tableSort.sortedRows}
          tableKey="rum-reources-table"
        />
      </Loader>
    </>
  );
};

export default RumResourcesTable;
