import { useSearches } from 'hooks/useSearch';
import { useMemo } from 'react';
import { parseUrlParamByKey } from 'utils/url';

import { ConditionProps } from '../AlertsCreateCondition';
import { logsAlertValidatorForCreate } from '../AlertsCreateValidators';
import {
  getGraphQLQueryStringForTracesAlert,
  getQueriesAndFormulasFromAnnotations,
  getSelectedTracesQueryOrFormula,
} from '../AlertsCreateTraces/utils';
import { useAlertsCreate } from '../hooks';
import { aggregateEventTimeSeries, useEventsState } from 'screens/Events';
import { AlertType, RuleType, RuleProps } from '../types';

const useAlertsCreateEvents = ({
  alertsCreateState,
  rule,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  rule: RuleProps;
}) => {
  const eventsSearches = parseUrlParamByKey('alertsEventsSearches');
  const { queries: initialQueries } = getQueriesAndFormulasFromAnnotations(
    rule?.annotations,
  );
  const { searches } = useSearches({
    shouldWriteToUrl: false,
    initialSearches: eventsSearches || initialQueries,
  });
  const queries = useMemo(
    () => searches.map((search) => search.state),
    [searches],
  );
  const eventsState = useEventsState({ urlKeysToSync: [] });

  const {
    alertsDetails,
    addToast,
    date,
    setErrorHandling,
    setIsSaving,
    mutateAlertsRule,
  } = alertsCreateState;

  const createEventsAlertThreshold = async (condition: ConditionProps) => {
    const { queryKey } = condition;
    const selectedQueryOrFormula = getSelectedTracesQueryOrFormula(
      [],
      queries,
      queryKey,
    );
    if (!selectedQueryOrFormula) {
      addToast({ status: 'error', text: 'No query or formula selected' });
      return;
    }

    const { formulas: selectedFormulas, queries: selectedQueries } =
      selectedQueryOrFormula;

    const validation = logsAlertValidatorForCreate({
      alertsDetails,
      condition,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const queryType = queryKey.includes('Query') ? 'query' : 'formula';
    const extraData = {
      queryType,
      queries: selectedQueries,
      formulas: selectedFormulas,
      queryKey,
    };

    const queryStrings = await aggregateEventTimeSeries({
      date,
      queries: selectedQueries,
      returnQueryString: true,
      transformer: [],
    });

    const graphQLQueryString = getGraphQLQueryStringForTracesAlert({
      queryStrings,
      queryType,
    });

    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'events',
      promqlQuery: graphQLQueryString,
      ruleAnnotations: {
        ruleType: RuleType.EVENTS,
        alertType: AlertType.THRESHOLD,
        extraData: JSON.stringify(extraData),
      },
    });
  };

  return { createEventsAlertThreshold, searches, eventsState, queries };
};

export default useAlertsCreateEvents;
