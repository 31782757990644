import { CheckboxWithLabel, CopyButton, Key } from 'components';
import {
  useLogsPageStateContext,
  useServicesPageStateContext,
  useTracesPageStateContext,
} from 'context';
import {
  keyCodes,
  CloudLabelsBitmap,
  CoreLabelsBitmap,
  LogLabels,
  KubernetesLabelsBitmap,
  GCP,
  KINESIS,
} from 'kfuse-constants';
import dayjs from 'dayjs';
import {
  Filter,
  FilterType,
  Operator,
  useKeyExistsState,
  useRequest,
} from 'hooks';
import { get, has } from 'lodash';
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { DateSelection, LogEvent, PanelPosition, SelectedLog } from 'types';
import {
  formatLogLineParts,
  formatLogMessage,
  formatFpPatternFromLogLineParts,
  getFacetKey,
  parseLogFacetsFromLogEvent,
  defaultLogsQuery,
  tryJsonParse,
  extractPathNameFromURL,
} from 'utils';
import { useLogsState } from './hooks';
import LogsSelectedLogEvent from './LogsSelectedLogEvent';
import LogsSelectedLogAttributes from './LogsSelectedLogAttributes';
import LogsSelectedLogFingerprint from './LogsSelectedLogFingerprint';
import { FacetBase } from './types';
import { useFeatureFlag } from 'configcat-react';
import getServiceGroupLabels from 'requests/getServiceGroupLabels';

const getShowInContextFacets = (labels: { [key: string]: string }) => {
  const isFacetPresentInLabels = (facet: FacetBase) => has(labels, facet.name);

  if (get(labels, LogLabels.agent.name) === GCP) {
    return [LogLabels.agent, LogLabels.service].filter(isFacetPresentInLabels);
  }

  if (get(labels, LogLabels.agent.name) === KINESIS) {
    return [
      LogLabels.agent,
      LogLabels.logGroup,
      LogLabels.logStream,
      LogLabels.region,
      LogLabels.availabilityZone,
    ].filter(isFacetPresentInLabels);
  }

  if (has(labels, LogLabels.podName.name)) {
    return [
      LogLabels.kubeClusterName,
      LogLabels.kubeNamespace,
      LogLabels.podName,
      LogLabels.kubeContainerName,
      LogLabels.fileName,
      LogLabels.host,
    ].filter(isFacetPresentInLabels);
  }

  if (has(labels, LogLabels.dockerImage.name)) {
    return [
      LogLabels.host,
      LogLabels.dockerImage,
      LogLabels.containerName,
    ].filter(isFacetPresentInLabels);
  }

  // we assume this is vm
  return [
    LogLabels.region,
    LogLabels.availabilityZone,
    LogLabels.project,
    LogLabels.host,
    LogLabels.fileName,
  ].filter(isFacetPresentInLabels);
};

const mapLabelAsFacetName = (labelsMap) => (labelName) => ({
  name: labelName,
  type: 'STRING',
  value: labelsMap[labelName],
});

const filterLabelsInBitmap = (labelsMap, bitmap) =>
  Object.keys(labelsMap)
    .filter((labelName) => bitmap[labelName])
    .map(mapLabelAsFacetName(labelsMap));

const filterLabelsNotInBitmap = (labelsMap, bitmap) =>
  Object.keys(labelsMap)
    .filter((labelName) => !bitmap[labelName])
    .map(mapLabelAsFacetName(labelsMap));

type Props = {
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  logs: LogEvent[];
  logsState: ReturnType<typeof useLogsState>;
  selectedLog: SelectedLog;
  setRefreshFacetGroups: (component: string) => void;
  prev: () => void;
  next: () => void;
  shouldDisableFilterActions?: boolean;
};

const getPageStateToUseForNavigation = () => {
  const path = extractPathNameFromURL();

  if (path.startsWith('/logs')) {
    return 'logs';
  }

  if (path.startsWith('/apm/services')) {
    return 'services';
  }

  if (path.startsWith('/apm/traces/list')) {
    return 'traces';
  }

  return null;
};

const LogsSelectedLog = ({
  customColumnsState,
  logs = [],
  logsState,
  prev,
  next,
  selectedLog,
  shouldDisableFilterActions,
  setRefreshFacetGroups,
}: Props): ReactElement => {
  const { prettifyJsonToggle } = useLogsPageStateContext();

  const {
    value: isCustomerFilteringEnabled,
    loading: isCustomerFilteringConfigLoading,
  } = useFeatureFlag('isCustomerFilteringEnabled', false);
  const getServiceGroupLabelRequest = useRequest(
    getServiceGroupLabels,
    true,
    true,
  );

  const logsPageState = useLogsPageStateContext();
  const { tracesState: tracesPageState } = useTracesPageStateContext();
  const servicesPageState = useServicesPageStateContext();

  useEffect(() => {
    if (isCustomerFilteringEnabled) {
      const type = getPageStateToUseForNavigation();
      if (!type) {
        getServiceGroupLabelRequest.call();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerFilteringEnabled]);

  const selectedLabel = useMemo(() => {
    if (
      getServiceGroupLabelRequest.result &&
      getServiceGroupLabelRequest.result.length
    ) {
      const firstLabel = getServiceGroupLabelRequest.result[0];
      return firstLabel;
    }
    return null;
  }, [getServiceGroupLabelRequest.result]);

  const customerFilterToBeUsed = useMemo(() => {
    if (!isCustomerFilteringEnabled) {
      return null;
    }
    const type = getPageStateToUseForNavigation();

    if (type === 'logs') {
      return logsPageState.customerFilter;
    }

    if (type === 'services') {
      return servicesPageState.customerFilter;
    }
    if (type === 'traces') {
      return tracesPageState.customerFilter;
    }
    if (selectedLabel) {
      return { key: selectedLabel, value: 'All' };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerFilteringEnabled, selectedLabel]);

  const elementRef = useRef<HTMLDivElement>();
  const isSelectedLogIndexMatching =
    selectedLog.log === logs[selectedLog.index];
  const logEvent = selectedLog.log;
  const logEvents = isSelectedLogIndexMatching ? logs : [logEvent];
  const index = isSelectedLogIndexMatching ? selectedLog.index : 0;

  const parsedMessage = useMemo(
    () => tryJsonParse(logEvent.message),
    [logEvent],
  );

  const isMessageJson = useMemo(
    () =>
      Array.isArray(parsedMessage) ||
      (typeof parsedMessage === 'object' && parsedMessage !== null),
    [parsedMessage],
  );

  const formattedLogMessage = useMemo(
    () => {
      if (isMessageJson && prettifyJsonToggle.value) {
        return JSON.stringify(parsedMessage, null, 2);
      }

      return logEvent.message
        ? formatLogMessage(logEvent.message)
        : formatLogLineParts(logEvent.logLineParts);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMessageJson, logEvent, parsedMessage, prettifyJsonToggle.value],
  );

  const renderedMessage = useMemo(() => {
    if (isMessageJson && prettifyJsonToggle.value) {
      return parsedMessage;
    }

    return logEvent.message;
  }, [isMessageJson, logEvent, parsedMessage, prettifyJsonToggle.value]);

  const fpPattern = useMemo(
    () =>
      logEvent.fpPattern ||
      formatFpPatternFromLogLineParts(logEvent.logLineParts),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logEvent],
  );
  const { fpHash, labels, timestamp } = logEvent;

  const logFacets = useMemo(
    () => parseLogFacetsFromLogEvent(logEvent),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logEvent],
  );

  const showInContext = () => {
    const endTime = dayjs(logEvent.timestamp);
    const date: DateSelection = {
      startTimeUnix: endTime.subtract(5, 'minute').unix(),
      endTimeUnix: Math.ceil(endTime.valueOf() / 1000),
    };

    const searchParams = new URLSearchParams();

    searchParams.set('logsDate', JSON.stringify(date));

    const showInContextFacets = getShowInContextFacets(labels);

    const filters: Filter[] = showInContextFacets
      .filter((facet) => labels[facet.name])
      .map((facet) => {
        const facetKey = getFacetKey({
          component: facet.component,
          name: facet.name,
          type: 'string',
        });

        return {
          type: FilterType.selectedFacetValue,
          value: {
            facet: facetKey,
            operator: Operator.equal,
            values: {
              [labels[facet.name]]: 1,
            },
          },
        };
      }, {});

    if (customerFilterToBeUsed) {
      searchParams.set(
        'customerFilter',
        JSON.stringify(customerFilterToBeUsed),
      );
    }

    searchParams.set(
      'LogsMetricsQueries',
      JSON.stringify([{ ...defaultLogsQuery, filters }]),
    );

    searchParams.set('contextTimestamp', timestamp);
    searchParams.set('contextMessage', formattedLogMessage);

    window.open(`/#/logs?${searchParams.toString()}`, '_blank');
  };

  const kubernetesFacets = filterLabelsInBitmap(labels, KubernetesLabelsBitmap);
  const cloudFacets = filterLabelsInBitmap(labels, CloudLabelsBitmap);
  const additionalFacets = filterLabelsNotInBitmap(labels, {
    ...CloudLabelsBitmap,
    ...KubernetesLabelsBitmap,
    ...CoreLabelsBitmap,
  });

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.click();
    }
  }, []);

  return (
    <div className="logs__selected-log" ref={elementRef}>
      <div className="logs__selected-log__header flex">
        <div className="flex__left">
          <div className="logs__selected-log__navigator">
            <div className="text--weight-medium">{`${
              index + 1
            } of ${logEvents.length} logs`}</div>
            <div className="logs__selected-log__navigator__keys">
              <Key keyCode={keyCodes.LEFT} onClick={prev} text="←" />
              <Key keyCode={keyCodes.RIGHT} onClick={next} text="→" />
            </div>
          </div>
        </div>
        <button
          className="logs__selected-log__message__context-button button button--short"
          onClick={showInContext}
        >
          Show in context
        </button>
      </div>
      <div className="logs__selected-log__section">
        {isMessageJson ? (
          <CheckboxWithLabel
            className="mb-2"
            dataTestId="logs-selected-log-prettify-json-toggle"
            label="Prettify JSON"
            onChange={prettifyJsonToggle.toggle}
            value={prettifyJsonToggle.value}
          />
        ) : null}
        <div className="logs__selected-log__message">
          <div className="logs__selected-log__message__main">
            <LogsSelectedLogEvent
              customColumnsState={customColumnsState}
              isJSONPrettified={prettifyJsonToggle.value}
              logEvent={logEvent}
              logsState={logsState}
              renderedMessage={renderedMessage}
              shouldDisableFilterActions={shouldDisableFilterActions}
            />
            <div className="logs__selected-log__message__copy-button">
              <CopyButton text={formattedLogMessage} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="logs__selected-log__section">
          <div className="logs__selected-log__section__header">Fingerprint</div>
          {logEvent.fpHash ? (
            <LogsSelectedLogFingerprint
              fpHash={logEvent.fpHash}
              fpPattern={fpPattern}
              logsState={logsState}
              shouldDisableFilterActions={shouldDisableFilterActions}
            />
          ) : null}
        </div>
        <div className="logs__selected-log__section">
          <div className="logs__selected-log__section__header">Log Facets</div>
          <LogsSelectedLogAttributes
            customColumnsState={customColumnsState}
            areLogFacets={true}
            logFacets={logFacets}
            enableKeyExistsFilter
            fpHash={fpHash}
            logsState={logsState}
            searchPlaceholder={'Search logs facets'}
            showType
            shouldDisableFilterActions={shouldDisableFilterActions}
            source={labels.source}
            setRefreshFacetGroups={setRefreshFacetGroups}
          />
        </div>
        <div className="logs__selected-log__section">
          <div className="logs__selected-log__section__header">
            Kubernetes Labels
          </div>
          <LogsSelectedLogAttributes
            customColumnsState={customColumnsState}
            logsState={logsState}
            logFacets={kubernetesFacets}
            searchPlaceholder={'Search Kubernetes labels'}
            shouldDisableFilterActions={shouldDisableFilterActions}
            source="Kubernetes"
          />
        </div>
        <div className="logs__selected-log__section">
          <div className="logs__selected-log__section__header">
            Cloud Labels
          </div>
          <LogsSelectedLogAttributes
            customColumnsState={customColumnsState}
            logFacets={cloudFacets}
            logsState={logsState}
            searchPlaceholder={'Search Cloud Labels'}
            shouldDisableFilterActions={shouldDisableFilterActions}
            source="Cloud"
          />
        </div>
        <div className="logs__selected-log__section">
          <div className="logs__selected-log__section__header">
            Additional Labels
          </div>
          <LogsSelectedLogAttributes
            customColumnsState={customColumnsState}
            logFacets={additionalFacets}
            logsState={logsState}
            panelPosition={PanelPosition.TOP}
            searchPlaceholder={'Search Additional Labels'}
            shouldDisableFilterActions={shouldDisableFilterActions}
            source="Additional"
          />
        </div>
      </div>
    </div>
  );
};

export default LogsSelectedLog;
