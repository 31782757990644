import {
  Button,
  ConfirmationModal,
  LeftSidebar,
  Loader,
  Paginator,
  ProductTour,
  ShowSidebarTooltipButton,
  Table,
  TableSearch,
  TooltipTrigger,
  useColumnsState,
  useLeftSidebarState,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useToaster,
} from 'components';
import { useLogsPageStateContext } from 'context';
import dayjs from 'dayjs';
import { useLogsSavedQueriesPageState, useRequest } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { GrEdit, GrTrash } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { deleteWorkbook } from 'requests';
import LogsSavedViewsRenameModal from './LogsSavedViewsRenameModal';
import {
  convertSavedQueryToUrlSearchParams,
  filterDashboardList,
} from './utils';
import LogsSavedViewsSidebar from './LogsSavedViewsSidebar';
import { ValueCount } from 'types/generated';
import { QueryLangType } from 'types/kfuseQl';

type CellType = {
  row: {
    logsState: string;
    name: string;
  };
};

const columns = ({
  openDeleteModal,
  openRenameModal,
  openLogsSavedView,
}: {
  openDeleteModal: (workbook: any) => () => void;
  openRenameModal: (workbook: any) => () => void;
  openLogsSavedView: (workbook: any) => () => void;
}) => [
  {
    key: 'name',
    label: 'Name',
    renderCell: ({ row }: CellType) => {
      return (
        <Button onClick={openLogsSavedView(row)} variant="link" size="sm">
          {row.name}
        </Button>
      );
    },
  },
  {
    key: 'logsFilter',
    label: 'Logs Filter',
    renderCell: ({ row }: CellType) => {
      return row.logsState;
    },
  },
  {
    key: 'Folder',
    label: 'Folder',
    renderCell: ({ row }: CellType) => {
      return row.folderName || 'General';
    },
  },
  {
    key: 'Subfolder',
    label: 'Subfolder',
    renderCell: ({ row }: CellType) => {
      return row.subfolderName || 'General';
    },
  },
  {
    key: 'Free Text',
    label: 'Free Text',
    renderCell: ({ row }: CellType) => {
      return row.isFreeText ? 'Yes' : 'No';
    },
  },
  {
    key: 'createdAt',
    label: 'Created',
    renderCell: ({ value }) => dayjs(value).fromNow(),
  },
  {
    key: 'actions',
    label: 'Actions',
    renderCell: ({ row }: any) => (
      <div className="logs-saved-views__actions">
        <TooltipTrigger tooltip="Rename">
          <button onClick={openRenameModal(row)}>
            <GrEdit />
          </button>
        </TooltipTrigger>
        <TooltipTrigger tooltip="Delete">
          <button onClick={openDeleteModal(row)}>
            <GrTrash />
          </button>
        </TooltipTrigger>
      </div>
    ),
  },
];

type Props = {
  getWorkbooksRequest: ReturnType<typeof useRequest>;
};

const LogsSavedViews = ({ getWorkbooksRequest }: Props) => {
  const {
    dependenciesForWriteStateToUrl,
    selectedFacetValuesByNameState,
    writeStateToUrl,
  } = useLogsSavedQueriesPageState();
  const leftSidebarState = useLeftSidebarState('logs-saved-views');

  const modals = useModalsContext();
  const toastmaster = useToaster();
  const logsPageState = useLogsPageStateContext();
  const [_, setQueriesState] = logsPageState.queriesState;
  const navigate = useNavigate();

  const [filterProperties, setFilterProperties] = useState<{
    [key: string]: ValueCount[];
  }>({ foldername: [], subfoldername: [], freetext: [] });

  const getStateFacetValues = (facetName: string) => () => {
    return new Promise((resolve) => {
      resolve(filterProperties[facetName]);
    });
  };

  const filteredList = useMemo(() => {
    if (!getWorkbooksRequest.result) return [];
    const { filteredList, folders, subfolders, freeText } = filterDashboardList(
      getWorkbooksRequest.result as any[],
      selectedFacetValuesByNameState.state,
    );

    setFilterProperties({
      foldername: folders,
      subfoldername: subfolders,
      freetext: freeText,
    });
    return filteredList;
  }, [selectedFacetValuesByNameState.state, getWorkbooksRequest.result]);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  const openDeleteModal = (workbook) => () => {
    const onConfirm = () => {
      const onSuccess = () => {
        getWorkbooksRequest.call();
        modals.pop();
        toastmaster.addToast({
          status: 'success',
          text: `Successfully deleted '${workbook.name}'`,
        });
      };

      deleteWorkbook(workbook.id).then(onSuccess, () => {});
    };

    modals.push(
      <ConfirmationModal
        onCancel={() => modals.pop()}
        onConfirm={onConfirm}
        description={`Are you sure you want to delete '${workbook.name}'`}
        title={`Delete ${workbook.name}`}
      />,
    );
  };

  const openRenameModal = (workbook) => () => {
    modals.push(
      <LogsSavedViewsRenameModal
        getWorkbooksRequest={getWorkbooksRequest}
        workbook={workbook}
      />,
    );
  };

  const openLogsSavedView = (workbook) => () => {
    const { customerFilter, queries } = convertSavedQueryToUrlSearchParams(
      workbook.logsState,
    );
    if (workbook.isFreeText) {
      logsPageState.queryLangTypeState[1](
        QueryLangType.ADVANCED_KFUSEQL_SEARCH,
      );
    }
    if (customerFilter) {
      logsPageState.setCustomerFilter(customerFilter);
    }
    setQueriesState(queries);
    setTimeout(() => {
      navigate(`/logs`);
    }, 100);
  };

  const columnsState = useColumnsState({
    columns: columns({
      openDeleteModal,
      openRenameModal,
      openLogsSavedView,
    }),
    key: 'logs-saved-views',
    initialState: {
      selectedColumns: {
        name: 1,
        createdAt: 1,
        updatedAt: 1,
        actions: 1,
      },
    },
  });

  const tableSearch = useTableSearch({
    rows: filteredList,
  });

  const tableSort = useTableSort({
    columns: columns({
      openDeleteModal,
      openRenameModal,
      openLogsSavedView,
    }),
    rows: tableSearch.searchedRows,
  });

  const paginator = usePaginator({ rows: tableSort.sortedRows });

  return (
    <div className="alerts">
      <LeftSidebar
        className="alerts__left-sidebar"
        leftSidebarState={leftSidebarState}
      >
        <LogsSavedViewsSidebar
          filterProperties={filterProperties}
          request={getStateFacetValues}
          selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        />
      </LeftSidebar>
      <div className="alerts__main">
        <div className="alerts__header">
          <div className="alerts__header__left">
            {leftSidebarState.width === 0 ? (
              <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
            ) : null}
            <div className="alerts__header__title">Logs Saved Queries</div>
          </div>
        </div>
        <TableSearch
          className="dashboard__list__search mb-2"
          placeholder="Search saved queries"
          tableSearch={tableSearch}
          dataTestId="logs-saved-views-search"
        />
        <Loader isLoading={getWorkbooksRequest.isLoading}>
          <Table
            className="table--bordered table--bordered-cells table--padded"
            columns={columnsState.columns}
            rows={paginator.paginatedRows}
            externalTableSort={tableSort}
            isSortingEnabled
            dataTestId="logs-saved-views"
          />
          {<ProductTour />}
          <div className="table-footer">
            <Paginator paginator={paginator} />
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default LogsSavedViews;
