import { ChartGridV2 } from 'components';
import { getTimeDiffInSeconds } from 'components/TimeseriesExplorer/utils';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
} from 'react';
import {
  getLatencyDistribution,
  getServiceExecTimeBreakdown,
  traces,
} from 'requests';
import {
  ChartGridItem,
  ChartGridKeysState,
  ChartGridReferenceLine,
  ChartJsData,
  ChartLegendTableColumn,
  DateSelection,
  EventListProps,
  LatencyDistribution,
  PercentileSummary,
  SelectedFacetValuesByName,
  SpanFilter,
  TimeseriesToolProps,
  Trace,
} from 'types';
import {
  buildPromQLClausesFromSelectedFacetValuesByName,
  formatDurationNs,
  formatLatencyYAxisTick,
  getApdexFilters,
  getApmSumBy,
  multipleQueryRangeWithLabels,
  removeLabelsFromSelectedFacetValuesByName,
} from 'utils';
import ServiceTabChartGridVersion from './ServiceTabChartGridVersion';
import { SidebarState } from './types';
import { transFormFacetNames } from './utils';

const xAxisTickFormatter = (labels: number[]) => {
  return (labelIndex: number) => formatDurationNs(labels[labelIndex]);
};

const EXCLUDED_CHARTS_FOR_ASM = ['latencyDistribution', 'execTimeBreakdown'];

const calculatePercentileRange = (
  percentiles: PercentileSummary,
  barDurationInNs: number,
  index: number,
) => {
  const sortedPercentiles = Object.entries(percentiles).sort(
    (a, b) => a[1] - b[1],
  );

  for (let i = 0; i < sortedPercentiles.length; i++) {
    const [currentPercentile, currentPercentileValue] = sortedPercentiles[i];
    const [previousPercentile, previousPercentileValue] =
      i > 0 ? sortedPercentiles[i - 1] : ['0', 0];
    const isInRange =
      barDurationInNs * index < +currentPercentileValue &&
      barDurationInNs * (index + 1) > +previousPercentileValue;

    if (isInRange) {
      return `${previousPercentile}${currentPercentile ? `-${currentPercentile}` : ''}`;
    }
  }

  return null;
};

const formatLatencyDistribution = (
  result: LatencyDistribution & { durationSecs: number; numBuckets: number },
): ChartGridItem => {
  const data: ChartJsData[] = [];
  const referenceLines: ChartGridReferenceLine[] = [];
  const timestamps: number[] = [];

  const { buckets, percentiles } = result;
  const barDurationInNs =
    buckets.length > 1 ? buckets[1].bucketStart - buckets[0].bucketStart : 1;

  buckets.forEach((bucket, i) => {
    data.push({ 'Number of Requests': bucket.count });
    timestamps.push(bucket.bucketStart);

    const startValue = i === 0 ? 0 : buckets[i - 1].bucketStart;
    const endValue = bucket.bucketStart;

    Object.keys(percentiles).forEach((p: keyof typeof percentiles) => {
      const value = +percentiles[p];
      if (value >= startValue && value < endValue) {
        referenceLines.push({ x: i, label: p });
      }
    });
  });

  return {
    data,
    keys: ['Number of Requests'],
    referenceLines,
    renderTooltipTimestamp: ({ index }: { index: number }) => {
      const start = formatDurationNs(barDurationInNs * index, 1, 2);
      const end = formatDurationNs(barDurationInNs * (index + 1), 1, 2);
      const percentileRange = calculatePercentileRange(
        percentiles,
        barDurationInNs,
        index,
      );

      return (
        <div className="logs__timeline__chart__tooltip__label">
          <div>{`Duration: ${start} - ${end} (${formatDurationNs(barDurationInNs, 1, 2)})`}</div>
          {!!percentileRange && (
            <div>{`Percentile Range: ${percentileRange}`}</div>
          )}
        </div>
      );
    },
    timestamps,
  };
};

const getRows = ({
  chartGridKeysState,
  date,
  eventListRef,
  hideExecutionBreakDown,
  isServiceFromDatabasesList,
  kfSource,
  selectedFacetValuesByName,
  service,
  serviceDistinctLabels,
  serviceHash,
  setDate,
  setSidebar,
  shouldUseClientServiceName,
}: {
  chartGridKeysState: ChartGridKeysState;
  date: DateSelection;
  eventListRef?: MutableRefObject<EventListProps[] | null>;
  hideExecutionBreakDown?: boolean;
  isServiceFromDatabasesList?: boolean;
  kfSource?: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  service: string;
  serviceDistinctLabels: Record<string, string>;
  serviceHash?: string;
  setDate: (date: DateSelection) => void;
  setSidebar: Dispatch<SetStateAction<SidebarState>>;
  shouldUseClientServiceName?: boolean;
}) => {
  const [chartGridKeys, setChartGridKeys] = chartGridKeysState;
  const getKeyChangeProps = (key: string) => ({
    initialKey: chartGridKeys[key] || null,
    onKeyChange: (param: string) => {
      setChartGridKeys((prevSummaryChartGridInitialParams) => ({
        ...prevSummaryChartGridInitialParams,
        [key]: param,
      }));
    },
  });

  const serviceHashKey = shouldUseClientServiceName
    ? 'client_service_hash'
    : 'service_hash';
  const filters = buildPromQLClausesFromSelectedFacetValuesByName({
    selectedFacetValuesByName,
  });
  const filtersString = filters ? `,${filters}` : '';

  const onSelection = (newDate) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };

  const { endTimeUnix, startTimeUnix } = date;
  const sumBy = getApmSumBy([serviceHashKey]);

  const getTraceListQuery = () => {
    return traces({
      date,
      facetRegex: [],
      keyExists: {},
      limit: 5,
      pageNum: 1,
      selectedFacetValuesByName: {
        [isServiceFromDatabasesList
          ? 'kf_database_service_hash'
          : 'service_hash']: { [serviceHash]: 1 },
        ...selectedFacetValuesByName,
      },
      selectedFacetRangeByName: {},
      selectedHcFacetValuesByName: {},
      spanFilter: SpanFilter.serviceEntrySpans,
      sortBy: 'duration',
      sortOrder: 'Desc',
      traceIdSearch: '',
    });
  };

  const chartRows = [
    [
      {
        ...getKeyChangeProps('requests'),
        charts: [
          {
            key: 'requestsPerSecond',
            chartType: 'bar',
            colorMap: { Hits: '#26BBF0', Errors: '#da545b' },
            enableAnomaly: true,
            enableExportToDashboard: true,
            label: 'Requests/s',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}]))`,
              ],
              [['Hits']],
            ),
          },
          {
            chartType: 'bar',
            key: 'requestsAndErrors',
            colorMap: { Hits: '#26BBF0' },
            enableAnomaly: true,
            enableExportToDashboard: true,
            label: 'Requests',
            labels: [() => 'Hits'],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds, stepInMs }) =>
                  `sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) * ${Math.round(
                    stepInMs / 1000,
                  )}`,
              ],
              [['Hits']],
            ),
          },
        ],
      },
      {
        ...getKeyChangeProps('errors'),
        charts: [
          {
            key: 'rate',
            chartType: 'bar',
            colorMap: { Errors: '#da545b' },
            enableAnomaly: true,
            enableExportToDashboard: true,
            label: 'Errors/sec',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `(sum by (${sumBy}) (rate(edge_latency_count{error="true",${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}]))) OR (sum by (${serviceHashKey}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}]))) * 0`,
              ],
              [['Errors']],
            ),
          },
          {
            key: 'increase',
            chartType: 'bar',
            colorMap: { Errors: '#da545b' },
            enableAnomaly: true,
            enableExportToDashboard: true,
            label: 'Errors',
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds, stepInMs }) =>
                  `((sum by (${sumBy}) (rate(edge_latency_count{error="true",${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) * ${Math.round(
                    stepInMs / 1000,
                  )})) OR ((sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}]))) * 0)`,
              ],
              [['Errors']],
            ),
          },
          {
            key: 'percent',
            chartType: 'bar',
            colorMap: { Errors: '#da545b' },
            enableAnomaly: true,
            enableExportToDashboard: true,
            label: 'Errors %',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds, stepInMs }) =>
                  `(
                    (sum by (${sumBy}) (rate(edge_latency_count{error="true",${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])))
                    OR
                    (sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) * 0)
                ) * 100.0
                /
                  sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}]))
                `,
              ],
              [['Errors']],
            ),
          },
        ],
      },
    ],
    [
      {
        ...getKeyChangeProps('latency'),
        charts: [
          {
            key: 'p99latency',
            colorMap: {
              p99: '#003f5c',
              p95: '#58508d',
              p90: '#bc5090',
              p75: '#ff6361',
              p50: '#ffa600',
            },
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'p99 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  return `histogram_quantile(0.99, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) by (${sumBy}, le))`;
                },
              ],
              [['p99']],
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
            unit: 'ms',
          },
          {
            key: 'p95latency',
            colorMap: {
              p99: '#003f5c',
              p95: '#58508d',
              p90: '#bc5090',
              p75: '#ff6361',
              p50: '#ffa600',
            },
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'p95 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `histogram_quantile(0.95, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) by (${sumBy}, le))`,
              ],
              [['p95']],
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
            unit: 'ms',
          },
          {
            key: 'p90latency',
            colorMap: {
              p99: '#003f5c',
              p95: '#58508d',
              p90: '#bc5090',
              p75: '#ff6361',
              p50: '#ffa600',
            },
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'p90 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `histogram_quantile(0.90, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) by (${sumBy}, le))`,
              ],
              [['p90']],
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
            unit: 'ms',
          },
          {
            key: 'p75latency',
            colorMap: {
              p99: '#003f5c',
              p95: '#58508d',
              p90: '#bc5090',
              p75: '#ff6361',
              p50: '#ffa600',
            },
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'p75 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `histogram_quantile(0.75, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) by (${sumBy}, le))`,
              ],
              [['p75']],
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
            unit: 'ms',
          },
          {
            key: 'p50latency',
            colorMap: {
              p99: '#003f5c',
              p95: '#58508d',
              p90: '#bc5090',
              p75: '#ff6361',
              p50: '#ffa600',
            },
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'p50 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `histogram_quantile(0.50, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) by (${sumBy}, le))`,
              ],
              [['p50']],
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
            unit: 'ms',
          },
          {
            key: 'avgLatency',
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Average Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            libraryType: 'uplot',
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `sum by (${sumBy}) (rate(edge_latency_sum{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}])) / sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}]))`,
              ],
              [['Average Latency']],
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
            unit: 'ms',
          },
          {
            key: 'min',
            yAxisTickFormatter: formatLatencyYAxisTick,
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            label: 'Min Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `min by (${sumBy}) (min_over_time(edge_latency_min{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}]))`,
              ],
              [['Min Latency']],
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
            unit: 'ms',
          },
          {
            key: 'max',
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Max Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) =>
                  `max by (${sumBy}) (max_over_time(edge_latency_max{${serviceHashKey}="${serviceHash}"${filtersString}}[${rateIntervalSeconds}]))`,
              ],
              [['Max Latency']],
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
            unit: 'ms',
          },
          {
            key: 'APDEX',
            enableAnomaly: true,
            enableExportToDashboard: true,
            enableSpanOverlay: !isServiceFromDatabasesList,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'APDEX',
            libraryType: 'uplot',
            onSelection,
            onSetSidebar: (activeTrace: Trace) =>
              setSidebar({ activeTrace, isOpen: true }),
            renderComponent: (props: TimeseriesToolProps) => (
              <ServiceTabChartGridVersion
                eventListRef={eventListRef}
                timeseriesProps={props}
              />
            ),
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const {
                    tolerableFilter,
                    satisfiedFilter,
                    denominatorFilter,
                  } = getApdexFilters([`${serviceHashKey}="${serviceHash}"`]);
                  return `clamp_max((sum by (${sumBy}) (increase(edge_latency_bucket{${tolerableFilter}${filtersString}}[${rateIntervalSeconds}]))+sum by (${sumBy}) (increase(edge_latency_bucket{${satisfiedFilter}${filtersString}}[${rateIntervalSeconds}])))/(2*sum by (${sumBy}) (increase(edge_latency_count{${denominatorFilter}${filtersString}}[${rateIntervalSeconds}]))),1)`;
                },
              ],
              [['APDEX']],
              true,
            ),
            queryEventOverlay: isServiceFromDatabasesList
              ? undefined
              : getTraceListQuery,
          },
          isServiceFromDatabasesList
            ? null
            : {
                key: 'latencyDistribution',
                allowYAxisDecimals: false,
                chartType: 'bar',
                datasetsFormatter: formatLatencyDistribution,
                disabledCompare: true,
                disableExplore: true,
                enableLogScaleByDefault: true,
                instant: true,
                label: 'Latency Distribution',
                skipToast: true,
                query: (args) =>
                  getLatencyDistribution({
                    ...args,
                    serviceHash,
                    selectedFacetValuesByName: transFormFacetNames({
                      selectedFacetValuesByName:
                        removeLabelsFromSelectedFacetValuesByName({
                          selectedFacetValuesByName,
                          serviceDistinctLabels,
                        }),
                      ignorePodNameAndSpanType: true,
                    }),
                  }).then(formatLatencyDistribution),
                xAxisTickFormatter,
              },
        ].filter(Boolean),
      },
      {
        ...getKeyChangeProps('execTimeBreakdown'),
        charts: [
          {
            key: 'execTimeBreakdown',
            chartType: 'area',
            disabledCompare: true,
            disableExplore: true,
            enablePercentageChart: true,
            instant: false,
            label: 'Execution Time Breakdown',
            libraryType: 'uplot',
            onSelection: (newDate) => {
              if (typeof newDate === 'object') {
                setDate(newDate);
              } else {
                const { startTimeUnix, endTimeUnix } = newDate;
                setDate({ startTimeUnix, endTimeUnix });
              }
            },
            renderComponent: (props: TimeseriesToolProps) => {
              const hrInSecs = getTimeDiffInSeconds('1h');
              const show1HrWarning = endTimeUnix - startTimeUnix > hrInSecs;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                  infoMessage={
                    show1HrWarning
                      ? 'Execution Time Breakdown shown is for the 1hr from the end time'
                      : null
                  }
                />
              );
            },
            query: (args) =>
              getServiceExecTimeBreakdown({
                ...args,
                labelFormatter: (GroupVal) => {
                  return Object.keys(GroupVal)
                    .filter(
                      (k) =>
                        k !== 'service_name' &&
                        GroupVal[k] !== 'null' &&
                        GroupVal[k] !== '',
                    )
                    .map((k) => {
                      if (k === 'target_service') {
                        const nameToUse =
                          GroupVal[k] === 'UNKNOWN' ? 'EXTERNAL' : GroupVal[k];
                        return `service: ${nameToUse}`;
                      }
                      return `${k}: ${GroupVal[k]}`;
                    })
                    .join(', ');
                },
                serviceName: service,
                serviceHash: serviceHash,
                selectedFacetValuesByName: transFormFacetNames({
                  selectedFacetValuesByName:
                    removeLabelsFromSelectedFacetValuesByName({
                      serviceDistinctLabels,
                      selectedFacetValuesByName,
                    }),
                  ignorePodNameAndSpanType: true,
                }),
              }),
            unit: 'ns',
          },
        ],
        shouldShowTop5: false,
      },
    ],
  ];

  if (kfSource === 'knight') {
    const knightChartRows = [
      chartRows[0],
      chartRows[1].map((row) => {
        return {
          ...row,
          charts: row.charts.filter(
            (chart) => !EXCLUDED_CHARTS_FOR_ASM.includes(chart.key),
          ),
        };
      }),
    ];
    return knightChartRows;
  }

  if (hideExecutionBreakDown) {
    return [
      chartRows[0],
      chartRows[1].map((row) => {
        return {
          ...row,
          charts: row.charts.filter(
            (chart) => chart.key !== 'execTimeBreakdown',
          ),
        };
      }),
    ];
  }

  return chartRows;
};

type Props = {
  chartGridKeysState: ChartGridKeysState;
  date: DateSelection;
  eventListRef?: MutableRefObject<EventListProps[] | null>;
  hideExecutionBreakDown?: boolean;
  isServiceFromDatabasesList?: boolean;
  kfSource?: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  service: string;
  serviceDistinctLabels: Record<string, string>;
  serviceHash: string;
  setDate: (date: DateSelection) => void;
  setSidebar: Dispatch<SetStateAction<SidebarState>>;
  shouldUseClientServiceName?: boolean;
};

const ServiceSummaryGrid = ({
  chartGridKeysState,
  date,
  eventListRef,
  hideExecutionBreakDown,
  isServiceFromDatabasesList,
  kfSource,
  selectedFacetValuesByName,
  service,
  serviceDistinctLabels,
  serviceHash,
  setDate,
  setSidebar,
  shouldUseClientServiceName,
}: Props) => {
  const rows = useMemo(
    () =>
      getRows({
        date,
        eventListRef,
        hideExecutionBreakDown,
        isServiceFromDatabasesList,
        kfSource,
        selectedFacetValuesByName,
        service,
        serviceDistinctLabels,
        serviceHash,
        setDate,
        setSidebar,
        shouldUseClientServiceName,
        chartGridKeysState,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, selectedFacetValuesByName, service, serviceHash],
  );
  return <ChartGridV2.ChartGrid date={date} rows={rows} />;
};

export default ServiceSummaryGrid;
