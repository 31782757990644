import {
  DashboardPanelProps,
  DashboardTemplateValueProps,
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
} from 'types';
import {
  getMappedToAdvanceFunctionParams,
  getMetricsExplorerDefaultQuery,
  parsePromqlAndBuildQuery,
} from 'utils';
import { transformPromql } from '../../utils';

const getNextRefIdByIndex = (lastRef: string, index: number) => {
  if (!lastRef) return 'a';
  const refIdChar = lastRef.charCodeAt(0);
  return String.fromCharCode(refIdChar + index);
};

/**
 * Convert promql to query builder format for dashboard edit metrics
 * @param targets - DashboardPanelProps['targets']
 * @returns
 */
export const getQueryBuilderTransformedPromql = ({
  panel,
}: {
  panel: DashboardPanelProps;
}): { queries: ExplorerQueryProps[]; formulas: FormulaProps[] } => {
  const { targets, fieldConfig } = panel;
  const newQueries: ExplorerQueryProps[] = [];
  const newFormulas: FormulaProps[] = [];

  const advanceFunctionOption = getMappedToAdvanceFunctionParams(
    fieldConfig?.defaults?.mappings,
  );

  targets.forEach(({ expr, hide, legendFormat }) => {
    const { queries, formulas } = parsePromqlAndBuildQuery([expr]);

    if (queries.length === 0) {
      const defaultQuery = getMetricsExplorerDefaultQuery('');
      defaultQuery.promql = expr;
      defaultQuery.showInput = true;
      queries.push(defaultQuery);
    }

    const lastRef = newQueries[newQueries.length - 1]?.queryKey;
    queries.map((query, idx: number) => {
      const queryLastRef = queries[idx - 1]?.queryKey;
      const refId = getNextRefIdByIndex(queryLastRef || lastRef, idx + 1);
      query.isActive = hide ? false : query.isActive;
      query.queryKey = refId.toLowerCase();
      query.legendFormat =
        !legendFormat || legendFormat === '__auto' ? undefined : legendFormat;

      if (query.isActive) {
        query.isActive = formulas.length === 0;
      }

      if (advanceFunctionOption) {
        if (!query.functions) {
          query.functions = [];
        }
        query.functions.push(advanceFunctionOption);
      }
    });
    formulas.map((formula) => {
      formula.isActive = hide ? false : formula.isActive;
    });
    newQueries.push(...queries);
    newFormulas.push(...formulas);
  });

  return { queries: newQueries, formulas: newFormulas };
};

export const getTransformedForQueryBuilder = ({
  date,
  promql,
  templateValues,
  width,
}: {
  date: DateSelection;
  promql: string;
  templateValues?: DashboardTemplateValueProps;
  width?: number;
}): string | string[] => {
  if (Array.isArray(promql)) {
    return promql.map((p) => {
      const decodedPromql = decodeURIComponent(p);
      const transformedPromql = transformPromql({
        date,
        promql: decodedPromql,
        templateValues,
        width,
        chartType: undefined,
      });
      return decodeURIComponent(transformedPromql);
    });
  }

  const decodedPromql = decodeURIComponent(promql);
  const transformedPromql = transformPromql({
    date,
    promql: decodedPromql,
    templateValues,
    width,
    chartType: undefined,
  });

  return decodeURIComponent(transformedPromql);
};
