import { SearchState, useRequest } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { DateSelection, DashboardPanelType, QueryDataProps } from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import {
  combineRangeQueryData,
  eventsDataTransformer,
} from 'utils/DataTransformer';

import { AlertsChart } from '../AlertsChart';
import { ConditionProps } from '../AlertsCreateCondition';
import { aggregateEventTimeSeries } from 'screens/Events';

const AlertsCreateEventsChart = ({
  condition,
  date,
  queries,
}: {
  condition: ConditionProps;
  date: DateSelection;
  queries: SearchState[];
}): ReactElement => {
  const eventQueryRequest = useRequest(aggregateEventTimeSeries, true, true);

  const loadEventTimeseries = () => {
    const baseTransformers = eventsDataTransformer();
    eventQueryRequest.call({
      date,
      dataFormat: DashboardPanelType.TIMESERIES,
      instant: false,
      queries,
      transformer: baseTransformers,
    });
  };

  useDebouncedEffect(
    () => {
      loadEventTimeseries();
    },
    { timeout: 100, ignoreInitialCall: false },
    [date, queries],
  );

  const { queriesStatus } = useMemo(() => {
    const queriesStatus = queries.map((query) => {
      return { isActive: query.isActive, queryKey: query.queryKey };
    });

    return { queriesStatus };
  }, [queries]);
  const { result: eventQueryData, refId } = useMemo(() => {
    const result: QueryDataProps = {};
    let refId = '';
    if (!eventQueryRequest.result) {
      queriesStatus.forEach(({ queryKey }) => {
        const queryId = `query_${queryKey}`;
        result[queryId] = {
          isLoading: eventQueryRequest.isLoading,
          meta: null,
        };
      });
      return { result, refId };
    }

    eventQueryRequest.result.forEach((data) => {
      const { meta } = data;
      const queryId = `query_${meta.refId}`;
      result[queryId] = {
        isLoading: eventQueryRequest.isLoading,
        meta,
        range: data.data,
      };
      refId = meta.refId;
    });

    return { result, refId };
  }, [eventQueryRequest.isLoading, eventQueryRequest.result, queriesStatus]);

  const combinedData = useMemo(() => {
    if (!eventQueryData) return;
    return combineRangeQueryData({
      formulas: [],
      queries: queriesStatus,
      queryData: eventQueryData,
      combineTimestamp: true,
      combineLabelWithQueryKey: true,
    });
  }, [eventQueryData, queriesStatus]);

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={condition.value ? Number(condition.value) : undefined}
      date={date}
      isChartCompact={false}
      enableEvaluationLine={true}
      isLoading={combinedData.isLoading}
      queryData={combinedData}
      unit={'number'}
    />
  );
};

export default AlertsCreateEventsChart;
