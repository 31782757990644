import {
  DownloadPopover,
  Loader,
  Table,
  TableHeader,
  useColumnsState,
  useTableOptions,
  useTableBESort,
  useModalsContext,
  Button,
} from 'components';
import {
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import { startCase } from 'lodash';
import React, { useMemo, useState } from 'react';
import { DownloadType } from 'types';
import useRumTableRequest from './useRumTableRequest';
import { RumTableColumnKey } from './contants';
import { rumErrorTableColumns } from './rumEventTableColumns';
import useRumState from './hooks/useRumState';
import { RumEventType, RumEvent, RumErrorTab } from './types';
import useSortState from 'hooks/useSortState';
import RumDownloadModal from './RumDownloadModal';
import { extractStartAndTimeUnixFromEvent } from './RumFlameGraph/utils';
import {
  errorDefaultColumns,
  getIdFilter,
  isSortingDisabledForRumColumn,
} from './utils';
import RumEventDrawerErrorGroup from './RumEventDrawerErrorGroup';
import classNames from 'classnames';

type Props = {
  activeRumEvent: RumEvent;
  rumState: ReturnType<typeof useRumState>;
  setActiveRumEvent: (rumEvent: RumEvent) => void;
};

const RumEventDrawerErrors: React.FC<Props> = ({
  activeRumEvent,
  rumState,
  setActiveRumEvent,
}) => {
  const [errorTab, setErrorTab] = useState(RumErrorTab.ERROR);
  const modals = useModalsContext();
  const { applicationFilter, idSearch } = rumState;

  const selectedFacetRangeByNameState = useSelectedFacetRangeByNameState();
  const selectedFacetValuesByNameState = useSelectedFacetValuesByNameState();

  const onRowClick = ({ row }: { row: RumEvent }) => {
    setActiveRumEvent(row);
  };
  const rumErrorsTableSortState = useSortState({
    urlStateKey: 'rumErrorsTableSort',
    initalState: {
      key: RumTableColumnKey.date,
      isAsc: false,
    },
  });
  const idFilter = getIdFilter({
    activeRumEvent,
    shoulduseActionIds: true,
  });

  const errorColumns = rumErrorTableColumns();
  const errorsColumnsState = useColumnsState({
    columns: errorColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        ...errorDefaultColumns,
      },
    },
    key: 'rum-error-table-event-drawer',
  });

  const dateFilter = useMemo(() => {
    const { startTimeUnix, endTimeUnix } =
      extractStartAndTimeUnixFromEvent(activeRumEvent);
    return { startTimeUnix, endTimeUnix };
  }, [activeRumEvent]);

  const rumEventsRequest = useRumTableRequest({
    applicationFilter,
    columnsState: errorsColumnsState,
    date: dateFilter,
    eventType: RumEventType.ERROR,
    idSearch: '',
    rumTableSortState: rumErrorsTableSortState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    ...idFilter,
  });

  const columnsState = errorsColumnsState;
  const columns = errorColumns;
  const rows = (rumEventsRequest.result && rumEventsRequest.result.data) || [];
  const tableOptions = useTableOptions({
    isTableColumnsSearchEnabled: true,
  });
  const tableSort = useTableBESort({
    columns,
    initialKey: RumTableColumnKey.date,
    rows: rows,
    onSortChange: ({ sortBy, sortOrder }) => {
      rumErrorsTableSortState.sortBy({ sortBy, sortOrder });
    },
  });

  const openRumDownloadModal = (downloadType: string) => {
    modals.push(
      <RumDownloadModal
        applicationFilter={applicationFilter}
        columnsState={columnsState}
        date={dateFilter}
        downloadType={downloadType}
        eventType={RumEventType.ERROR}
        idSearch={idSearch}
        rumTableSortState={rumErrorsTableSortState}
        selectedFacetRangeByNameState={selectedFacetRangeByNameState}
        selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        {...idFilter}
      />,
      true,
    );
  };

  return (
    <>
      <div className="m-2">
        <div className="rum-search__visualize button-group">
          {[RumErrorTab.ERROR, RumErrorTab.ERROR_GROUP].map((tab, index) => (
            <Button
              variant="secondary"
              className={classNames({
                'rum-error-group': true,
                'button-group__item': true,
                'rum-error-group--active': tab === errorTab,
                'rum-error-group__first-item': index === 0,
                'rum-error-group__last-item': index !== 0,
              })}
              key={tab}
              onClick={() => setErrorTab(tab)}
            >
              {startCase(tab)}
            </Button>
          ))}
        </div>
      </div>
      {errorTab === RumErrorTab.ERROR_GROUP ? (
        <RumEventDrawerErrorGroup
          applicationFilter={applicationFilter}
          dateFilter={dateFilter}
          idFilter={idFilter}
        />
      ) : (
        <>
          <TableHeader
            columnsState={columnsState}
            tableOptions={tableOptions}
            tableOptionsClassName="rum-events__toolbar overflow-auto"
          >
            <div className="flex">
              <div className="ml-3">
                <DownloadPopover
                  downloadTypes={[DownloadType.csv, DownloadType.json]}
                  openModal={openRumDownloadModal}
                />
              </div>
            </div>
          </TableHeader>
          <Loader
            className="rum-events__table overflow-auto"
            isLoading={rumEventsRequest.isLoading}
            dataTestId="rum-events-table"
          >
            <Table
              className="table--padded table--bordered-cells font-robotoMono"
              columns={columnsState.renderedColumns}
              externalTableSort={tableSort}
              isFullWidth
              isResizingEnabled
              isSortingEnabled
              isSortingDisabledForColumn={isSortingDisabledForRumColumn}
              isStickyHeaderEnabled
              onScrollEnd={rumEventsRequest.onScrollEnd}
              onRowClick={onRowClick}
              rows={tableSort.sortedRows}
              tableKey="rum-errors-table-event-drawer"
            />
          </Loader>
        </>
      )}
    </>
  );
};

export default RumEventDrawerErrors;
