import { ChartGridV2 } from 'components';
import { useSelectedFacetValuesByNameState } from 'hooks';
import React, { MutableRefObject, useMemo } from 'react';
import {
  ChartGridKeysState,
  ChartLegendTableColumn,
  DateSelection,
  EventListProps,
  SelectedFacetValuesByName,
  Service,
  TimeseriesToolProps,
} from 'types';
import {
  buildPromQLClausesFromSelectedFacetValuesByName,
  formatLatencyYAxisTick,
  getApmSumBy,
  getServiceNamePropertyToSumBy,
  multipleQueryRangeWithLabels,
} from 'utils';

import ServiceTabChartGridVersion from './ServiceTabChartGridVersion';
import { Property } from './types';

type GetRowsArgs = {
  chartGridKeysState: ChartGridKeysState;
  colorMap: { [key: string]: string };
  eventListRef: MutableRefObject<EventListProps[] | null>;
  isDownStream?: boolean;
  property: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceByHash?: Record<string, Service>;
  serviceHash?: string;
  setDate: (date: DateSelection) => void;
};

const getMetricKeyHandler =
  ({ metricKey, serviceByHash }) =>
  (metric: Record<string, any>): string => {
    if (metricKey === 'service_hash' || metricKey === 'client_service_hash') {
      const serviceHash = metric[metricKey];
      if (serviceHash) {
        const service = serviceByHash[serviceHash];
        if (service) {
          return service.name;
        }

        const serviceNamePropertyToSumBy =
          getServiceNamePropertyToSumBy(metricKey);

        if (serviceNamePropertyToSumBy && metric[serviceNamePropertyToSumBy]) {
          return metric[serviceNamePropertyToSumBy];
        }
      }
    }

    if (metric[metricKey] === undefined) {
      return 'undefined';
    }

    return metric[metricKey];
  };

const getRows = ({
  chartGridKeysState,
  colorMap,
  eventListRef,
  isDownStream,
  property,
  selectedFacetValuesByName,
  serviceByHash,
  serviceHash,
  setDate,
}: GetRowsArgs) => {
  const [chartGridKeys, setChartGridKeys] = chartGridKeysState;
  const getKeyChangeProps = (keySuffix: string) => {
    const key = `service-tab-chart-grid:${keySuffix}`;
    return {
      initialKey: chartGridKeys[key] || null,
      onKeyChange: (param: string) => {
        setChartGridKeys((prevSummaryChartGridInitialParams) => ({
          ...prevSummaryChartGridInitialParams,
          [key]: param,
        }));
      },
    };
  };

  const serviceHashKey = isDownStream ? 'client_service_hash' : 'service_hash';
  const formValueFilters = buildPromQLClausesFromSelectedFacetValuesByName({
    selectedFacetValuesByName,
    isDownStream,
  });
  const formValueFiltersString = formValueFilters ? `,${formValueFilters}` : '';
  const onSelection = (newDate: any) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };

  const metricKey =
    property === Property.clientServiceName && isDownStream
      ? 'service_name'
      : property;

  const serviceTabsRows = [
    [
      {
        ...getKeyChangeProps('requests'),
        charts: [
          {
            key: 'requestsPerSecond',
            chartType: 'bar',
            colorMap,
            enableExportToDashboard: true,
            label: 'Requests/s',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}]))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
          },
          {
            key: 'requests',
            chartType: 'bar',
            colorMap,
            enableExportToDashboard: true,
            label: 'Requests',
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds, stepInMs }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) * ${Math.round(
                    stepInMs / 1000,
                  )}`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
          },
        ],
      },
      {
        ...getKeyChangeProps('latency'),
        charts: [
          {
            key: 'p99Latency',
            colorMap,
            enableExportToDashboard: true,
            label: 'p99 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    'le',
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `histogram_quantile(0.99, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) by (${sumBy}))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
            yAxisTickFormatter: formatLatencyYAxisTick,
            unit: 'ms',
          },
          {
            key: 'p95Latency',
            colorMap,
            enableExportToDashboard: true,
            label: 'p95 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    'le',
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `histogram_quantile(0.95, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) by (${sumBy}))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
            yAxisTickFormatter: formatLatencyYAxisTick,
            unit: 'ms',
          },
          {
            key: 'p90Latency',
            colorMap,
            enableExportToDashboard: true,
            label: 'p90 Latency',
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    'le',
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `histogram_quantile(0.90, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) by (${sumBy}))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
            yAxisTickFormatter: formatLatencyYAxisTick,
            unit: 'ms',
          },
          {
            key: 'p75Latency',
            colorMap,
            enableExportToDashboard: true,
            label: 'p75 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    'le',
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `histogram_quantile(0.75, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) by (${sumBy}))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
            yAxisTickFormatter: formatLatencyYAxisTick,
            unit: 'ms',
          },
          {
            key: 'p50Latency',
            colorMap,
            enableExportToDashboard: true,
            label: 'p50 Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    'le',
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `histogram_quantile(0.50, sum(rate(edge_latency_bucket{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) by (${sumBy}))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
            yAxisTickFormatter: formatLatencyYAxisTick,
            unit: 'ms',
          },
          {
            key: 'avgLatency',
            colorMap,
            enableExportToDashboard: true,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Average Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `sum by (${sumBy}) (rate(edge_latency_sum{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) / sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}]))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
            unit: 'ms',
          },
          {
            key: 'min',
            colorMap,
            enableExportToDashboard: true,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Min Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `min by (${sumBy}) (min_over_time(edge_latency_min{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}]))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
            unit: 'ms',
          },
          {
            key: 'max',
            colorMap,
            enableExportToDashboard: true,
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Max Latency',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `max by (${sumBy}) (max_over_time(edge_latency_max{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}]))`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
            unit: 'ms',
          },
        ],
      },
      {
        ...getKeyChangeProps('errors'),
        charts: [
          {
            key: 'errorsPerSecond',
            colorMap,
            enableExportToDashboard: true,
            chartType: 'bar',
            label: 'Errors/sec',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `(sum by (${sumBy}) (rate(edge_latency_count{error="true",${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}]))) OR (sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) * 0)`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
          },
          {
            key: 'errors',
            colorMap,
            enableExportToDashboard: true,
            chartType: 'bar',
            label: 'Errors',
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds, stepInMs }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `(sum by (${sumBy}) (rate(edge_latency_count{error="true",${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) * ${Math.round(
                    stepInMs / 1000,
                  )}) OR (sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) * 0)`;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
          },
          {
            key: 'errorsPercent',
            colorMap,
            enableExportToDashboard: true,
            chartType: 'bar',
            label: 'Errors %',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            renderComponent: (props: TimeseriesToolProps) => {
              if (property !== Property.version) return;
              return (
                <ServiceTabChartGridVersion
                  eventListRef={eventListRef}
                  timeseriesProps={props}
                />
              );
            },
            query: multipleQueryRangeWithLabels(
              [
                ({ rateIntervalSeconds, stepInMs }) => {
                  const serviceNamePropertyToSumBy =
                    getServiceNamePropertyToSumBy(property);
                  const sumBy = getApmSumBy([
                    property,
                    ...(serviceNamePropertyToSumBy
                      ? [serviceNamePropertyToSumBy]
                      : []),
                  ]);
                  return `(
                    (sum by (${sumBy}) (rate(edge_latency_count{error="true",${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])))
                    OR
                    (sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}])) * 0)
                ) * 100.0
                /
                  sum by (${sumBy}) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${formValueFiltersString}}[${rateIntervalSeconds}]))
                `;
                },
              ],
              [
                [
                  getMetricKeyHandler({ metricKey, serviceByHash }),
                  'undefined',
                ],
              ],
            ),
          },
        ],
      },
    ],
  ];

  return serviceTabsRows;
};

type Props = {
  chartGridKeysState: ChartGridKeysState;
  colorMap: { [key: string]: string };
  date: DateSelection;
  eventListRef: MutableRefObject<EventListProps[] | null>;
  isDownStream?: boolean;
  property: Property;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  serviceByHash: Record<string, Service>;
  serviceHash: string;
  setDate: (date: DateSelection) => void;
};

const ServiceTabChartGrid = ({
  chartGridKeysState,
  colorMap,
  date,
  eventListRef,
  isDownStream,
  property,
  selectedFacetValuesByNameState,
  serviceByHash,
  serviceHash,
  setDate,
}: Props) => {
  const rows = useMemo(
    () => {
      return getRows({
        chartGridKeysState,
        colorMap,
        eventListRef,
        isDownStream,
        property,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        serviceHash,
        serviceByHash,
        setDate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      colorMap,
      date,
      isDownStream,
      selectedFacetValuesByNameState.state,
      serviceHash,
      serviceByHash,
    ],
  );
  return <ChartGridV2.ChartGrid date={date} rows={rows} />;
};

export default ServiceTabChartGrid;
