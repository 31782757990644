import {
  ChartLegendTableColumn,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { Filter, useSearch } from 'hooks';
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { ProductTour } from 'components';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ChartGridV2 } from 'components';
import multipleQueryRangeWithLabelsForRUM from 'utils/chartGrid/multipleQueryRangeWithLabelsForRUM';
import { formatLatencyYAxisTick } from 'utils';
import { RumEventType } from '../types';

type GetRowArgs = {
  applicationFilter: string;
  facetRegex: FacetRegexTerm[];
  setDate: (date: DateSelection) => void;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  filters: Filter[];
  search: ReturnType<typeof useSearch>;
  eventType: RumEventType;
};

export const getRows = ({
  applicationFilter,
  facetRegex,
  setDate,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  filters,
  search,
  eventType,
}: GetRowArgs) => {
  const onSelection = (newDate) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };

  const rumQueries = (aggregateField: string, aggregateFunction: string) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
    },
    queryType: 'rum-performance',
    eventType,
  });

  return [
    [
      {
        charts: [
          {
            key: 'p99InteractionToNextPaint',
            colorMap: {
              p99: '#1E88E5',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Interaction To Next Paint p99',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.interaction_to_next_paint', 'percentile99'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.interaction_to_next_paint',
                  aggregateFunction: 'percentile99',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p99']],
            ),
            unit: 'ns',
            eventType,
            enableCreateAlert: true,
          },
          {
            key: 'p95InteractionToNextPaint',
            colorMap: {
              p95: '#2BAF7F',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Interaction To Next Paint p95',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.interaction_to_next_paint', 'percentile95'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.interaction_to_next_paint',
                  aggregateFunction: 'percentile95',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p95']],
            ),
            unit: 'ns',
            eventType,
            enableCreateAlert: true,
          },
          {
            key: 'p90InteractionToNextPaint',
            colorMap: {
              p90: '#6F53B7',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Interaction To Next Paint p90',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.interaction_to_next_paint', 'percentile90'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.interaction_to_next_paint',
                  aggregateFunction: 'percentile90',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p90']],
            ),
            unit: 'ns',
            eventType,
            enableCreateAlert: true,
          },
          {
            key: 'p75InteractionToNextPaint',
            colorMap: {
              p75: '#cd863c',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Interaction To Next Paint p75',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.interaction_to_next_paint', 'percentile75'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.interaction_to_next_paint',
                  aggregateFunction: 'percentile75',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p75']],
            ),
            unit: 'ns',
            eventType,
            enableCreateAlert: true,
          },
          {
            key: 'p50InteractionToNextPaint',
            colorMap: {
              p50: '#A8D08D',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Interaction To Next Paint p50',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.interaction_to_next_paint', 'percentile50'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'view.interaction_to_next_paint',
                  aggregateFunction: 'percentile50',
                  facetRegex,
                  groupByLimit: 10,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType,
                }),
              ],
              [['p50']],
            ),
            unit: 'ns',
            eventType,
            enableCreateAlert: true,
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};
const RumInteractionToNextPaint = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    dateState,
    filtersState,
  } = rumState;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const facetRegex = facetRegexState.state;
  const [date, setDate] = dateState;
  const search = useSearch();
  const rows = useMemo(
    () =>
      getRows({
        applicationFilter,
        facetRegex,
        setDate,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        filters: filtersState.state,
        search,
        eventType: RumEventType.VIEW,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicationFilter,
      date,
      facetRegex,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <div
      className={classnames({
        rum: true,
      })}
    >
      <div className="rum__main overflow-auto">
        <div className="rum__performance-charts">
          <ChartGridV2.ChartGrid date={date} rows={rows} />
        </div>
      </div>
      <ProductTour />
    </div>
  );
};

export default RumInteractionToNextPaint;
