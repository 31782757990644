import React, { ReactElement } from 'react';
import LogsTableLiveLogs from './LogsTableLiveLogs';
import LogsTableLogs from './LogsTableLogs';
import { LogsTableProps } from '../types';

const LogsTable = ({
  clearHoveredLogDateUnix,
  columnsState,
  customColumnsState,
  disableOnlyAddToDashboardInToolBar,
  disableOnlySaveQueryInToolBar,
  getLogStackedBarCountsUsingMetricsRequest,
  getLogsStackedBarChartUsingMetricsRequestWithKfuseql,
  getWorkbooksRequest,
  isAdvancedFuseQlSearch,
  freeText,
  logsLiveTail,
  logsState,
  logsTable,
  logsWorkbooksState,
  queryScheduler,
  selectedLog,
  selectedLogFromContext,
  setHoveredLogDateUnix,
  setSelectedLog,
  bindKeyHandlersRef,
  tableOptions,
  tabFetchedRef,
}: LogsTableProps): ReactElement => {
  const { logs } = logsState;
  const { isEnabled } = logsLiveTail;

  return isEnabled ? (
    <LogsTableLiveLogs
      bindKeyHandlersRef={bindKeyHandlersRef}
      clearHoveredLogDateUnix={clearHoveredLogDateUnix}
      columnsState={columnsState}
      customColumnsState={customColumnsState}
      getWorkbooksRequest={getWorkbooksRequest}
      logsLiveTail={logsLiveTail}
      logsState={logsState}
      logsWorkbooksState={logsWorkbooksState}
      selectedLog={selectedLog}
      setHoveredLogDateUnix={setHoveredLogDateUnix}
      setSelectedLog={setSelectedLog}
      tableOptions={tableOptions}
    />
  ) : (
    <LogsTableLogs
      bindKeyHandlersRef={bindKeyHandlersRef}
      clearHoveredLogDateUnix={clearHoveredLogDateUnix}
      columnsState={columnsState}
      disableOnlySaveQueryInToolBar={disableOnlySaveQueryInToolBar}
      disableOnlyAddToDashboardInToolBar={disableOnlyAddToDashboardInToolBar}
      customColumnsState={customColumnsState}
      getLogStackedBarCountsUsingMetricsRequest={
        getLogStackedBarCountsUsingMetricsRequest
      }
      getLogsStackedBarChartUsingMetricsRequestWithKfuseql={
        getLogsStackedBarChartUsingMetricsRequestWithKfuseql
      }
      getWorkbooksRequest={getWorkbooksRequest}
      isAdvancedFuseQlSearch={isAdvancedFuseQlSearch}
      freeText={freeText}
      logs={logs}
      logsState={logsState}
      logsTable={logsTable}
      logsWorkbooksState={logsWorkbooksState}
      queryScheduler={queryScheduler}
      selectedLog={selectedLog}
      selectedLogFromContext={selectedLogFromContext}
      setHoveredLogDateUnix={setHoveredLogDateUnix}
      setSelectedLog={setSelectedLog}
      tableOptions={tableOptions}
      tabFetchedRef={tabFetchedRef}
    />
  );
};

export default LogsTable;
