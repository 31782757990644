import { useFilterState } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import {
  DateSelection,
  FilterTagsProps,
  FilterTagsStateAndMethods,
} from 'types';

import { RuleProps } from '../types';
import { AlertsTracesParsedProps } from '../AlertsCreateTraces/type';
import { buildSearchFilterTags } from 'utils/SearchBar';
import { getRollupByVisualization } from 'utils/rollup';

const AlertsDetailsPropertiesEvents = ({
  date,
  parsedMetadata,
  rule,
}: {
  date: DateSelection;
  parsedMetadata: AlertsTracesParsedProps;
  rule: RuleProps;
}): ReactElement => {
  const filterState = useFilterState({ shouldWriteToUrl: false });
  const { filterStructure, getMethodAndStateByFilterKey } = filterState;

  useEffect(() => {
    const { queries } = parsedMetadata;
    const searchBarState = queries[0].searchBarState;
    filterState.replaceFilterByKey('searchTerms', [
      ...(searchBarState?.filterState?.searchTerms ?? []),
    ]);
    filterState.replaceFilterByKey('filterByFacets', [
      ...(searchBarState?.filterState?.filterByFacets ?? []),
    ]);
    filterState.replaceFilterByKey('sidebarFilters', {
      ...(searchBarState?.filterState?.sidebarFilters ?? {}),
    });
    filterState.replaceFilterByKey('facetRangeFilters', {
      ...(searchBarState?.filterState?.facetRangeFilters ?? {}),
    });
    filterState.replaceFilterByKey('keyExists', {
      ...(searchBarState?.filterState?.keyExists ?? {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tags = useMemo(() => {
    const newTags: FilterTagsProps[] = [];
    const filterKeys = Object.keys(filterState.filter);
    filterKeys.map((filterKey) => {
      if (!filterStructure[filterKey]) return;

      const stateAndMethods = getMethodAndStateByFilterKey(
        filterKey,
        filterStructure[filterKey].dataType,
      );
      const stateAndMethodsForTags: FilterTagsStateAndMethods = {
        ...stateAndMethods,
        setSearch: () => {},
        setEditSearch: () => {},
        updateActiveOperator: () => {},
      };
      const newTag = buildSearchFilterTags({
        filterKey,
        filterStructure,
        stateAndMethods: stateAndMethodsForTags,
        splitByOperatorInQuery: true,
      });
      newTags.push(...newTag);
    });
    return newTags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.filter]);

  return (
    <div className="alerts__details__properties__summary__item">
      <span className="text-sm font-bold text-text-secondary">Query Info</span>{' '}
      {tags.map((tag, index) => {
        const query = parsedMetadata.queries[index];
        return (
          <div key={index} className="flex flex-col pt-2">
            <div className="mr-2 font-bold text-text-secondary">
              Query ({query.queryKey})
            </div>
            {tags.length > 0 && (
              <div className="flex flex-row items-center">
                <div className="mr-2 text-text-secondary">Filters:</div>
                <div>
                  {tags.map((t) => (
                    <div className="chip" key={index}>
                      {t.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex flex-row items-center">
              <div className="mr-2 text-text-secondary">Limit to:</div>
              <div>{query.limitToValue}</div>, &nbsp;
              <div className="mr-2 text-text-secondary">Sort direction:</div>
              <div>{query.limitTo}</div>, &nbsp;
              <div className="mr-2 text-text-secondary">Rollup:</div>
              <div>
                {query.rollUpInSeconds ||
                  `${getRollupByVisualization(date, 'bar')}s`}
              </div>
              , &nbsp;
              <div className="mr-2 text-text-secondary">Group by:</div>
              {query.groupBys.map((groupBy) => (
                <div className="chip" key={groupBy}>
                  {groupBy === '*'
                    ? 'Everything'
                    : groupBy === 'service_hash'
                      ? 'service'
                      : groupBy}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AlertsDetailsPropertiesEvents;
