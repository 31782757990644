import { ConfirmationModal, Stepper, useModalsContext } from 'components';
import { useSearchFormulas } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { DashboardPanelType } from 'types/Dashboard';

import {
  AlertsCreateCondition,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import {
  AlertsCreateContacts,
  AlertsCreateDetails,
  CreateRuleButton,
} from '../components';
import { useAlertsCreate } from '../hooks';
import { AlertType, RuleProps } from '../types';
import { confirmSelectedLogsAlertQueryKey } from '../AlertsCreateLogs/utils';
import { parseAlertsQueryAndConditions } from '../AlertsCreateMetrics/utils';
import useAlertsCreateRum from './hooks/useAlertsCreateRum';
import { RumApplicationFilter, RumSearchAnalytics } from 'screens/Rum';
import { EventTypeFilter } from 'screens/Rum';
import AlertsCreateRumChart from './AlertsCreateRumChart';
import AlertsCreateRumChartGroupBy from './AlertsCreateRumChartGroupBy';
import rumSlowRenderTimeSeries from 'screens/Rum/requests/rumSlowRenderTimeSeries';
import rumFrozenFrameTimeSeries from 'screens/Rum/requests/rumFrozenFrameTimeSeries';
import rumTopListCrashRate from 'screens/Rum/requests/rumTopListCrashRate';

const AlertsCreateRum = ({
  baseWidth,
  alertsCreateState,
  conditionState,
}: {
  baseWidth: number;
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const modal = useModalsContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryType = searchParams.get('queryType');
  const rule = useMemo(() => location.state as RuleProps, [location.state]);
  const { alertType, setUpdateAlertsRuleState, setAlertType } =
    alertsCreateState;
  const alertsCreateRumState = useAlertsCreateRum({
    alertsCreateState,
    baseWidth,
  });
  const {
    createRumAlertThreshold,
    rumState,
    rumLabelNamesRequest,
    queries,
    searches,
  } = alertsCreateRumState;
  const { applicationFilter, state } = rumState;
  const formulas = [];
  const { condition, setUpdateConditionState } = conditionState;

  const searchesFormulas = useSearchFormulas(queries);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (rule) {
      const newAlertType: AlertType =
        (rule.annotations?.alertType as AlertType) || AlertType.THRESHOLD;

      const parsed = parseAlertsQueryAndConditions({
        data: rule.ruleData,
        annotations: rule.annotations,
      });
      if (newAlertType !== alertType) {
        setAlertType({ value: newAlertType });
      }

      if (rule.noData && parsed.condition) {
        parsed.condition.noData = rule.noData;
      }

      if (rule.executionError && parsed.condition) {
        parsed.condition.executionError = rule.executionError;
      }
      setUpdateAlertsRuleState(rule);
      setUpdateConditionState(parsed.condition);
    }
  }, [rule]);

  const onConfirmRumAlertCreate = () => {
    const { queryKey } = condition;
    const confirmSelected = confirmSelectedLogsAlertQueryKey(
      formulas.length,
      queries.length,
      queryKey,
    );
    if (confirmSelected) {
      modal.push(
        <ConfirmationModal
          description={confirmSelected}
          onCancel={() => modal.pop()}
          onConfirm={() => {
            createRumAlertThreshold(condition);
            modal.pop();
          }}
          submitText="Confirm and Create"
          title="Confirm Query Selection"
        />,
      );
    } else {
      createRumAlertThreshold(condition);
    }
  };
  let queryToCall = null;
  if (queryType === 'rum-slow-renders') {
    queryToCall = rumSlowRenderTimeSeries;
  } else if (queryType === 'rum-frozen-frames') {
    queryToCall = rumFrozenFrameTimeSeries;
  } else if (queryType === 'rum-toplist-view-crashes') {
    queryToCall = rumTopListCrashRate;
  }
  return (
    <div className="alerts__create__logs">
      <div className="alerts__create__logs__chart">
        {queryType === 'rum-slow-renders' ||
        queryType === 'rum-frozen-frames' ||
        queryType === 'rum-toplist-view-crashes' ? (
          <AlertsCreateRumChartGroupBy
            condition={condition}
            date={alertsCreateState.date}
            eventType={rumState.eventType}
            facetRegex={state.facetRegex}
            idSearch={state.idSearch}
            queries={queries}
            selectedFacetRangeByName={state.selectedFacetRangeByNameState}
            selectedFacetValuesByName={state.selectedFacetValuesByName}
            applicationFilter={applicationFilter}
            isEditing={alertsCreateState.isEditing}
            queryToCall={queryToCall}
          />
        ) : (
          <AlertsCreateRumChart
            condition={condition}
            date={alertsCreateState.date}
            eventType={rumState.eventType}
            facetRegex={state.facetRegex}
            idSearch={state.idSearch}
            queries={queries}
            selectedFacetRangeByName={state.selectedFacetRangeByNameState}
            selectedFacetValuesByName={state.selectedFacetValuesByName}
            applicationFilter={applicationFilter}
            isEditing={alertsCreateState.isEditing}
          />
        )}
      </div>
      <Stepper
        steps={[
          {
            title: 'Choose Application and Event Type',
            component: (
              <div className="flex flex-row items-center gap-6">
                <div className="flex flex-row items-center gap-2">
                  <label className="text-sm font-medium">
                    Application Filter
                  </label>
                  <RumApplicationFilter hideHeader rumState={rumState} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <label className="text-sm font-medium">Event Type</label>
                  <EventTypeFilter rumState={rumState} />
                </div>
              </div>
            ),
          },
          {
            title: 'Pick RUM metric',
            component: (
              <div className="max-height-50-vh rum-search__query-builder overflow-auto">
                <RumSearchAnalytics
                  placeholder="Search"
                  searches={searches}
                  rumTab={DashboardPanelType.TIMESERIES}
                  rumLabelNamesRequest={rumLabelNamesRequest}
                  rumState={rumState}
                  searchesFormulas={searchesFormulas}
                />
              </div>
            ),
          },
          {
            title: 'Set Condition',
            component: (
              <AlertsCreateCondition
                conditionState={conditionState}
                queryAndFormulaKeysLabel={[
                  {
                    label: 'Query (a)',
                    value: 'Query (a)',
                  },
                ]}
                alertsCreateState={alertsCreateState}
              />
            ),
          },
          {
            title: 'Add Details',
            component: (
              <AlertsCreateDetails alertsCreateState={alertsCreateState} />
            ),
          },
          {
            title: 'Add Contacts',
            component: (
              <AlertsCreateContacts alertsCreateState={alertsCreateState} />
            ),
          },
        ]}
      />
      <CreateRuleButton
        isEditing={alertsCreateState.isEditing}
        onClick={onConfirmRumAlertCreate}
      />
    </div>
  );
};

export default AlertsCreateRum;
