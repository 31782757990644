import { DashboardPanelType } from 'types/Dashboard';

import { ConditionGrafana } from '../AlertsCreateCondition/types';

export type SelectedItemsFilter = {
  facet: string;
  facetValue: string;
  type: 'eq' | 'neq';
};

export enum RuleType {
  APM = 'apm',
  LOGS = 'logs',
  METRICS = 'metrics',
  SLO = 'slo',
  RUM = 'rum',
  TRACES = 'traces',
  EVENTS = 'events',
}

export enum AlertType {
  THRESHOLD = 'threshold',
  CHANGE = 'change',
  OUTLIERS = 'outliers',
  FORECAST = 'forecast',
  ANOMALY = 'anomaly',
  SLO = 'slo',
}

export enum AlertQueryExpressionType {
  math = 'math',
  reduce = 'reduce',
  resample = 'resample',
  classic = 'classic_conditions',
  threshold = 'threshold',
  sql = 'sql',
}

export type AlertQueryReducerType =
  | 'avg'
  | 'min'
  | 'max'
  | 'sum'
  | 'count'
  | 'last'
  | 'median'
  | 'diff'
  | 'diff_abs'
  | 'percent_diff'
  | 'percent_diff_abs'
  | 'count_non_null';

export type DeleteRuleProps = {
  groupName: string;
  folderName: string;
  folderUid: string;
  uid: string;
};

export type AlertAnomalyQueryItem = {
  band?: number;
  metricName: string;
  promql?: string;
  kfuseql?: string;
  queryType: 'query' | 'formula';
  refId: string;
  step: number;
  type: DashboardPanelType;
};

export type AlertQueryExpression = {
  conditions?: ConditionGrafana[];
  datasource: {
    name?: string;
    type: 'loki' | 'prometheus' | '__expr__';
    uid?: string;
  };
  expr?: string;
  expression?: string;
  hide: boolean;
  interval?: string;
  intervalMs?: number;
  range?: boolean;
  reducer?: AlertQueryReducerType;
  refId: string;
  settings?: Record<string, unknown>;
  type?: AlertQueryExpressionType;
};

export type AlertQueryItem = {
  datasourceUid: string;
  model: AlertQueryExpression;
  queryType: string;
  refId: string;
  relativeTimeRange?: { from: number; to: number };
};

export type AlertQuery = {
  condition: string;
  data: AlertQueryItem[];
  exec_err_state: string;
  id: number;
  intervalSeconds: number;
  is_paused: boolean;
  namespace_id: number;
  namespace_uid: string;
  no_data_state: string;
  orgId: number;
  rule_group: string;
  title: string;
  uid: string;
  updated: string;
  version: number;
};

export type AlertRule = {
  annotation: Record<string, string>;
  expr: string;
  for: string;
  grafana_alert: AlertQuery;
  labels: Record<string, string>;
};

export type AlertRuleGroup = {
  interval: string;
  name: string;
  rules: AlertRule[];
};

export type AlertRulerByGroup = {
  [key: string]: AlertRuleGroup[];
};

export type MutedAlertProps = {
  comment: string;
  createdBy: string;
  endsAt: string;
  id: string;
  matchers: {
    isEqual: boolean;
    isRegex: boolean;
    name: string;
    value: string;
  }[];
  startsAt: string;
  status: { state: string };
  updatedAt: string;
};

export type AlertEventProps = {
  activeAt: string;
  annotations: Record<string, string>;
  labels: Record<string, string>;
  state: string;
  value: string;
};
