import { QueryDataPropsRange } from 'types';

const addPrefixToSeriesAndDash = (
  queryData: QueryDataPropsRange,
  prefix: (label: string) => string,
): QueryDataPropsRange => {
  const { series } = queryData;

  const newSeries = series.map((item) => {
    return {
      ...item,
      label: prefix(item.label),
      dash: [4, 8],
    };
  });

  return { ...queryData, series: newSeries };
};

export default addPrefixToSeriesAndDash;
