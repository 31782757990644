import { alertQueryAndVariableParser } from 'screens/NewAlerts/utils';
import {
  DashboardAdvanceFunctionMappedName,
  DashboardAdvanceFunctionOptionProps,
  DashboardPanelConfigMappingProps,
  DashboardPanelProps,
  DashboardPanelType,
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
} from 'types';
import { DataFrameMeta } from 'utils/DataTransformer';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';
import {
  anomalyProphetModelParams,
  getForecastSeasonalParams,
  getFunctionParams,
  getPromqlWithMetaToLoad,
} from 'utils/MetricsQueryBuilder';

export const getMetricsExportPanelTargets = ({
  dataFormat = DashboardPanelType.TIMESERIES,
  date,
  formulas,
  queries,
  formulaOnly = false,
}: {
  dataFormat?: DashboardPanelType;
  date: DateSelection;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  formulaOnly?: boolean;
}): {
  mappings: DashboardPanelConfigMappingProps[];
  targets: DashboardPanelProps['targets'];
} => {
  const promqlWithMeta = getPromqlWithMetaToLoad({
    dataFormat,
    date,
    formulas,
    queries,
    formulaOnly,
  });

  const mappings: DashboardPanelConfigMappingProps[] = [];
  const targets: DashboardPanelProps['targets'] = [];
  promqlWithMeta.forEach((item) => {
    if (!item.promql) return;

    const { meta } = item;
    const advanceFunctionMappedOptions = getAdvanceFunctionMappedOptions({
      meta,
      queries,
    });
    mappings.push({ options: advanceFunctionMappedOptions, type: 'value' });

    let expr = Array.isArray(item.promql) ? item.promql[0] : item.promql;
    if (meta.isAnomaly || meta.isForecast) {
      expr = item.promql[1];
    }

    if (meta.isOutlier && typeof item.promql === 'string') {
      const { query: innerPromql } = alertQueryAndVariableParser({
        algoKey: 'dbscan',
        promql: item.promql,
        numberOfVariable: 2,
        hasWindow: false,
      });
      expr = innerPromql;
    }

    targets.push({
      expr,
      refId: item.meta.refId,
      datasource: { type: 'prometheus', uid: '' },
      editorMode: 'code',
      hide: !item.meta.isActive,
      range: true,
      instant: false,
    });
  });

  return { mappings, targets };
};

export const getAdvanceFunctionMappedOptions = ({
  meta,
  queries,
}: {
  meta: DataFrameMeta;
  queries: ExplorerQueryProps[];
}) => {
  const {
    KFUSE_FUNCTION,
    KFUSE_FORECAST_ALGO,
    KFUSE_FORECAST_SEASONALITY,
    KFUSE_ANOMALIES_ALGO,
    KFUSE_ANOMALIES_WINDOW,
    KFUSE_ANOMALIES_BOUND,
    KFUSE_ANOMALIES_SEASONALITY,
    KFUSE_OUTLIER_ALGO,
    KFUSE_OUTLIER_TOLERANCE,
  } = DashboardAdvanceFunctionMappedName;
  const mappingsOptions: { [key: string]: { index: number; text: string } } =
    {};

  if (meta.isAnomaly) {
    const query = queries.find((query) => query.queryKey === meta.refId);
    mappingsOptions[KFUSE_FUNCTION] = {
      index: 0,
      text: KfuseQlFunction.ANOMALIES,
    };
    if (query) {
      const { functions } = query;
      const lastFunction = functions[functions.length - 1];
      if (lastFunction) {
        const { name, params } = lastFunction;
        const [algo, window, bound_param] = params;
        const bound = algo.value === 'agile' ? window : bound_param;
        const seasonality = algo.value === 'agile-robust' ? window : undefined;

        mappingsOptions[KFUSE_ANOMALIES_ALGO] = { index: 1, text: algo.value };
        mappingsOptions[KFUSE_ANOMALIES_BOUND] = {
          index: 2,
          text: bound.value,
        };

        if (window && window !== bound) {
          mappingsOptions[KFUSE_ANOMALIES_WINDOW] = {
            index: 3,
            text: window.value,
          };
        }

        if (seasonality) {
          mappingsOptions[KFUSE_ANOMALIES_SEASONALITY] = {
            index: 4,
            text: seasonality.value,
          };
        }
      }
    }
  }

  if (meta.isForecast) {
    const query = queries.find((query) => query.queryKey === meta.refId);
    mappingsOptions[KFUSE_FUNCTION] = {
      index: 0,
      text: KfuseQlFunction.FORECAST,
    };
    if (query) {
      const { functions } = query;
      const lastFunction = functions[functions.length - 1];
      if (lastFunction) {
        const { name, params } = lastFunction;
        const [algo, seasonality] = params;
        mappingsOptions[KFUSE_FORECAST_ALGO] = { index: 0, text: algo.value };

        if (seasonality) {
          mappingsOptions[KFUSE_FORECAST_SEASONALITY] = {
            index: 1,
            text: seasonality.value,
          };
        }
      }
    }
  }

  if (meta.isOutlier) {
    const query = queries.find((query) => query.queryKey === meta.refId);
    mappingsOptions[KFUSE_FUNCTION] = {
      index: 0,
      text: KfuseQlFunction.OUTLIERS,
    };

    if (query) {
      const { functions } = query;
      const lastFunction = functions[functions.length - 1];
      if (lastFunction) {
        const { name, params } = lastFunction;
        const [algo, tolerance] = params;

        mappingsOptions[KFUSE_OUTLIER_ALGO] = { index: 1, text: algo.value };
        mappingsOptions[KFUSE_OUTLIER_TOLERANCE] = {
          index: 2,
          text: tolerance.value,
        };
      }
    }
  }

  return mappingsOptions;
};

export const mappedAdvanceFunctionParams = (
  mappings: DashboardPanelConfigMappingProps[],
) => {
  const {
    KFUSE_FUNCTION,
    KFUSE_FORECAST_ALGO,
    KFUSE_FORECAST_SEASONALITY,
    KFUSE_ANOMALIES_ALGO,
    KFUSE_ANOMALIES_WINDOW,
    KFUSE_ANOMALIES_BOUND,
    KFUSE_ANOMALIES_SEASONALITY,
    KFUSE_OUTLIER_TOLERANCE,
    KFUSE_OUTLIER_ALGO,
  } = DashboardAdvanceFunctionMappedName;
  const kfuseFunction = mappings?.find(
    (m) => m.type === 'value' && m.options[KFUSE_FUNCTION],
  );

  if (!kfuseFunction) {
    return null;
  }

  const { options } = kfuseFunction;
  const advanceFunctionOption: DashboardAdvanceFunctionOptionProps = {
    function: '',
  };
  const advanceFunction = options[KFUSE_FUNCTION];
  advanceFunctionOption.function = advanceFunction?.text || '';

  if (advanceFunction.text === 'forecast') {
    advanceFunctionOption[KFUSE_FORECAST_ALGO] =
      options[KFUSE_FORECAST_ALGO]?.text || 'linear';
    advanceFunctionOption[KFUSE_FORECAST_SEASONALITY] =
      options[KFUSE_FORECAST_SEASONALITY]?.text || 'hourly';
  }

  if (advanceFunction.text === 'anomalies') {
    advanceFunctionOption[KFUSE_ANOMALIES_ALGO] =
      options[KFUSE_ANOMALIES_ALGO]?.text || 'basic';
    advanceFunctionOption[KFUSE_ANOMALIES_WINDOW] =
      options[KFUSE_ANOMALIES_WINDOW]?.text || '10m';
    advanceFunctionOption[KFUSE_ANOMALIES_BOUND] =
      options[KFUSE_ANOMALIES_BOUND]?.text || '1';
    advanceFunctionOption[KFUSE_ANOMALIES_SEASONALITY] =
      options[KFUSE_ANOMALIES_SEASONALITY]?.text || '';
  }

  if (advanceFunction.text === 'outliers') {
    advanceFunctionOption[KFUSE_OUTLIER_ALGO] =
      options[KFUSE_OUTLIER_ALGO]?.text || 'DBSCAN';
    advanceFunctionOption[KFUSE_OUTLIER_TOLERANCE] =
      options[KFUSE_OUTLIER_TOLERANCE]?.text || '0.6';
  }

  return advanceFunctionOption;
};

export const getMappedToAdvanceFunctionParams = (
  mappings: DashboardPanelConfigMappingProps[] | undefined,
) => {
  if (!mappings) return undefined;
  const advanceFunctionOption = mappedAdvanceFunctionParams(mappings);

  if (!advanceFunctionOption) return undefined;

  const { function: advanceFunction } = advanceFunctionOption;

  if (advanceFunction === 'forecast') {
    const { kfuse_forecast_algo, kfuse_forecast_seasonality } =
      advanceFunctionOption;
    const params = getFunctionParams(KfuseQlFunction.FORECAST);
    params[0].value = kfuse_forecast_algo;

    if (kfuse_forecast_algo === 'seasonal') {
      params.push(...getForecastSeasonalParams());
      params[params.length - 1].value = kfuse_forecast_seasonality;
    }

    return { params, name: KfuseQlFunction.FORECAST };
  }

  if (advanceFunction === 'anomalies') {
    const {
      kfuse_anomalies_algo,
      kfuse_anomalies_window,
      kfuse_anomalies_bound,
      kfuse_anomalies_seasonality,
    } = advanceFunctionOption;

    const params = getFunctionParams(KfuseQlFunction.ANOMALIES);
    params[0].value = kfuse_anomalies_algo;
    if (kfuse_anomalies_algo === 'basic') {
      params[1].value = kfuse_anomalies_window;
      params[2].value = Number(kfuse_anomalies_bound);
    }

    if (kfuse_anomalies_algo === 'agile') {
      // remove index 1 it window and not needed for agile
      params.splice(1, 1);
      params[1].value = Number(kfuse_anomalies_bound);
    }

    if (kfuse_anomalies_algo === 'robust') {
      params[1].value = kfuse_anomalies_window;
      params[2].value = Number(kfuse_anomalies_bound);
    }

    if (kfuse_anomalies_algo === 'agile-robust') {
      params[1] = anomalyProphetModelParams[0];
      params[1].value = kfuse_anomalies_seasonality;
      params[2].value = Number(kfuse_anomalies_bound);
    }

    return { params, name: KfuseQlFunction.ANOMALIES };
  }

  if (advanceFunction === 'outliers') {
    const { kfuse_outlier_algo, kfuse_outlier_tolerance } =
      advanceFunctionOption;
    const params = getFunctionParams(KfuseQlFunction.OUTLIERS);
    params[0].value = kfuse_outlier_algo;
    params[1].value = kfuse_outlier_tolerance;

    return { params, name: KfuseQlFunction.OUTLIERS };
  }
};
