import {
  ChartToolbar,
  LegendsAggregate,
  Timeseries,
  TooltipCompact,
  useTimeseries,
} from 'components';
import React, { ReactElement, useMemo } from 'react';
import { MetricsQueriesDataProps, TimeseriesRenderProps } from 'types';

import useTimeseriesExplorer from './useTimeseriesExplorer';

const TimeseriesExplorerChart = ({
  combinedData,
  renderComponent,
  timeseries,
  timeseriesExplorer,
  timeseriesProps,
}: {
  activeChartType: string;
  combinedData: MetricsQueriesDataProps['query']['data'];
  renderComponent: TimeseriesRenderProps['renderComponent'];
  timeseries: ReturnType<typeof useTimeseries>;
  timeseriesExplorer: ReturnType<typeof useTimeseriesExplorer>;
  timeseriesProps: any;
  unit: string;
}): ReactElement => {
  const {
    logqlQueryRangeRequest,
    rumPerformanceQueryRequest,
    traceQueryRequest,
  } = timeseriesExplorer;

  const defaultChartStorke = useMemo(() => {
    const length = combinedData?.data[1]?.filter(Boolean)?.length;
    return length < 5 ? 'none' : 'normal';
  }, [combinedData?.data]);

  return (
    <Timeseries
      {...timeseriesProps}
      bands={combinedData?.bands || []}
      chartData={combinedData}
      isLoading={
        combinedData?.isLoading ||
        logqlQueryRangeRequest.isLoading ||
        rumPerformanceQueryRequest.isLoading ||
        traceQueryRequest.isLoading
      }
      hooks={combinedData?.hooks}
      layoutType="modal"
      renderComponent={renderComponent}
      renderLegend={({
        config,
        data,
        onFocusSeries,
        onLegendItemClick,
        unit,
      }) => (
        <LegendsAggregate
          config={config}
          data={data}
          maxHeight={(window.innerHeight - 200) / 2}
          onFocusSeries={onFocusSeries}
          onItemClick={onLegendItemClick}
          unit={unit}
        />
      )}
      renderTooltip={({
        activeChart,
        config,
        getRawData,
        layoutType,
        styles,
        unit,
      }) => (
        <TooltipCompact
          chartType={activeChart}
          config={config}
          getRawData={getRawData}
          layoutType={layoutType}
          styles={styles}
          unit={unit}
        />
      )}
      renderToolbar={({
        activeChart,
        activeStroke,
        setActiveChart,
        setActiveStroke,
      }) => (
        <ChartToolbar
          activeChart={activeChart}
          activeStroke={activeStroke}
          chartTypes={['Line', 'Stacked Bar', 'Area', 'Points']}
          setActiveChart={setActiveChart}
          setActiveStroke={setActiveStroke}
          toolbar={{
            toolbarMenuType: 'dropdown',
          }}
        />
      )}
      timeseries={timeseries}
      strokeType={defaultChartStorke}
    />
  );
};

export default TimeseriesExplorerChart;
