import { useUrlState } from 'hooks';
import { useState } from 'react';

type Options = {
  initalState?: Record<string, any>;
  urlStateKey?: string;
  shouldWriteToUrl?: boolean;
};

const useKeyExistsState = (options?: Options) => {
  const shouldWriteToUrl =
    typeof options?.shouldWriteToUrl === 'boolean'
      ? options?.shouldWriteToUrl
      : true;
  const urlStateKey = options?.urlStateKey || 'keyExists';
  const [urlState, setUrlState] = useUrlState(
    urlStateKey,
    options?.initalState || {},
  );
  const [regularState, setRegularState] = useState(options?.initalState || {});

  const localState = shouldWriteToUrl ? urlState : regularState;
  const setState = shouldWriteToUrl ? setUrlState : setRegularState;

  const toggleKeyExists = (key: string) => {
    setState((prevState) => {
      const nextState = { ...prevState };
      nextState[key] = prevState[key] ? 0 : 1;
      return nextState;
    });
  };

  const setKeyExists = (key: string, value?: number) => {
    setState((prevState) => ({
      ...prevState,
      [key]: typeof value === 'number' ? value : 1,
    }));
  };

  const setKeysExists = (keys: string[], values?: number[]) => {
    setState((prevState) => {
      const nextState = {};
      keys.forEach((key) => {
        nextState[key] =
          typeof values?.[keys.indexOf(key)] === 'number'
            ? values[keys.indexOf(key)]
            : 1;
      });
      return nextState;
    });
  };

  const unsetKeyExists = (key: string) => {
    setState((prevState) => {
      const nextState = {
        ...prevState,
      };
      delete nextState[key];
      return nextState;
    });
  };

  return {
    setKeyExists,
    setKeysExists,
    state: localState,
    toggleKeyExists,
    unsetKeyExists,
    setState,
  };
};

export default useKeyExistsState;
