import classNames from 'classnames';
import {
  AutocompleteV2,
  Badge,
  Button,
  ServiceWithLabels,
  TooltipTrigger,
} from 'components';
import React, { ReactElement } from 'react';
import { Plus } from 'react-feather';
import { components } from 'react-select';
import { Service } from 'types';

import AlertsCreateAPMServiceLabel from './AlertsCreateAPMServiceLabel';
import { useAlertsCreateAPM } from './hooks';
import { conditionForAPMReducer } from './utils';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  alertsCreateAPMState: ReturnType<typeof useAlertsCreateAPM>;
  defaultMatchers?: string[];
  disableServiceSelection?: boolean;
  hideTriggerType?: boolean;
  pickLabelsText?: string;
};

const AlertsCreateAPMService = ({
  alertsCreateAPMState,
  defaultMatchers,
  disableServiceSelection,
  hideTriggerType,
  pickLabelsText,
}: Props): ReactElement => {
  const {
    apmAlertDetails,
    loadInitialLabelsAndValues,
    serviceListRequest,
    setApmAlertDetails,
    serviceByHashRequest,
    updateTriggerType,
  } = alertsCreateAPMState;
  const { additionalLabels, uniqueLabels, serviceName, triggerType } =
    apmAlertDetails;

  return (
    <div>
      <div className="search__button-group">
        {serviceListRequest?.error && (
          <div className="flex gap-[4px] w-full justify-start pr-[14px]">
            <IoIosWarning
              className="overlay-message__icon-and-message__icon"
              size={16}
            />
            <div className="text-red-500">Failed to fetch service list</div>
          </div>
        )}
        <div className="button-group">
          <div className="button-group__item button-group__item--label">
            Service name
          </div>
        </div>
        <div className="search__button-group__divider">
          <div />
        </div>
        <div className="button-group">
          <div className="button-group__item button-group__item--value">
            <AutocompleteV2
              className={classNames({
                'autocomplete-container--no-border': true,
                'autocomplete__fixed-height-30': true,
                'button-group__item__autocomplete--value': true,
                'metrics__query-builder__series__builder__placeholder--highlighted':
                  Boolean(serviceName),
              })}
              components={{
                Option: (props) => {
                  const { data } = props;
                  const parsedValue: Service = JSON.parse(data.value || '{}');
                  return (
                    <components.Option {...props}>
                      <ServiceWithLabels
                        className="apm__service__table__name"
                        color={data.color}
                        distinctLabels={parsedValue?.distinctLabels}
                        labels={parsedValue?.labels}
                        name={parsedValue.name}
                        selected={data.value === apmAlertDetails.rawValue}
                      />
                    </components.Option>
                  );
                },
              }}
              isLoading={
                serviceListRequest.isLoading || serviceByHashRequest.isLoading
              }
              onChange={(val: string) => {
                const parsedValue: Service = JSON.parse(val);
                setApmAlertDetails((prev) => ({
                  ...prev,
                  serviceName: parsedValue.name,
                  serviceHash: parsedValue.hash,
                  additionalLabels: defaultMatchers || [
                    'span_name=""',
                    'span_type=""',
                  ],
                  uniqueLabels: parsedValue?.labels || {},
                  rawValue: val,
                }));
                if (!val) return;
                loadInitialLabelsAndValues(
                  parsedValue.name,
                  parsedValue.hash,
                  parsedValue.labels || {},
                );
              }}
              filterOption={(option, rawInput) => {
                if (!rawInput) return true;
                const parsedValue: Service = JSON.parse(option.value || '{}');
                const labelWithDistinctLabels = `${
                  option.label
                } ${Object.values(parsedValue.distinctLabels || {}).join(' ')}`;
                return labelWithDistinctLabels.toLowerCase().includes(rawInput);
              }}
              options={serviceListRequest.result}
              placeholder={serviceName || 'Pick a service'}
              value={serviceListRequest.result ? apmAlertDetails.rawValue : ''}
              isDisabled={disableServiceSelection}
            />
          </div>
        </div>
      </div>
      <div className="service__header__bottom mt-1 flex">
        {Object.keys(uniqueLabels).map((label) => {
          if (!uniqueLabels[label]) return null;
          return (
            <Badge
              className="mb-1 mr-1 font-normal"
              key={label}
              variant="outline"
            >
              {`${label}: ${uniqueLabels[label]}`}
            </Badge>
          );
        })}
      </div>
      {triggerType === 'apdex' ? null : (
        <div className="search__button-group">
          <div className="button-group">
            <div className="button-group__item button-group__item--label">
              {pickLabelsText ? pickLabelsText : 'Pick Labels'}
            </div>
          </div>
          <div className="search__button-group__divider">
            <div />
          </div>
          {additionalLabels.map((item, index) => (
            <AlertsCreateAPMServiceLabel
              alertsCreateAPMState={alertsCreateAPMState}
              labelIndex={index}
              key={index}
              labelValue={item}
            />
          ))}
          <div className="button-group">
            <div className="button-group__item button-group__item--unpadded">
              <TooltipTrigger className="flex h-full" tooltip="Add Label">
                <Button
                  className="h-full"
                  onClick={() => {
                    const newMatchers = [...additionalLabels, '=""'];
                    setApmAlertDetails((prev) => ({
                      ...prev,
                      additionalLabels: newMatchers,
                    }));
                  }}
                  variant="icon"
                  size="sm"
                >
                  <Plus size={20} />
                </Button>
              </TooltipTrigger>
            </div>
          </div>
        </div>
      )}
      {hideTriggerType ? null : (
        <div className="search__button-group mb-2">
          <div className="button-group">
            <div className="button-group__item button-group__item--label">
              Trigger type
            </div>
          </div>
          <div className="search__button-group__divider">
            <div />
          </div>
          <div className="button-group">
            <div className="button-group__item button-group__item--value">
              <AutocompleteV2
                className={classNames({
                  'autocomplete-container--no-border': true,
                  'autocomplete__fixed-height-30': true,
                  'button-group__item__autocomplete--value': true,
                })}
                onChange={updateTriggerType}
                options={conditionForAPMReducer}
                value={triggerType}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertsCreateAPMService;
