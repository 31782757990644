import {
  BasicLogsTable,
  TableOptionsPopover,
  useColumnsState,
  useTableOptions,
} from 'components';
import { Separator } from 'components/shadcn';
import useKeyExistsState from 'hooks/useKeyExistsState';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { getColumns, MESSAGE, SOURCE, TIMESTAMP } from 'screens/Logs/constants';
import { DateSelection } from 'types';

const AlertsServiceCorrelationsLogs = ({
  date,
}: {
  date: DateSelection;
}): ReactElement => {
  const [reload, setReload] = useState(true);

  const tableOptions = useTableOptions({ shouldUseLocalStorage: false });
  const customColumnsState = useKeyExistsState({
    urlStateKey: 'customColumns',
    shouldWriteToUrl: false,
    initalState: {},
  });

  const logsState = useMemo(
    () => {
      return { date };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date],
  );

  const baseColumns = useMemo(
    () => getColumns({ customColumnsState, logsState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customColumnsState.state],
  );
  const columnsState = useColumnsState({
    columns: baseColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: { [MESSAGE]: 1, [SOURCE]: 1, [TIMESTAMP]: 1 },
    },
    key: 'logs-table',
    shouldUseLocalStorage: false,
  });

  useEffect(() => {
    setReload(true);
  }, [date]);

  return (
    <div>
      <div className="flex items-center justify-between pb-1">
        <div className="table-toolbar__left">
          <TableOptionsPopover
            columnsState={columnsState}
            tableOptions={tableOptions}
          />
        </div>
      </div>

      <Separator className="mt-2" />
      <BasicLogsTable
        columnsState={columnsState}
        customColumnsState={customColumnsState}
        disableAddCustomColumn={true}
        enableWebSocket={false}
        isInView={true}
        isReload={reload}
        onLogsLoaded={() => setReload(false)}
        logsState={logsState}
        shouldDisableFilterActions
        tableOptions={tableOptions}
      />
    </div>
  );
};

export default AlertsServiceCorrelationsLogs;
