import dayjs from 'dayjs';
import { parseAndTransformQueryForSparkline } from 'utils/KfuseqlBuilder';

export const formatLogMetricsResultWithFuseQlStreamResult = (result) => {
  try {
    const tableResult = result?.TableResult || [];

    const resultGroupedByTags = tableResult.reduce((acc, row) => {
      const tagKey = row[2];
      if (!acc[tagKey]) {
        acc[tagKey] = [];
      }
      const point = {
        ts: row[1],
        value: row[0],
      };
      acc[tagKey].push(point);
      return acc;
    }, {});

    const timeSeriesResponse = Object.keys(resultGroupedByTags).map((key) => {
      const points = resultGroupedByTags[key];
      const tags = {
        level: key,
      };

      return {
        points,
        tags,
      };
    });
    return timeSeriesResponse;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getLogMetricsResultWithFuseQlStream = (args) => {
  const { logsState, query } = args;
  const { date } = logsState;
  const { startTimeUnix, endTimeUnix } = date;
  const startTime = dayjs.unix(startTimeUnix);
  const endTime = dayjs.unix(endTimeUnix);

  const parsedQueryForSparkLine = parseAndTransformQueryForSparkline({
    query: query || '',
    date: date,
  });

  return `
  subscription {
    getLogMetricsResultWithFuseQlStream(
      query: "${parsedQueryForSparkLine}",
      startTs: "${startTime.format()}"
      endTs: "${endTime.format()}"
    ) {
      TableResult
      ColumnHeaders
    }
  }
`;
};

export default getLogMetricsResultWithFuseQlStream;
