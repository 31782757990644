import { ToastProps } from 'components/Toasts/types';
import { promqlQueryRangeV3 } from 'requests';
import {
  DateSelection,
  PromqlWithMetaProps,
  QueryDataProps,
  QueryDataPropsRange,
} from 'types';
import {
  adjustForecastTimeseriesTimestamp,
  DataFrame,
  forecastDataTransformer,
  mergeTwoTimeSeries,
  metricsDataTransformer,
  shiftForecastBandTimeseriesTimestamps,
} from 'utils/DataTransformer';
import { getForecastLookbackAndPredictDate } from 'utils/MetricsQueryBuilder';

const FORECAST_MIN_TIME_RANGE = 3600;

const metricsForecastLoader = async ({
  addToast,
  date,
  forecastSeasonality,
  meta,
  promql,
}: {
  addToast: (toast: ToastProps) => void;
  date: DateSelection;
  forecastSeasonality: string;
  meta: PromqlWithMetaProps['meta'];
  promql: string[];
}): Promise<QueryDataPropsRange[] | QueryDataPropsRange> => {
  const seconds = date.endTimeUnix - date.startTimeUnix;
  const { startTimeUnix, endTimeUnix } = date;
  const diffInSeconds = endTimeUnix - startTimeUnix;
  if (diffInSeconds < FORECAST_MIN_TIME_RANGE) {
    addToast({
      text: 'By default, minimum 1 hour time range is set for forecasting',
      status: 'info',
    });
  }
  const dateForForecast = getForecastLookbackAndPredictDate({
    forecastDuration: `${seconds}s`,
    lookbackDuration: `${seconds}s`,
    date,
    type: 'metrics',
  });
  const { lookbackDate, predictedDate, step } = dateForForecast;
  const forecastStep = meta.customStep || step;
  const datasets = await Promise.all(
    promql.map((p, idx) => {
      const dateForChart = idx === 0 ? lookbackDate : predictedDate;
      const baseTransformer = metricsDataTransformer();
      if (idx === 0) {
        baseTransformer.splice(2, 0, {
          id: 'adjustForecastTimeseriesTimestamp',
          func: (dataFrame: DataFrame) =>
            adjustForecastTimeseriesTimestamp({
              dataFrame,
              startTimeUnix: lookbackDate.endTimeUnix,
              step: forecastStep,
            }),
        });

        if (forecastSeasonality) {
          baseTransformer.pop();
          baseTransformer.push({
            id: 'forecastDataTransformer',
            func: forecastDataTransformer,
          });
        }
      }

      return promqlQueryRangeV3({
        date: dateForChart,
        promqlQuery: p,
        meta: { ...meta, step: forecastStep },
        transformer: baseTransformer,
      }) as unknown as QueryDataPropsRange;
    }),
  );

  const [predictedRes, lookbackRes] = datasets;
  if (!lookbackRes || !predictedRes) return;
  if (!forecastSeasonality) {
    return mergeTwoTimeSeries({
      timeSeries1: lookbackRes as unknown as QueryDataProps['k']['range'],
      timeSeries2: predictedRes as unknown as QueryDataProps['k']['range'],
    });
  }

  const [upper, lower, mean] = predictedRes as unknown as QueryDataPropsRange[];
  const { lowerShifted, upperShifted } = shiftForecastBandTimeseriesTimestamps({
    lower,
    upper,
    shiftByTimestamps: lookbackRes.data[0],
  });
  const mergedData = mergeTwoTimeSeries({
    timeSeries1: lookbackRes as unknown as QueryDataProps['k']['range'],
    timeSeries2: mean as unknown as QueryDataProps['k']['range'],
  });

  return [upperShifted, lowerShifted, mergedData];
};

export default metricsForecastLoader;
