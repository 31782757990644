import React from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import RumActionsWithErrorsTable from './RumActionsWithErrorsTable';
import RumErrorGroupTable from './RumErrorGroupTable';
import RumErrorsTopView from './RumErrorsTopView';
import RumErrorsByVersion from './RumErrorsByVersion';
import RumTotalErrors from './RumTotalErrors';
import RUMCrashesByVersion from './RUMMobileVitals/RUMCrashesByVersion';
import RUMErrorsByVersion from './RUMMobileVitals/RUMErrorsByVersion';
import RUMViewsWithMostCrashes from './RUMMobileVitals/RUMViewsWithMostCrashes';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RumAnalyzeErrors = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const { applicationInfo } = rumState;
  return (
    <div>
      <div className="rum-performance__errors_grid">
        {applicationInfo?.type === 'web' && (
          <div className="rum-performance__errors-top">
            <div
              className="rum-performance__errors-item rum-performance__errors-item--top flex-1"
              data-testid="rum_errors_by_version"
            >
              <RumErrorsByVersion rumPageState={rumPageState} />
            </div>
            <div
              className="rum-performance__errors-item rum-performance__errors-item--top flex-1"
              data-testid="rum_total_errors"
            >
              <RumTotalErrors rumPageState={rumPageState} />
            </div>
            <div
              className="rum-performance__errors-item rum-performance__errors-item--top flex-1"
              data-testid="rum_errors_top_view"
            >
              <RumErrorsTopView
                rumPageState={rumPageState}
                className="rum-performance__errors-group"
              />
            </div>
          </div>
        )}
        {applicationInfo?.type !== 'web' && (
          <div className="rum-performance__errors-top">
            <div
              className="rum-performance__errors-item rum-performance__errors-item--top flex-1"
              data-testid="rum_errors_by_version"
            >
              <RUMCrashesByVersion rumPageState={rumPageState} />
            </div>
            <div
              className="rum-performance__errors-item rum-performance__errors-item--top flex-1"
              data-testid="rum_total_errors"
            >
              <RUMErrorsByVersion rumPageState={rumPageState} />
            </div>
            <div
              className="rum-performance__errors-item rum-performance__errors-item--top flex-1"
              data-testid="rum_errors_top_view"
            >
              <RUMViewsWithMostCrashes
                rumPageState={rumPageState}
                className="rum-performance__errors-group"
              />
            </div>
          </div>
        )}
        <div className="rum-performance__errors-bottom">
          <div
            className="rum-performance__errors-item rum-performance__errors-item--bottom"
            data-testid="rum_top_actions_error_view"
          >
            <div className="rum__performance-subtitle">
              Top Actions Triggering Errors
            </div>
            <RumActionsWithErrorsTable rumPageState={rumPageState} />
          </div>
          <div
            className="rum-performance__errors-item rum-performance__errors-item--bottom"
            data-testid="rum_ongoing_errors_view"
          >
            <div className="rum__performance-subtitle">Ongoing Issues</div>
            <RumErrorGroupTable rumState={rumPageState.rumState} onlyTop10 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RumAnalyzeErrors;
