import {
  ConfirmationModal,
  MetricsQueryBuilder,
  Stepper,
  useModalsContext,
} from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { convertFromAndToDate } from 'utils';

import { useAlertsCreateCondition } from '../AlertsCreateCondition';
import AlertsCreateMetricsChart from './AlertsCreateMetricsChart';
import AlertsCreateMetricsDetection from './AlertsCreateMetricsDetection';
import AlertsCreateMetricsCondition from './AlertsCreateMetricsCondition';
import {
  AlertsCreateContacts,
  AlertsCreateDetails,
  CreateRuleButton,
} from '../components';
import { useAlertsCreate } from '../hooks';
import { useAlertsCreateMetrics, useAlertsCreateMetricsAnomaly } from './hooks';
import { AlertType, RuleProps } from '../types';
import { confirmSelectedLogsAlertQueryKey } from '../AlertsCreateLogs/utils';
import {
  evaluationWindowTimeMapping,
  parseAlertsQueryAndConditions,
} from './utils';

type Props = {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  baseWidth: number;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  evaluationWindowTime: string;
};

const AlertsCreateMetrics = ({
  alertsCreateState,
  baseWidth,
  conditionState,
  evaluationWindowTime,
}: Props): ReactElement => {
  const modal = useModalsContext();
  const location = useLocation();
  const rule = location.state as RuleProps;
  const { evaluate, setEvaluate } = alertsCreateState;

  const {
    appendPromqlForChart,
    createForecastDetectionRule,
    createChangeAlertsRule,
    createThresoldAlertsRule,
    createOutlierAlertsRule,
    setChangeAndOutlierConditions,
  } = useAlertsCreateMetrics(alertsCreateState, conditionState);

  const { createAnomalyDetectionRuleForQuery } = useAlertsCreateMetricsAnomaly({
    alertsCreateState,
  });

  const {
    condition,
    metricsChangeCondition,
    outlierCondition,
    anomalyCondition,
    setUpdateConditionState,
  } = conditionState;

  const metricsQueryState = useMetricsQueryStateV2({
    date: alertsCreateState.date,
    preReloadQuery: (promql, metricName) =>
      appendPromqlForChart(promql, metricName),
    isRange: true,
  });

  const { alertType, date, setAlertType, setUpdateAlertsRuleState } =
    alertsCreateState;

  const {
    callMultiplePromqlQueries,
    formulas,
    loadInitialLabelsAndValues,
    loadLabelList,
    queries,
    setFormulas,
    setQueries,
  } = metricsQueryState;

  const getevaluationWindowTimeInterval: (timeStr: string) => string = (
    timeStr,
  ) => {
    return evaluationWindowTimeMapping[timeStr] || '1m';
  };

  useEffect(() => {
    if (rule) {
      const newAlertType: AlertType =
        (rule.annotations?.alertType as AlertType) || AlertType.THRESHOLD;

      const parsed = parseAlertsQueryAndConditions({
        data: rule.ruleData,
        annotations: rule.annotations,
      });

      if (newAlertType !== AlertType.THRESHOLD) {
        setChangeAndOutlierConditions({
          newAlertType,
          newAnomalyCondition: parsed.anomalyCondition,
          newForecastCondition: parsed.forecastCondition,
          newChangeCondition: parsed.changeCondition,
          newOutlierCondition: parsed.outlierCondition,
          relativeTimeRange: parsed.relativeTimeRange,
        });
      }
      setAlertType({ value: newAlertType });

      const { from, to } = parsed.relativeTimeRange;
      alertsCreateState.setDate(convertFromAndToDate(from, to));

      setQueries(parsed.queries);
      parsed.queries.forEach((query, idx: number) => {
        loadLabelList([], query.metric, -1);
        loadInitialLabelsAndValues(query.metric, query.series);
      });
      if (parsed.formulas.length > 0) {
        setFormulas(parsed.formulas);
      }

      setUpdateAlertsRuleState(rule);
      const parsedCondition = parsed.condition;

      if (rule.noData && parsedCondition) {
        parsedCondition.noData = rule.noData;
      }

      if (rule.executionError && parsedCondition) {
        parsedCondition.executionError = rule.executionError;
      }
      setUpdateConditionState(parsedCondition);

      if (parsed.queries.length > 0 && newAlertType !== AlertType.ANOMALY) {
        callMultiplePromqlQueries(parsed.queries, parsed.formulas, date);
      }
    }

    return () => {
      setQueries([]);
      setFormulas([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callMultiplePromqlQueries(queries, formulas, date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertType, outlierCondition, metricsChangeCondition]);

  useEffect(() => {
    setEvaluate((prevState) => ({
      ...prevState,
      every: getevaluationWindowTimeInterval(evaluationWindowTime),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationWindowTime]);

  const onCreateMetricAlerts = () => {
    if (alertType == AlertType.CHANGE) {
      createChangeAlertsRule({
        condition,
        formulas,
        changeCondition: metricsChangeCondition,
        queries,
      });
    } else if (alertType == AlertType.OUTLIERS) {
      createOutlierAlertsRule({
        condition,
        formulas,
        outlierCondition,
        queries,
      });
    } else if (alertType == AlertType.ANOMALY) {
      createAnomalyDetectionRuleForQuery({
        anomalyCondition,
        condition,
        formulas,
        queries,
      });
    } else if (alertType == AlertType.FORECAST) {
      createForecastDetectionRule({
        condition,
        formulas,
        queries,
        conditionState,
      });
    } else {
      createThresoldAlertsRule({ condition, formulas, queries });
    }
  };

  const onConfirmMetricAlertCreate = () => {
    const { queryKey } = condition;
    const confirmSelected = confirmSelectedLogsAlertQueryKey(
      formulas.length,
      queries.length,
      queryKey,
    );
    if (confirmSelected) {
      modal.push(
        <ConfirmationModal
          description={confirmSelected}
          onCancel={() => modal.pop()}
          onConfirm={() => {
            onCreateMetricAlerts();
            modal.pop();
          }}
          submitText="Confirm and Create"
          title="Confirm Query Selection"
        />,
      );
    } else {
      onCreateMetricAlerts();
    }
  };

  return (
    <div>
      <AlertsCreateMetricsChart
        alertType={alertType}
        baseWidth={baseWidth}
        conditionState={conditionState}
        date={date}
        forWindow={evaluate.for}
        isEditing={alertsCreateState.isEditing}
        metricsQueryState={metricsQueryState}
      />
      <Stepper
        steps={[
          {
            title: 'Choose the detection method',
            component: (
              <AlertsCreateMetricsDetection
                selectedAlertType={alertType}
                handleTabClick={(tab) => setAlertType({ value: tab })}
              />
            ),
          },
          {
            title: 'Pick metric',
            component: (
              <>
                <MetricsQueryBuilder
                  metricsQueryState={metricsQueryState}
                  blockedFunctionsCategories={['Algorithms']}
                />
              </>
            ),
          },
          {
            title: 'Set Condition',
            component: (
              <AlertsCreateMetricsCondition
                alertsCreateState={alertsCreateState}
                conditionState={conditionState}
                metricsQueryState={metricsQueryState}
              />
            ),
          },
          {
            title: 'Add Details',
            component: (
              <>
                <AlertsCreateDetails alertsCreateState={alertsCreateState} />
              </>
            ),
          },
          {
            title: 'Add Contacts',
            component: (
              <AlertsCreateContacts alertsCreateState={alertsCreateState} />
            ),
          },
        ]}
      />
      <CreateRuleButton
        isEditing={alertsCreateState.isEditing}
        onClick={onConfirmMetricAlertCreate}
      />
    </div>
  );
};

export default AlertsCreateMetrics;
