import { useLocalStorageState } from 'hooks';
import { useState } from 'react';

const useTableOptions = ({
  shouldUseLocalStorage = true,
  initialState = { linesToShow: 1 },
  isTableColumnsSearchEnabled = false,
  disableTableSorting = false,
}: {
  shouldUseLocalStorage?: boolean;
  initialState?: { linesToShow: number };
  isTableColumnsSearchEnabled?: boolean;
  disableTableSorting?: boolean;
}) => {
  const [regularState, setRegularState] = useState(initialState);
  const [localState, setLocalState, localIsReady] = useLocalStorageState(
    'logs-table-options',
    initialState,
  );
  const state = shouldUseLocalStorage ? localState : regularState;
  const setState = shouldUseLocalStorage ? setLocalState : setRegularState;
  const isReady = shouldUseLocalStorage ? localIsReady : true;

  const setLinesToShow = (nextLinesToShow: number) => {
    setState((prevState) => ({
      ...prevState,
      linesToShow: nextLinesToShow,
    }));
  };

  return {
    disableTableSorting,
    isReady,
    isTableColumnsSearchEnabled,
    setLinesToShow,
    state,
    setState,
  };
};

export default useTableOptions;
