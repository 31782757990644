import { snakeCase, lowerCase } from 'lodash';
import { DateSelection } from 'types/DateSelection';
import { escapeRegex, getRollupByVisualization, getRollupToSecond } from '..';
import { SortOrder } from 'types/generated';

export const AGGREGATION_OPERATORS = [
  'count',
  'count_unique',
  'avg',
  'sum',
  'max',
  'min',
  'first',
  'last',
  'stddev',
  'stddvar',
  'p50',
  'p75',
  'p90',
  'p95',
  'p99',
];

export const formatSortBy = (s: string) => {
  const parts = s.split('.');
  const key = parts[parts.length - 1];

  if (key === 'timestamp') {
    return 'ts';
  }

  return snakeCase(key);
};

/**
 * Parses a query string to extract sort information
 * @param {string} queryString - The input query string
 * @returns {Object} Object containing sort information or null if no sort found
 */
export const extractSortInfo = (queryString: string) => {
  // Regular expression to match "sort by <field> <order>"
  // Handles optional spaces and different case variations
  const sortRegex = /sort\s+by\s+(\w+)\s+(asc|desc)/i;

  const match = queryString.match(sortRegex);

  if (!match) {
    return {
      hasSortBy: false,
      sortField: null,
      sortOrder: null,
    };
  }

  return {
    hasSortBy: true,
    sortField: match[1].toLowerCase(),
    sortOrder: match[2].toLowerCase(),
  };
};

/**
 * Extracts limit information from a query string
 * @param queryString - The input query string to parse
 * @returns Limit information including presence of limit and its value
 */
const extractLimitInfo = (queryString: string) => {
  // Regular expression to match "limit <number>"
  // Handles optional spaces and ensures it's a valid limit clause
  const limitRegex = /limit\s+(\d+)(?:\s*\||$)/i;

  const match = queryString.match(limitRegex);

  if (!match) {
    return {
      hasLimit: false,
      limitValue: null,
    };
  }

  return {
    hasLimit: true,
    limitValue: parseInt(match[1], 10),
  };
};

/**
 * Parses and transforms a query based on whether it contains aggregation operators.
 * @param details - The query details including query string, timestamps, and cursor.
 * @returns A new query string for the appropriate endpoint.
 */
export const parseAndTransformQueryFromAggregationToList = ({
  query,
  sort,
}: {
  query: string;
  sort: {
    sortBy: string;
    sortOrder: string;
  };
}): string => {
  // Check if the query contains any aggregation operators
  const containsAggregation = AGGREGATION_OPERATORS.some((op) =>
    new RegExp(`\\b${op}\\b`).test(query),
  );

  let transformedQuery: string;

  const sortInfo = extractSortInfo(query);
  const limitInfo = extractLimitInfo(query);
  const sortBy = sortInfo.hasSortBy
    ? sortInfo.sortField
    : formatSortBy(sort.sortBy || 'timestamp');
  const sortOrder = sortInfo.hasSortBy
    ? sortInfo.sortOrder
    : lowerCase(sort.sortOrder || SortOrder.Desc);

  const limitValue = Math.min(
    limitInfo.hasLimit ? limitInfo.limitValue : 200,
    1000,
  );

  const sortClause = `sort by ${sortBy} ${sortOrder}`;

  const limitClause = `limit ${limitValue}`;

  if (containsAggregation) {
    // Aggregation query: Remove everything after timeslice and replace with sort and limit
    const beforeAggregation = query.split(/\| ?timeslice/)[0];
    transformedQuery = `${beforeAggregation} | ${sortClause} | ${limitClause}`;
  } else {
    const parts = query
      .split('|')
      .map((part) => part.trim())
      .filter(Boolean);

    // Remove any existing sort by parts
    const cleanedParts = parts.filter(
      (part) =>
        !part.toLowerCase().startsWith('sort by') &&
        !part.toLowerCase().startsWith('limit'),
    );

    // Add new sort clause and limit
    cleanedParts.push(sortClause);
    cleanedParts.push(limitClause);

    // Join parts back together
    transformedQuery = cleanedParts.join(' | ');
  }

  return escapeRegex(transformedQuery);
};

const removeAggregation = (query: string): string => {
  // Split the query into parts by pipe
  const parts = query.split('|').map((part) => part.trim());

  // Process each part
  const cleanedParts = parts.filter((part) => {
    // Skip parts that start with aggregation operators
    const firstWord = part.split(/\s+/)[0];
    if (AGGREGATION_OPERATORS.includes(firstWord)) {
      return false;
    }

    // Skip parts that contain "by"
    if (part.match(/\bby\s+/)) {
      return false;
    }

    return true;
  });

  // Join the parts back together
  return cleanedParts.join(' | ');
};

const ensureTimeslice = (query: string, defaultStep = 15): string => {
  // Split the query into parts by pipe
  const parts = query.split('|').map((part) => part.trim());

  // Check if timeslice exists
  const hasTimeslice = parts.some((part) => part.startsWith('timeslice'));

  if (!hasTimeslice) {
    // Only add timeslice if it doesn't exist
    parts.push(`timeslice ${defaultStep}s`);
  }

  return parts.join(' | ');
};

/**
 * Transforms a query for sparkline visualization by replacing aggregation with count
 * @param query - The original query string
 * @returns A new query string transformed for sparkline visualization
 */
export const parseAndTransformQueryForSparkline = ({
  query,
  date,
}: {
  query: string;
  date: DateSelection;
}): string => {
  const parts = query
    .split('|')
    .map((part) => part.trim())
    .filter(Boolean);

  // Remove any existing sort by parts
  const cleanedParts = parts.filter(
    (part) =>
      !part.toLowerCase().startsWith('sort by') &&
      !part.toLowerCase().startsWith('limit'),
  );

  const queryWithoutSort = cleanedParts.join(' | ');

  let modifiedQuery = removeAggregation(queryWithoutSort);
  const step = getRollupByVisualization(date, 'bar');
  modifiedQuery = ensureTimeslice(modifiedQuery, step);
  modifiedQuery = `${modifiedQuery} | count by (_timeslice, level)`;
  modifiedQuery = escapeRegex(modifiedQuery);
  return modifiedQuery;
};

const parseFiltersToKeyExists = (
  filterString: string,
): {
  hasKeyExists: boolean;
  keyExists: Record<string, number>;
} => {
  // Initialize result
  const result = {
    hasKeyExists: false,
    keyExists: {} as Record<string, number>,
  };

  // Check if string is empty or undefined
  if (!filterString) {
    return result;
  }

  // Extract source and attributes
  const parts = filterString.split(' and ');
  let source = '';
  const attributes: string[] = [];

  // Pattern to match @attribute:TYPE format
  const keyExistsPattern = /^@[a-zA-Z_]+:([A-Z]+)$/;

  parts.forEach((part) => {
    part = part.trim();
    if (part.startsWith('source=')) {
      source = part.split('=')[1].replace(/"/g, '');
    } else if (keyExistsPattern.test(part)) {
      result.hasKeyExists = true;
      attributes.push(part);
    }
  });

  // Only process if we found both source and keyExists attributes
  if (result.hasKeyExists && source) {
    attributes.forEach((attr) => {
      const [attrName, attrType] = attr.replace('@', '').split(':');
      const key = `${source}:!:${attrName}:!:${attrType}`;
      result.keyExists[key] = 1;
    });
  }

  return result;
};

export const removeTimesliceInQuery = (str: string) => {
  return str.replace(/timeslice\s+[^|]+\|/, '').trim();
};

/**
 * Parses and modifies aggregation clauses in query strings.
 * If aggregation only contains _timeslice, replaces it with _everything.
 * Otherwise, removes _timeslice from the aggregation list.
 *
 * @param {string} queryString - The input query string
 * @returns {string} Modified query string
 */
export const modifyAggregationForFreeTextInstant = (queryString: string) => {
  if (!queryString) return queryString;

  // Find the aggregation part (after 'by' keyword)
  const byMatch = queryString.match(/\|.+by\s*\((.*?)\)/);
  if (!byMatch) return queryString;

  const operator = queryString.match(/\|([^|]*?)by/)[1].trim();

  if (!operator) return queryString;

  // Get the aggregation fields
  const aggregationContent = byMatch[1];
  const fields = aggregationContent
    .split(',')
    .map((field: string) => field.trim())
    .filter((field: string) => field.length > 0);

  // Check if _timeslice is present
  const hasTimeslice = fields.includes('_timeslice');
  if (!hasTimeslice) return queryString;

  // If _timeslice is the only field, replace with _everything
  if (fields.length === 1) {
    return queryString.replace(
      /\|.+by\s*\((.*?)\)/,
      `| ${operator} by (__everything__)`,
    );
  }

  // Remove _timeslice from the fields list
  const updatedFields = fields.filter(
    (field: string) => field !== '_timeslice',
  );
  const newAggregation = updatedFields.join(', ');

  return queryString.replace(
    /\|.+by\s*\((.*?)\)/,
    `| ${operator} by (${newAggregation})`,
  );
};

export const doesFreeTextHasAggregation = (query: string) => {
  return AGGREGATION_OPERATORS.some((op) =>
    new RegExp(`\\b${op}\\b`).test(query),
  );
};

export const checkTimesliceInAggregate = (query: string) => {
  const byMatch = query.match(/\|.+by\s*\((.*?)\)/);
  if (!byMatch) return false;

  const aggregationContent = byMatch[1];
  const fields = aggregationContent
    .split(',')
    .map((field: string) => field.trim())
    .filter((field: string) => field.length > 0);

  return fields.includes('_timeslice');
};

export const getAppropriateTabForFreeText = (query: string) => {
  const hasAggregation = doesFreeTextHasAggregation(query);
  const timeSliceRegex = /timeslice\s+\d+/;
  const hasTimeslice = timeSliceRegex.test(query);

  if (hasAggregation && hasTimeslice) {
    const hasTimesliceInAggregate = checkTimesliceInAggregate(query);

    if (hasTimesliceInAggregate) {
      return '/timeseries';
    } else {
      return '/table';
    }
  } else if (hasAggregation && !hasTimeslice) {
    return '/table';
  } else {
    return '/';
  }
};

export const extractStepFromQuery = (query: string) => {
  // Match pattern: timeslice followed by space and time value
  const timeSliceRegex = /timeslice\s+(\d+(?:ms|[smhdwMy]))/i;
  const match = query.match(timeSliceRegex);

  if (!match) {
    return 5;
  }

  return getRollupToSecond(match[1]);
};
