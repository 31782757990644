import {
  IconWithLabel,
  Loader,
  PopoverTriggerV2,
  ResourceWithStatusCode,
  Resizer,
  ResizerOrientation,
  RightSidebar,
  ErrorMessage,
} from 'components';
import { FacetPickerValuesItemPopoverPanel } from '../FacetPicker';
import dayjs from 'dayjs';
import {
  useLocalStorageState,
  useMap,
  useRequest,
  useTracesState,
} from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { describeTrace, getSpanMetrics } from 'requests';
import { useNavigate } from 'react-router-dom';
import { ChartGridKeysState, Span, SpanMetrics, Trace } from 'types';
import { formatDurationNs, isSpanRoot } from 'utils';
import TraceSidebarActiveSpan from './TraceSidebarActiveSpan';
import TraceSidebarCursorTooltip from './TraceSidebarCursorTooltip';
import TraceSidebarLatencyTooltip from './TraceSidebarLatencyTooltip';
import TraceSidebarMain from './TraceSidebarMain';
import { PopoverPosition } from 'components/PopoverTriggerV2';
import {
  useServicesPageStateContext,
  useTracesPageStateContext,
} from 'context';
import { apmErrors } from 'utils/error/apmErrors';

const getTotalDurationInNs = (spans: Span[]): number => {
  let minStartTimeNs: number = null;
  let maxEndTimeNs: number = null;

  spans.forEach((span) => {
    const { endTimeNs, startTimeNs } = span;
    if (!maxEndTimeNs || endTimeNs > maxEndTimeNs) {
      maxEndTimeNs = endTimeNs;
    }

    if (!minStartTimeNs || startTimeNs < minStartTimeNs) {
      minStartTimeNs = startTimeNs;
    }
  });

  if (maxEndTimeNs && minStartTimeNs) {
    return maxEndTimeNs - minStartTimeNs;
  }

  return 0;
};

type Props = {
  applyFilterOnTracesPage?: boolean;
  chartGridKeysState: ChartGridKeysState;
  close: VoidFunction;
  colorsByServiceName: { [key: string]: string };
  trace: Trace;
  tracesState: ReturnType<typeof useTracesState>;
};

const TraceSidebar = ({
  applyFilterOnTracesPage,
  chartGridKeysState,
  close,
  colorsByServiceName,
  trace,
  tracesState,
}: Props) => {
  const [error, setError] = useState({
    describeTraces: null,
  });
  const { tracesState: tracesPageState } = useTracesPageStateContext();
  const { customerFilter } = useServicesPageStateContext();

  const spanMetricsMap = useMap({});

  const fetchSpanMetrics = (span: Span) => {
    const { endTimeNs, durationNs, service, name, spanId } = span;
    if (spanMetricsMap.state[spanId]) {
      return new Promise((resolve) => {
        resolve(spanMetricsMap.state[spanId] as SpanMetrics);
      });
    }

    return getSpanMetrics({
      endTimeNs,
      latencyNs: durationNs,
      serviceHash: service.hash,
      spanName: name,
    }).then((result) => {
      spanMetricsMap.add(spanId, result);
      return result;
    });
  };

  const { dateState, selectedFacetValuesByNameState } = tracesState;
  const [date] = dateState;
  const navigate = useNavigate();

  const [height, setHeight] = useLocalStorageState(
    'traceSidebarMainContentHeight',
    360,
  );
  const onResize = ({ deltaY }) => {
    setHeight((prevHeight) => Math.max(prevHeight - deltaY, 120));
  };

  const describeTraceRequest = useRequest(
    (args) =>
      describeTrace(args).then(({ spans, traceMetrics }) => ({
        rootSpan: spans.find(isSpanRoot),
        spans,
        traceMetrics,
      })),
    true,
    true,
  );

  const activeTrace = useMemo(
    () => describeTrace.result || trace,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [describeTrace.result],
  );

  const { span, traceId } = activeTrace;
  const rootSpan = describeTraceRequest.result?.rootSpan || null;
  const traceMetrics = describeTraceRequest.result?.traceMetrics || null;

  const spans = useMemo(
    () => describeTraceRequest.result?.spans || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [describeTraceRequest.result],
  );

  const totalDurationInNs = useMemo(() => getTotalDurationInNs(spans), [spans]);

  const [hoveredSpanId, setHoveredSpanId] = useState<string>(null);
  const [clickedSpanId, setClickedSpanId] = useState<string>(trace.span.spanId);

  const clickedSpan = useMemo(
    () => spans.find((span) => span.spanId === clickedSpanId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clickedSpanId, describeTraceRequest.result],
  );

  const hoveredSpan = useMemo(
    () => spans.find((span) => span.spanId === hoveredSpanId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoveredSpanId, describeTraceRequest.result],
  );

  const setClickedSpanIdHandler = (nextClickedSpanId: string) => {
    setClickedSpanId((prevClickedSpanId) =>
      nextClickedSpanId === prevClickedSpanId ? null : nextClickedSpanId,
    );
  };

  const { endpoint, method, statusCode } = clickedSpan || {};
  const shouldShowResourceWithStatusCode = endpoint && method && statusCode;

  const startTimeDayJs = dayjs(Math.round(span.startTimeNs / 1000000));

  useEffect(() => {
    describeTraceRequest
      .call({ traceId, endTimeNs: trace.span.endTimeNs })
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, describeTraces: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          describeTraces: { message: apmErrors.describeTraces },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const TRACE_ID = 'traceId';
  return (
    <RightSidebar
      className="trace-sidebar"
      close={close}
      title={`${trace.span.name.split('\n')[0]}`}
      dataTestId="trace-sidebar"
    >
      <ErrorMessage
        message={error?.describeTraces?.message}
        className="justify-start mt-[16px] pl-[12px]"
      />
      <div className="trace-sidebar__trace-id">
        <PopoverTriggerV2
          popover={({ close }) => (
            <FacetPickerValuesItemPopoverPanel
              close={close}
              excludeFacetValue={() => {
                if (applyFilterOnTracesPage) {
                  tracesPageState.selectedFacetValuesByNameState.excludeFacetValue(
                    {
                      name: TRACE_ID,
                      value: trace.traceId,
                    },
                  );
                  if (customerFilter) {
                    tracesPageState.setCustomerFilter(customerFilter);
                  }
                  navigate(`/apm/traces/list`);
                } else {
                  selectedFacetValuesByNameState.excludeFacetValue({
                    name: TRACE_ID,
                    value: trace.traceId,
                  });
                }
              }}
              label={`${TRACE_ID}:${trace.traceId}`}
              selectOnlyFacetValue={() => {
                if (applyFilterOnTracesPage) {
                  tracesPageState.selectedFacetValuesByNameState.selectOnlyFacetValue(
                    {
                      name: TRACE_ID,
                      value: trace.traceId,
                    },
                  );
                  if (customerFilter) {
                    tracesPageState.setCustomerFilter(customerFilter);
                  }
                  navigate(`/apm/traces/list`);
                } else {
                  selectedFacetValuesByNameState.selectOnlyFacetValue({
                    name: TRACE_ID,
                    value: trace.traceId,
                  });
                }
              }}
              value={trace.traceId}
            ></FacetPickerValuesItemPopoverPanel>
          )}
          position={PopoverPosition.BOTTOM}
        >
          <div className="trace-sidebar__trace-id__label">Trace Id:</div>
          <div className="trace-sidebar__trace-id" data-testid="trace-id-label">
            {trace.traceId}
          </div>
        </PopoverTriggerV2>
      </div>
      <div className="trace-sidebar__time">
        <div className="trace-sidebar__time__left">
          {shouldShowResourceWithStatusCode ? (
            <div className="trace-sidebar__time__item">
              <ResourceWithStatusCode
                endpoint={endpoint}
                method={method}
                statusCode={statusCode}
              />
            </div>
          ) : null}
          <div className="trace-sidebar__time__item">
            <IconWithLabel
              icon={<AiOutlineClockCircle size={14} />}
              label={
                rootSpan
                  ? `${formatDurationNs(
                      totalDurationInNs,
                      1,
                      2,
                    )} Total Duration`
                  : null
              }
            />
          </div>
          <div className="trace-sidebar__time__item">
            {rootSpan ? (
              <TraceSidebarLatencyTooltip
                fetchSpanMetrics={fetchSpanMetrics}
                label="trace"
                span={rootSpan}
                spanMetrics={spanMetricsMap.state[rootSpan.spanId]}
              />
            ) : null}
          </div>
        </div>
        <div className="trace-sidebar__time__right">
          <span>
            {`on ${startTimeDayJs.format('MMM D, YYYY H:mm:ss.SSS')} `}
          </span>
          <span>{`(${startTimeDayJs.fromNow()})`}</span>
        </div>
      </div>
      <Loader
        className="trace-sidebar__body"
        isLoading={describeTraceRequest.isLoading}
      >
        <div className="trace-sidebar__body__top">
          {traceMetrics ? (
            <TraceSidebarMain
              clickedSpanId={clickedSpanId}
              colorsByServiceName={colorsByServiceName}
              hoveredSpanId={hoveredSpanId}
              setClickedSpanId={setClickedSpanIdHandler}
              setHoveredSpanId={setHoveredSpanId}
              spans={spans}
              traceMetrics={traceMetrics}
            />
          ) : null}
          <Resizer
            onMouseMove={onResize}
            orientation={ResizerOrientation.horizontal}
          />
        </div>
        {clickedSpan ? (
          <div
            className="trace-sidebar__body__bottom"
            style={{ height: `${height}px` }}
          >
            <TraceSidebarActiveSpan
              applyFilterOnTracesPage={applyFilterOnTracesPage}
              chartGridKeysState={chartGridKeysState}
              colorsByServiceName={colorsByServiceName}
              fetchSpanMetrics={fetchSpanMetrics}
              isRootSpan={rootSpan?.spanId === clickedSpan?.spanId}
              key={clickedSpan.spanId}
              span={clickedSpan}
              spans={spans}
              spanMetrics={spanMetricsMap.state[clickedSpan?.spanId]}
              traceMetrics={traceMetrics}
              tracesState={tracesState}
            />
          </div>
        ) : null}
        {hoveredSpanId ? (
          <TraceSidebarCursorTooltip
            colorsByServiceName={colorsByServiceName}
            hoveredSpan={hoveredSpan}
            hoveredSpanId={hoveredSpanId}
            totalDurationInNs={totalDurationInNs}
          />
        ) : null}
      </Loader>
    </RightSidebar>
  );
};

export default TraceSidebar;
