import { DateSelection, MetricsQueriesDataProps, QueryDataProps } from 'types';
import {
  buildKfuseQlForAnomaly,
  buildKfuseQlForForecast,
  buildKfuseQlForOutlier,
  getAnomaliesPromQL,
  getColorForOneSeries,
  getForecastPromQL,
  getForecastSeasonalParams,
  getForecastSeasonalParamsForKfuseQl,
  getFunctionParams,
  getLogsFunctionNameParams,
} from 'utils';

import { CompareToPreviousProps, TimeseriesExplorerPromqlProps } from './types';
import { MetricsExplorerFunctionListProps } from '../types';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';
import { Series } from 'uplot';

export const compareToPreviousList = [
  { label: 'Hour', value: '1h', isActive: false },
  { label: 'Day', value: '1d', isActive: false },
  { label: 'Week', value: '1w', isActive: false },
  { label: 'Month', value: '1M', isActive: false },
  { label: 'Quarter', value: '3M', isActive: false },
];

export const getCompareToPreviousList = () => {
  return compareToPreviousList.map((item) => ({
    ...item,
    isActive: false,
  }));
};

export const predefinedFunctions: MetricsExplorerFunctionListProps[] = [
  {
    label: 'Basic Anomalies',
    value: 'anomalies',
    algorithm: 'basic',
    isActive: false,
    default: { window: '5m', bound: '1' },
  },
  {
    label: 'Agile Anomalies',
    value: 'anomalies',
    algorithm: 'agile',
    isActive: false,
    default: { bound: '1' },
  },
  {
    label: 'Robust Anomalies',
    value: 'anomalies',
    algorithm: 'robust',
    isActive: false,
    default: { window: '5m', bound: '1' },
  },
  {
    label: 'Agile Robust Anomalies',
    value: 'anomalies',
    algorithm: 'agile-robust',
    isActive: false,
    default: { seasonality: '1', bound: '1' },
  },
  {
    label: 'Outliers',
    value: 'outliers',
    algorithm: 'dbscan',
    isActive: false,
    default: { tolerance: '0.8' },
  },
  {
    label: 'Linear Forecast',
    value: 'forecast',
    algorithm: 'linear',
    isActive: true,
    default: { window: '1h' },
  },
  {
    label: 'Seasonal Forecast',
    value: 'forecast',
    algorithm: 'seasonal',
    isActive: false,
    default: { seasonality: '1' },
  },
];

export const getPredefinedFunctions =
  (): MetricsExplorerFunctionListProps[] => {
    return predefinedFunctions.map((item) => ({
      ...item,
      isActive: false,
    }));
  };

export const getTimeDiffInSeconds = (code: string) => {
  switch (code) {
    case '1h':
      return 3600;
    case '1d':
      return 86400;
    case '1w':
      return 604800;
    case '1M':
      return 2592000;
    case '3M':
      return 7776000;
    default:
      return 0;
  }
};

export const getPreviousTimeRangeWithPromql = (
  compareToPrev: CompareToPreviousProps[],
  date: DateSelection,
  promql: string,
  logql: string,
): TimeseriesExplorerPromqlProps[] => {
  const promqlQueries: TimeseriesExplorerPromqlProps[] = [];
  const { startTimeUnix, endTimeUnix } = date;

  compareToPrev.forEach(({ isActive, label, value }) => {
    if (isActive) {
      const timeDiff = getTimeDiffInSeconds(value);
      const newStartTimeUnix = startTimeUnix - timeDiff;
      const newEndTimeUnix = endTimeUnix - timeDiff;

      promqlQueries.push({
        date: { startTimeUnix: newStartTimeUnix, endTimeUnix: newEndTimeUnix },
        logql,
        promql,
        label: `${label.toLocaleLowerCase()}_ago`,
      });
    }
  });

  return promqlQueries;
};

export const getFunctionListWithPromql = ({
  activeFunctionList,
  promqlQueries,
}: {
  activeFunctionList: MetricsExplorerFunctionListProps[];
  promqlQueries: TimeseriesExplorerPromqlProps[];
}): TimeseriesExplorerPromqlProps[] => {
  promqlQueries.forEach(({ promql, date }, idx) => {
    activeFunctionList.forEach((item) => {
      if (item.value === 'anomalies') {
        const { algorithm, default: defaultValue } = item;
        const params = getFunctionParams(KfuseQlFunction.ANOMALIES);
        // change the algorithm
        params[0].value = algorithm;

        if (algorithm === 'agile-robust') {
          // change the seasonality
          params[1].value = defaultValue.seasonality;
        }
        const newPromql = getAnomaliesPromQL({
          func: { name: KfuseQlFunction.ANOMALIES, params },
          isRangeVector: false,
          query: promql,
          date,
        });
        promqlQueries[idx].promql = newPromql;
        promqlQueries[idx].isAnomaly = true;
      }
      if (item.value === 'forecast') {
        const { algorithm, default: defaultValue } = item;
        const params = getFunctionParams(KfuseQlFunction.FORECAST);
        const seasonalityParams = getForecastSeasonalParams();
        params.push(seasonalityParams[0]);
        params[0].value = algorithm;
        if (algorithm === 'seasonal') {
          params[1].value = defaultValue.seasonality;
        }
        promqlQueries[idx].promql = getForecastPromQL({
          func: { name: KfuseQlFunction.FORECAST, params },
          query: promql,
          date,
        });
        promqlQueries[idx].isForecast = true;
        promqlQueries[idx].forecastSeasonality =
          algorithm === 'seasonal' ? defaultValue.seasonality : '';
      }
      if (item.value === 'outliers') {
        const { default: defaultValue } = item;
        promqlQueries[idx].isOutliers = true;
        promqlQueries[idx].promql =
          `dbscan(${promql}, ${defaultValue.tolerance}, 0.6)`;
      }
    });
  });
  return promqlQueries;
};

export const getFunctionListWithKfuseQl = ({
  activeFunctionList,
  kfuseqlQueries,
}: {
  activeFunctionList: MetricsExplorerFunctionListProps[];
  kfuseqlQueries: TimeseriesExplorerPromqlProps[];
}): TimeseriesExplorerPromqlProps[] => {
  kfuseqlQueries.forEach(({ logql, date, metricName, steps }, idx) => {
    activeFunctionList.forEach((item) => {
      if (item.value === 'anomalies') {
        const { algorithm, default: defaultValue } = item;
        const params = getLogsFunctionNameParams(
          KfuseQlFunction.ANOMALIES,
          steps,
        );
        params[0].value = algorithm;
        if (algorithm === 'agile-robust') {
          // change the seasonality
          params[1].value = defaultValue.seasonality;
        }
        const anomalyKfuseQl = buildKfuseQlForAnomaly({
          fn: { name: KfuseQlFunction.ANOMALIES, params },
          operation: `_${metricName}`,
          step: steps,
        });
        const newKfuseql = `${logql} | ${anomalyKfuseQl.functionStr}`;
        kfuseqlQueries[idx].logql = newKfuseql;
        kfuseqlQueries[idx].isAnomaly = true;
      }
      if (item.value === 'forecast') {
        const { algorithm, default: defaultValue } = item;
        const params = getLogsFunctionNameParams(
          KfuseQlFunction.FORECAST,
          steps,
        );
        params[0].value = algorithm;
        if (algorithm === 'seasonal') {
          const seasonalityParams = getForecastSeasonalParamsForKfuseQl();
          params.push(seasonalityParams[0]);
          params[1].value = defaultValue.seasonality;
        }
        const forecastKfuseQl = buildKfuseQlForForecast({
          date,
          fn: { name: KfuseQlFunction.FORECAST, params },
          operation: `_${metricName}`,
          step: steps,
        });
        const newKfuseql = `${logql} | ${forecastKfuseQl.functionStr}`;
        kfuseqlQueries[idx].logql = newKfuseql;
        kfuseqlQueries[idx].isForecast = true;
        kfuseqlQueries[idx].forecastSeasonality =
          algorithm === 'seasonal' ? defaultValue.seasonality : '';
      }
      if (item.value === 'outliers') {
        const params = getLogsFunctionNameParams(
          KfuseQlFunction.OUTLIERS,
          steps,
        );
        const outlierKfuseQl = buildKfuseQlForOutlier({
          fn: { name: KfuseQlFunction.OUTLIERS, params },
          step: steps,
          operation: `_${metricName}`,
        });
        const newKfuseql = `${logql} | ${outlierKfuseQl.functionStr}`;
        kfuseqlQueries[idx].logql = newKfuseql;
        kfuseqlQueries[idx].isOutliers = true;
      }
    });
  });
  return kfuseqlQueries;
};

export const getPreviousTimeWithLabel = (
  compareToPrev: CompareToPreviousProps[],
  date: DateSelection,
) => {
  const { startTimeUnix, endTimeUnix } = date;
  const previousQueries: Array<{
    date: DateSelection;
    label: string;
  }> = [];

  compareToPrev.forEach(({ isActive, label, value }) => {
    if (isActive) {
      const timeDiff = getTimeDiffInSeconds(value);
      const newStartTimeUnix = startTimeUnix - timeDiff;
      const newEndTimeUnix = endTimeUnix - timeDiff;

      previousQueries.push({
        date: { startTimeUnix: newStartTimeUnix, endTimeUnix: newEndTimeUnix },
        label: `${label.toLocaleLowerCase()}_ago`,
      });
    }
  });

  return previousQueries;
};

/**
 * Transform the data from the API to the format that the chart needs
 */
export const transformMetricsExplorerData = (
  datasets: MetricsQueriesDataProps[],
  promqlQueries: TimeseriesExplorerPromqlProps[],
  seriesBitmap: { [key: string]: boolean },
): MetricsQueriesDataProps => {
  const queryData: MetricsQueriesDataProps = {};

  datasets.forEach((dataset, promqlIndex) => {
    Object.keys(dataset).forEach((key, idx) => {
      if (!dataset[key].data) return;
      const { series } = dataset[key].data;
      const { label } = promqlQueries[promqlIndex];

      const newSeries = series.map((item: any) => {
        const seriesShow =
          seriesBitmap[item.label] !== undefined
            ? seriesBitmap[item.label]
            : true;

        return {
          ...item,
          dash: label ? [4, 8] : undefined,
          label: label ? `${label}(${item.label})` : item.label,
          stroke: getColorForOneSeries({}, idx + promqlIndex + 1),
          show: seriesShow,
        };
      });

      queryData[key] = dataset[key];
      queryData[key].data.series = newSeries;
    });
  });

  return queryData;
};

export const transformLogqlExplorerData = (
  datasets: MetricsQueriesDataProps['query']['data'][],
  logqlQueries: TimeseriesExplorerPromqlProps[],
  seriesBitmap: { [key: string]: boolean },
): QueryDataProps => {
  const queryData: QueryDataProps = {};

  datasets.forEach((dataset, logqlIndex) => {
    const { series, ...rest } = dataset;
    const { label } = logqlQueries[logqlIndex];

    const newSeries = series.map((item: any) => {
      const seriesShow =
        seriesBitmap[item.label] !== undefined
          ? seriesBitmap[item.label]
          : true;

      return {
        ...item,
        dash: label ? [4, 8] : undefined,
        label: label ? `${label}(${item.label})` : item.label,
        stroke: getColorForOneSeries({}, logqlIndex + 1),
        show: seriesShow,
      };
    });

    const queryKey = String.fromCharCode(97 + logqlIndex);
    queryData[`query_${queryKey}`] = {
      range: { series: newSeries, ...rest },
      isLoading: false,
      meta: undefined,
    };
  });
  return queryData;
};

export const getSeriesShownState = (series: Series[]) => {
  if (!series) return {};

  const seriesBitmap: { [key: string]: boolean } = {};
  series.forEach((item) => {
    seriesBitmap[item.label] = item.show;
  });

  return seriesBitmap;
};

export const convertArrayDatasetsToObject = (array: any[]) => {
  const transformedData: { [key: string]: any } = {};
  array.map((item) => {
    const keys = Object.keys(item);
    keys.map((key) => {
      transformedData[key] = item[key];
    });
  });
  return transformedData;
};
