import {
  ErrorBoundary,
  Paginator,
  Table,
  TableSearch,
  TooltipTrigger,
  usePaginator,
  useTableSearch,
  useTableSort,
} from 'components';
import { useRequest } from 'hooks';
import { colorsByAlertState, dateTimeFormat } from 'kfuse-constants';
import dayjs from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { getGrafanaAlertsByGroup } from 'requests';
import { DateSelection } from 'types/DateSelection';

import { AlertEventProps } from '../types';
import { AlertsServiceCorrelations } from '../AlertsServiceCorrelation';

const columns = (onAnalyze: (row: any, rowIndex: number) => void) => [
  {
    key: 'state',
    label: 'State',
    renderCell: ({ row }: { row: any }) => {
      const status = row.state ? row.state.toLowerCase() : '';
      return (
        <div
          className="chip alerts__list__status-chip"
          style={{
            backgroundColor: colorsByAlertState[status]
              ? colorsByAlertState[status]
              : colorsByAlertState['no_data'],
          }}
        >
          {status}
        </div>
      );
    },
  },
  {
    key: 'labels',
    label: 'Labels',
    renderCell: ({ row }: { row: any }) => {
      const newLabels = { ...row.labels };
      delete newLabels.alertname;
      return JSON.stringify(newLabels);
    },
  },
  {
    key: 'value',
    label: 'Value',
    renderCell: ({ row }: { row: any }) => {
      if (!row.value) return;

      if (!isNaN(row.value)) {
        return Number(row.value).toString();
      }
      return row.value;
    },
  },
  {
    key: 'activeAt',
    label: 'Created At',
    renderCell: ({ row }: { row: any }) => {
      const date = dayjs(row.activeAt);
      if (date.year() > 1970) {
        return date.format(dateTimeFormat);
      }
    },
  },
  {
    key: 'Action',
    label: 'Action',
    renderCell: ({ row, rowIndex }: { row: any; rowIndex: number }) => {
      return (
        <div className="alerts__details__events-list__action">
          <TooltipTrigger tooltip="Analyze correlation">
            <button
              className="button button--blue"
              onClick={() => onAnalyze(row, rowIndex)}
            >
              Analyze
            </button>
          </TooltipTrigger>
        </div>
      );
    },
  },
];

const emptyArray = [];

const AlertsDetailsEventsList = ({
  date,
  enableAnalysis = false,
  fileNameForAlertEvents,
  kfSource,
  promqls,
  ruleGroup,
  ruleName,
  serviceHash,
}: {
  date: DateSelection;
  enableAnalysis: boolean;
  fileNameForAlertEvents?: string;
  kfSource?: string;
  promqls: string;
  ruleName: string;
  ruleGroup: string;
  serviceHash?: string;
}): ReactElement => {
  const [activeAlertEvent, setActiveAlertEvent] =
    useState<AlertEventProps | null>(null);
  const grafanaAlertsByGroupRequest = useRequest(getGrafanaAlertsByGroup);

  const onAnalyze = (row: AlertEventProps) => {
    setActiveAlertEvent(row);
  };

  const tableSearch = useTableSearch({
    rows: grafanaAlertsByGroupRequest.result || emptyArray,
  });
  const tableSort = useTableSort({
    columns: columns(onAnalyze),
    rows: tableSearch.searchedRows,
  });
  const paginator = usePaginator({ rows: tableSort.sortedRows });

  const filteredColumns = enableAnalysis
    ? columns(onAnalyze)
    : columns(onAnalyze).filter((column: any) => column.key !== 'Action');

  useEffect(() => {
    grafanaAlertsByGroupRequest.call({
      groupName: ruleGroup,
      ruleName,
      fileName: fileNameForAlertEvents,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promqls]);

  return (
    <>
      {activeAlertEvent && (
        <ErrorBoundary errorMessage={<h1>Failed to load analysis</h1>}>
          <AlertsServiceCorrelations
            activeAlertEvent={activeAlertEvent}
            date={date}
            promqls={promqls}
            kfSource={kfSource}
            serviceHash={serviceHash}
          />
        </ErrorBoundary>
      )}
      <div className="alerts__details__events-list box-shadow">
        <div>
          <TooltipTrigger tooltip="Alert events analysis">
            <div className="pt-1 text-lg font-semibold">Events</div>
          </TooltipTrigger>
        </div>
        {grafanaAlertsByGroupRequest.result && (
          <>
            <TableSearch
              className="dashboard__list__search"
              placeholder="Search Alert Events..."
              tableSearch={tableSearch}
            />
            <Table
              activeRowId={activeAlertEvent ? activeAlertEvent.rowIndex : null}
              className="table--bordered table--bordered-cells table--padded alerts__details__events-list__table"
              columns={filteredColumns}
              externalTableSort={tableSort}
              isSortingEnabled
              rows={paginator.paginatedRows}
              rowClassName="alerts__details__events-list__table-row"
            />
            <div className="table-footer">
              <Paginator paginator={paginator} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AlertsDetailsEventsList;
