import { alertParseConditionValue } from '../../AlertsCreateCondition';
import { AlertQueryItem } from '../../types';
import { AlertsTracesParsedProps } from '../../AlertsCreateTraces/type';

export const parseEventsAlertQuery = ({
  data,
  annotations,
}: {
  data: AlertQueryItem[];
  annotations: any;
}): AlertsTracesParsedProps => {
  const parsed: AlertsTracesParsedProps = {};

  const parsedThreshold = parseEventsAlertThresholdCondition({ data });
  Object.assign(parsed, parsedThreshold);

  const extraData = JSON.parse(annotations.extraData || '{}');
  if (extraData.queryType === 'query') {
    const query = extraData.queries[0];
    parsed.condition.queryKey = `Query (${query.queryKey})`;
    parsed.queries = [query];
    parsed.formulas = [];
  }

  if (!extraData.queryType) {
    parsed.condition.queryKey = `Query (a)`;
  }

  return parsed;
};

const parseEventsAlertThresholdCondition = ({
  data,
}: {
  data: AlertQueryItem[];
}) => {
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;
  const { expr } = model;

  const condition = alertParseConditionValue(data);

  return { condition, promqls: [expr], relativeTimeRange };
};
