import {
  DashboardPanelProps,
  DashboardPanelTargetsProps,
  DashboardPanelType,
} from 'types/Dashboard';
import {
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types/LogsMetricsQueryBuilder';
import {
  buildLogql,
  buildLogqlFormula,
  defaultLogsQuery,
  logsQueryDecodeTransform,
} from 'utils';
import { getPanelFieldConfig, nextRefId } from './panelManipulation';

export const getQueryBuilderTransformedLogQl = (
  panel: DashboardPanelProps,
): {
  customerFilter?: { key: string; value: string };
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
} | null => {
  const queries: LogsMetricQueryProps[] = [];
  const formulas: LogsMetricForumlaProps[] = [];
  if (!panel) {
    queries.push({ ...defaultLogsQuery, queryKey: 'a' });
    return { formulas, queries };
  }

  const { targets, fieldConfig } = panel;
  const kAnnotations = fieldConfig?.defaults?.custom?.kAnnotations as string;
  if (!kAnnotations) {
    targets.forEach((target) => {
      const query = target.expr;
      queries.push({
        ...defaultLogsQuery,
        logql: query,
        queryKey: `${target.refId}`,
        showInput: true,
      });
    });
    return { formulas, queries };
  }

  const transformed = logsQueryDecodeTransform(kAnnotations);
  return transformed;
};

export const getLogqlforQueryAndFormula = (
  queries: LogsMetricQueryProps[],
  formulas: LogsMetricForumlaProps[],
) => {
  const logqlQueries: { expr: string; hide: boolean }[] = [];
  queries.map((query: LogsMetricQueryProps) => {
    if (!query.isActive) return;

    if (query.showInput) {
      logqlQueries.push({ expr: query.logql, hide: false });
      return;
    }

    const logql = buildLogql({ queries: [query], step: query.step || '1s' });
    logqlQueries.push({ expr: logql[0], hide: false });
  });

  formulas.map((formula: LogsMetricForumlaProps) => {
    if (!formula.isActive) return;

    const affectedQueries = queries.filter((query) =>
      formula.expression.includes(query.queryKey),
    );
    const formulaStep = affectedQueries[0].step || '1m';
    const logql = buildLogqlFormula({ formulas, queries, step: formulaStep });
    logqlQueries.push({ expr: logql[0], hide: false });
  });

  return logqlQueries.map(({ expr, hide }) => ({
    expr: decodeURIComponent(expr),
    hide,
  }));
};

const getPanelTargetsForLogs = ({
  formulas,
  panelType,
  prevTargets,
  queries,
  uid,
}: {
  formulas: LogsMetricForumlaProps[];
  panelType: DashboardPanelType;
  prevTargets: DashboardPanelTargetsProps[];
  queries: LogsMetricQueryProps[];
  uid: string;
}): DashboardPanelTargetsProps[] => {
  const targets: DashboardPanelTargetsProps[] = [];
  const range = panelType === DashboardPanelType.TIMESERIES ? true : false;

  const logqlQueries = getLogqlforQueryAndFormula(queries, formulas);
  const commonTargetProps = {
    datasource: { type: 'loki', uid },
    editorMode: 'code',
    range,
    instant: range ? false : true,
  };
  logqlQueries.forEach((query, idx) => {
    const refId = nextRefId(targets[idx - 1]?.refId);
    const target: DashboardPanelTargetsProps = {
      ...(prevTargets.find((target) => target.refId === refId) || {}),
      ...commonTargetProps,
      ...query,
      refId,
    };
    targets.push(target);
  });

  return targets;
};

export const getEditedPanelForLogs = ({
  editedPanel,
  formulas,
  queries,
  queryLangType,
  uid,
}: {
  editedPanel: DashboardPanelProps;
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
  queryLangType: string;
  uid: string;
}): DashboardPanelProps => {
  const targets = getPanelTargetsForLogs({
    formulas,
    panelType: editedPanel.type,
    prevTargets: editedPanel.targets,
    queries,
    uid,
  });
  const prevAnnotations =
    editedPanel.fieldConfig?.defaults?.custom?.kAnnotations;
  const parsedPrevAnnotations = prevAnnotations
    ? JSON.parse(prevAnnotations)
    : null;
  const annotations = {
    ...parsedPrevAnnotations,
    queries,
    formulas,
    queryLangType,
    isTransformed: false,
  };

  const fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });

  fieldConfig.defaults.custom.kAnnotations = JSON.stringify(annotations);
  const datasource = { type: 'loki', uid };
  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };
  return {
    fieldConfig,
    options,
    ...editedPanel,
    isEdited: true,
    targets,
    datasource,
  };
};

export const getEditedPanelForLogsEvents = ({
  editedPanel,
  expr,
}: {
  editedPanel: DashboardPanelProps;
  expr: string;
}): DashboardPanelProps => {
  const targets = [
    {
      expr,
      refId: 'A',
      datasource: { type: 'loki', uid: '' },
      editorMode: 'code',
      range: false,
      instant: true,
    },
  ];

  const fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });
  const datasource = { type: 'loki', uid: '' };
  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };

  return {
    fieldConfig,
    options,
    ...editedPanel,
    isEdited: true,
    targets,
    datasource,
  };
};
