import { FacetPicker } from 'components';
import { useSelectedFacetValuesByNameState, useToggle } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { LogsSavedViewsFacet } from './utils';
import { ValueCount } from 'types/generated';

const LogsSavedViewsSidebarFacetGroup = ({
  filterProperties,
  facetNames,
  forceExpanded,
  request,
  selectedFacetValuesByNameState,
}: {
  filterProperties: { [key: string]: ValueCount[] };
  facetNames: { name: string; forceExpanded?: boolean }[];
  forceExpanded?: boolean;
  request: any;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
}): ReactElement => {
  const [lastRefreshedAt, setLastRefreshedAt] = useState(null);
  const expandedToggle = useToggle();
  const expanded = forceExpanded || expandedToggle.value;

  useEffect(() => {
    setLastRefreshedAt(new Date());
  }, [selectedFacetValuesByNameState.state, filterProperties]);

  const handlersByName = (name: string) => ({
    excludeFacetValue: (value: string) => {
      selectedFacetValuesByNameState.excludeFacetValue({ name, value });
    },
    selectOnlyFacetValue: (value: string) => {
      selectedFacetValuesByNameState.selectOnlyFacetValue({ name, value });
    },
    toggleFacetValue: (value: string, allValues: Array<string>) => {
      selectedFacetValuesByNameState.toggleFacetPickerValueCheckbox({
        facetName: name,
        facetValueToToggle: value,
        allFacetValues: allValues,
      });
    },
  });

  const clearFacetHandler = (name: string) => () => {
    selectedFacetValuesByNameState.clearFacet(name);
  };

  return (
    <div>
      {expanded && (
        <div className="events__sidebar__facet-group__facet-names">
          {facetNames.map(
            (facet: { name: string; forceExpanded?: boolean }) => {
              const name = facet.name;
              return (
                <FacetPicker
                  forceExpanded={facet.forceExpanded || false}
                  clearFacet={clearFacetHandler(name)}
                  key={name}
                  lastRefreshedAt={lastRefreshedAt}
                  name={name}
                  renderName={(s) => {
                    const facet = LogsSavedViewsFacet.find((f) => f.name === s);
                    return facet.label.replace(/_+/g, ' ');
                  }}
                  request={request(name)}
                  selectedFacetValues={
                    selectedFacetValuesByNameState.state[name] || {}
                  }
                  renderValue={(label) => {
                    return label;
                  }}
                  {...handlersByName(name)}
                />
              );
            },
          )}
        </div>
      )}
    </div>
  );
};

export default LogsSavedViewsSidebarFacetGroup;
