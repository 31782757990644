import { TimeseriesRenderer } from 'components';
import React, { ReactElement, useMemo } from 'react';
import {
  DateSelection,
  DashboardPanelProps,
  TimeseriesDataProps,
  UplotExtended,
  LegendTypes,
} from 'types';
import { setDateRangeOnChartZoom } from 'utils';

import { DashboardPanelNoData } from '../../components';
import {
  checkIfDataNotAvailable,
  getPanelStyles,
  mapTimeseriesDrawStyle,
} from '../../utils';

const WIDTH_LEFT_RIGHT_OFFSET = 32;

const DashboardEditGraphTimeseries = ({
  baseWidth,
  onDateChange,
  panel,
  dataFormatter,
}: {
  baseWidth: number;
  onDateChange: (date: DateSelection) => void;
  panel: DashboardPanelProps;
  dataFormatter: () => TimeseriesDataProps;
}): ReactElement => {
  const { fieldConfig } = panel;

  const panelStyles = useMemo(
    () => getPanelStyles(panel.fieldConfig?.defaults),
    [panel],
  );

  const defaultChartTypes = useMemo(
    () => mapTimeseriesDrawStyle(fieldConfig?.defaults?.custom),
    [fieldConfig],
  );

  // Get the viewport height
  const viewportHeight = window.innerHeight;
  const thirtyVh = viewportHeight * 0.3;
  const combinedChartData = useMemo(() => dataFormatter(), [dataFormatter]);

  const hooks = useMemo(() => {
    return [
      {
        hook: (u: UplotExtended) => setDateRangeOnChartZoom(u, onDateChange),
        type: 'setSelect',
      },
      ...(combinedChartData.hooks || []),
    ];
  }, [onDateChange, combinedChartData.hooks]);

  return (
    <>
      {!checkIfDataNotAvailable(
        combinedChartData.isLoading ? null : combinedChartData,
      ) ? (
        <TimeseriesRenderer
          bands={combinedChartData.bands || []}
          chartTypes={defaultChartTypes}
          chartData={combinedChartData || { data: [], series: [] }}
          hooks={hooks}
          isLoading={combinedChartData.isLoading}
          layoutType="modal"
          size={{
            width: baseWidth - WIDTH_LEFT_RIGHT_OFFSET,
            height: Math.max(thirtyVh, 170),
          }}
          legend={{ legendType: LegendTypes.COMPACT_ONE_LINE }}
          styles={panelStyles}
          tooltipType={'compact'}
          unit={panel.fieldConfig?.defaults?.unit || 'number'}
        />
      ) : (
        <DashboardPanelNoData
          panelSize={{
            heightContainer: Math.max(thirtyVh, 170),
            width: baseWidth - WIDTH_LEFT_RIGHT_OFFSET,
            height: Math.max(thirtyVh, 170),
          }}
        />
      )}
    </>
  );
};

export default DashboardEditGraphTimeseries;
