import {
  DateSelection,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  QueryLangType,
} from 'types';

import { buildKfuseQl } from './kfuseqlBuilder';
import { getLogqlLogicalOperatorMap } from '../LogqlBuilder/LogqlBuilderFormula';
import { escapeLinesTabsAndQuotes } from '..';

export const kfuseqlBuilderFormula = ({
  customerFilter,
  date,
  formulas,
  queries,
  queryLangType,
  step,
  instant,
}: {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
  queryLangType: string;
  step: string;
  instant?: boolean;
}): { kfuseQl: string; operation: string }[] => {
  const kfuseQls =
    queryLangType === QueryLangType.ADVANCED_KFUSEQL_SEARCH
      ? queries.map((query) => ({
          kfuseQl: escapeLinesTabsAndQuotes(query.fuseql),
          operation: '',
        }))
      : buildKfuseQl({
          customerFilter,
          date,
          queries,
          step,
          instant,
        });
  const queryKeys = queries.map((query) => query.queryKey);

  const logqlBitmap: { [key: string]: { kfuseQl: string; operation: string } } =
    {};
  kfuseQls.forEach(({ kfuseQl, operation }, index) => {
    logqlBitmap[queryKeys[index]] = { kfuseQl, operation };
  });

  const formulaLogqls: { kfuseQl: string; operation: string }[] = [];
  formulas.forEach((formula: LogsMetricForumlaProps) => {
    if (formula.isValid) {
      let expression = '';
      let operation = '';
      const logicalOperatorMap = getLogqlLogicalOperatorMap(formula.expression);
      const formulaLength = formula.expression.length;
      for (let i = 0; i < formulaLength; i++) {
        if (logicalOperatorMap.length > 0) {
          const logicalOperator = logicalOperatorMap.some((operator) => {
            return i >= operator[0] && i <= operator[1];
          });

          if (logicalOperator) {
            expression += formula.expression.charAt(i);
            continue;
          }
        }

        const char = formula.expression.charAt(i);
        if (queryKeys.includes(char)) {
          expression += `( ${logqlBitmap[char].kfuseQl} )`;
          operation = logqlBitmap[char].operation;
        } else {
          expression += char;
        }
      }
      formulaLogqls.push({ kfuseQl: expression, operation });
    }
  });

  return formulaLogqls;
};
