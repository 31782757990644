export const escapePeriod = (s: string) => s.replace(/\./g, '\\\\.');

export const escapeBackslash = (s: string) => s.replace(/\\/g, '\\\\');

export const escapeDoubleQuote = (s: string) => s.replace(/"/g, '\\"');

export const escapeRegex = (s: string) =>
  escapePeriod(escapeDoubleQuote(escapeNextLineAndTabs(escapeBackslash(s))));

export const escapeNextLineAndTabs = (s: string) =>
  s.replace(/\n/g, '\\n').replace(/\t/g, '\\t');

export const escapeLinesTabsAndQuotes = (s: string) =>
  escapeNextLineAndTabs(escapeDoubleQuote(s));

export const getIsInQuotes = (s: string) =>
  s.length && s[0] === '"' && s[s.length - 1] === '"';

export const getWithoutQuotes = (s: string) => {
  if (getIsInQuotes(s)) {
    return s.slice(1, s.length - 1);
  }

  return s;
};

export const unescapeDoubleQuotes = (str: string): string =>
  str.replace(/\\"/g, '"');
