import { Button, useColumnsState, useToaster } from 'components';
import { Input, Loader } from 'components';
import React, { ReactElement, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useLogsState } from 'hooks/useLogsState';
import useRequest from 'hooks/useRequest';
import createWorkbook from 'requests/createWorkbook';

const LogsSaveQueryFolderExport = ({
  customColumnsState,
  closeModal,
  getWorkbooksRequest,
  logsState,
}: {
  customColumnsState: ReturnType<typeof useColumnsState>;
  closeModal: () => void;
  getWorkbooksRequest: ReturnType<typeof useRequest>;
  logsState: ReturnType<typeof useLogsState>;
}): ReactElement => {
  const [exportDetails, setExportDetails] = useState({
    folder: '',
    subFolder: '',
    queryName: '',
  });
  const toastmaster = useToaster();
  const createWorkbookRequest = useRequest(createWorkbook);

  const handleSaveQuery = () => {
    const onSuccess = () => {
      toastmaster.addToast({
        status: 'success',
        text: `${exportDetails.queryName} successfully saved`,
      });
      closeModal();
      getWorkbooksRequest.call();
    };

    createWorkbookRequest
      .call({
        name: exportDetails.queryName,
        logsState: JSON.stringify({
          ...(logsState.customerFilter && logsState.customerFilter.value
            ? { customerFilter: logsState.customerFilter }
            : {}),
          customColumns: customColumnsState?.state || {},
          filters: logsState?.filtersState?.state,
          freeText: logsState?.freeText,
        }),
        folderName: exportDetails.folder,
        subfolderName: exportDetails.subFolder,
        isFreeText: !!logsState?.freeText,
      })
      .then(onSuccess, () => {});
  };

  const isSaveDisabled = useMemo(() => {
    const isValid =
      exportDetails.folder.trim() &&
      exportDetails.queryName.trim() &&
      exportDetails.subFolder.trim();

    return !isValid;
  }, [exportDetails.folder, exportDetails.queryName, exportDetails.subFolder]);

  return (
    <div
      className="logs__analytics__export-dashboard"
      data-testid="export-query"
    >
      <div className="logs__analytics__export-dashboard__header">
        <div className="logs__analytics__export-dashboard__header__title">
          Save Query in Folder
        </div>
        <div
          className="logs__analytics__export-dashboard__header__close"
          onClick={closeModal}
        >
          <X />
        </div>
      </div>

      <Loader isLoading={false}>
        <div>
          <div className="logs__analytics__export-dashboard__label">
            Folder *
          </div>
          <Input
            onChange={(val) => {
              setExportDetails({ ...exportDetails, folder: val });
            }}
            placeholder="Folder Name"
            type="text"
            value={exportDetails.folder}
            size="2"
            variant="default"
          />
        </div>
        <div>
          <div className="logs__analytics__export-dashboard__label">
            Sub Folder *
          </div>
          <Input
            onChange={(val) => {
              setExportDetails({ ...exportDetails, subFolder: val });
            }}
            placeholder="Sub Folder Name"
            type="text"
            size="2"
            variant="default"
            value={exportDetails.subFolder}
          />
        </div>
        <div>
          <div className="logs__analytics__export-dashboard__label">
            Enter the Name For Query *
          </div>
          <Input
            onChange={(val) => {
              setExportDetails({ ...exportDetails, queryName: val });
            }}
            placeholder="Give Query a name"
            type="text"
            size="2"
            variant="default"
            value={exportDetails.queryName}
          />
        </div>

        <div className="logs__analytics__export-dashboard__footer">
          <Button variant="outline" size="sm" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="default"
            size="sm"
            className="ml-2"
            onClick={handleSaveQuery}
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </div>
      </Loader>
    </div>
  );
};

export default LogsSaveQueryFolderExport;
