import { ReactElement } from 'react';
import { Layout } from 'react-grid-layout';
import { DataFrameMeta, DataTransformerConfig } from 'utils';

import {
  useDashboardState,
  useDashboardTemplateState,
} from '../screens/Dashboard/hooks';
import { DateSelection } from './DateSelection';

export enum DashboardPanelType {
  BARCHART = 'barchart',
  EMBED = 'embed',
  FINGERPRINT = 'fingerprint',
  GAUGE = 'gauge',
  GEOMAP = 'geomap',
  GRAFANA_POLYSTAT_PANEL = 'grafana-polystat-panel',
  GRAFANA_SINGLESTAT_PANEL = 'grafana-singlestat-panel',
  LEGACY_GRAPH = 'graph',
  LOG_ANALYTICS_TABLE = 'log-analytics-table',
  GROUP = 'group',
  IMAGE = 'image',
  HEATMAP = 'heatmap',
  LOGS = 'logs',
  PLACEHOLDER = 'placeholder',
  ROW = 'row',
  TABLE = 'table',
  TEXT = 'text',
  TOP_LIST = 'toplist',
  TREEMAP = 'treemap',
  TIMESERIES = 'timeseries',
  PIECHART = 'piechart',
  STAT = 'stat',
  TRACES = 'traces',
  TRACES_ANALYTICS = 'traces-analytics',
}

export enum DashboardStreamType {
  LOG = 'logs',
  METRIC = 'metrics',
  RUM = 'rum',
  TRACE = 'traces',
  EVENT = 'event',
}

export const SUPPORTED_PANELS = Object.values(DashboardPanelType);

export type WidgetItemProps = {
  icon?: ReactElement;
  label: string;
  name: DashboardPanelType;
};

export type WidgetProps = {
  label: string;
  list: WidgetItemProps[];
  name: string;
};

export type DashboardPanelTargetsProps = {
  datasource?: { type: string; uid: string };
  editorMode?: string;
  expr: string;
  expression?: string;
  format?: string;
  hide?: boolean;
  instant?: boolean;
  interval?: string;
  legendFormat?: string;
  range?: boolean;
  refId: string;
  type?: 'query' | 'formula';
};

export type DashboardPanelProps = {
  collapsed?: boolean;
  datasource?: { type: string; uid: string };
  description?: string;
  fieldConfig?: {
    defaults: DashboardFieldConfigProps;
    overrides?: DashboardPanelOverrideProps[];
  };
  gridPos: Layout;
  id?: number;
  isEdited?: boolean; // Not part of Grafana Dashboard model
  links?: { targetBlank: boolean; title: string; url: string }[];
  options?: {
    colorMode?: string;
    content?: string;
    legend?: { calcs: string[]; displayMode: string; placement: string };
    mode?: 'markdown' | 'html';
    textMode?: string;
    tooltip?: { mode: string; sort: 'desc' | 'asc' };
  };
  panels?: DashboardPanelProps[];
  repeat?: string;
  repeatValue?: string;
  repeatedRowId?: number;
  targets?: DashboardPanelTargetsProps[];
  timeFrom?: string;
  timeShift?: string;
  title: string;
  transformations?: DashboardPanelTableTransformProps[];
  type: DashboardPanelType;
};

export type DashboardProps = {
  description: string;
  id?: string;
  isEdited?: boolean; // Not part of Grafana Dashboard model
  links?: DashboardLinkProps[];
  templating?: { list: DashboardTemplateProps[] };
  time?: {
    from: string;
    to: string;
  };
  title: string;
  uid?: string;
};

export type DashboardLinkProps = {
  asDropdown: boolean;
  icon: string;
  includeVars: boolean;
  keepTime: boolean;
  tags: string[];
  targetBlank: boolean;
  title: string;
  tooltip: string;
  type: string;
  url: string;
};

export type DashboardTemplateProps = {
  allValue: string;
  current: {
    isNone?: boolean;
    selected: boolean;
    text: string | string[];
    value: string;
  };
  definition: string;
  hide?: number;
  includeAll: boolean;
  label: string;
  multi: boolean;
  name: string;
  options: { selected?: boolean; text: string; value: string }[];
  query: { qryType?: number; query: string; refId: string } | string;
  regex?: string;
  scope?: {
    matchers?: string[];
    label?: string;
    metric?: string;
  };
  type: string;
};

export type DashboardFieldConfigProps = {
  color?: { mode: string };
  custom?: {
    barAlignment?: number;
    drawStyle?: string;
    fillOpacity?: number;
    gradientMode?: 'none' | 'opacity' | 'hue';
    lineInterpolation?: 'linear' | 'smooth';
    lineWidth?: number;
    pointSize?: number;
    scaleDistribution?: { log: number; type: 'linear' | 'log' };
    showPoints?: 'auto' | 'always' | 'never';
    stacking?: { group: string; mode: string };
  };
  decimals?: number;
  mappings?: DashboardPanelConfigMappingProps[];
  thresholds?: DashboardPanelConfigThresholdProps;
  unit?: string;
};

export type DashboardReloadPanelsProps = {
  [key: string]: boolean | DashboardReloadPanelsProps;
};

export type DashboardPanelComponentProps = {
  baseHeight?: number;
  baseWidth?: number;
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardTemplateState: ReturnType<typeof useDashboardTemplateState>;
  disableEditPanel?: boolean;
  disableDeletePanel?: boolean;
  isInView?: boolean;
  nestedIndex?: string;
  panel: DashboardPanelProps;
  panelIndex: number;
  panelSize: { height: number; width: number; heightContainer: number };
};

export type DashboardPanelConfigMappingProps = {
  options: {
    from?: number;
    to?: number;
    match?: string;
    result?: DashboardPanelConfigMappingOptionsProps;
    [key: string]: DashboardPanelConfigMappingOptionsProps;
  };
  type: 'value' | 'range' | 'special' | 'regex';
};

export type DashboardPanelConfigThresholdProps = {
  mode: 'absolute' | 'percentage' | 'palette-classic';
  steps: Array<{ color: string; value: number }>;
};

export type DashboardPanelConfigMappingOptionsProps = {
  color: string;
  index: number;
  text: string;
};

export type DashboardPanelStatTextProps = {
  color?: string;
  prefix?: string;
  suffix?: string;
  text: string;
};

export type DashboardPanelTableTransformProps = {
  id: string;
  options: any;
};

export type DashboardPanelOverrideProps = {
  matcher: { id: string; options: any };
  properties: Array<{ id: string; value: any }>;
};

export type DashboardTemplateValueProps = {
  [key: string]: string | string[];
};

export type DashboardPromqlWithMetaProps = {
  addEncoding?: boolean;
  allowFutureTime?: boolean;
  date: DateSelection;
  meta: DataFrameMeta;
  promqlQuery: string;
  transformer: DataTransformerConfig[];
  useCeilInAdjustingTime?: boolean;
};

export type DashboardLogsWithMetaProps = {
  addEncoding?: boolean;
  allowFutureTime?: boolean;
  date: DateSelection;
  meta: DataFrameMeta;
  logql: string;
  kfuseql: string;
  transformer: DataTransformerConfig[];
  useCeilInAdjustingTime?: boolean;
  width?: number;
};

export type DashboardAdvanceFunctionOptionProps = {
  function: string;
  kfuse_forecast_algo?: string;
  kfuse_forecast_seasonality?: string;
  kfuse_anomalies_algo?: string;
  kfuse_anomalies_window?: string;
  kfuse_anomalies_bound?: string;
  kfuse_anomalies_seasonality?: string;
  kfuse_outlier_algo?: string;
  kfuse_outlier_tolerance?: string;
};

export enum DashboardAdvanceFunctionMappedName {
  KFUSE_FUNCTION = 'kfuse_function',
  KFUSE_FORECAST_ALGO = 'kfuse_forecast_algo',
  KFUSE_FORECAST_SEASONALITY = 'kfuse_forecast_seasonality',
  KFUSE_ANOMALIES_ALGO = 'kfuse_anomalies_algo',
  KFUSE_ANOMALIES_WINDOW = 'kfuse_anomalies_window',
  KFUSE_ANOMALIES_BOUND = 'kfuse_anomalies_bound',
  KFUSE_ANOMALIES_SEASONALITY = 'kfuse_anomalies_seasonality',
  KFUSE_OUTLIER_ALGO = 'kfuse_outlier_algo',
  KFUSE_OUTLIER_TOLERANCE = 'kfuse_outlier_tolerance',
}
