import { startCase } from 'lodash';
import { eventsFacets } from 'kfuse-constants';
import dayjs from 'dayjs';
import queryEventService from 'requests/queryEventService';
import { buildEventsFilter } from 'requests/utils';
import {
  DashboardPanelType,
  DateSelection,
  EventCountDataProps,
  TimeSeries,
} from 'types';
import {
  DataTransformerConfig,
  convertArrayToObject,
  getRollupByVisualization,
  getRollupToSecond,
} from 'utils';
import { SearchState } from 'hooks';

type Args = {
  aggregation: string;
  aggregationField: string;
  date: DateSelection;
  groupBys: string[];
  instant?: boolean;
  rollUpSeconds: number;
  transformer: DataTransformerConfig[];
  returnFormat?: DashboardPanelType;
  returnQueryString?: boolean;
  limitToValue: number;
  limitTo: string;
  measure: string;
  searchBarState: SearchState['searchBarState'];
};

const aggregateTimeSeriesForEvent = async ({
  date,
  groupBys,
  instant = false,
  rollUpSeconds,
  limitToValue,
  limitTo,
  measure,
  searchBarState,
  returnQueryString = false,
}: Args): Promise<TimeSeries[] | string> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const rollup = rollUpSeconds
    ? getRollupToSecond(rollUpSeconds as unknown as string)
    : getRollupByVisualization(date, 'bar');

  const { filterByFacets, searchTerms, sidebarFilters } =
    searchBarState.filterState;

  const filterQuery = buildEventsFilter(
    convertArrayToObject(filterByFacets),
    searchTerms,
    sidebarFilters,
  );

  const groupBysArray = groupBys
    .filter((item) => item !== '*')
    .map((groupBy) => {
      return eventsFacets.includes(groupBy) ? `@${groupBy}` : groupBy;
    });

  const countUnique = measure === '*' ? null : measure ? `"@${measure}"` : null;

  const queryStr = `
    {
    eventCounts(
        countUnique: ${countUnique},
        durationSecs: ${durationSecs},
        filter: ${filterQuery},
        ${instant ? `roundSecs: ${rollup}` : ''}
        ${
          groupBysArray.length > 0
            ? `groupBys: ["${groupBysArray.join('","')}"]`
            : ''
        },
        timestamp: "${endTime.format()}",
        topOrBottomK: { type: ${startCase(limitTo)}, k: ${limitToValue} },
        limit: 9999
      ) {
        timestamp
        count
        keys
        values
      }
    }
  `;

  if (returnQueryString) return queryStr;

  const eventLevelCounts = await queryEventService<
    EventCountDataProps[],
    'eventCounts'
  >(queryStr)
    .then((data) => data.eventCounts || [])
    .then((res) => {
      const resp = res.map((item) => {
        return {
          BucketStart: dayjs(item.timestamp).unix(),
          Value: item.count,
          GroupVal: groupBysArray.reduce((acc, groupBy, idx) => {
            return {
              ...acc,
              [groupBy]: item.values[idx],
            };
          }, {}),
        };
      });

      return resp;
    });

  return eventLevelCounts;
};

export default aggregateTimeSeriesForEvent;
