import { Filter, FormulaState, SearchState } from 'hooks';
import {
  DashboardPanelProps,
  DashboardPanelTargetsProps,
  DashboardPanelType,
} from 'types/Dashboard';
import { getPanelFieldConfig } from './panelManipulation';
import {
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types/selectedFacets';
import { isDurationFacet } from 'screens/Rum/utils';
import { RumEventType } from 'screens/Rum';

export const getRumPanelExprToQueryAndFormula = (
  panel: DashboardPanelProps,
): {
  queries: SearchState[];
  formulas: FormulaState[];
  applicationFilter: string;
  eventType: RumEventType;
  idSearch: string;
} => {
  const queries: SearchState[] = [];
  const formulas: FormulaState[] = [];
  if (!panel?.targets[0]?.expr) {
    return {
      queries: undefined,
      formulas: undefined,
      applicationFilter: '',
      eventType: RumEventType.VIEW,
      idSearch: '',
    };
  }
  const expr = panel.targets[0].expr;
  const parsedExpr = JSON.parse(expr);

  if (parsedExpr.query) {
    queries.push(parsedExpr.query);
  }

  if (parsedExpr.formula) {
    formulas.push(parsedExpr.formula);
    queries.push(...(parsedExpr.queries || []));
  }

  if (parsedExpr.queries && !parsedExpr.query) {
    queries.push(...parsedExpr.queries);
  }

  if (parsedExpr.formulas && !parsedExpr.formula) {
    formulas.push(...(parsedExpr.formulas || []));
  }

  return {
    queries,
    formulas,
    applicationFilter: parsedExpr.applicationFilter,
    eventType: parsedExpr.eventType,
    idSearch: parsedExpr.idSearch,
  };
};

export const getRumPanelExpr = ({
  editedPanel,
  applicationFilter,
  facetRegex,
  eventType,
  queries,
  filters,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  idSearch,
}: {
  editedPanel: DashboardPanelProps;
  applicationFilter: string;
  facetRegex: FacetRegexTerm[];
  eventType: string;
  filters: Filter[];
  queries: SearchState[];
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  idSearch: string;
}): string => {
  const prevExpr = editedPanel.targets[0].expr;
  const parsedExpr = JSON.parse(prevExpr);

  const newQueries = [...queries];
  newQueries[0].searchBarState = { applicationFilter, filters, idSearch };

  const exprObject = {
    ...parsedExpr,
    applicationFilter,
    facetRegex,
    eventType,
    queries: newQueries,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    idSearch,
    unit: isDurationFacet(queries[0]?.multiAggregations?.[0]?.measure)
      ? 'ns'
      : 'number',
  };

  return JSON.stringify(exprObject);
};

export const getEditedPanelForRum = ({
  newExpr,
  editedPanel,
}: {
  editedPanel: DashboardPanelProps;
  newExpr: string;
}): DashboardPanelProps => {
  const panelType = editedPanel.type;

  const targets: DashboardPanelTargetsProps[] = [];
  const range = panelType === DashboardPanelType.TIMESERIES ? true : false;
  const fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });
  const datasource = { type: 'rum', uid: '' };

  const commonTargetProps = {
    datasource,
    editorMode: 'code',
    range,
    instant: range ? false : true,
    refId: 'a',
    expr: newExpr,
  };
  targets.push(commonTargetProps);

  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };

  const panel = {
    ...editedPanel,
    targets,
    fieldConfig,
    options,
    datasource,
    isEdited: true,
  };

  return panel;
};
