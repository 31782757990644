import {
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  useColumnsState,
  useToaster,
} from 'components';
import { useKeyExistsState, useRequest, useSubscriptionRequest } from 'hooks';
import delimiter from 'kfuse-constants/delimiter';
import debounce from 'lodash.debounce';
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FingerprintQueryProps, LabelsProps, RequestResult } from 'types';

import LogsFingerprintsListBuilder from './LogsFingerprintsListBuilder';
import LogsFingerprintsListHeader from './LogsFingerprintsListHeader';
import LogsFingerprintsListItem from './LogsFingerprintsListItem';
import {
  useLogsState,
  useQueryScheduler,
  useQuerySchedulerEffect,
} from './hooks';

const LogsFingerprintsList = ({
  customColumnsState,
  columnsState,
  fingerprintQueryState,
  getLogStackedBarCountsUsingMetricsRequest,
  getFacetNamesRequest,
  getFpListRequest,
  handleFingerPrintsListMount,
  labels,
  logsState,
  queryScheduler,
  tabFetchedRef,
}: {
  customColumnsState: ReturnType<typeof useKeyExistsState>;
  columnsState: ReturnType<typeof useColumnsState>;
  fingerprintQueryState: ReturnType<typeof useState<FingerprintQueryProps>>;
  getLogStackedBarCountsUsingMetricsRequest: ReturnType<
    typeof useSubscriptionRequest
  >;
  getFacetNamesRequest: RequestResult;
  getFpListRequest: ReturnType<typeof useRequest>;
  handleFingerPrintsListMount?: () => void;
  labels: LabelsProps;
  logsState: ReturnType<typeof useLogsState>;
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  tabFetchedRef: MutableRefObject<Record<string, number>>;
}): ReactElement => {
  const { addToast } = useToaster();
  const [fingerprintQuery, setFingerprintQuery] = fingerprintQueryState;
  const { groupBy, sortBy, sortOrder } =
    fingerprintQuery as FingerprintQueryProps;

  const [error, setError] = useState({
    getFpList: null,
  });

  const fetchFpList = () => {
    getLogStackedBarCountsUsingMetricsRequest.close();
    return getFpListRequest
      .call({
        logsState,
        groupBy: groupBy.map((f: string) => f.split(delimiter)[1]),
        sortBy,
        sortOrder,
      })
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getFpList: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getFpList: { message: 'Failed to fetch fp list' },
        }));
      });
  };

  const onFingerprintGroupByChange = (val: string[]) => {
    const newSortOrder = val.length === 0 ? 'Desc' : sortOrder;
    let newSortBy = val.length === 0 ? '' : sortBy;
    const isSortKeyInGroupBy = val.find(
      (f: string) => f.split(delimiter)[1] === sortBy,
    );
    if (!isSortKeyInGroupBy) newSortBy = '';
    const newVal =
      val.length === 0 ? [`Core${delimiter}source${delimiter}string`] : val;

    if (val.length === 0 && groupBy.length === 1 && newVal[0] === groupBy[0]) {
      addToast({ text: 'Core:source is the default group by', status: 'info' });
      return;
    }

    setFingerprintQuery({
      ...fingerprintQuery,
      groupBy: newVal,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    });
    getFpListRequest.call({
      logsState,
      groupBy: newVal.map((f: string) => f.split(delimiter)[1]),
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    });
  };

  const onSortFingerprintChange = (
    sortKey: string,
    sortDir: FingerprintQueryProps['sortOrder'],
  ) => {
    setFingerprintQuery({
      ...fingerprintQuery,
      sortBy: sortKey,
      sortOrder: sortDir,
    });
    getFpListRequest.call({
      logsState,
      groupBy: groupBy.map((f: string) => f.split(delimiter)[1]),
      sortBy: sortKey,
      sortOrder: sortDir,
    });
  };

  const { clearTabFetched } = useQuerySchedulerEffect({
    cb: fetchFpList,
    logsState,
    queryScheduler,
    tab: 'fingerprint',
    tabFetchedRef,
  });

  const elementRef = useRef(null);
  const [lastIndex, setLastIndex] = useState(100);

  const onScroll = debounce(() => {
    const element = elementRef.current;
    if (element) {
      const { offsetHeight, scrollHeight, scrollTop } = element;
      if (scrollTop > scrollHeight - offsetHeight - 40) {
        setLastIndex((prevLastIndex) => prevLastIndex + 100);
      }
    }
  }, 200);

  const fingerprintGroupByKeys = useMemo(() => {
    if (!groupBy || groupBy.length == 0) {
      return ['source'];
    }

    const groupByKeys = groupBy.map((f: string) => f.split(delimiter)[1]);
    return groupByKeys.map((f: string) => f.replace(/^@/, ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fingerprintQuery]);

  const overlayMessageProps: OverlayMessageProps = error.getFpList
    ? {
        isActive: true,
        iconName: 'warning',
        message: <>{error?.getFpList?.message || 'Error.'}</>,
      }
    : { isActive: false };

  useEffect(() => {
    handleFingerPrintsListMount?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex--direction-col width-100-per height-100-per flex">
      {getFacetNamesRequest && (
        <LogsFingerprintsListBuilder
          customColumnsState={customColumnsState}
          columnsState={columnsState}
          fingerprintQuery={fingerprintQuery}
          labels={labels}
          logsState={logsState}
          getFacetNamesRequest={getFacetNamesRequest}
          onFingerprintGroupByChange={onFingerprintGroupByChange}
        />
      )}
      <Loader
        className="logs__fingerprints-list"
        isLoading={getFpListRequest.isLoading}
        onScroll={onScroll}
        ref={elementRef}
        dataTestId="logs-fingerprints-table"
      >
        <div className="logs__fingerprints-list__body font-robotoMono">
          <LogsFingerprintsListHeader
            fingerprintGroupByKeys={fingerprintGroupByKeys}
            fingerprintQuery={fingerprintQuery}
            onSortFingerprintChange={onSortFingerprintChange}
          />

          <OverlayMessage {...overlayMessageProps}>
            {(getFpListRequest.result || [])
              .slice(0, lastIndex)
              .map((fingerprint, idx: number) => (
                <LogsFingerprintsListItem
                  clearTabFetched={clearTabFetched}
                  fingerprint={fingerprint}
                  fingerprintGroupByKeys={fingerprintGroupByKeys}
                  getFpListRequest={getFpListRequest}
                  key={`${fingerprint.source}:${fingerprint.hash}:${idx}`}
                  logsState={logsState}
                />
              ))}
          </OverlayMessage>
        </div>
      </Loader>
    </div>
  );
};

export default LogsFingerprintsList;
