import classNames from 'classnames';
import { Checkbox, Picker, SearchInput } from 'components';
import React, { ReactElement, useMemo, useState } from 'react';
import useColumnsState from './useColumnsState';
import useTableOptions from './useTableOptions';

type Props = {
  className?: string;
  close: () => void;
  columnsState: ReturnType<typeof useColumnsState>;
  shouldHideLinesToShow?: boolean;
  tableOptions: ReturnType<typeof useTableOptions>;
};

const TableOptionsPopoverPanel = ({
  className,
  columnsState,
  shouldHideLinesToShow,
  tableOptions,
}: Props): ReactElement => {
  const { columns, state, toggleSelectedColumnByKey } = columnsState;
  const [tableSearch, setTableSearch] = useState('');
  const { selectedColumns } = state;

  const { isTableColumnsSearchEnabled, setLinesToShow } = tableOptions;
  const { linesToShow } = tableOptions.state;

  const onChangeHandler = (key: string) => () => {
    toggleSelectedColumnByKey(key);
  };

  const filteredColumns = useMemo(() => {
    if (!isTableColumnsSearchEnabled) {
      return columns;
    }

    return columns.filter((column) => {
      if (typeof column.label === 'string') {
        return (
          column.label.toLowerCase().indexOf(tableSearch.toLowerCase().trim()) >
          -1
        );
      }
      return (
        column.key.toLowerCase().indexOf(tableSearch.toLowerCase().trim()) > -1
      );
    });
  }, [columns, isTableColumnsSearchEnabled, tableSearch]);

  return (
    <div
      className={classNames({
        ['logs__table__toolbar__options__panel']: true,
        [className]: !!className,
      })}
    >
      {isTableColumnsSearchEnabled ? (
        <SearchInput
          containerClassName="logs__table__toolbar__options__panel__search"
          data-testid="table-header-search"
          onChange={setTableSearch}
          value={tableSearch}
          type="text"
          size="2"
        />
      ) : null}
      <div
        className="logs__table__toolbar__options__panel__section"
        data-testid="options-panel"
      >
        {filteredColumns.map((column) => (
          <div
            className="logs__table__toolbar__options__panel__item"
            key={column.key}
          >
            <Checkbox
              onChange={onChangeHandler(column.key)}
              value={Boolean(selectedColumns[column.key])}
            />
            <div className="logs__table__toolbar__options__panel__item__label">
              {column.label}
            </div>
          </div>
        ))}
      </div>
      {!shouldHideLinesToShow ? (
        <div className="logs__table__toolbar__options__panel__section">
          <div className="logs__table__toolbar__options__panel__item">
            <div className="logs__table__toolbar__options__panel__item__label">
              Lines to show
            </div>
            <Picker
              onChange={setLinesToShow}
              options={[1, 3, 5, 10].map((value) => ({
                label: String(value),
                value,
              }))}
              value={linesToShow}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TableOptionsPopoverPanel;
